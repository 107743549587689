import Icon from '@mui/material/Icon'

import Home from './Home/index'
import CreateClient from './CreateClient'
import CreateConsignee from './CreateConsignee'
import ConsigneeTable from './ConsigneeTable'
import ClientTable from './ClientTable'
import PendingJobTable from './PendingJobTable'
import CompletedJobTable from './CompletedJobTable'
import JobRelated from './MakeRequest/JobRelated'
import NonJobRelated from './MakeRequest/NonJobRelated'
import AllRequest from './AllRequest'
import PendingRequests from './PendingRequests'
import ApprovedRequests from './ApprovedRequests'
import DeclinedRequests from './DeclinedRequests'

const routes = [
  {
    type: 'singleRoute',
    singleRoute: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: 'dashboard',
    component: <Home />,
  },
  { type: 'divider', key: 'divider-0' },

  {
    type: 'title',
    title: 'FREIGHT FORWARDING',
    key: 'HOD',
  },
  {
    type: 'collapse',
    name: 'Client Management',
    key: 'client-management',
    icon: <Icon fontSize="small">groups</Icon>,
    collapse: [
      {
        name: 'Create Client',
        key: 'create-client',
        route: 'client-management/create-client/:id',
        icon: <Icon fontSize="small">group_add</Icon>,
        component: <CreateClient />,
      },
      {
        name: 'Create Consignee',
        key: 'create-consignee',
        route: 'client-management/create-consignee/:id',
        icon: <Icon fontSize="small">group_add</Icon>,
        component: <CreateConsignee />,
      },
      {
        name: 'Consignee',
        key: 'consignee',
        route: 'client-management/consignee',
        icon: <Icon fontSize="small">group</Icon>,
        component: <ConsigneeTable />,
      },
      {
        name: 'Client',
        key: 'client',
        route: 'client-management/client',
        icon: <Icon fontSize="small">group</Icon>,
        component: <ClientTable />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Job File Management',
    key: 'job-management',
    icon: <Icon fontSize="small">folder</Icon>,
    collapse: [
      {
        name: 'Pending Job Files',
        key: 'pending-job-files',
        route: 'job-management/pending-job',
        icon: <Icon fontSize="small">work_history</Icon>,
        component: <PendingJobTable />,
      },
      {
        name: 'Completed Job Files',
        key: 'completed-job-files',
        route: 'job-management/completed-job',
        icon: <Icon fontSize="small">work_off</Icon>,
        component: <CompletedJobTable />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Finance Management',
    key: 'financial-request',
    icon: <Icon fontSize="small">monetization_on</Icon>,
    collapse: [
      {
        name: 'All Requests',
        key: 'all-request',
        route: 'financial-request/all-requests',
        icon: <Icon fontSize="small">view_list</Icon>,
        component: <AllRequest />,
      },
      {
        name: 'Pending Requests',
        key: 'pending-request',
        route: 'financial-request/pending-request',
        icon: <Icon fontSize="small">hourglass_bottom</Icon>,
        component: <PendingRequests />,
      },
      {
        name: 'Approved Requests',
        key: 'approved-request',
        route: 'financial-request/approved-request',
        icon: <Icon fontSize="small">check_circle_outline</Icon>,
        component: <ApprovedRequests />,
      },
      {
        name: 'Declined Requests',
        key: 'declined-request',
        route: 'financial-request/declined-request',
        icon: <Icon fontSize="small">cancel</Icon>,
        component: <DeclinedRequests />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Make Request',
    key: 'make-request',
    icon: <Icon fontSize="small">queue</Icon>,
    collapse: [
      {
        name: 'Job Related',
        key: 'job-related',
        route: 'financial-request/make-request/job-related/:id',
        icon: <Icon fontSize="small">content_paste</Icon>,
        component: <JobRelated />,
      },
      {
        name: 'Non Job Related',
        key: 'non-job-related',
        route: 'financial-request/make-request/non-job-related',
        icon: <Icon fontSize="small">content_paste_off</Icon>,
        component: <NonJobRelated />,
      },
    ],
  },
]

export default routes
