import { useState } from 'react'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { CircularProgress, Paper } from '@mui/material'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import documentationData from './data/data'

import MDButton from 'components/MDButton'

// Firebase Hook
import { useFirestore } from 'hooks/useFirestore'
import FormFields from 'layouts/pages/account/components/FormField'

import PopSuccess from 'views/PopMessage/PopSuccess'


const CreateClient = () => {
  const [clientName, setClientName] = useState('')
  const [clientType, setClientType] = useState('')
  const [prefix, setPrefix] = useState('')
  const [rcNumber, setRcNumber] = useState('')
  const [clientCategory, setClientCategory] = useState('')
  const [businessCategory, setBusinessCategory] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [businessAddress, setBusinessAddress] = useState('')
  const [altPhone, setAltPhone] = useState('')
  const [wareHouseAddress, setWareHouseAddress] = useState('')
  const [companyEmail, setCompanyEmail] = useState('')
  const [tin, setTin] = useState('')
  const [jobOwner, setJobOwner] = useState('')
  const [jobOwnerContact, setJobOwnerContact] = useState('')
  const [registeredCompanyAddress, setRegisteredCompanyAddress] = useState('')
  const [companyPhone, setCompanyPhone] = useState('')
  const [contactName, setContactName] = useState('')
  const [AltCompanyPhone, setAltCompanyPhone] = useState('')

  const { addDocument, message, isPending } = useFirestore('clients')

  const handleSubmit = (e) => {
    e.preventDefault()

    addDocument({
      clientName,
      clientType,
      prefix,
      rcNumber,
      clientCategory,
      businessCategory,
      companyAddress,
      phone,
      businessAddress,
      altPhone,
      wareHouseAddress,
      companyEmail,
      tin,
    })
    if (message) {
      e.target.reset()
    }
  }

  const { dropdownData } = documentationData
  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox component="form" onSubmit={handleSubmit} noValidate>
          <Paper
            sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}
          //bgcolor: '#F4FBF8'
          >
            <MDBox>
              <MDTypography
                variant="h5"
                fontWeight="bold"
                sx={{ borderBottom: 1, pb: 1, pt: 4 }}
              >
                Client Details
              </MDTypography>
            </MDBox>
            <MDBox mt={3}>
              <Grid container spacing={2} mb={4}>
                <Grid item xs={12} md={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Client's Name{' '}
                  </MDTypography>
                  <TextField
                    name="clientName"
                    fullWidth
                    required
                    placeholder="Enter Company Name"
                    onChange={(e) => setClientName(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Client Type{' '}
                  </MDTypography>
                  <MDBox>
                    <Autocomplete
                      defaultValue="Client Type"
                      options={dropdownData.type}
                      onChange={(e, newValue) => {
                        setClientType(newValue)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="ClientType"
                          InputLabelProps={{ shrink: true }}
                          placeholder="Select Company Type"
                        />
                      )}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Client Prefix{' '}
                  </MDTypography>
                  <TextField
                    name="CompanyPrefix"
                    fullWidth
                    placeholder="Enter Company Prefix"
                    onChange={(e) => setPrefix(e.target.value)}
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={4}>
                <Grid item xs={12} md={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    RC/BN Number{' '}
                  </MDTypography>
                  <TextField
                    name="EmployeeID"
                    fullWidth
                    placeholder="Enter RC/BN Number"
                    onChange={(e) => setRcNumber(e.target.value)}
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Client Category{' '}
                  </MDTypography>
                  <MDBox>
                    <Autocomplete
                      defaultValue="Client Category"
                      options={dropdownData.clientCategory}
                      onChange={(e, newValue) => {
                        setClientCategory(newValue)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          placeholder="Select Client Category"
                        />
                      )}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Business Category{' '}
                  </MDTypography>
                  <MDBox>
                    <Autocomplete
                      defaultValue="Business Category"
                      options={dropdownData.businessCategory}
                      onChange={(e, newValue) => {
                        setBusinessCategory(newValue)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          placeholder="Select Business Category"
                        />
                      )}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container spacing={3} mb={4}>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Tax Identification Number{' '}
                  </MDTypography>
                  <FormFields
                    name="TaxIdentificationNumber"
                    placeholder="Enter TIN Number"
                    type="number"
                    fullWidthn
                    onChange={(e) => setTin(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Company Phone Number
                  </MDTypography>
                  <FormFields
                    name="CompanyPhoneNumber"
                    placeholder="Enter Phone Number"
                    type="number"
                    onChange={(e) => setPhone(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Alternative Company Phone Number{' '}
                  </MDTypography>
                  <FormFields
                    name="CompanyPhoneNumber"
                    placeholder="Enter Phone Number"
                    type="number"
                    onChange={(e) => setAltPhone(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} mb={4}>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Registered Company Address{' '}
                  </MDTypography>
                  <FormFields
                    value={companyAddress}
                    name="RegisteredCompanyAddress"
                    placeholder="Enter Company Address"
                    onChange={(e) => setCompanyAddress(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Business Address
                  </MDTypography>
                  <FormFields
                    value={businessAddress}
                    name="BusinessAddress"
                    placeholder="Enter Business Address"
                    onChange={(e) => setBusinessAddress(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Ware House Address
                  </MDTypography>
                  <FormFields
                    value={wareHouseAddress}
                    name="WareHouseAddress"
                    placeholder="Enter Ware House Address"
                    onChange={(e) => setWareHouseAddress(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} mb={4}>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Company Email Address{' '}
                  </MDTypography>
                  <FormFields
                    value={companyEmail}
                    name="CompanyEmail"
                    placeholder="Enter Ware House Address"
                    onChange={(e) => setCompanyEmail(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>Job Owner </MDTypography>
                  <FormFields
                    value={jobOwner}
                    name="JobOwner"
                    placeholder="Enter Job Owner"
                    onChange={(e) => setJobOwner(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Job Owner's Contact{' '}
                  </MDTypography>
                  <FormFields
                    value={jobOwnerContact}
                    name="JobOwnerContact"
                    placeholder="Enter Job Owner's Contact"
                    type="number"
                    onChange={(e) => setJobOwnerContact(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
              </Grid>
              <MDBox>
                <MDTypography
                  variant="h5"
                  fontWeight="bold"
                  sx={{ borderBottom: 1, pb: 1, pt: 4 }}
                >
                  Contact Person Details
                </MDTypography>
              </MDBox>
              <MDBox sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <MDTypography sx={{ fontSize: 13 }}>
                      Contact Name{' '}
                    </MDTypography>
                    <TextField
                      value={contactName}
                      name="ContactName"
                      fullWidth
                      placeholder="Enter Contact Name"
                      onChange={(e) => setContactName(e.target.value)}
                      inputProps={{ style: { textTransform: 'capitalize' } }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDTypography sx={{ fontSize: 13 }}>
                      Company Phone Number{' '}
                    </MDTypography>
                    <TextField
                      value={companyPhone}
                      name="CompanyPhoneNumber"
                      fullWidth
                      placeholder="Enter Phone Number"
                      type="number"
                      onChange={(e) => setCompanyPhone(e.target.value)}
                      inputProps={{ style: { textTransform: 'capitalize' } }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDTypography sx={{ fontSize: 13 }}>
                      Alternative Company Phone Number{' '}
                    </MDTypography>
                    <TextField
                      value={AltCompanyPhone}
                      name="altCompanyPhone"
                      fullWidth
                      placeholder="Enter Phone Number"
                      type="number"
                      onChange={(e) => setAltCompanyPhone(e.target.value)}
                      inputProps={{ style: { textTransform: 'capitalize' } }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDTypography sx={{ fontSize: 13 }}>
                      Registered Company Address{' '}
                    </MDTypography>
                    <TextField
                      value={registeredCompanyAddress}
                      name="CompanyAddress"
                      fullWidth
                      placeholder="Enter  Company Address"
                      onChange={(e) =>
                        setRegisteredCompanyAddress(e.target.value)
                      }
                      inputProps={{ style: { textTransform: 'capitalize' } }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox
                sx={{ pt: 6, display: 'flex', justifyContent: 'flex-end' }}
              >
                {!isPending && (
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="dark"
                    sx={{ width: '100px' }}
                  >
                    Submit
                  </MDButton>
                )}
                {isPending && (
                  <MDButton
                    variant="gradient"
                    color="dark"
                    disabled
                    sx={{ width: '100px' }}
                  >
                    <CircularProgress color="white" size={20} />
                  </MDButton>
                )}
              </MDBox>
            </MDBox>
          </Paper>
        </MDBox>
      </DashboardLayout>
      {message && <PopSuccess />}
    </div>
  )
}

export default CreateClient
