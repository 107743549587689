const selectData = {
  gender: ["Male", "Female", "Others"],
  banks: [
    "Access Bank",
    "Access Bank (Diamond)",
    "Citibank",
    "Ecobank Nigeria",
    "Dynamic Standard Bank",
    "Fidelity Bank Nigeria",
    "First Bank of Nigeria",
    "First City Monument Bank",
    "Guaranty Trust Bank",
    "Heritage Bank Plc",
    "Jaiz Bank",
    "Keystone Bank Limited",
    "Providus Bank Plc",
    "Polaris Bank",
    "Stanbic IBTC Bank Nigeria Limited",
    "Standard Chartered Bank",
    "Sterling Bank",
    "Suntrust Bank Nigeria Limited",
    "Union Bank of Nigeria",
    "United Bank for Africa",
    "Unity Bank Plc",
    "Wema Bank",
    "Zenith Bank"
  ],
  states: [
    "--Select State--",
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "FCT - Abuja",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara"
  ],
  // employeeUnit: [
  //   "Admin & Human Resource",
  //   "Documentation",
  //   "Monitoring & Compliance",
  //   "Direct Trade Input",
  //   "Finance & Accounting",
  //   "Terminal",
  //   "Managing Director",
  //   "Executive Director",
  //   "Chief Operating Officer",
  //   "Gate",
  //   "Chief Operating Officer",
  //   "Operation(H.O.D.)",
  //   "Monitoring & Compliance(H.O.D.)",
  //   "Commercial(H.O.D.)",
  //   "Finance(H.O.D.)",
  //   "Marines(H.O.D.)",
  //   "Satey(H.O.D.)",
  //   "Transport(H.O.D.)",
  //   "Field Agent",
  //   "Marine",
  //   "Dispatch",
  //   "Transport & Logistics",
  //   "Pay Officer"
  // ],
  employeeType: [
    "Full Time",
    "Contract",
    "Expatriate",
    "Consultant",
    "NYSC",
    "SIWES",
    "Exited Employee"
  ],
  employeeDepartment: [
    "-- Select Department --",
    "Admin & Human Resource",
    "Monitoring & Compliance",
    "Executive Management Office",
    "Commercial",
    "Finance and Accounting",
    "Marine",
    "Transport & Logistics",
    "Operations"
  ],
  academicQualifications: ["HND", "OND", "BSC", "BED"],
  MaritalStatus: ["Single", "Married", "Divorced"],
  employeedesignation: [
    "MD",
    "Chief Operating Officer",
    "Admin",
    "Head of Department"
  ],
  employeeLocation: [
    "Corporate Head Office",
    "lagos Office",
    "Eleme Base",
    "Warri Base",
    "Aba Base",
    "Others"
  ],
  employeeConfirmation: ["Probation", "Confirmed", "Not Confirmed"],
  numberOfChildren: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  nextOfKin: [
    "--Select Relationship--",
    "Father",
    "Mother",
    "Sister",
    "Brother",
    "Uncle",
    "Aunt",
    "Husband",
    "Wife",
    "Son",
    "Daughter",
    "Sister-In-Law",
    "Brother-In-Law",
    "Others"
  ],
  universities: [
    "--Select University--",
    "Michael Okpara University of Agriculture at Umudike (Federally owned)",
    "Abia State University in Uturu (State owned)",
    "College of Health Sciences Aba",
    "Abia State polytechnic",
    "Covenant Polytechnic, Aba",
    "Temple Gate Polytechnic, Abayi",
    "Valley View Polytechnic, Aba",
    "Gregory University Uturu",
    "Rhema University",
    "Adamawa State polytechnic, Yola",
    "Adamawa State University, Mubi",
    "Anambra State University, Anambra",
    "Nnamdi Azikiwe University",
    "the Federal Polytechnic, Oko",
    "Nwafor Orizu University of Education",
    "The Tansian University, Umunya",
    "Madonna University, Okija",
    "St Paul's University, Awka",
    "University of Uyo",
    "Maritime Academy of Nigeria, Oron",
    "Akwa Ibom State University [Oruk Anam LGA And Mkpat Enin LGA]",
    "Obong University, Obong Ntak Inyang",
    "Akwa Ibom State Polytechnic",
    "Uyo City Polytechnic",
    "Apex Polytechnic",
    "Heritage Polytechnic, Eket",
    "School of Nursing Uyo, Eket, Oron, Ikot Ekpene, Etinan",
    "Akwa Ibom State College of Education, Afaha Nsit",
    "School Of Basic Studies",
    "College of Arts & Sciences, Nnung Ukim",
    "Ritman University",
    "Sure Polytechnic, Ukanafun",
    "Abubakar Tafawa Balewa University",
    "Federal Polytechnic, Bauchi",
    "FGGC Bauchi",
    "Lys Children School",
    "College of Education - Azare",
    "National Open University Bauchi",
    "Bayelsa State College of Health Technology",
    "Federal Polytechnic, Ekowe",
    "Federal University, Otueke",
    "Institute of Science and Technology Yenagoa",
    "Niger Delta University",
    "Federal Science and Technical College",
    "Federal University of Agriculture, Makurdi",
    "Benue State University, Makurdi",
    "University of Mkar, Mkar, Gboko.",
    "Benue State Polytechnic, Ugbokolo",
    "Fidei polytechnic, Gboko",
    "Akperan Orshi college of Agriculture Yandev",
    "Federal College of Education Agasha",
    "College of Education Oju",
    "College of Education Kastina Ala.",
    "College of Education Waka-Biu",
    "Federal Training Centre, Maiduguri",
    "Federal University of Maiduguri",
    "MAI University Of Maiduguri, Maiduguri",
    "National Open University of Nigeria",
    "Ramat Polytechnic Maiduguri",
    "School of Health and Technology, Maiduguri",
    "School Of Nursing and Midwifery",
    "University of Calabar",
    "Cross River State University of Technology, Calabar",
    "Ibrahim Babangida College of Agriculture, Obubra",
    "Cross River State College of Education, akamkpa",
    "College of Health Technology, Iboko, Okpoma, Yala",
    "Technical college Ugep",
    "Federal University of Petroleum Resources, Effurun",
    "Delta State University (Abraka Campus, Oleh Campus, Asaba Campus)",
    "Delta State Polytechnic, Ogwashi-Uku",
    "Delta State Polytechnic, Otefe-Oghara",
    "Delta State Polytechnic, Ozoro",
    "College of Education, Agbor",
    "College of Education, Warri",
    "Federal College of Education Technical, Asaba",
    "College of Physical Education, Mosogar",
    "School of Health Technology, Ughelli",
    "Petroleum Training Institute, Effurun",
    "Western Delta University, Oghara",
    "Novena University, Ogume-Amai",
    "National Open University of Nigeria, Asaba Study Center, Asaba",
    "Delta State School of Marine Technology, Burutu",
    "Nigerian Maritime University, Okerenkoko, Warri",
    "Conarina School of Maritime & Transport Technology, Oria-Abraka",
    "University of Information and Communication Technology, Agbor",
    "State School of Midwifery, Asaba",
    "School of Nursing, Agbor",
    "School of Nursing, Warri",
    "Baptist School of Nursing",
    "Eku Edwin Clark University",
    "Kiagbodo Eagle Heights University, Omadino",
    "Warri Nigerian Naval School",
    "Sapele Nigerian Navy Maritime University, Ibusa",
    "Ebonyi State University, Abakaliki (EBSU)",
    "Federal University Ndufu Alike Ikwo (FUNAI)",
    "Ebonyi State College of Education Ikwo (EBSCOEI)",
    "Akanu Ibiam Federal Polytechnic, Unwana",
    "College of Health Sciences",
    "Ezzamgbo and Federal College of Agriculture, Ishiagu.",
    "University of Nigeria, Nsukka",
    "Enugu State University of Science & Technology (ESUT)",
    "Institute of Management and Technology (IMT)",
    "Federal Cooperative College, Oji River (FCCO)",
    "Enugu State College of Education Technical, Enugu",
    "Caritas University, Amorji-Nike",
    "Renaissance University, Ugbawka",
    "Federal School of Dental Technology & Therapy",
    "College of Immaculate Conception",
    "Our Saviour Institute of Science and Technology, Enugu",
    "the Federal College of Education",
    "Eha-AmufuCaritas University",
    "(ICE) Institute of Continuing Education, Auchi",
    "(MILI) Miracle Invasion Leadership Institute",
    "(NICPS) Nigerian Institute Of Computer Science And Professional Studies",
    "(SAU) Samuel Adegboyega University, Ogwa",
    "AAU Ambrose Ali University, Ekpoma",
    "BEN University Of Benin, Benin",
    "Bendel State University, Ekpoma",
    "Benin Technical College, Benin City",
    "BIU Benson Idahosa University, Benin City",
    "College of Education Benin",
    "Edo State Institute of Technology and Management Usen",
    "Federal Polytechnic Auchi",
    "IGB Igbinedion University, Okada-Benin",
    "Lighthouse Polytechnic, Evbuobanosa",
    "National Open University Of Nigeria",
    "Seminary of All Saints, Uhiele, Ekpoma ",
    "Shaka Polytechnic, Benin City",
    "State School of Nursing, Sapele Road, Benin City",
    "Usen Polytechnic",
    "Wellspring University, Benin City",
    "World Outreach Bible College",
    "Afe Babalola University",
    "College of Education,Ikere-Ekiti",
    "Crown Polytechnic Ado Ekiti",
    "Divisional Teacher's Training College, Oye-Ekiti",
    "Federal Polytechnic, Ado-Ekiti",
    "Federal University Oye Ekiti",
    "Government Technical College, Ado-Ekiti",
    "His Mercy Computer Institute ",
    "School of Nursing",
    "UAD University Of Ado-Ekiti, Ado-Ekiti",
    "University Of Science And Technology, Ifaki",
    "(CABS) College of Administrative and Business Studies, Kumo",
    "(FUK) Federal University Kashere",
    "Federal College of Education (Tech) Gombe",
    "GSU Gombe State University Tudun Wada, Tudun Wada",
    "National Open University Of Nigeria",
    "(CIP) Claretian Institute of Philosophy, Maryland Nekede",
    "African Institute Of Science And Technology",
    "ALV Alvan Ikoku College Of Education, Owerri",
    "College of Business and Legal Studies",
    "College of Health Science and Technology, Amaigbo, Nwangele",
    "Federal Polytechnic Nekede Owerri",
    "FUTO Fed University Of Technology, Owerri",
    "Imo State Polytechnic, Umuagwo",
    "Imo State Technological Skills Acquisition Institution, Orlu",
    "IMSU Imo State University, Owerri",
    "National Horticultural Research Institute, Ibadan",
    "National Open University of Nigeria",
    "School of Nursing",
    "Word of Faith Bible Institute",
    "Federal University, Dutse",
    "Hussaini Adamu Federal Polytechnic Kazaure",
    "informatics Institute of Information Technology, Kazaure",
    "Jigawa State College Of Agriculture, Hadejia",
    "Jigawa State College of Education, Gumel, Dutse",
    "Jigawa State Polytechnic",
    "(NTI) National Teacher's Institute",
    "ABU Ahmadu Bello University, Zaria",
    "Air Force Institute Of Technology",
    "Ameer Shehu Idris College of Advanced Studies, Zaria",
    "Bouyeat Computer Training School",
    "Dialogue Computer Institute",
    "ECWA Theological Seminary Kagoro",
    "Fantsuam Foundation, Kafanchan",
    "Federal College of Chemical and Leather Technology",
    "Federal College Of Forestry Mechanization",
    "Federal Cooperative College",
    "Federal School of Statistics",
    "Federal Training Centre",
    "Gralom Computer Institute",
    "Innovation Institute of Technology",
    "KAD Kaduna Polytechnic, Kaduna",
    "Kaduna State College of Education",
    "KAS Kaduna State University, Kaduna",
    "National Open University of Nigeria, Kaduna",
    "Nigerian College Of Aviation Technology, Zaria",
    "Nigerian Defence Academy, Kaduna",
    "Nigerian Institute of Leather and Science Technology, Samuru",
    "Nuhu Bamalli Polytechnic Zaria",
    "School Of Health Technology, Makarfi",
    "St. Gerard's School of Nursing, Kakuri",
    "St. Luke Anglican School of Nursing, Wusasa, Zaria",
    "The Federal College of Chemical and Leather Technology, Zaria",
    "ZAR Federal College Of Education, Zaria",
    "(SRCOE) Sa'adatu Rimi College Of Education, Kumbotso",
    "Aminu Kano College of Islamic And Legal Studies",
    "BAY Bayero University, Kano",
    "British Career Training College",
    "College of Art, Science and Remedial Studies",
    "Computer Training Institute",
    "Federal College of Education (Technical), Bichi",
    "KAN Federal College Of Education, Kano",
    "Kano State Polytechnic",
    "king's Glory University",
    "KUT Kano University Of Technology, Kano",
    "Nigerian Police Academy, Wudil",
    "School of Health Technology",
    "Federal College of Education Katsina",
    "Federal University Dutsin-Ma",
    "Hassan Usman Katsina Polytechnic",
    "Isah Kaita College of Education Dutsinma",
    "KTS Katsina State University, Katsina",
    "National Open University Of Nigeria",
    "School of Basic and Remedial Studies Funtua",
    "School of Health Technology Daura",
    "School of Health Technology Kankia",
    "School of Midwifery Malumfashi",
    "School of Nursing Katsina",
    "Umaru Musa Yaradua University",
    "YBU College of Legal and General Studies Daura",
    "Adamu Augie College of Education, Argungu",
    "KUS Kebbi State Univ. Of Science & Tech. Aliero, Aliero",
    "Waziri Umaru Federal Polytechnic",
    "ECWA School of Nursing (ECWASON)",
    "Federal College of Education, Okene",
    "Federal University Of Lokoja",
    "Kogi State College of Education",
    "Kogi State College of Nursing",
    "Kogi State Polytechnic, Lokoja",
    "KSU Kogi State University, Anyigba",
    "National Open University of Nigeria",
    "SUL Salem University, Lokoja",
    "The Federal Polytechnic Idah",
    "College of Nursing, Ilorin",
    "Federal Polytechnic Offa",
    "HIK Al-Hikmah University, Ilorin",
    "ILO University Of Ilorin, Ilorin",
    "Kwara State College of Arabic and Islamic Legal Studies",
    "Kwara State College of Education",
    "Kwara State College of Health and Technology",
    "Kwara State Polytechnic, Ilorin",
    "Kwara State University (KWASU)",
    "Landmark university omu-aran",
    "National Open University of Nigeria",
    "(CIBN) The Chartered Institute of Bankers of Nigeria",
    "(CISN) Certified Institute of Shipping of Nigeria",
    "(ICAN) The Institute of Chartered Accountants of Nigeria",
    "(ISMN) Institute of Strategic Management, Nigeria",
    "(LASOTAT) Lagos School of Travels and Tourism",
    "(LBS) Lagos Business School",
    "(NIPR) The Nigerian Institute Of Public Relations",
    "(NITT) Nigerian Institute of Transport Technology",
    "(NOIC) Nigerian Opportunity Industrialization Centre, Gbagada",
    "Adeniran Ogunsanya College Of Education",
    "Administrative Staff College of Nigeria (ASCON) Badagry",
    "Aeroland Aviation School",
    "African Maritime Academy",
    "AMEC college",
    "Aptech",
    "Association of National Accountants of Nigeria (ANAN)",
    "Bells Univ. Of Tech. Badagry, Badagry",
    "Chartered Institute of Administration",
    "Chartered Institute of Marketing of Nigeria",
    "Chartered Institute of Purchasing and Supply Management",
    "College Of Aviation And Management Studies",
    "Command Day Secondary School, Ojo",
    "Caleb University, Lagos",
    "Dalewares Institute of Technology",
    "Excel College",
    "Ezekiel Private College",
    "Federal College of Education",
    "Federal College of Education (Technical), Akoka",
    "Federal College of Fisheries and Marine Technology",
    "Federal College Of Orthopedic Technology, Igbobi",
    "Federal School of Arts and Sciences, Victoria Island",
    "Federal School of Occupational Therapy",
    "Federal School of Science",
    "Federal Science and Technical College",
    "Federal Training Centre, Ikoyi",
    "First Immaculate Computer Institute",
    "Goethe-Institut",
    "Government Technical College, Ado-Soba",
    "Government Technical College, Ikorodu",
    "Grano Institute of Catering and Hotel Management",
    "Helpers International College  Institute for Industrial Technology",
    "Institute of Chartered Economists of Nigeria (ICEN)",
    "Institute of Data Processing Management (IDPM)",
    "Ipakodo Grammar School, Ikorodu",
    "karROX ICT Institute",
    "Keme Balogun College, Ibeshe",
    "LAG University Of Lagos, Lagos",
    "Lagos City Computer College (LCC)",
    "Lagos State College of Health Technology, Yaba",
    "Lagos state polytechnic",
    "Lagos University Teaching Hospital",
    "LIFE Theological Seminary, Ikorodu,",
    "Logos Institute, Maryland",
    "LSU Lagos State University, Ojo Lagos",
    "McAdolph Institute Of Information Technology",
    "Michael Otedola College of Primary Education",
    "National Open University of Nigeria",
    "New Horizons Computer Learning Centres",
    "Nigerian Army School Of Finance And Administration (NASFA)",
    "Nigerian Army School Of Medical Sciences",
    "Nigerian Army School of Nursing and Midwifery",
    "Nigerian Institute of Journalism  Nigerian Institute Of Management (Chartered)",
    "Nigerian Institute of Safety Professionals, Ikotun",
    "Nigerian Law School",
    "NIIT National Institute of Information Technology",
    "PEFTI Film Institute  Portfolio And Debt Management Institute Of Nigeria",
    "Redeemers University  Ronik Polytechnic",
    "Saint Finbarr's College, Akoka",
    "Yaba  Speedway Information Technology Institute",
    "Times Journalism Institute, Surulere",
    "Wolex Polytechnic",
    "YAB Yaba College Of Technology, Yaba, Lagos",
    "Yetkem Institute of Catering and Hotel Management",
    "Bingham University, Karu",
    "BIN Gham University, New Karu",
    "Federal University of Technology, Lafia",
    "National Open University Of Nigeria",
    "NSU Nasarawa State University, Keffi",
    "College of Education, Akwanga",
    "College of Agriculture, Lafia",
    "FEDPOLYNAS Federal Polytechnic Nasarawa",
    "Nacabs Polytechnic, Akwanga",
    "Nasarawa State Polytechnic, Lafia",
    "Federal University of Technology",
    "MIN Fed University Of Technology, Minna",
    "National Open University Of Nigeria",
    "Federal Polytechnic Bida",
    "Niger State Polytechnic, Zungeru",
    "(CABS) College of Administrative and Business Studies, Bida",
    "CEM College Of Education, Minna",
    "College for Legal and General Studies, Minna",
    "College of Arts and Islamic Legal Studies",
    "Federal College of Education, Kontagora",
    "Federal College of Wildlife Management, New Bussa",
    "Niger State College of Nursing, Bida",
    "Suleiman Barau Technical College, Suleja",
    "BAB Babcock University, Ilisan-Remo",
    "Babcock University",
    "Bells University of Technology",
    "CRA Crawford University, Igbesa",
    "Crescent University, Abeokuta",
    "National Open University Of Nigeria",
    "OOU Olabisi Onabanjo University, Ago-Iwoye",
    "UAA University Of Agriculture, Abeokuta",
    "Abraham Adesanya Polytechnic",
    "Allover Central Polytechnic",
    "D S Adegbenro Ict Polytechnic, Itoro, Ewekoro",
    "Federal Polytechnic Ilaro",
    "Grace Polytechnic",
    "Moshood Abiola Polytechnic, Ojere, Abeokuta",
    "The Gateway Polytechnic",
    "Federal College of Education Abeokuta",
    "Ifo College of Management and Technology",
    "Ogun State College Of Health Technology",
    "Savannah College of Art and Design",
    "TAI Tai Solarin College Of Education, Ijebu-Ode",
    "Yewa Central College of Education",
    "Zulikha Abiola College of Arabic And Islamic Studies",
    "Adekunle Ajasin University",
    "Federal University of Technology, Akure",
    "Rufus Giwa Polytechnic",
    "Achievers University",
    "University of Medical Sciences, Ondo",
    "Ondo State University of Science and Technology",
    "Wesley University of Science and Technology",
    "Elizade University",
    "Adeyemi College of Education, Ondo.",
    "Adeleke University, Ede",
    "Bowen University, Iwo",
    "Christwealth University ifewara",
    "FUO Fountain University, Osogbo",
    "Ladoke Akintola University Of Technology Ogbomoso",
    "National Open University of Nigeria",
    "OAU Obafemi Awolowo University, Ile-Ife",
    "Obafemi Awolowo University Staff School, Ife",
    "Oduduwa University",
    "Osun State University, Osogbo",
    "University of Abu",
    "Federal Polytechnic Ede",
    "Interlink Polytechnic",
    "Osun State Polytechnic, Iree",
    "The Polytechnic, Ile-Ife",
    "Osun State College of Technolgy, Esa oke",
    "Universal College of Technology Ile-ife",
    "College of Arts and Science, Ile Ife",
    "OSC Osun State College Of Education Ilesa, Ilesa",
    "OSS college of EDucation, Ila-Orangun",
    "ACU Ajayi Crowther University, Oyo",
    "University Of Ibadan, Ibadan",
    "Lead City University, Ibadan",
    "National Open University of Nigeria",
    "The Polytechnic, Ibadan",
    "St. Winifred Innovative Polytechnic, Ibadan",
    "The Polytechnic, Ibadan",
    "Tower Polytechnic, Ibadan",
    "Best Legacy College of Education, Ogbomoso",
    "Federal College of Agriculture, Moor Plantation, Ibadan",
    "Federal College Of Animal Production and Health",
    "Federal College of Education",
    "Federal College of Forestry",
    "Mufutau Lanihun College of Education, Ibadan",
    "Oyo Stae College of Education",
    "St. Andrews College Of Education, Oyo",
    "JOS University Of Jos, Jos",
    "PSU Plateau State University, Bokkos",
    "Plateau State Polytechnic",
    "(NCA) Nigerian College of Accountancy, Jos",
    "College of Arabic and Islamic Studies(cais)Rikkos",
    "College of Arts, Science and Technology, Kurgwi",
    "College of Education, Jos",
    "College of Health Technology, Zawan",
    "Federal College of Education, Pankshin",
    "Madonna University",
    "National Open University Of Nigeria",
    "University Of Port-Harcourt, Port-Harcourt",
    "Rivers State University Of Technology, Port-Harcourt",
    "University of Science and Technology, Diobu, Port Harcourt",
    "Rivers State polytechnic",
    "King's College of Commerce, Buguma",
    "Federal College of Education (Technical), Omoku",
    "College Of Education, Port-Harcourt",
    "Rivers State College Of Arts",
    "Rivers State College of Health Science and Technology",
    "Sokoto State University",
    "National Open University of Nigeria",
    "UDU Usmanu Danfodio University, Sokoto",
    "Usmanu Danfodiyo University Teaching Hospital School Of Nursing (UDUTHSON)",
    "Sokoto State Polytechnic",
    "Haliru Binji College Of Art and Science",
    "Sokoto Teachers' College",
    "TAR Taraba State University, Jalingo",
    "Taraba State Polytechnic, Jalingo",
    "Taraba State Polytechnic, Jalingo",
    "Federal Science and Technical College",
    "Taraba State College of Agriculture, Jalingo",
    "Buka Abba Ibrahim University, Damaturu",
    "Federal Polytechnic Damaturu",
    "College of Administrative and Business Studies, Potiskum",
    "College of Education, Gashua",
    "Abdul Gusau Polytechnic",
    "Federal Polytechnic Kaura Namoda",
    "Kaura Knamoda Polytechnic",
    "College of Islamic Science, Gusau",
    "Federal University Gusau, Zamfara State",
    "(NTNU) Nigerian Turkish Nile University",
    "African University of Science and Technology",
    "National Open University of Nigeria",
    "Others"

  ]
};

export default selectData;
