import { Alert, Autocomplete, CircularProgress, Grid, Paper, TextField } from "@mui/material"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import MDTypography from "components/MDTypography"
import DashboardLayout from "customs/LayoutContainers/DashboardLayout"
import DashboardNavbar from "customs/Navbars/DashboardNavbar"
import { useSignup } from "hooks/useSignup"
import { useState } from "react"
import selectData from "./data"

export const ClientAuthentication = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [photoURL, setPhotoURL] = useState('')
    const [displayName, setDisplayName] = useState('')
    const { signup, error, isPending, success } = useSignup()

    const handleSubmit = (e) => {
        e.preventDefault()
        signup(email, password, displayName, photoURL)
        if (success) {
        e.target.reset()
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox component="form" onSubmit={handleSubmit} noValidate>
        <Paper
          sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}
        >
          <MDBox>
            <MDTypography
              variant="h5"
              fontWeight="bold"
              sx={{ borderBottom: 1, pb: 1, pt: 4 }}
            >
              Client Login Credentials
            </MDTypography>
          </MDBox>
          {success && (
            <Alert className="alert" severity="success">
              {success}
            </Alert>
          )}
          {error && (
            <Alert className="alert" severity="error">
              {error}
            </Alert>
          )}
          <MDBox mt={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <MDTypography sx={{ fontSize: 13 }}>Client ID </MDTypography>
                <TextField
                  name="StaffId"
                  fullWidth
                  placeholder="Enter Staff ID"
                  onChange={(e) => setDisplayName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MDTypography sx={{ fontSize: 13 }}>Client Unit </MDTypography>
                <MDBox>
                  <Autocomplete
                    defaultValue="Admin & Human Resource"
                    options={selectData.employeeDepartment}
                    onChange={(e, newValue) => {
                      setPhotoURL(newValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        placeholder="Select Company Type"
                      />
                    )}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={4}>
                <MDTypography sx={{ fontSize: 13 }}>Email </MDTypography>
                <TextField
                  name="EmployeeEmail"
                  fullWidth
                  placeholder="Enter Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MDTypography sx={{ fontSize: 13 }}>Password</MDTypography>
                <TextField
                  name="password"
                  type="password"
                  fullWidth
                  placeholder="Input Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
            </Grid>
            <MDBox sx={{ pt: 6, display: 'flex', justifyContent: 'flex-end' }}>
              {!isPending && (
                <MDButton type="submit" variant="gradient" color="dark" sx={{ width: '170px'}}>
                  Create Account
                </MDButton>
              )}
              {isPending && (
                <MDButton variant="gradient" color="dark" disabled sx={{ width: '170px'}}>
                    <CircularProgress size={20} />
                </MDButton>
              )}
            </MDBox>
          </MDBox>
        </Paper>
      </MDBox>
        </DashboardLayout>
    )
}