/** 
  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from '@mui/material/Icon'
import Home from './Home/index'
import CreateClient from './CreateClient'
import CreateConsignee from './CreateConsignee'
import ConsigneeTable from './ConsigneeTable'
import CreateJobManager from './CreateJobManager'
import JobManager from './JobManager'
import PreAlertJobs from './PreAlertJob/index'
import PreAlertJobsForm from './PreAlertJob/PreAlertJobForm/index'
import PendingJobTable from './PendingJobFiles/index'
import CompletedJobFile from './CompletedJobFiles'
import ClientTable from './ClientTable'
import JobRelated from './MakeRequest/JobRelated'
import NonJobRelated from './MakeRequest/NonJobRelated'
import FormUpdate from './PendingJobFiles/PendingJobForm/uploadForm'
import AirFormUpdate from './PendingJobFiles/PendingJobForm/airUploadForm'
import PendingJobModal from './PendingJobFiles/Modal/PendingJobModal'
import PendingJobModal1 from './PendingJobFiles/Modal1/PendingJobModal'
import Approved from './Approved'
import Pending from './Pending'
import Declined from './Declined'
import AirwayBillForm from './PendingJobFiles/AirwayBillForm'
import PreAlertExport from './PreAlertJob/PreAlertExport'
import ExportJobTable from './PendingJobFiles/Export'
import ExportUpdate from './PendingJobFiles/PendingJobForm/ExportUploadForm'
import PendingExportModal from './PendingJobFiles/Modal2/PendingJobModal'
import EditSeaJob from './PreAlertJob/PreAlertJobForm/EditSeaJob'
import EditExport from './PreAlertJob/PreAlertExport/EditExport'
import EditAirJob from './PendingJobFiles/AirwayBillForm/EditAirJob'

const routes = [
  {
    type: 'singleRoute',
    singleRoute: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: 'dashboard',
    component: <Home />,
  },
  { type: 'divider', key: 'divider-0' },

  {
    type: 'title',
    title: 'FREIGHT FORWARDING',
    key: 'M&C',
  },
  {
    type: 'collapse',
    name: 'Client Management',
    key: 'client-management',
    icon: <Icon fontSize="small">groups</Icon>,
    collapse: [
      {
        name: 'Create Client',
        key: 'create-client',
        route: 'm&c/create-client/:id',
        icon: <Icon fontSize="small">group_add</Icon>,
        component: <CreateClient />,
      },
      {
        name: 'Create Consignee',
        key: 'create-consignee',
        route: 'm&c/create-consignee/:id',
        icon: <Icon fontSize="small">group_add</Icon>,
        component: <CreateConsignee />,
      },
      {
        name: 'Consignee',
        key: 'consignee',
        route: 'm&c/consignee',
        icon: <Icon fontSize="small">group</Icon>,
        component: <ConsigneeTable />,
      },
      {
        name: 'Client',
        key: 'client',
        route: 'm&c/client',
        icon: <Icon fontSize="small">group</Icon>,
        component: <ClientTable />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Job File Management',
    key: 'job-management',
    icon: <Icon fontSize="small">folder</Icon>,
    collapse: [
      {
        name: 'Create Job Manager',
        key: 'create-job-manager',
        route: 'job-management/create-job-manager/:id',
        icon: <Icon fontSize="small">manage_accounts</Icon>,
        component: <CreateJobManager />,
      },
      {
        name: 'Job Managers',
        key: 'job-manager',
        route: 'job-management/job-manager',
        icon: <Icon fontSize="small">person</Icon>,
        component: <JobManager />,
      },
      {
        name: 'Pre-Alert Jobs',
        key: 'pre-alert-jobs',
        route: 'job-management/pre-alert-jobs',
        icon: <Icon fontSize="small">work</Icon>,
        component: <PreAlertJobs />,
      },

      {
        name: 'Pending Job Files',
        key: 'pending-job-files',
        route: 'job-management/pending-job-files',
        icon: <Icon fontSize="small">work_history</Icon>,
        component: <PendingJobTable />,
      },
      {
        name: 'Export Jobs',
        key: 'pending-export-job',
        route: 'job-management/export-job',
        icon: <Icon fontSize="small">work_history</Icon>,
        component: <ExportJobTable />,
      },

      // {
      //   type: "hiddenRoute",
      //   name: "Pending Job Form",
      //   key: "pending-job-form",
      //   route: "job-management/pending-job-form",
      //   icon: <Icon fontSize="medium">company</Icon>,
      //   component: <JobLayout />
      // },
      {
        name: 'Completed Job Files',
        key: 'completed-job-files',
        route: 'job-management/completed-job-files',
        icon: <Icon fontSize="small">work_off</Icon>,
        component: <CompletedJobFile />,
      },
    ],
  },
  {
    type: 'hiddenRoute',
    name: 'Airway Bill Form',
    key: 'airway-bill-form',
    route: 'job-management/airway-bill-form/:id',
    icon: <Icon fontSize="small">work_history</Icon>,
    component: <AirwayBillForm />,
  },
  {
    type: 'hiddenRoute',
    name: 'Edit Air Bill Form',
    key: 'edit-airway-bill-form',
    route: 'job-management/edit-airway-bill-form/:id',
    icon: <Icon fontSize="small">work_history</Icon>,
    component: <EditAirJob />,
  },
  {
    type: 'hiddenRoute',
    name: 'Export Form',
    key: 'export-form',
    route: 'job-management/export-form/:id',
    icon: <Icon fontSize="small">work_history</Icon>,
    component: <ExportUpdate />,
  },
  {
    type: 'hiddenRoute',
    name: 'Edit Export Form',
    key: 'edit-export-form',
    route: 'job-management/edit-export-form/:id',
    icon: <Icon fontSize="small">work_history</Icon>,
    component: <EditExport />,
  },
  {
    type: 'hiddenRoute',
    name: 'Pending Job Form',
    key: 'pending-job-form',
    route: 'job-management/pending-job-form/:id',
    icon: <Icon fontSize="medium">company</Icon>,
    component: <FormUpdate />,
  },
  {
    type: 'hiddenRoute',
    name: 'Pending Job Form',
    key: 'air-pending-job-form',
    route: 'job-management/air-pending-job-form/:id',
    icon: <Icon fontSize="medium">company</Icon>,
    component: <AirFormUpdate />,
  },
  {
    type: 'collapse',
    name: 'Financial Requests',
    key: 'financial-requests',
    icon: <Icon fontSize="small">reorder</Icon>,
    collapse: [
      {
        name: 'Approved',
        key: 'approved',
        route: 'request-status/approved',
        icon: <Icon fontSize="small">check_circle_outline</Icon>,
        component: <Approved />,
      },
      {
        name: 'Pending',
        key: 'pending',
        route: 'request-status/pending',
        icon: <Icon fontSize="small">hourglass_bottom</Icon>,
        component: <Pending />,
      },
      {
        name: 'Declined',
        key: 'declined',
        route: 'request-status/declined',
        icon: <Icon fontSize="small">cancel</Icon>,
        component: <Declined />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Make Request',
    key: 'make-request',
    icon: <Icon fontSize="small">queue</Icon>,
    collapse: [
      {
        name: 'Job Related',
        key: 'job-related',
        route: 'make-request/job-related',
        icon: <Icon fontSize="small">content_paste</Icon>,
        component: <JobRelated />,
      },
      {
        name: 'Non Job Related',
        key: 'non-job-related',
        route: 'make-request/non-job-related',
        icon: <Icon fontSize="small">content_paste_off</Icon>,
        component: <NonJobRelated />,
      },
    ],
  },
  // {
  //   type: "hiddenRoute",
  //   name: "Create Client",
  //   key: "create-client",
  //   route: "m&c/create-client/:id",
  //   icon: <Icon fontSize="small">group_add</Icon>,
  //   component: <CreateClient />
  // },
  // {
  //   type: "hiddenRoute",
  //   name: "Create Consignee",
  //   key: "create-consignee",
  //   route: "m&c/create-consignee/:id",
  //   icon: <Icon fontSize="small">group_add</Icon>,
  //   component: <CreateConsignee />
  // },
  // {
  //   type: "hiddenRoute",
  //   name: "Create Job Manager",
  //   key: "create-job-manager",
  //   route: "job-management/create-job-manager/:id",
  //   icon: <Icon fontSize="small">manage_accounts</Icon>,
  //   component: <CreateJobManager />
  // },
  {
    type: 'hiddenRoute',
    name: 'Pre-Alert Jobs Form',
    key: 'pre-alert-jobs-form',
    route: 'job-management/pre-alert-jobs-form/:id',
    icon: <Icon fontSize="medium">company</Icon>,
    component: <PreAlertJobsForm />,
  },
  {
    type: 'hiddenRoute',
    name: 'Edit sea Jobs Form',
    key: 'edit-sea-jobs-form',
    route: 'job-management/edit-sea-jobs-form/:id',
    icon: <Icon fontSize="medium">company</Icon>,
    component: <EditSeaJob />,
  },
  {
    type: 'hiddenRoute',
    name: 'PendingJobs',
    key: 'pending-jobs',
    route: 'job-management/pending-jobs/:id',
    icon: <Icon fontSize="medium">company</Icon>,
    component: <PendingJobModal />,
  },
  {
    type: 'hiddenRoute',
    name: 'PendingJobs',
    key: 'pending-jobs',
    route: 'job-management/pending-jobs-airwybill/:id',
    icon: <Icon fontSize="medium">company</Icon>,
    component: <PendingJobModal1 />,
  },
  {
    type: 'hiddenRoute',
    name: 'PendingJobs',
    key: 'pending-export-jobs',
    route: 'job-management/pending-export-jobs/:id',
    icon: <Icon fontSize="medium">company</Icon>,
    component: <PendingExportModal />,
  },
  {
    type: 'hiddenRoute',
    name: 'Pre Alert Export',
    key: 'pre-alert-export',
    route: 'job-management/pre-alert-export/:id',
    icon: <Icon fontSize="medium">company</Icon>,
    component: <PreAlertExport />,
  },
  { type: 'divider', key: 'divider-1' },
]

export default routes
