// @mui material components
import Grid from '@mui/material/Grid'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import FormFields from 'layouts/pages/account/components/FormField'

// page components
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import { Paper, MenuItem, Select } from '@mui/material'

import { useState, useEffect } from 'react'

//Firebase Hook
// import { useFirestore } from '../../../hooks/useFirestore'
import { useNavigate, useParams } from 'react-router-dom'
import {useKingsooService} from '../../../../hooks/useKingsooServices'

// import PopSuccess from '../../PopMessage/Success'

function PendingJobForm() {
  const [containerInfo, setContainerInfo] = useState([
    { containerSize: '', waybillNumber: '', security: '', gateInDate: '' },
  ])

  const { id } = useParams()
  const navigate = useNavigate()
  const {getJob, updateTerminateJob} = useKingsooService()

  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await getJob(id)
        // console.log(docSnap.data())
        setContainerInfo(docSnap.data().containerInfo)
        //   setBillOfLaden(docSnap.data().airWayBill)
        //   setPreAlertImage(docSnap.data().preALertImage)
      } catch {
        //   console.log('error')
      }
    }
    // console.log('na u know', id)
    if ((id !== undefined || id !== '') && id !== ':id') {
      detail()
    }
  }, [id])

  const handleSubmit = async (e) => {
    e.preventDefault()
    // console.log(containerInfo)
    try {
      await updateTerminateJob(id, { containerInfo })
      navigate('/gate/inwarded-containers')
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleFormChange = (index, e) => {
    let data = [...containerInfo]
    data[index][e.target.name] = e.target.value
    setContainerInfo(data)
  }

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
          <MDBox mt={5} component="form" onSubmit={handleSubmit}>
            {containerInfo.map((con, index) => {
              return (
                <MDBox mt={5}>
                  <MDTypography
                    variant="h5"
                    fontWeight="bold"
                    sx={{ borderBottom: 1, pb: 1, pt: 4 }}
                  >
                    {con.containerId} Inward Details
                  </MDTypography>
                  {!con.gateStatus ? (
                    <MDBox mt={1.625}>
                      <Grid container spacing={3} mb={4}>
                        <Grid item xs={12} sm={4}>
                          <MDTypography sx={{ fontSize: 13 }}>
                            Waybill Number{' '}
                          </MDTypography>
                          <FormFields
                            value={con.wayBillNo}
                            onChange={(e) => handleFormChange(index, e)}
                            name="waybillNumber"
                            // placeholder="Enter Waybill Number"
                            inputProps={{
                              style: { textTransform: 'uppercase' },
                            }}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MDTypography sx={{ fontSize: 13 }}>
                            Container Size
                          </MDTypography>
                          <FormFields
                            name="ContainerSize"
                            value={con.containerSize}
                            onChange={(e) => handleFormChange(index, e)}
                            // placeholder="Autogenerated"
                            inputProps={{
                              style: { textTransform: 'capitalize' },
                            }}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MDTypography sx={{ fontSize: 13 }}>
                            Container Number{' '}
                          </MDTypography>
                          <FormFields
                            name="ContainerNumber"
                            value={con.containerId}
                            onChange={(e) => handleFormChange(index, e)}
                            // placeholder="Enter Container Number"
                            inputProps={{
                              style: { textTransform: 'uppercase' },
                            }}
                            disabled
                          />
                        </Grid>
                      </Grid>
                      {con.transTransferTruckDriverName && (
                        <Grid container spacing={3} mb={4}>
                          <Grid item xs={12} sm={4}>
                            <MDTypography sx={{ fontSize: 13 }}>
                              Transfered Driver's Name{' '}
                            </MDTypography>
                            <FormFields
                              value={con.transTransferTruckDriverName}
                              onChange={(e) => handleFormChange(index, e)}
                              name="truckDriverName"
                              // placeholder="Enter Driver's Name"
                              type="text"
                              inputProps={{
                                style: { textTransform: 'capitalize' },
                              }}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <MDTypography sx={{ fontSize: 13 }}>
                              Transfered Driver's Contact
                            </MDTypography>
                            <FormFields
                              value={con.transTransferTruckDriverNumber}
                              onChange={(e) => handleFormChange(index, e)}
                              name="truckDriverNumber"
                              // placeholder="Enter Driver's Contact"
                              type="tel"
                              inputProps={{
                                style: { textTransform: 'capitalize' },
                              }}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <MDTypography sx={{ fontSize: 13 }}>
                              Transfered Truck Number{' '}
                            </MDTypography>
                            <FormFields
                              value={con.transTransferTruckPlateNumber}
                              disabled
                              onChange={(e) => handleFormChange(index, e)}
                              name="TruckNumber"
                              // placeholder="Enter Truck Number"
                              inputProps={{
                                style: { textTransform: 'uppercase' },
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}

                      <Grid container spacing={3} mb={4}>
                        <Grid item xs={12} sm={4}>
                          <MDTypography sx={{ fontSize: 13 }}>
                            Driver's Name{' '}
                          </MDTypography>
                          <FormFields
                            value={con.truckDriverName}
                            onChange={(e) => handleFormChange(index, e)}
                            name="truckDriverName"
                            // placeholder="Enter Driver's Name"
                            type="text"
                            inputProps={{
                              style: { textTransform: 'capitalize' },
                            }}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MDTypography sx={{ fontSize: 13 }}>
                            Driver's Contact
                          </MDTypography>
                          <FormFields
                            value={con.truckDriverNumber}
                            onChange={(e) => handleFormChange(index, e)}
                            name="truckDriverNumber"
                            // placeholder="Enter Driver's Contact"
                            type="tel"
                            inputProps={{
                              style: { textTransform: 'capitalize' },
                            }}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MDTypography sx={{ fontSize: 13 }}>
                            Truck Number{' '}
                          </MDTypography>
                          <FormFields
                            value={con.truckPlateNumber}
                            disabled
                            onChange={(e) => handleFormChange(index, e)}
                            name="TruckNumber"
                            // placeholder="Enter Truck Number"
                            inputProps={{
                              style: { textTransform: 'uppercase' },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <MDTypography sx={{ fontSize: 13 }}>
                            Security on Seat{' '}
                          </MDTypography>
                          <FormFields
                            value={con.security}
                            onChange={(e) => handleFormChange(index, e)}
                            name="security"
                            placeholder="Enter Security on Seat"
                            inputProps={{
                              style: { textTransform: 'capitalize' },
                            }}
                            disabled={con.security && 'disabled'}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MDTypography sx={{ fontSize: 13 }}>
                            Date{' '}
                          </MDTypography>
                          <input
                            type="date"
                            name="gateInDate"
                            value={con.gateInDate}
                            onChange={(e) => handleFormChange(index, e)}
                            className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            disabled={con.gateInDate && 'disabled'}
                          />
                        </Grid>
                        {con.gateStatus !== 'Gate-In' &&
                        con.gateStatus !== 'Gate-Out' ? (
                          <Grid item xs={12} sm={4}>
                            <MDTypography sx={{ fontSize: 13 }}>
                              Gate In{' '}
                            </MDTypography>
                            <Select
                              labelId="demo-simple-select-autowidth-label"
                              id="demo-simple-select-autowidth"
                              value={con.gateInDate}
                              onChange={(e) => handleFormChange(index, e)}
                              fullWidth
                              label="Gate"
                              name="gateStatus"
                              className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            >
                              <MenuItem value="Gate-In">Yes</MenuItem>
                            </Select>
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={4}>
                            <MDTypography sx={{ fontSize: 13 }}>
                              Gated In{' '}
                            </MDTypography>
                            <FormFields
                              value="Gated In"
                              disabled
                              // onChange={(e) => handleFormChange(index, e)}
                              // name="security"
                              // placeholder="Enter Security on Seat"
                              inputProps={{
                                style: { textTransform: 'capitalize' },
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </MDBox>
                  ) : (
                    <MDTypography sx={{ fontSize: 33 }}>Gated In </MDTypography>
                  )}
                </MDBox>
              )
            })}
            <MDBox
              mt={2}
              width="100%"
              display="flex"
              justifyContent="right"
              pl={4}
              fontSize="14px"
            >
              <MDButton
                type="submit"
                variant="gradient"
                color="dark"
                sx={{ width: '180px' }}
              >
                Gated In
              </MDButton>
            </MDBox>
          </MDBox>
        </Paper>
      </DashboardLayout>
    </div>
  )
}

export default PendingJobForm
