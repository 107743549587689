import * as React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'

import { Visibility } from '@mui/icons-material'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: '#ffffff' }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export default function Modal() {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <div onClick={handleClickOpen}>
        <Visibility
          style={{
            width: '20px',
            height: '20px',
            color: '#033237',
          }}
        />
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          sx={{ backgroundColor: '#033237', color: '#ffffff' }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Job File - Company Name
        </BootstrapDialogTitle>
        <Grid container sm={12}>
          <Grid item sm={6} sx={{ pl: 2, pt: 4, mr: 4 }}>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              File ID: NLNG/IMP/20/00017
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Import Type: Air Freight
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Number of Containers: 1
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Job Manager: Okon Esther
            </Typography>
          </Grid>
          <Grid item sm={4} sx={{ pl: 2, pt: 4 }}>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Consignee ID: NLNG
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              BOL/MAWB: 574-32967933
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              ETA: 10 days left
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Job Status: Open
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Date & Time of Job Pre-Alert: 22/04/2022 04:56:45
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Date & Time of Job Creation: 22/04/2022 04:56:45
            </Typography>
          </Grid>
        </Grid>
      </BootstrapDialog>
    </div>
  )
}
