import Icon from '@mui/material/Icon'

import Home from './Home/index'
import TerminalTable from './TerminalTable'
import JobRelated from './MakeRequest/JobRelated'
import NonJobRelated from './MakeRequest/NonJobRelated'
import Jobs from './jobs'
import Approved from './Approved'
import Pending from './Pending'
import Declined from './Declined'
import PendingJobModal from './PendingJobTable/Modal/PendingJobModal'
import PendingJobTable from './PendingJobTable'

const routes = [
  {
    type: 'singleRoute',
    singleRoute: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: 'dashboard',
    component: <Home />,
  },
  { type: 'divider', key: 'divider-0' },
  {
    type: 'singleRoute',
    singleRoute: 'Job Table',
    key: 'job-table',
    route: 'job-table',
    icon: <Icon fontSize="small">train</Icon>,
    component: <Jobs />,
  },
  {
    type: 'hidden',
    singleRoute: 'Terminal Table',
    key: 'terminal-table',
    route: '/:id/terminal-table',
    icon: <Icon fontSize="small">train</Icon>,
    component: <TerminalTable />,
  },
  {
    type: 'collapse',
    name: 'Financial Requests',
    key: 'financial-requests',
    icon: <Icon fontSize="small">reorder</Icon>,
    collapse: [
      {
        name: "Approved",
        key: "approved",
        route: "request-status/approved",
        icon: <Icon fontSize="small">check_circle_outline</Icon>,
        component: <Approved />,
      },
      {
        name: "Pending",
        key: "pending",
        route: "request-status/pending",
        icon: <Icon fontSize="small">hourglass_bottom</Icon>,
        component: <Pending />,
      },
      {
        name: "Declined",
        key: "declined",
        route: "request-status/declined",
        icon: <Icon fontSize="small">cancel</Icon>,
        component: <Declined />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Job File Management',
    key: 'job-management',
    icon: <Icon fontSize="small">folder</Icon>,
    collapse: [
      {
        name: 'Pending Job Files',
        key: 'pending-job-files',
        route: 'job-management/pending-job',
        icon: <Icon fontSize="small">work_history</Icon>,
        component: <PendingJobTable />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Make Request',
    key: 'make-request',
    icon: <Icon fontSize="small">queue</Icon>,
    collapse: [
      {
        name: 'Non Job Related',
        key: 'non-job-related',
        route: 'financial-request/make-request/non-job-related',
        icon: <Icon fontSize="small">content_paste_off</Icon>,
        component: <NonJobRelated />,
      },
    ],
  },
  {
    type: 'hiddenRoute',
    name: 'Job Related',
    key: 'job-related',
    route: 'make-request/job-related/:id',
    icon: <Icon fontSize="small">content_paste</Icon>,
    component: <JobRelated />,
  },
  {
    type: 'hiddenRoute',
    name: 'PendingJobs',
    key: 'pending-jobs',
    route: 'job-management/pending-jobs/:id',
    icon: <Icon fontSize="medium">company</Icon>,
    component: <PendingJobModal />,
  },
]

export default routes
