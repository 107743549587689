import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

//Firebase Hook
import {useKingsooService} from '../../../../hooks/useKingsooServices'

import { Visibility } from "@mui/icons-material";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose
        ? <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500]
            }}>
            <CloseIcon sx={{ color: "#ffffff" }} />
          </IconButton>
        : null}
    </DialogTitle>
  );
};

export default function Modal({ id }) {
  const [open, setOpen] = useState(false);
  const [paymentValue, setPaymentValue] = useState("");
  const [request, setRequest] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [requestId, setRequestId] = useState("");
  const [status, setStatus] = useState("");
  console.log(id, "id");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const {getjnr} = useKingsooService()

  useEffect(
    () => {
      const detail = async () => {
        try {
          const docSnap = await getjnr(id);

          setPaymentValue(docSnap.data().paymentValue);
          setRequest(docSnap.data().request);
          setEmail(docSnap.data().email);
          setDescription(docSnap.data().description);
          setDate(docSnap.data().date);
          setRequestId(docSnap.data().requestId);
          setStatus(docSnap.data().status);
        } catch (error) {
          // console.log(error)
        }
      };
      if (id !== undefined || id !== "") {
        detail();
      }
    },
    [id]
  );
  const ngn = Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN"
  });
  return (
    <div>
      <div className="text-emerald-900" onClick={handleClickOpen}>
        <Visibility
          style={{
            width: "20px",
            height: "20px",
            color: "#033237"
          }}
        />
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <BootstrapDialogTitle
          sx={{ backgroundColor: "#033237", color: "#ffffff" }}
          id="customized-dialog-title"
          onClose={handleClose}>
          {requestId}
        </BootstrapDialogTitle>
        <Grid container sm={12}>
          <Grid item sm={6} sx={{ pl: 2, pt: 4, mr: 4 }}>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Value: {ngn.format(paymentValue)}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Job File Id: {requestId}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Requester: {email}
            </Typography>
          </Grid>
          <Grid item sm={4} sx={{ pl: 2, pt: 4 }}>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Request For: {request}
            </Typography>

            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Description: {description}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Date: {date}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Status: {status}
            </Typography>
          </Grid>
        </Grid>
      </BootstrapDialog>
    </div>
  );
}
