import { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FlagCircleIcon from '@mui/icons-material/FlagCircle'
import { Button, Grid, TextareaAutosize } from '@mui/material'

//Firebase Hooks
import {useKingsooService} from '../../../../hooks/useKingsooServices'

export default function FormDialog({ id, setUid }) {
  const [open, setOpen] = useState(false)
  const [flag, setFlag] = useState('')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const {updateFNJR} = useKingsooService()

  const transaction = async (e) => {
    e.preventDefault()
    console.log(flag, id)
    try {
      await updateFNJR(id, { flag })
      setOpen(false)
    } catch (error) {
      // console.log(error)
    }
  }
 
  return (
    <div>
      <button variant="gradient" onClick={handleClickOpen}>
        <FlagCircleIcon
          color="error"
          style={{
            width: '20px',
            height: '20px',
          }}
        />
      </button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <Grid component="form" onSubmit={transaction}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ marginBottom: '10px' }}>
              Reason for calling back transaction
            </DialogContentText>
            <TextareaAutosize
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 
            rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 
            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              autoFocus
              onChange={(e) => setFlag(e.target.value)}
              margin="dense"
              id="name"
              type="text"
              placeholder="Enter reason..."
              minRows={5}
              style={{ width: '400px', outline: 'none' }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="error"
              size="small"
              style={{ marginRight: '1rem' }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={handleClose}
              variant="outlined"
              color="success"
              size="small"
              style={{ marginRight: '1.5rem' }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </div>
  )
}
