import { useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Paper } from '@mui/material'
import Link from '@mui/material/Link'
import logo from '../../../assets/images/logo.png'
import MDBox from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import '../index.css'
import { Transition } from '@headlessui/react'
// Firebase Hook
import { useLogin } from '../../../hooks/useLogin'

import CircularProgress from '@mui/material/CircularProgress'
import styled from '@emotion/styled'
import { CheckIcon, XIcon } from '@heroicons/react/outline'
import { ArrowBackIos } from '@mui/icons-material'

const theme = createTheme()

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'grey',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#033237',
    },
  },
})

const LoginButton = styled(Button)({
  boxShadow: 'none',
  padding: '6px 12px',
  backgroundColor: '#033237',
  '&:hover': {
    backgroundColor: '#033233',
  }
})

function Footer(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {' © '}
      <Link color="inherit" href="#" sx={{ textDecoration: 'none' }}>
        Kingsoo Group
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  )
}

export default function ForgotPassword() {
  const [email, setEmail] = useState('')
  const { forgotPassword, message, isPending, error } = useLogin()

  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault()
    forgotPassword(email)
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="login xl:bg-blue-50 md:bg-blue-50 bg-teal-900 flex justify-center md:justify-between">
        <div className='left h-screen w-1/2 hidden md:block lg:block'>
          <Link href='/'>
            <img
              src={logo}
              alt="logo"
              className='h-34 w-24 mt-2'
            />
          </Link>
          <h1 className='flex flex-col items-center 2xl:mt-80 mt-36 md:mt-80 2xl:text-7xl xl:text-6xl md:text-5xl lg:mt-36 text-6xl text-center text-white font-extrabold'>
            <span className='mr-44'>KGM</span>
            <span className='ml-34'>ERP</span>
            <span className='ml-64 md:ml-44'>SYSTEM</span>
          </h1>
          <div className='2xl:mt-96 mt-44 md:mt-96 xl:mt-60 text-white'>
            <Footer
              sx={{ color: 'white' }}
            />
          </div>
        </div>
        <MDBox px={1} height="100vh" mx-auto>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Paper className="paper-box" sx={{ borderRadius: '20px', background: '#eef3fb' }}>
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: -7,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <img src={logo} alt="logo" className="logo" />
                    <Typography component="h1" variant="h5" mt={3}>
                      Reset Password
                    </Typography>
                  </Box>

                  <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{
                      mt: 3,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      width: '300px',
                      height: '250px'
                    }}
                  >
                    <CssTextField
                      margin="normal"
                      required
                      fullWidth
                      name="email"
                      type="email"
                      autoComplete="email"
                      onChange={(e) => setEmail(e.target.value)}
                      id="email"
                      label="Email"
                      placeholder="Enter your email address"
                      size="small"
                    />

                    {!isPending && (
                      <LoginButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Reset
                      </LoginButton>
                    )}
                    {isPending && (
                      <LoginButton
                        type="submit"
                        fullWidth
                        sx={{ mt: 3, mb: 2 }}
                      >
                        <CircularProgress size={25} />
                      </LoginButton>
                    )}
                  </Box>
                  <Box
                    sx={{ mt: 3, cursor: 'pointer' }}
                    onClick={() => navigate('/')}
                  >
                    <Typography variant="body2" display="flex" alignItems="center" mb={4} gutterBottom>
                      <ArrowBackIos sx={{ fontSize: 15 }} /> Back To Login
                    </Typography>
                  </Box>
                </Box>
              </Container>
            </Paper>
          </Grid>
        </MDBox>
        <div className='right'></div>
        {message && (
          <div
            aria-live="assertive"
            className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
          >
            <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
              {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
              <Transition
                show={true}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="p-4">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 rounded-full bg-green-700">
                        <CheckIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-green-700">
                          {message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        )}
        {error && (
          <div
            aria-live="assertive"
            className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
          >
            <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
              {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
              <Transition
                show={true}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="p-4">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 rounded-full bg-red-500">
                        <XIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-red-700">
                          {error}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        )}
      </div>
    </ThemeProvider>
  )
}
