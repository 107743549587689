import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import FormFields from "layouts/pages/account/components/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import { Field } from "formik";

// NewUser page components
import { Typography } from "@mui/material";
import FormField from "../FormField/index";
import selectData from "../data";
import "./style.css";
import {
  Abia,
  Adamawa,
  AkwaIbom,
  Anambra,
  Bauchi,
  Bayelsa,
  Benue,
  Borno,
  CrossRiver,
  Delta,
  Ebonyi,
  Edo,
  Ekiti,
  Enugu,
  FCT,
  Gombe,
  Imo,
  Jigawa,
  Kaduna,
  Kano,
  Katsina,
  Kebbi,
  Kogi,
  Kwara,
  Lagos,
  Nasarawa,
  Niger,
  Ogun,
  Ondo,
  Osun,
  Oyo,
  Plateau,
  Rivers,
  Sokoto,
  Taraba,
  Yobe,
  Zamfara
} from "./LGA";
import DatePickerField from "../FormField/DatePickerField";

function UserInfo({ formData }) {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const {
    firstName,
    otherName,
    lastName,
    gender,
    maritalStatus,
    workEmail,
    dateOfBirth,
    nationality,
    stateOfOrigin,
    lga,
    currentAddress,
    permanentAddress,
  } = formField;
  const {
    firstName: firstNameV,
    lastName: lastNameV
  } = values;

  const [selected, setSelected] = useState("");

  const changeSelectOptionHandler = e => {
    setSelected(e.target.value);
    setFieldValue("stateOfOrigin", e.target.value);
  };

  /** Type variable to store different array for different dropdown */
  let type = null;

  /** This will be used to create set of options that user will see */
  let options = null;

  /** Setting Type variable according to dropdown */
  if (selected === "Abia") {
    type = Abia;
  } else if (selected === "Adamawa") {
    type = Adamawa;
  } else if (selected === "Akwa Ibom") {
    type = AkwaIbom;
  } else if (selected === "Anambra") {
    type = Anambra;
  } else if (selected === "Bauchi") {
    type = Bauchi;
  } else if (selected === "Bayelsa") {
    type = Bayelsa;
  } else if (selected === "Benue") {
    type = Benue;
  } else if (selected === "Borno") {
    type = Borno;
  } else if (selected === "Cross River") {
    type = CrossRiver;
  } else if (selected === "Delta") {
    type = Delta;
  } else if (selected === "Akwa Ibom") {
    type = AkwaIbom;
  } else if (selected === "Ebonyi") {
    type = Ebonyi;
  } else if (selected === "Edo") {
    type = Edo;
  } else if (selected === "Ekiti") {
    type = Ekiti;
  } else if (selected === "Rivers") {
    type = Rivers;
  } else if (selected === "Enugu") {
    type = Enugu;
  } else if (selected === "FCT - Abuja") {
    type = FCT;
  } else if (selected === "Gombe") {
    type = Gombe;
  } else if (selected === "Imo") {
    type = Imo;
  } else if (selected === "Jigawa") {
    type = Jigawa;
  } else if (selected === "Kaduna") {
    type = Kaduna;
  } else if (selected === "Kano") {
    type = Kano;
  } else if (selected === "Katsina") {
    type = Katsina;
  } else if (selected === "Kebbi") {
    type = Kebbi;
  } else if (selected === "Kogi") {
    type = Kogi;
  } else if (selected === "Kwara") {
    type = Kwara;
  } else if (selected === "Lagos") {
    type = Lagos;
  } else if (selected === "Nasarawa") {
    type = Nasarawa;
  } else if (selected === "Niger") {
    type = Niger;
  } else if (selected === "Ogun") {
    type = Ogun;
  } else if (selected === "Ondo") {
    type = Ondo;
  } else if (selected === "Osun") {
    type = Osun;
  } else if (selected === "Oyo") {
    type = Oyo;
  } else if (selected === "Plateau") {
    type = Plateau;
  } else if (selected === "Sokoto") {
    type = Sokoto;
  } else if (selected === "Taraba") {
    type = Taraba;
  } else if (selected === "Yobe") {
    type = Yobe;
  } else if (selected === "Zamfara") {
    type = Zamfara;
  }
  if (type) {
    options = type.map(el =>
      <option key={el}>
        {el}
      </option>
    );
  }
  return (
    <MDBox className="userinfo">
      <MDBox lineHeight={0}>
        <Typography variant="h5" sx={{ borderBottom: 1, pb: 1, pt: 4 }}>
          Personal Data
        </Typography>
      </MDBox>
      <MDBox>
        <Grid container spacing={3} mt={2} mb={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">First Name* </Typography>
            <FormField
              type={firstName.type}
              name={firstName.name}
              value={firstNameV}
              placeholder="Enter First Name"
              error={errors.firstName && touched.firstName}
              success={firstNameV.length > 0 && !errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Other Name </Typography>
            <FormField
              name={otherName.name}
              placeholder="Enter Other Name"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Last Name* </Typography>
            <FormField
              type={lastName.type}
              name={lastName.name}
              value={lastNameV}
              placeholder="Enter Last Name"
              error={errors.lastName && touched.lastName}
              success={lastNameV.length > 0 && !errors.lastName}

            />
          </Grid>
        </Grid>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Gender * </Typography>
            <div className="gender">
              <MDBox className="selector">
                <Field
                  className="mt-1 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  as="select"
                  name={gender.name}>
                  <option value="" disabled selected>
                    Select gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Field>
              </MDBox>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Work Email </Typography>
            <FormField
              name={workEmail.name}
              placeholder="Enter Email Address"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Phone Number </Typography>
            <FormField name="phoneNumber" placeholder="Enter Phone Number" />
          </Grid>
        </Grid>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Nationality </Typography>
            <FormField
              name={nationality.name}
              placeholder="Enter Nationality"

            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">State Of Origin </Typography>
            <select
              onChange={changeSelectOptionHandler}
              className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
              name={stateOfOrigin.name}>
              {selectData.states.map(states =>
                <option>
                  {states}
                </option>
              )}
            </select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Local Government Area</Typography>
            <Field
              className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
              as="select"
              name={lga.name}>
              {options}
            </Field>
          </Grid>
        </Grid>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Marital Status </Typography>
            <Field
              className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
              as="select"
              name={maritalStatus.name}>
              <option value="" disabled selected>
                Select Marital Status
              </option>
              <option value="Married">Married</option>
              <option value="Single">Single</option>
            </Field>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Date Of Birth</Typography>
            <DatePickerField
              name={dateOfBirth.name}
              format="dd/MM/yy"
              views={["year", "month", "date"]}
              minDate={"2022/01/01"}
              maxDate={new Date("2024/12/31")}
              fullWidth
            />
          </Grid>
        </Grid>
        <Typography pt={5} variant="h5" sx={{ borderBottom: 1, pb: 1, pt: 4 }}>
          Addresses
        </Typography>
        <Grid container spacing={3} mt={2} mb={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Current Address </Typography>
            <FormField
              name={currentAddress.name}
              placeholder="Enter Current Address"

            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Permanent Address </Typography>
            <FormField
              name={permanentAddress.name}
              placeholder="Enter Permanent Address"

            />
          </Grid>
        </Grid>
        <Typography pt={5} variant="h5" sx={{ borderBottom: 1, pb: 1, pt: 4 }}>
          Qualifications
        </Typography>
        <Grid container spacing={3} mb={4} mt={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">
              Accademic Qualifications{" "}
            </Typography>
            <Field
              className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
              as="select"
              name="academicQualification">
              <option disabled selected value="">
                Academic Qualification
              </option>

              <option>HND</option>
              <option>OND</option>

              <option>BSC</option>
              <option>BED</option>
            </Field>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Awarding Institution </Typography>
            <Autocomplete
              disablePortal
              options={selectData.universities}
              name="universities"
              renderInput={params => <FormFields {...params} />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Date of Graduation</Typography>
            <DatePickerField
              name="dateOfGraduation"
              format="dd/MM/yy"
              views={["year", "month", "date"]}
              minDate={"2022/01/01"}
              maxDate={new Date("2024/12/31")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">
              Accademic Qualifications{" "}
            </Typography>
            <Field
              className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
              as="select"
              name="academicQualification1">
              <option disabled selected value="">
                Academic Qualification
              </option>

              <option>HND</option>
              <option>OND</option>

              <option>BSC</option>
              <option>BED</option>
            </Field>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Awarding Institution </Typography>
            <Autocomplete
              disablePortal
              options={selectData.universities}
              name="awardingInstitution1"
              renderInput={params => <FormFields {...params} />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Date of Graduation </Typography>
            <DatePickerField
              name="dateOfGraduation1"
              format="dd/MM/yy"
              views={["year", "month", "date"]}
              minDate={"2022/01/01"}
              maxDate={new Date("2024/12/31")}
              fullWidth
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired
};

export default UserInfo;
