import MUIDataTable from 'mui-datatables'
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

import React, { useState } from 'react'

import Modal from './Modal'

// Firebase Hook
import { useCollection } from 'hooks/useCollection'

const NonStatutoryTable = () => {
  const [responsive] = useState('horizontal')
  const [tableBodyHeight] = useState('auto')
  const [tableBodyMaxHeight] = useState('')
  const [searchBtn] = useState(true)
  const [downloadBtn] = useState(true)
  const [printBtn] = useState(true)
  const [viewColumnBtn] = useState(true)
  const [filterBtn] = useState(true)
  const [uid, setUid] = useState('')

  const { documents } = useCollection('jobRelatedForm')
  const data = documents.filter(
    (data) =>
      data.paymentType === 'Non Statutory' &&
      data.status === 'Declined' &&
      (data.department === 'Safety' || data.department === 'Safety(H.O.D)')
  )

  const getId = (id) => {
    console.log('the id', id)
    setUid(id)
  }

  const muiCache = createCache({
    key: 'mui-datatables',
    prepend: true,
  })

  const columns = [
    {
      name: 'requestId',
      label: 'Request ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'id',
      label: 'Id',
      hide: true,
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'fileId',
      label: 'Job File Number',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Requester',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'date',
      label: 'Date & Time',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'paymentValue',
      label: 'Value',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'request',
      label: 'Request for',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (documents.status === 'Pending') {
            return (
              <div style={{ display: 'flex' }}>
                <button
                  style={{
                    background: 'transparent',
                    border: 'none',
                  }}
                  onClick={(e) => getId(tableMeta.rowData[1])}
                >
                  <Modal id={uid} setUid={setUid} />
                </button>
              </div>
            )
          }
        },
      },
    },
  ]
  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: 'requestId',
      direction: 'asc',
    },
  }

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 1,
              paddingTop: 1,
              backgroundColor: '#033237',
            },
          },
        },

        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              '&:nth-child(odd)': {
                //backgroundColor: "#f6f6f6"
              },
              fontSize: '12px',
            },
          },
        },
      },
    })
  return (
    <div>
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </ThemeProvider>
      </CacheProvider>
    </div>
  )
}

export default NonStatutoryTable
