const selectData = {
    employeeDepartment: [
        "MD",
        "ED",
        "Freight Forwarding",
        "Marine",
        "Compliance Business Development",
        "Corporate & Commercial",
        "Finance and Accounting",
        "Transport & Logistics",
        "Chief Operating Officer",
        "Admin & Human Resources"
    ]
}

export default selectData