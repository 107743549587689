// prop-type is a library for typechecking of props
// import PropTypes from "prop-types";

// @mui material components
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
// import Autocomplete from "@mui/material/Autocomplete";
// import FormFields from "layouts/pages/account/components/FormField";
import { Field } from 'formik'
// NewUser page components
import FormField from '../FormField/index'
import selectData from '../data'
import './style.css'
import { Typography } from '@mui/material'
function BankInfo() {
  // const { formField, values, errors, touched } = formData;
  // const { twitter, facebook, instagram } = formField;
  // const { twitter: twitterV, facebook: facebookV, instagram: instagramV } = values;

  return (
    <div className="bank">
      <MDBox>
        <Typography
          variant="h5"
          sx={{ borderBottom: 1, pb: 1, pt: 4 }}
        >
          Bank Details
        </Typography>
        <MDBox mt={1.625}>
          <Grid container spacing={4} mt={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2">Bank Name </Typography>
              <Field
                className="mt-1 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                as="select"
                name="employeeBank"
              >
                {selectData.banks.map((person) => (
                  <option>{person}</option>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2">
                Bank Account Number{' '}
              </Typography>
              <FormField
                name="accountNumber"
                placeholder="Enter Account Number"
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2">Pension Manager </Typography>
              <FormField
                name="pensionManager"
                placeholder="Pension Manager"
                inputProps={{ style: { textTransform: 'capitalize' } }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2">Pension Number </Typography>
              <FormField
                name="pensionNumber"
                type="number"
                placeholder="Enter Pension Number"
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </div>
  )
}

// typechecking props for BankInfo
// BankInfo.propTypes = {
//   formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
// };

export default BankInfo
