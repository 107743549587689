import { useState } from 'react'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import MUIDataTable from 'mui-datatables'
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

// Modal
import Modal from './Modal'

//Firebase Hook
import { useCollection } from 'hooks/useCollection'

const muiCache = createCache({
  key: 'mui-datatables',
  prepend: true,
})

const CompletedJobFile = () => {
  const [responsive] = useState('horizontal')
  const [tableBodyHeight] = useState('auto')
  const [tableBodyMaxHeight] = useState('')
  const [searchBtn] = useState(true)
  const [downloadBtn] = useState(true)
  const [printBtn] = useState(true)
  const [viewColumnBtn] = useState(true)
  const [filterBtn] = useState(true)
  const [userId, setUserId] = useState('')

  const { documents } = useCollection('users')

  const getUserId = (id) => {
    // console.log('the id', id)
    setUserId(id)
  }

  const columns = [
    {
      name: 'firstName',
      label: 'Employee Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'id',
      hide: true,
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
      },
    },
    {
      name: 'staffid',
      label: 'Staff ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'employeeDepartment',
      label: 'Department',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'employeedesignation',
      label: 'Designation',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Phone Number',
      label: 'Contact',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'workEmail',
      label: 'Email Address',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: 'flex' }}>
              <button
                style={{
                  background: 'transparent',
                  border: 'none',
                }}
                onClick={(e) => getUserId(tableMeta.rowData[1])}
              >
                <Modal setUserId={setUserId} id={userId} />
              </button>
            </div>
          )
        },
      },
    },
  ]

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: 'firstName',
      direction: 'asc',
    },
  }

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 1,
              paddingTop: 1,
              fontSize: '2pt',
              borderBottom: '2px solid black',
              backgroundColor: '#033237',
            },
          },
          paddingBottom: 2,
          marginBottom: 8,
          paddingTop: 1,
          backgroundColor: '#033237',
        },
      },
    })

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="mt-4">
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              title={'All Employees'}
              data={documents}
              columns={columns}
              options={options}
              style={{ marginTop: '10px' }}
            />
          </ThemeProvider>
        </CacheProvider>
      </div>
    </DashboardLayout>
  )
}

export default CompletedJobFile
