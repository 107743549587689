import { useState } from 'react'
import { Link } from 'react-router-dom'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import MUIDataTable from 'mui-datatables'
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

// Modal
import Modal from './Modal'
import { Edit } from '@mui/icons-material'

//Firebase Hook
import { useCollection } from 'hooks/useCollection'
import {useKingsooService} from '../../../hooks/useKingsooServices'

const muiCache = createCache({
  key: 'mui-datatables',
  prepend: true,
})

const ClientTable = () => {
  const [responsive] = useState('horizontal')
  const [tableBodyHeight] = useState('auto')
  const [tableBodyMaxHeight] = useState('')
  const [searchBtn] = useState(true)
  const [downloadBtn] = useState(true)
  const [printBtn] = useState(true)
  const [viewColumnBtn] = useState(true)
  const [filterBtn] = useState(true)
  const [clientId, setClientId] = useState('')
  const {deleteClient} = useKingsooService()

  const { documents } = useCollection('clients')

  const getClientId = (id) => {
    // console.log('the id', id)
    setClientId(id)
  }

  const handleDelete = async (id) => {
    await deleteClient(id)
  }

  const columns = [
    {
      name: 'clientName',
      label: 'Client Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'id',
      hide: true,
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
      },
    },
    {
      name: 'prefix',
      label: 'Prefix',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'businessCategory',
      label: 'Category',
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'companyEmail',
      label: 'Email Address',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: 'flex', gap: '.8rem' }}>
              <button
                style={{
                  background: 'transparent',
                  border: 'none',
                }}
                onClick={(e) => getClientId(tableMeta.rowData[1])}
              >
                <Modal id={clientId} setClientId={setClientId} />
              </button>
              <button
                style={{
                  border: 'none',
                  background: 'transparent',
                  marginRight: '2px',
                  cursor: 'pointer',
                }}
              // onClick={() => console.log(value, tableMeta)}
              >
                <Link
                  to={`/documentation/client-management/create-company/${tableMeta.rowData[1]}`}
                  style={{ textDecoration: 'none', color: '#033237' }}
                >
                  <Edit
                    sx={{
                      width: '20px',
                      height: '20px',
                      color: 'green',
                    }}
                  />
                </Link>
              </button>
            </div>
          )
        },
      },
    },
  ]
  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: 'clientName',
      direction: 'asc',
    },
  }

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 1,
              paddingTop: 1,
              backgroundColor: '#033237',
            },
          },
        },

        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              '&:nth-child(odd)': {
                //backgroundColor: "#f6f6f6"
              },
              fontSize: '12px',
            },
          },
        },
      },
    })

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme}>
          <MUIDataTable
            title={'Client Table'}
            data={documents}
            columns={columns}
            options={options}
            style={{ marginTop: '10px' }}
          />
        </ThemeProvider>
      </CacheProvider>
    </DashboardLayout>
  )
}

export default ClientTable
