export const unit = {
  employeeUnit: ['Gate', 'Satey(H.O.D.)'],
}

export const Admin = [
  '-- Select Unit --',
  'Admin & Human Resource',
  'Admin Staff',
  'PA'
  // 'Control'
]

export const EMC = [
  '-- Select Unit --',
  'Managing Director',
  'Executive Director',
  'Chief Operating Officer',
]

export const Compliance = [
  '-- Select Unit --',
  'Monitoring & Compliance',
  'Monitoring & Compliance(H.O.D)',
]

export const Commercial = [
  '-- Select Unit --',
  'Commercial(H.O.D)',
  'Commercial',
]

export const Transport = [
  '-- Select Unit --',
  'Transport & Logistics(H.O.D)',
  'Transport & Logistics',
]

export const Operation = [
  '-- Select Unit --',
  'Documentation',
  'Direct Trade Input',
  'Terminal',
  'Operation(H.O.D)',
  'HSE',
  'Field Agent',
]

export const Finance = [
  '-- Select Unit --',
  'Finance & Accounting',
  'Finance(H.O.D)',
  'Pay Officer',
]

export const Marine = ['-- Select Unit --', 'Marine(H.O.D.)', 'Marine']
