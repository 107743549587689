import React, { useState, useEffect } from "react";
import { useField } from "formik";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import DateFnsUtils from "@date-io/date-fns";
// import {
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider
// } from "@material-ui/pickers";
import { Grid, ThemeProvider } from "@mui/material";
import theme from "../../../../../assets/theme";
export default function DatePickerField(props) {
  const [field, meta, helper] = useField(props);
  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;
  const { value } = field;
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(
    () => {
      if (value) {
        const date = new Date(value);
        setSelectedDate(date);
      }
    },
    [value]
  );

  function _onChange(date) {
    if (date) {
      setSelectedDate(date);
      try {
        const ISODateString = date.toISOString();
        setValue(ISODateString);
      } catch (error) {
        setValue(date);
      }
    } else {
      setValue(date);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            value={selectedDate}
            onChange={_onChange}
            fullWidth
            renderInput={params => <TextField {...params} />}
          />
        </LocalizationProvider>
        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            {...field}
            {...props}
            value={selectedDate}
            inputVariant="outlined"
            onChange={_onChange}
            error={isError}
            invalidDateMessage={isError && error}
            helperText={isError && error}
          />
        </MuiPickersUtilsProvider> */}
      </Grid>
    </ThemeProvider>
  );
}
