import { EyeIcon } from '@heroicons/react/solid'
import { Paper, Grid } from '@mui/material'
import MDAccordion from 'components/MDAccordion'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import {useKingsooService} from '../../../../hooks/useKingsooServices'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#fff' }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#033237',
  color: '#fff',
  borderRadius: 10,
  margin: 3,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}))

const PendingJobModal = () => {
  const [expanded, setExpanded] = useState(null)
  const [paarReceivalDate, setPaarReceivalDate] = useState()
  const [expectedTimeOfArrival, setExpectedTimeOfArrival] = useState()
  const [containerInfo, setContainerInfo] = useState([])
  const [billOfLaden, setBillOfLaden] = useState('')
  const [isPaarAvailable, setIsPaarAvailable] = useState()
  const [companyId, setCompanyId] = useState('')
  const [consigneeId, setConsigneeId] = useState('')
  const [jobManagerId, setJobManagerId] = useState('')
  const [jobType, setJobType] = useState('')
  const [importType, setImportType] = useState('')
  const [scacOrAirline, setScacOrAirline] = useState('')
  const [freightType, setFreightType] = useState('')
  const [paarValue, setPaarValue] = useState('')
  const [preAlertstatus, setPreAlertStatus] = useState('')
  const [fileId, setFileId] = useState('')
  const [preAlertCreatedAt, setPreAlertCreatedAt] = useState('')
  const [jobCreatedDate, setJobCreatedDate] = useState('')
  const [formMImage, setFormMImage] = useState('')
  const [customDutyReceiptImage, setCustomDutyReceiptImage] = useState('')
  const [anlcaImage, setAnlcaImage] = useState('')
  const [nafdacCertImage, setNafdacCertImage] = useState('')
  const [commercialInvoiceImage, setCommercialInvoiceImage] = useState('')
  const [soncapCertImage, setSoncapCertImage] = useState('')
  const [ccvoFormC16Image, setCcvoFormC16Iamge] = useState('')
  const [terminalInvoiceImage, setTerminalInvoiceImage] = useState('')
  const [shippingInvoiceImage, setShippingInvoiceImage] = useState('')
  const [fieldAgentName, setFieldAgentName] = useState('')
  const [dispatchOfficer, setDispatchOfficer] = useState('')
  const [letterOfTransireFile, setLetterOfTransireFile] = useState('')
  const [loadListFile, setLoadListFile] = useState('')
  const [wayBillFile, setWayBillFile] = useState('')
  const [shippingPaymentInfo, setShippingPaymentInfo] = useState([])
  const [terminalPaymentInfo, setTerminalPaymentInfo] = useState([])
  const [dutyPaymentInfo, setDutyPaymentInfo] = useState([])

  const {getJob} = useKingsooService()

  const { id } = useParams()

  const detail = async () => {
    try {
      const docSnap = await getJob(id)
      setBillOfLaden(docSnap.data().billOfLaden)
      setBillOfLaden(docSnap.data().airWayBill)
      setCompanyId(docSnap.data().companyId)
      setConsigneeId(docSnap.data().consigneeId)
      setIsPaarAvailable(docSnap.data().isPaarAvailable)
      setJobManagerId(docSnap.data().jobManagerId)
      setJobType(docSnap.data().jobType)
      setImportType(docSnap.data().importType)
      setScacOrAirline(docSnap.data().scacOrAirline)
      setFreightType(docSnap.data().freightType)
      setPaarValue(docSnap.data().paarValue)
      setPreAlertStatus(docSnap.data().preAlertstatus)
      setFileId(docSnap.data().fileId)
      setContainerInfo(docSnap.data().containerInfo)
      setPaarReceivalDate(docSnap.data().paarReceivalDate)
      setExpectedTimeOfArrival(docSnap.data().expectedTimeOfArrival)
      setPreAlertCreatedAt(docSnap.data().preAlertCreatedAt)
      setJobCreatedDate(docSnap.data().jobCreatedDate)
      setAnlcaImage(docSnap.data().anlcaImage)
      setFormMImage(docSnap.data().formMImage)
      setCustomDutyReceiptImage(docSnap.data().customDutyReceiptImage)
      setNafdacCertImage(docSnap.data().nafdacCertImage)
      setCommercialInvoiceImage(docSnap.data().commercialInvoiceImage)
      setSoncapCertImage(docSnap.data().soncapCertImage)
      setCcvoFormC16Iamge(docSnap.data().ccvoFormC16Image)
      setTerminalInvoiceImage(docSnap.data().terminalInvoiceImage)
      setShippingInvoiceImage(docSnap.data().shippingInvoiceImage)
      setFieldAgentName(docSnap.data().fieldAgentName)
      setDispatchOfficer(docSnap.data().dispatchOfficer)
      setLetterOfTransireFile(docSnap.data().letterOfTransireFile)
      setLoadListFile(docSnap.data().loadListFile)
      setWayBillFile(docSnap.data().wayBillFile)
      setShippingPaymentInfo(docSnap.data().shippingPaymentInfo)
      setTerminalPaymentInfo(docSnap.data().terminalPaymentInfo)
      setDutyPaymentInfo(docSnap.data().dutyPaymentInfo)

      // setContainerInfo(docSnap.data().containerInfo)
    } catch (error) {
      //console.log(error)
    }
  }

  useEffect(() => {
    // console.log('na u know', id)
    if (id !== undefined || id !== '') {
      detail()
      // console.log('id', id)
    }
  }, [id])

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
        <MDBox
          className="md:flex-row"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
          container
          spacing={2}
        >
          <MDBox>
            <MDTypography
              variant="h5"
              fontWeight="bold"
              sx={{ borderBottom: 1, pb: 1, pt: 4 }}
            >
              JOB FILE - {companyId}
            </MDTypography>
          </MDBox>
          <Grid container mb={4} sx={{ ml: 2, mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>FILE ID:</b> {fileId}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Client:</b> {companyId}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>IMPORT TYPE:</b> {importType}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>NUMBER OF CONTAINERS:</b> {containerInfo.length}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>JOB MANAGER:</b> {jobManagerId}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>DATE OF PAAR:</b> {paarReceivalDate}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>SCAC / AIRLINE:</b> {scacOrAirline}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>FIELD AGENT NAME:</b> {fieldAgentName}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>PAAR AVAILABLE:</b> {isPaarAvailable}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>JOB TYPE:</b> {jobType}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>FREIGHT TYPE:</b> {freightType}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>CONSIGNEE ID:</b> {consigneeId}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  {' '}
                  <b>BOL/MAWB:</b> {billOfLaden}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>ETA:</b> {expectedTimeOfArrival}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Dispatch Officer:</b> {dispatchOfficer}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>JOB STATUS:</b> {preAlertstatus}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>DATE & TIME OF JOB PRE-ALERT:</b> {preAlertCreatedAt}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>DATE & TIME OF JOB CREATION:</b> {jobCreatedDate}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>PAAR VALUE:</b> {paarValue}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {shippingPaymentInfo.map((ship) => {
                return (
                  <>
                    <MDBox
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: '1rem',
                      }}
                    >
                      <MDTypography
                        sx={{ fontSize: '15px' }}
                        mt={0.5}
                        gutterBottom
                      >
                        <li>
                          <b>SHIPPING DOCUMENT</b>
                        </li>
                      </MDTypography>
                      <a
                        href={ship.shippingFile}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <EyeIcon
                          style={{ color: '#033237' }}
                          className="ml-4 h-6 w-6"
                          aria-hidden="true"
                        />
                      </a>
                    </MDBox>
                    <MDTypography
                      sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                      gutterBottom
                    >
                      <li>
                        <b>LOADED BY:</b> {ship.shippingUploadedBy}
                      </li>
                    </MDTypography>
                    <MDTypography
                      sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                      gutterBottom
                    >
                      <li>
                        <b>DATE:</b> {ship.shippingDate}
                      </li>
                    </MDTypography>
                  </>
                )
              })}
            </Grid>
            <Grid item xs={12} sm={6}>
              {terminalPaymentInfo.map((terminal) => {
                return (
                  <>
                    <MDBox
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: '1rem',
                      }}
                    >
                      <MDTypography
                        sx={{ fontSize: '15px' }}
                        mt={0.5}
                        gutterBottom
                      >
                        <li>
                          <b>TERMINAL PAYMENT RECEIPT</b>
                        </li>
                      </MDTypography>
                      <a
                        href={terminal.terminalPaymentReceipt}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <EyeIcon
                          style={{ color: '#033237' }}
                          className="ml-4 h-6 w-6"
                          aria-hidden="true"
                        />
                      </a>
                    </MDBox>
                    <MDTypography
                      sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                      gutterBottom
                    >
                      <li>
                        <b>LOADED BY:</b> {terminal.terminalUploadBy}
                      </li>
                    </MDTypography>
                    <MDTypography
                      sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                      gutterBottom
                    >
                      <li>
                        <b>DATE:</b> {terminal.dateOfTerminalPayment}
                      </li>
                    </MDTypography>
                  </>
                )
              })}
            </Grid>
            <Grid item xs={12} sm={6}>
              {dutyPaymentInfo.map((duty) => {
                return (
                  <>
                    <MDBox
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: '1rem',
                      }}
                    >
                      <MDTypography
                        sx={{ fontSize: '15px' }}
                        mt={0.5}
                        gutterBottom
                      >
                        <li>
                          <b>DUTY PAYMENT RECEIPT</b>
                        </li>
                      </MDTypography>
                      <a
                        href={duty.dutyReceiptFile}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <EyeIcon
                          style={{ color: '#033237' }}
                          className="ml-4 h-6 w-6"
                          aria-hidden="true"
                        />
                      </a>
                    </MDBox>
                    <MDTypography
                      sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                      gutterBottom
                    >
                      <li>
                        <b>DUTY BY:</b> {duty.dutyReceiptUploadedBy}
                      </li>
                    </MDTypography>
                    <MDTypography
                      sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                      gutterBottom
                    >
                      <li>
                        <b>DATE:</b> {duty.dutyReceiptDate}
                      </li>
                    </MDTypography>
                  </>
                )
              })}
            </Grid>
            <Grid item xs={12} sm={6}>
              {customDutyReceiptImage && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>CUSTOM DUTY RECEIPT</b>
                    </li>
                  </MDTypography>
                  <a
                    href={customDutyReceiptImage}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EyeIcon
                      style={{ color: '#033237' }}
                      className="ml-4 h-6 w-6"
                      aria-hidden="true"
                    />
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {wayBillFile && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>WAYBILL DOCUMENT</b>
                    </li>
                  </MDTypography>
                  <a
                    href={wayBillFile}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EyeIcon
                      style={{ color: '#033237' }}
                      className="ml-4 h-6 w-6"
                      aria-hidden="true"
                    />
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {letterOfTransireFile && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>Letter Of transire File</b>
                    </li>
                  </MDTypography>
                  <a
                    href={letterOfTransireFile}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EyeIcon
                      style={{ color: '#033237' }}
                      className="ml-4 h-6 w-6"
                      aria-hidden="true"
                    />
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {formMImage && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>FORM M</b>
                    </li>
                  </MDTypography>
                  <a
                    href={formMImage}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EyeIcon
                      style={{ color: '#033237' }}
                      className="ml-4 h-6 w-6"
                      aria-hidden="true"
                    />
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {anlcaImage && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>ANLCA</b>
                    </li>
                  </MDTypography>
                  <a
                    href={anlcaImage}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EyeIcon
                      style={{ color: '#033237' }}
                      className="ml-4 h-6 w-6"
                      aria-hidden="true"
                    />
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {nafdacCertImage && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>NAFDAC CERTIFICATE</b>
                    </li>
                  </MDTypography>
                  <a
                    href={nafdacCertImage}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EyeIcon
                      style={{ color: '#033237' }}
                      className="ml-4 h-6 w-6"
                      aria-hidden="true"
                    />
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {commercialInvoiceImage && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>COMMERCIAL INVOICE</b>
                    </li>
                  </MDTypography>
                  <a
                    href={commercialInvoiceImage}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EyeIcon
                      style={{ color: '#033237' }}
                      className="ml-4 h-6 w-6"
                      aria-hidden="true"
                    />
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {loadListFile && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>LOAD LIST FILE</b>
                    </li>
                  </MDTypography>
                  <a
                    href={loadListFile}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EyeIcon
                      style={{ color: '#033237' }}
                      className="ml-4 h-6 w-6"
                      aria-hidden="true"
                    />
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {soncapCertImage && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>SONCAP CERTIFICATE</b>
                    </li>
                  </MDTypography>
                  <a
                    href={soncapCertImage}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EyeIcon
                      style={{ color: '#033237' }}
                      className="ml-4 h-6 w-6"
                      aria-hidden="true"
                    />
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {ccvoFormC16Image && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>CCVO FORM C16</b>
                    </li>
                  </MDTypography>
                  <a
                    href={ccvoFormC16Image}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EyeIcon
                      style={{ color: '#033237' }}
                      className="ml-4 h-6 w-6"
                      aria-hidden="true"
                    />
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {terminalInvoiceImage && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>TERMINAL INVOICE</b>
                    </li>
                  </MDTypography>
                  <a
                    href={terminalInvoiceImage}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EyeIcon
                      style={{ color: '#033237' }}
                      className="ml-4 h-6 w-6"
                      aria-hidden="true"
                    />
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {shippingInvoiceImage && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>SHIPPING Invoice</b>
                    </li>
                  </MDTypography>
                  <a
                    href={shippingInvoiceImage}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EyeIcon
                      style={{ color: '#033237' }}
                      className="ml-4 h-6 w-6"
                      aria-hidden="true"
                    />
                  </a>
                </MDBox>
              )}
            </Grid>
          </Grid>
          {containerInfo.map((container, index) => (
            <MDAccordion
              expanded={expanded === `panel${index + 1}`}
              onChange={handleChange(`panel${index + 1}`)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>Container {index + 1}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} mb={4} sx={{ pl: 2 }}>
                  <Grid item xs={12} sm={4}>
                    <MDTypography
                      sx={{ fontSize: '14px', width: '100%' }}
                      gutterBottom
                    >
                      <li>
                        <b>Container Size:</b> {container.containerSize}
                      </li>
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MDTypography
                      sx={{ fontSize: '14px', width: '100%' }}
                      gutterBottom
                    >
                      <li>
                        <b>Container Number:</b> {container.containerId}
                      </li>
                    </MDTypography>
                  </Grid>
                  {container.gateStatus && (
                    <Grid item xs={12} sm={4}>
                      <MDTypography
                        sx={{ fontSize: '14px', mb: 2, width: '100%' }}
                        gutterBottom
                      >
                        <li>
                          <b>Gate Status:</b> {container.gateStatus}
                        </li>
                      </MDTypography>
                    </Grid>
                  )}
                  {container.tdoImage && (
                    <Grid item xs={12} sm={4}>
                      <MDBox
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <MDTypography sx={{ fontSize: '14px' }} gutterBottom>
                          <li>
                            <b>TDO : </b>
                          </li>
                        </MDTypography>
                        <a href={container.tdoImage} target="_blank">
                          <EyeIcon
                            style={{ color: '#033237' }}
                            className="ml-4 h-6 w-6"
                            aria-hidden="true"
                          />
                        </a>
                      </MDBox>
                      <MDTypography
                        sx={{ fontSize: '14px', width: '100%', ml: 2 }}
                        gutterBottom
                      >
                        <li>
                          <b>By:</b>
                          {container.tdoUploadedBy}
                        </li>
                        <li>
                          <b>Date:</b>
                          {container.tdoDate}
                        </li>
                        <li>
                          <b>Value:</b>
                          {container.tdoValue}
                        </li>
                      </MDTypography>
                    </Grid>
                  )}
                  {container.cNumber && (
                    <Grid item xs={12} sm={4}>
                      <MDTypography
                        sx={{ fontSize: '14px', width: '100%' }}
                        gutterBottom
                      >
                        <li>
                          <b>C Number:</b> {container.cNumber}
                        </li>
                      </MDTypography>
                    </Grid>
                  )}
                  {container.aNumber && (
                    <Grid item xs={12} sm={4}>
                      <MDTypography
                        sx={{ fontSize: '14px', width: '100%' }}
                        gutterBottom
                      >
                        <li>
                          <b>A Number:</b> {container.aNumber}
                        </li>
                      </MDTypography>
                    </Grid>
                  )}
                  {container.examinationBy && (
                    <Grid item xs={12} sm={4}>
                      <MDTypography
                        sx={{ fontSize: '14px', width: '100%' }}
                        gutterBottom
                      >
                        <li>
                          <b>Examination:</b> <b>by-</b>
                          {container.examinationBy},
                        </li>
                        <li>
                          <b>Issues-</b>
                          {container.examinationIssues || 'None'}
                        </li>
                        <li>
                          <b>Date & Time-</b>
                          {container.examinationDate}
                        </li>
                        <li>
                          <b>Examination Message</b>:
                          {container.examinationMessage || 'None'}
                        </li>
                      </MDTypography>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={4}>
                    <MDTypography
                      sx={{ fontSize: '14px', width: '100%' }}
                      gutterBottom
                    >
                      <li>
                        <b>Has container been loaded:</b>{' '}
                        {container.isLoaded ? 'Yes' : 'No'}
                      </li>
                      {container.isLoaded && (
                        <MDTypography
                          sx={{ fontSize: '14px', width: '100%', ml: 2 }}
                          gutterBottom
                        >
                          <li>
                            <b>Loaded By:</b> {container.loadedBy}
                          </li>
                          <li>
                            <b>Message :</b> {container.loadingMessage}
                          </li>
                          <li>
                            <b>Date & Time:</b> {container.loadedDate}
                          </li>
                        </MDTypography>
                      )}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MDTypography
                      sx={{ fontSize: '14px', width: '100%' }}
                      gutterBottom
                    >
                      <li>
                        <b>Has container been Released:</b>{' '}
                        {container.isReleased ? 'Yes' : 'No'}
                      </li>
                      {container.isReleased && (
                        <MDTypography
                          sx={{ fontSize: '14px', width: '100%', ml: 2 }}
                          gutterBottom
                        >
                          <li>
                            <b>Released By:</b> {container.releasedBy}
                          </li>
                          <li>
                            <b>Message :</b> {container.releaseMessage}
                          </li>
                          <li>
                            <b>Date & Time:</b> {container.releasedDate}
                          </li>
                        </MDTypography>
                      )}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MDTypography
                      sx={{ fontSize: '14px', width: '100%' }}
                      gutterBottom
                    >
                      <li>
                        <b>Has container been Crossed:</b>{' '}
                        {container.isCrossed ? 'Yes' : 'No'}
                      </li>
                      {container.isCrossed && (
                        <MDTypography
                          sx={{ fontSize: '14px', width: '100%', ml: 2 }}
                          gutterBottom
                        >
                          <li>
                            <b>Crossed By:</b> {container.crossedBy}
                          </li>
                          <li>
                            <b>Message :</b> {container.crossingMessage}
                          </li>
                          <li>
                            <b>Date & Time:</b> {container.crossedDate}
                          </li>
                        </MDTypography>
                      )}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MDTypography
                      sx={{ fontSize: '14px', width: '100%' }}
                      gutterBottom
                    >
                      <li>
                        <b>Container Inter Change:</b>{' '}
                        {container.isInterchanged ? 'Yes' : 'No'}
                      </li>
                      {container.isInterchanged && (
                        <MDTypography
                          sx={{ fontSize: '14px', width: '100%', ml: 2 }}
                          gutterBottom
                        >
                          <li>
                            <b>Loaded By:</b> {container.interchangedBy}
                          </li>
                          <li>
                            <b>Message :</b> {container.interchangedMessage}
                          </li>
                          <li>
                            <b>Date & Time:</b> {container.interchangedDate}
                          </li>
                        </MDTypography>
                      )}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MDTypography
                      sx={{ fontSize: '14px', width: '100%' }}
                      gutterBottom
                    >
                      <li>
                        <b>Has Container been vetted:</b>{' '}
                        {container.vettedBy ? 'Yes' : 'No'}
                      </li>
                      {container.vettedBy && (
                        <MDTypography
                          sx={{ fontSize: '14px', width: '100%', ml: 2 }}
                          gutterBottom
                        >
                          <li>
                            <b>Vetted By:</b> {container.vettedBy}
                          </li>
                          <li>
                            <b>Message :</b> {container.vetMessage}
                          </li>
                          <li>
                            <b>Date & Time:</b> {container.vettedDate}
                          </li>
                        </MDTypography>
                      )}
                    </MDTypography>
                  </Grid>
                  {container.transloadingBy && (
                    <Grid item xs={12} sm={4}>
                      <MDTypography
                        sx={{ fontSize: '14px', width: '100%' }}
                        gutterBottom
                      >
                        <li>
                          <b>Transloaded By:</b> {container.transloadingBy}
                        </li>
                        <li>
                          <b>Transloaded Message:</b>{' '}
                          {container.transloadingMessage}
                        </li>
                        <li>
                          <b>Date & Time:</b> {container.transloadingDate}
                        </li>
                      </MDTypography>
                    </Grid>
                  )}
                  {container.destination && (
                    <Grid item xs={12} sm={4}>
                      <MDTypography
                        sx={{ fontSize: '14px', width: '100%' }}
                        gutterBottom
                      >
                        <li>
                          <b>Destination:</b> {container.destination}
                        </li>
                      </MDTypography>
                    </Grid>
                  )}
                  {container.isInsuranceReady && (
                    <Grid item xs={12} sm={4}>
                      <MDTypography
                        sx={{ fontSize: '14px', width: '100%' }}
                        gutterBottom
                      >
                        <li>
                          <b>Insurance Ready:</b> {container.isInsuranceReady}
                        </li>
                        <li>
                          <b>Insurance Message:</b> {container.insuranceMessage}
                        </li>
                        <li>
                          <b>Insured By:</b> {container.insuranceBy}
                        </li>
                        <li>
                          <b>Insurance Date:</b> {container.insuranceDate}
                        </li>
                      </MDTypography>
                    </Grid>
                  )}
                  {container.recommendationBy && (
                    <Grid item xs={12} sm={4}>
                      <MDTypography
                        sx={{ fontSize: '14px', width: '100%' }}
                        gutterBottom
                      >
                        <li>
                          <b>Recommendation By:</b> {container.recommendationBy}
                        </li>
                        <li>
                          <b>Recommendation Message:</b>{' '}
                          {container.hseRecommendation}
                        </li>
                        <li>
                          <b>Date & Time:</b> {container.transloadingDate}
                        </li>
                      </MDTypography>
                    </Grid>
                  )}
                  {container.issuesMessage && (
                    <Grid item xs={12} sm={4}>
                      <MDTypography
                        sx={{ fontSize: '14px', width: '100%' }}
                        gutterBottom
                      >
                        <li>
                          <b>Issues:</b> {container.issuesMessage}
                        </li>
                      </MDTypography>
                    </Grid>
                  )}
                  {container.truckDriverName && (
                    <Grid item xs={12} sm={4}>
                      <MDTypography
                        sx={{ fontSize: '14px', width: '100%' }}
                        gutterBottom
                      >
                        <li>
                          <b>Truck Driver Name:</b> {container.truckDriverName}
                        </li>
                        <li>
                          <b>Truck Mate Name:</b> {container.truckMateName}
                        </li>
                        <li>
                          <b>Truck driver Number:</b>{' '}
                          {container.truckDriverNumber}
                        </li>
                        <li>
                          <b>Truck Number:</b> {container.truckPlateNumber}
                        </li>
                        <li>
                          <b>Category Of Truck:</b> {container.categoryOfTruck}
                        </li>
                      </MDTypography>
                    </Grid>
                  )}
                  {container.transTransferTruckDriverName && (
                    <Grid item xs={12} sm={4}>
                      <MDTypography
                        sx={{ fontSize: '14px', width: '100%' }}
                        gutterBottom
                      >
                        <li>
                          <b>Truck Driver Name:</b>{' '}
                          {container.transTransferTruckDriverName}
                        </li>
                        <li>
                          <b>Truck Mate Name:</b>{' '}
                          {container.transTransferTruckMateName}
                        </li>
                        <li>
                          <b>Truck driver Number:</b>{' '}
                          {container.transTransferTruckDriverNumber}
                        </li>
                        <li>
                          <b>Truck Number:</b>{' '}
                          {container.transTransferTruckPlateNumber}
                        </li>
                        <li>
                          <b>Category Of Truck:</b>{' '}
                          {container.transTransferCategoryOfTruck}
                        </li>
                      </MDTypography>
                    </Grid>
                  )}
                  {container.transTransfer1TruckDriverName && (
                    <Grid item xs={12} sm={4}>
                      <MDTypography
                        sx={{ fontSize: '14px', width: '100%' }}
                        gutterBottom
                      >
                        <li>
                          <b>Truck Driver Name:</b>{' '}
                          {container.transTransfer1TruckDriverName}
                        </li>
                        <li>
                          <b>Truck Mate Name:</b>{' '}
                          {container.transTransfer1TruckMateName}
                        </li>
                        <li>
                          <b>Truck driver Number:</b>{' '}
                          {container.transTransfer1TruckDriverNumber}
                        </li>
                        <li>
                          <b>Truck Number:</b>{' '}
                          {container.transTransfer1TruckPlateNumber}
                        </li>
                        <li>
                          <b>Category Of Truck:</b>{' '}
                          {container.transTransfer1CategoryOfTruck}
                        </li>
                      </MDTypography>
                    </Grid>
                  )}
                  {container.transDirectTruckDriverName && (
                    <Grid item xs={12} sm={4}>
                      <MDTypography
                        sx={{ fontSize: '14px', width: '100%' }}
                        gutterBottom
                      >
                        <li>
                          <b>Truck Driver Name:</b>{' '}
                          {container.transDirectTruckDriverName}
                        </li>
                        <li>
                          <b>Truck Mate Name:</b>{' '}
                          {container.transDirectTruckMateName}
                        </li>
                        <li>
                          <b>Truck driver Number:</b>{' '}
                          {container.transDirectTruckDriverNumber}
                        </li>
                        <li>
                          <b>Truck Number:</b>{' '}
                          {container.transDirectTruckPlateNumber}
                        </li>
                        <li>
                          <b>Category Of Truck:</b>{' '}
                          {container.transDirectCategoryOfTruck}
                        </li>
                      </MDTypography>
                    </Grid>
                  )}
                  {container.transOnneTruckDriverName && (
                    <Grid item xs={12} sm={4}>
                      <MDTypography
                        sx={{ fontSize: '14px', width: '100%' }}
                        gutterBottom
                      >
                        <li>
                          <b>Truck Driver Name:</b>{' '}
                          {container.transOnneTruckDriverName}
                        </li>
                        <li>
                          <b>Truck Mate Name:</b>{' '}
                          {container.transOnneTruckMateName}
                        </li>
                        <li>
                          <b>Truck driver Number:</b>{' '}
                          {container.transOnneTruckDriverNumber}
                        </li>
                        <li>
                          <b>Truck Number:</b>{' '}
                          {container.transOnneTruckPlateNumber}
                        </li>
                        <li>
                          <b>Category Of Truck:</b>{' '}
                          {container.transOnneCategoryOfTruck}
                        </li>
                      </MDTypography>
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </MDAccordion>
          ))}
        </MDBox>
      </Paper>
    </DashboardLayout>
  )
}

export default PendingJobModal
