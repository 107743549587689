import { useState, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
} from "@mui/material";
import Link from "@mui/material/Link";
import logo from "../../../assets/images/logo.png";
import MDBox from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../index.css";
import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
// Firebase Hook
import { useLogin } from "../../../hooks/useLogin";
import { useAuthContext } from "../../../hooks/useAuthContext";

import CircularProgress from "@mui/material/CircularProgress";
import styled from "@emotion/styled";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const theme = createTheme();

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    marginBottom: "10px",
    "& fieldset": {
      borderColor: "grey",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#033237",
    },
  },
});

const LoginButton = styled(Button)({
  boxShadow: "none",
  padding: "6px 12px",
  backgroundColor: "#033237",
  "&:hover": {
    backgroundColor: "#033233",
  },
});

function Footer(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {" © "}
      <Link color="inherit" href="#" sx={{ textDecoration: "none" }}>
        Kingsoo Group
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Login() {
  const { user } = useAuthContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { login, error, isPending } = useLogin();
  let navigate = useNavigate();
  useEffect(() => {
    if (user && user.photoURL === "Admin & Human Resource") {
      navigate("/human-resources");
    } else if (user && user.photoURL === "Documentation") {
      navigate("/documentation");
    } else if (user && user.photoURL === "Monitoring & Compliance") {
      navigate("/monitoring-compliance");
    } else if (user && user.photoURL === "MD") {
      navigate("/admin");
    } else if (user && user.photoURL === "Finance & Accounting") {
      navigate("/finance");
    } else if (user && user.photoURL === "Operation(H.O.D)") {
      navigate("/head-of-department-operations");
    } else if (user && user.photoURL === "Monitoring & Compliance(H.O.D)") {
      navigate("/head-of-department-monitoring-compliance");
    } else if (user && user.photoURL === "Commercial(H.O.D)") {
      navigate("/head-of-department-commercial");
    } else if (user && user.photoURL === "Finance(H.O.D)") {
      navigate("/head-of-department-finance");
    } else if (user && user.photoURL === "Marines(H.O.D)") {
      navigate("/head-of-department-marines");
    } else if (user && user.photoURL === "Satey(H.O.D)") {
      navigate("/head-of-department-safety");
    } else if (user && user.photoURL === "Transport & Logistics(H.O.D)") {
      navigate("/head-of-department-transport");
    } else if (user && user.photoURL === "Chief Operating Officer") {
      navigate("/chief-operating-officer");
    } else if (user && user.photoURL === "Managing Director") {
      navigate("/managing-director");
    } else if (user && user.photoURL === "Executive Director") {
      navigate("/executive-director");
    } else if (user && user.photoURL === "Pay Officer") {
      navigate("/pay-officer");
    } else if (user && user.photoURL === "Gate") {
      navigate("/gate");
    } else if (user && user.photoURL === "Terminal") {
      navigate("/terminal");
    } else if (user && user.photoURL === "PA") {
      navigate("/personal-assistant");
    } else if (user && user.photoURL === "Control") {
      navigate("/control");
    } else if (user && user.photoURL !== "") {
      navigate("/staff");
    } else {
      navigate("/");
    }
  }, [navigate, user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="login xl:bg-blue-50 md:bg-blue-50 bg-teal-900 flex justify-center md:justify-between">
        <div className="left h-screen w-1/2 hidden md:block lg:block">
          <Link href="/">
            <img src={logo} alt="logo" className="h-34 w-24 mt-2" />
          </Link>
          <h1 className="items-center 2xl:mt-72 mt-36 md:mt-80 2xl:text-7xl xl:text-6xl md:text-5xl lg:mt-36 text-6xl text-center text-cyan-700 font-extrabold">
            KGM ERP SYSTEM
            {/* <span className='text-cyan-700'>ERP</span>
            <span className='md:ml-44'></span> */}
          </h1>
          <p className="text-slate-50 px-20 text-center text-base mt-6 font-bold">
            Quick and easy access to manage your organization's operational flow
            in real-time, keep track consignees and consignment, monitor
            progress of job.
          </p>
          <div className="2xl:mt-96 lg:mt-64 mt-44 md:mt-96 xl:mt-60 flex flex-col text-white">
            <Footer sx={{ color: "white" }} />
            <a
              href="https://www.appnovia.com"
              target="_blank"
              className=" text-center justify-center items-center text-sm cursor-pointer"
            >
              Built by Appnovia Team
            </a>
          </div>
        </div>
        <MDBox px={1} height="100vh" mx-auto>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Paper
              elevation={4}
              className="paper-box"
              sx={{ borderRadius: "20px", background: "#eef3fb" }}
            >
              <Container>
                <CssBaseline />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: 'center',
                    mt: -5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img src={logo} alt="logo" className="logo" />
                    <Typography component="h1" variant="h5" mt={3}>
                      Welcome back,
                    </Typography>
                    <span className="text-xs">Login with your credentials</span>
                  </Box>
                  <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{
                      mt: 3,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "300px",
                      height: "300px",
                    }}
                  >
                    <CssTextField
                      margin="normal"
                      required
                      fullWidth
                      name="email"
                      autoComplete="email"
                      onChange={(e) => setEmail(e.target.value)}
                      id="email"
                      label="Email"
                      placeholder="Enter your email address"
                    />
                    <FormControl
                      sx={{ m: 1, width: "100%" }}
                      variant="outlined"
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility sx={{ color: "black" }} />
                              ) : (
                                <VisibilityOff sx={{ color: "black" }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        fullWidth
                      />
                    </FormControl>
                    <span
                      className="ml-auto text-end text-sm cursor-pointer"
                      onClick={() => navigate("/forgot-password")}
                    >
                      Forgot Password?
                    </span>
                    {!isPending && (
                      <LoginButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        LOGIN
                      </LoginButton>
                    )}
                    {isPending && (
                      <LoginButton
                        type="submit"
                        fullWidth
                        sx={{ mt: 3, mb: 2 }}
                      >
                        <CircularProgress size={25} />
                      </LoginButton>
                    )}
                  </Box>
                </Box>
              </Container>
              <div className="xl:hidden md:hidden absolute inset-x-0 mt-4">
                <Footer sx={{ color: "white" }} />
              </div>
            </Paper>
          </Grid>
        </MDBox>
        <div className="right" />
        {error && (
          <div
            aria-live="assertive"
            className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
          >
            <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
              {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
              <Transition
                show={true}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="p-4">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 rounded-full bg-red-500">
                        <XIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-red-700">
                          {error}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
}
