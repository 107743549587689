import { useState } from "react";
import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

//Firebase Hook
import { useCollection } from "hooks/useCollection";
import React from "react";
import {
  ArrowNarrowDownIcon,
  ArrowNarrowUpIcon,
  FolderAddIcon,
  FolderOpenIcon,
  UsersIcon
} from "@heroicons/react/outline";
const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const GateOutReport = () => {
  const [responsive] = useState("horizontal");
  const [tableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight] = useState("");
  const [searchBtn] = useState(true);
  const [downloadBtn] = useState(true);
  const [printBtn] = useState(true);
  const [viewColumnBtn] = useState(true);
  const [filterBtn] = useState(true);
  const [userId, setUserId] = useState("");
  const [openTab, setOpenTab] = React.useState(1);
  const { documents: tp } = useCollection("jobs");
  const { documents: allJobs } = useCollection("jobs");
  const filterFalsy = array =>
    array
      .filter(Boolean)
      .map(item => (Array.isArray(item) ? filterFalsy(item) : item));
  const loaded = allJobs.filter(
    alls =>
      // alls.agentStarted === true &&
      alls.preAlertstatus !== "TERMINATED" &&
      alls.preAlertstatus !== "COMPLETED"
  );
  const overall = allJobs.filter(
    alls =>
      alls.preAlertstatus !== "TERMINATED" &&
      alls.preAlertstatus !== "COMPLETED"
  );
  const terminalCase = loaded.map(job => job.containerInfo);
  const result3 = terminalCase.flat(1);
  const releasedCon = result3.filter(el => el.gateStatus && el.gateStatus === "Gate-Out");
  const vettedCon = loaded.filter(el => el.vetCompleted === true);
  const vetAlert = result3.filter(el => el.vetStatus === "Yes");
  const pendingJobC = loaded.filter(el => el.vetCompleted === false);

  const isLoadedContainers = loaded.map(job =>
    job.containerInfo.map(el => el.isLoaded)
  );

  const truth = filterFalsy(isLoadedContainers);
  const resultTrue = truth.flat(1);

  // console.log(final);
  const dispatchreport = tp.filter(
    tps =>
      tps.agentStarted === true &&
      tps.preAlertstatus !== "COMPLETED" &&
      tps.preAlertstatus !== "TERMINATED"
  );

  const iscrosseds = dispatchreport.map(job => job.containerInfo);
  const result2 = iscrosseds.flat(1);
  const green = result2.filter(
    el => el.isLoaded === true && el.returnContainer === false
  );
  const red = result2.filter(el => el.isLoaded === false);
  const countAllJobs = allJobs.filter(
    alls =>
      alls.preAlertstatus === "IN PROGRESS" &&
      alls.jobType !== "Export" &&
      alls.preAlertstatus !== "TERMINATED"
  );
  const unprocessed = allJobs.filter(
    tps =>
      tps.dispatchStarted === false &&
      tps.preAlertstatus !== "COMPLETED" &&
      tps.preAlertstatus !== "TERMINATED" &&
      tps.agentStarted === true
  );
  const pending = unprocessed.length;
  console.log(releasedCon);
  const resultPercentage = 100 * dispatchreport.length / countAllJobs.length;
  const pendingPercentage = 100 * unprocessed.length / countAllJobs.length;
  const stats = [
    // {
    //   id: 1,
    //   name: "Total Jobs",
    //   stat: countAllJobs.length
    //     .toString()
    //     .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
    //   icon: UsersIcon,
    //   change: "",
    //   changeType: "increase"
    // },
    {
      id: 2,
      name: "Containers Gated Out",
      stat: releasedCon.length
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
      icon: FolderOpenIcon,
      change: resultPercentage.toFixed(2),
      changeType:
        countAllJobs.length !== resultTrue.length / 2 ? "decrease" : "increase"
    }

    // {
    //   id: 5,
    //   name: "Overall Total Containers",
    //   stat: tResult.length,
    //   icon: FolderAddIcon,
    //   change: pendingPercentage.toFixed(2),
    //   changeType: pending > 0 ? "decrease" : "increase"
    // }
  ];

  const loadedContainer = [
    {
      name: "containerId",
      label: "Container ID",
      options: { filter: true, sort: true }
    },
    {
      name: "containerSize",
      label: "Container Size",
      options: { filter: true, sort: true }
    },
    {
      name: "gateInDate",
      label: "Date Gated In",
      options: { filter: true, sort: true }
    },
    {
      name: "id",
      hide: true,
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false
      }
    }
  ];
  const notLoaded = [
    {
      name: "containerId",
      label: "Container ID",
      options: { filter: true, sort: true }
    },
    {
      name: "containerSize",
      label: "Container Size",
      options: { filter: true, sort: true }
    },
    {
      name: "vetMessage",
      label: "Reason",
      options: { filter: true, sort: true }
    },
    {
      name: "id",
      hide: true,
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false
      }
    }
  ];
  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    textLabels: { body: { noMatch: "Sorry no records have been generated" } },
    sortOrder: { name: "firstName", direction: "asc" }
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 1,
              paddingTop: 1,
              fontSize: "2pt",
              borderBottom: "2px solid black",
              backgroundColor: "#033237"
            }
          },
          paddingBottom: 2,
          marginBottom: 8,
          paddingTop: 1,
          backgroundColor: "#033237"
        }
      }
    });

  return (
    <div className="mt-4">
      <div>
        <dl className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-2 lg:grid-cols-3">
          {stats.map(item =>
            <div
              key={item.id}
              className="relative px-4 pt-5 pb-12 overflow-hidden bg-white rounded-lg shadow sm:px-6 sm:pt-6">
              <dt>
                <div className="absolute rounded-md bg-[#033237] p-3">
                  <item.icon
                    className="w-6 h-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                  {item.name}
                </p>
              </dt>
              <dd className="flex items-baseline ml-16 ">
                <p className="text-2xl font-semibold text-gray-900">
                  {item.stat}
                </p>
                {/* <p
                  className={classNames(
                    item.changeType === "increase"
                      ? "text-green-600"
                      : "text-red-600",
                    "ml-2 flex items-baseline text-sm font-semibold"
                  )}>
                  {item.changeType === "increase"
                    ? <ArrowNarrowUpIcon
                        className="self-center flex-shrink-0 w-5 h-5 text-green-500"
                        aria-hidden="true"
                      />
                    : <ArrowNarrowDownIcon
                        className="self-center flex-shrink-0 w-5 h-5 text-red-500"
                        aria-hidden="true"
                      />}
                  <span className="sr-only">
                    {" "}{item.changeType === "increase"
                      ? "Increased"
                      : "Decreased"}{" "}
                    by{" "}
                  </span>
                  {item.change}%
                </p> */}
              </dd>
            </div>
          )}
        </dl>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="flex flex-row flex-wrap pt-3 pb-4 mb-0 list-none"
            role="tablist">
            <li className="flex-auto mr-2 -mb-px text-center last:mr-0">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 1
                    ? "text-white bg-green-600"
                    : "text-black" + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist">
                Gated In Containers
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col w-full min-w-0 mb-6 break-words rounded shadow-lg">
            <div className="flex-auto px-4 py-5">
              <div className>
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <CacheProvider value={muiCache}>
                    <ThemeProvider theme={getMuiTheme}>
                      <MUIDataTable
                        data={releasedCon}
                        columns={loadedContainer}
                        options={options}
                        style={{ marginTop: "10px", zIndex: "z-10" }}
                      />
                    </ThemeProvider>
                  </CacheProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GateOutReport;
