import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import MDButton from 'components/MDButton'
// import { Close } from '@mui/icons-material'
import { Grid, TextareaAutosize } from '@mui/material'

//Firebase Hooks
import {useKingsooService} from '../../../../../hooks/useKingsooServices'

export default function FormDialog({ id, setJobId }) {
  const [open, setOpen] = useState(false)
  const [preAlertstatus] = useState('TERMINATED')
  const [preAlertTerminatedMessage, setPreAlertTerminatedMessage] = useState('')
  const [reset, setReset] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const navigate = useNavigate()
  const {updateTerminateJob} = useKingsooService()

  const terminate = async (e) => {
    e.preventDefault()
    try {
      await updateTerminateJob(id, {
        preAlertstatus,
        preAlertTerminatedMessage,
      })
      setReset(true)
    } catch (error) {
      console.log(error.message)
    }
  }
  if (reset) {
    navigate('/monitoring-compliance/job-management/pre-alert-jobs', {
      replace: true,
    })
  }

  return (
    <div>
      <MDButton color="error" variant="gradient" onClick={handleClickOpen}>
        Terminate
      </MDButton>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <Grid component="form" onSubmit={terminate}>
          <DialogTitle>Are you sure you want to terminate?</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ marginBottom: '10px' }}>Reason for Prealert Termination</DialogContentText>
            <TextareaAutosize
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 
            rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 
            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              autoFocus
              onChange={(e) => setPreAlertTerminatedMessage(e.target.value)}
              margin="dense"
              id="name"
              type="text"
              placeholder="Enter reason..."
              minRows={5}
              style={{ width: '400px', outline: 'none' }}
            />
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={handleClose}
              variant="outlined"
              color="dark"
              style={{ marginRight: '10px' }}
            >
              Cancel
            </MDButton>
            <MDButton
              type="submit"
              onClick={handleClose}
              variant="gradient"
              color="error"
            >
              Confirm
            </MDButton>
          </DialogActions>
        </Grid>
      </Dialog>
    </div>
  )
}
