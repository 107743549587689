import { useNavigate } from 'react-router-dom'

// @mui material components
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'

import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'

import ComplexStatisticsCard from 'customs/Cards/StatisticsCards/ComplexStatisticsCard'

//Firebase Hooks
import { useCollection } from '../../../hooks/useCollection'

function Home() {
  let navigate = useNavigate()

  const { documents: alerts } = useCollection('jobs')
  const { documents } = useCollection('consignee')
  const { documents: datas } = useCollection('clients')
  const { documents: users } = useCollection('users')

  const alert = alerts.filter((alert) => alert.preAlertstatus === 'pending')
  const pending = alerts.filter(
    (alert) => alert.preAlertstatus === 'inProgress'
  )
  const complete = alerts.filter((alert) => alert.preAlertstatus === 'closed')

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={3}
              lg={3}
              onClick={() =>
                navigate('/admin/client-management/consignees', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  icon="group"
                  title="Consignees"
                  count=""
                  users={documents.length}
                  percentage={{
                    color: 'dark',
                    amount: '',
                  }}
                  sx={{ boxShadow: 5, bgcolor: '#F4FBF8' }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="group"
                  color="success"
                  title="Clients"
                  count=""
                  users={datas.length}
                  percentage={{
                    color: 'dark',
                    amount: '',
                  }}
                  sx={{ boxShadow: 5, bgcolor: '#F4FBF8' }}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              lg={3}
              onClick={() =>
                navigate('/admin/employees-management/all-employees', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="people"
                  color="info"
                  title="Employees"
                  count=""
                  users={users.length}
                  percentage={{
                    color: 'dark',
                    amount: '',
                  }}
                  sx={{ boxShadow: 5, bgcolor: '#F4FBF8' }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="money"
                  color="error"
                  title="Financial Requests"
                  count=""
                  users="15"
                  percentage={{
                    color: 'dark',
                    amount: '',
                  }}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              lg={3}
              onClick={() =>
                navigate('/admin/job-file-management/pre-alert-jobs', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="work"
                  color="warning"
                  title="Pre-Alert Jobs"
                  count=""
                  users={alert.length}
                  percentage={{
                    color: 'dark',
                    amount: '',
                  }}
                  sx={{ boxShadow: 5, bgcolor: '#F4FBF8' }}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              lg={3}
              onClick={() =>
                navigate('/admin/job-file-management/pending-job-files', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="work_history"
                  color="info"
                  title="Pending Job"
                  count=""
                  users={pending.length}
                  percentage={{
                    color: 'dark',
                    amount: '',
                  }}
                  sx={{ boxShadow: 5, bgcolor: '#F4FBF8' }}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              lg={3}
              onClick={() =>
                navigate('/admin/job-file-management/completed-job-files', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="work_off"
                  color="error"
                  title="Completed Job"
                  count=""
                  users={complete.length}
                  percentage={{
                    color: 'dark',
                    amount: '',
                  }}
                  sx={{ boxShadow: 5, bgcolor: '#F4FBF8' }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  )
}

export default Home
