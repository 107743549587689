import { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Check from '@mui/icons-material/Check'
import {
  Button,
  Grid,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material'

//Firebase Hooks
import {useKingsooService} from '../../../../hooks/useKingsooServices'

export default function FormDialog({ id, setUid }) {
  const [open, setOpen] = useState(false)
  const [mdApprovedReason, setMdApprovedReason] = useState('')
  const [mdApprovedAmount, setMdApprovedAmount] = useState('')
  const [paymentValue, setPaymentValue] = useState('')

  const {getFnj, updateFNJR} = useKingsooService()

  const detail = async () => {
    try {
      const docSnap = await getFnj(id)
      setPaymentValue(docSnap.data().paymentValue)
    } catch (error) {
      //console.log()
    }
  }

  useEffect(() => {
    // console.log('na u know', id)
    if ((id !== undefined || id !== '') && id !== ':id') {
      detail()
      // console.log('id', id)
    }
  }, [id])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const transaction = async (e) => {
    e.preventDefault()
    const status = 'Approved'
    // console.log(flag, id)
    try {
      await updateFNJR(id, {
        mdApprovedReason,
        status,
        mdApprovedAmount,
      })
      setOpen(false)
    } catch (error) {
      // console.log(error)
    }
  }
  const ngn = Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN"
  });

  return <div>
      <button variant="gradient" onClick={handleClickOpen}>
        <Check color="success" style={{ width: "20px", height: "20px" }} />
      </button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <Grid component="form" onSubmit={transaction}>
          <DialogTitle>Comfirm Request</DialogTitle>
          <DialogContent>
            {/* <DialogContentText style={{ marginBottom: '10px' }}>
              Reason for Decline
            </DialogContentText> */}
            <Typography variant="body2" mb={1}>
              <b>Initial Amount:</b> {ngn.format(paymentValue)}
            </Typography>
            <Grid sx={{ mb: 2 }}>
              <TextField size="small" placeholder="Enter Approved Amount" onChange={e => setMdApprovedAmount(e.target.value)} />
            </Grid>
            <TextareaAutosize className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 
            rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 
            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" autoFocus onChange={e => setMdApprovedReason(e.target.value)} margin="dense" id="name" type="text" placeholder="Enter reason..." minRows={3} style={{ width: "400px", outline: "none" }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="error" size="small" style={{ marginRight: "1rem" }}>
              Cancel
            </Button>
            <Button type="submit" onClick={handleClose} variant="outlined" color="success" size="small" style={{ marginRight: "1.5rem" }}>
              Confirm
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </div>;
}
