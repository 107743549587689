import { useEffect, useState } from 'react'
import { appFirestore } from '../firebase/config'
import { collection, onSnapshot } from 'firebase/firestore'

export const useCollection = (c) => {
  const [documents, setDocuments] = useState([])
  // console.log(documents)

  useEffect(() => {
    let ref = collection(appFirestore, c)

    const unsub = onSnapshot(ref, (snapshot) => {
      let results = []
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id })
      })
      setDocuments(results)
    })

    return () => unsub()
  }, [c])
  return { documents }
}
