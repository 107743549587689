import { useState } from "react";
import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import React from "react";
import { collection, query, where } from "firebase/firestore";
//Firebase Hook
import { useCollection } from "hooks/useCollection";
import {
  ArrowNarrowDownIcon,
  ArrowNarrowUpIcon,
  CursorClickIcon,
  FolderOpenIcon,
  UsersIcon
} from "@heroicons/react/outline";
import { appFirestore } from "../../../../firebase/config";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const FieldAgentReport = () => {
  const [responsive] = useState("horizontal");
  const [tableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight] = useState("");
  const [searchBtn] = useState(true);
  const [downloadBtn] = useState(true);
  const [printBtn] = useState(true);
  const [viewColumnBtn] = useState(true);
  const [filterBtn] = useState(true);
  const [userId, setUserId] = useState("");
  const [openTab, setOpenTab] = React.useState(1);
  const { documents: fieldAgents } = useCollection("jobs");
  const { documents: allJobs } = useCollection("jobs");

  const totalJobs = allJobs.filter(
    el =>
      el.preAlertstatus !== "Terminated" && el.preAlertstatus !== "COMPLETED"
  );

  const filterFalsy = array =>
    array
      .filter(Boolean)
      .map(item => (Array.isArray(item) ? filterFalsy(item) : item));

  const iscrossed = totalJobs.map(job =>
    job.containerInfo.map(el => el.isCrossed)
  );

  const truth = filterFalsy(iscrossed);

  const resultTrue = truth.flat(1);
  // const realTruth = truth.filter(el => el === true)
  const real = totalJobs.flat(1);

  const fieldreport = real.filter(
    agent =>
      agent.agentStarted === true &&
      agent.preAlertstatus !== "COMPLETED" &&
      agent.preAlertstatus !== "TERMINATED"
  );
  const iscrosseds = fieldreport.map(job => job.containerInfo);

  const seal = Object.values(fieldreport).includes("isCrossed");

  // const seal = fieldreport.filter(function(el) {
  //   return el.containerInfo.indexOf("isCrossed") === true;
  // });
  const result2 = iscrosseds.flat(1);
  const green = result2.filter(el => el.isCrossed === true);
 const red = result2.filter(el => el.isCrossed === false)
  // console.log(green);
  const unprocessed = fieldAgents.filter(
    agent =>
      agent.agentStarted === false &&
      agent.preAlertstatus !== "COMPLETED" &&
      agent.preAlertstatus !== "TERMINATED"
    // &&
    // agent.jobType !== "Export"
  );
  const countAllJobs = allJobs.filter(
    alls =>
      alls.preAlertstatus === "IN PROGRESS" &&
      alls.preAlertstatus !== "COMPLETED" &&
      alls.preAlertstatus !== "TERMINATED"
  );

  const pending = unprocessed.length;
  const aaPercentage = countAllJobs.length;
  const processedPercentage = fieldreport.length;

  const resultPercentage = 100 * processedPercentage / aaPercentage;
  const pendingPercentage = 100 * unprocessed.length / aaPercentage;

  const stats = [
    // {
    //   id: 1,
    //   name: "Total Jobs",
    //   stat: countAllJobs.length
    //     .toString()
    //     .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
    //   icon: UsersIcon,
    //   change: "",
    //   changeType: "increase"
    // },
    {
      id: 2,
      name: "Jobs Processing",
      stat: fieldreport.length
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
      icon: FolderOpenIcon,
      change: resultPercentage.toFixed(2),
      changeType:
        countAllJobs.length !== fieldreport.length / 2 ? "decrease" : "increase"
    },
    {
      id: 4,
      name: " Crossed Containers",
      stat: resultTrue.length,
      icon: CursorClickIcon,
      change: null,
      changeType:
        resultTrue.length < countAllJobs.length ? "decrease" : "increase"
    },
    {
      id: 4,
      name: " Uncrossed Containers",
      stat: red.length,
      icon: CursorClickIcon,
      change: null,
      changeType:
        red.length < countAllJobs.length ? "decrease" : "increase"
    }
  ];
  const columns = [
    {
      name: "billOfLaden",
      label: "BOL/MAWB",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: dataIndex => {
          return fieldreport[dataIndex].billOfLaden === null
            ? fieldreport[dataIndex].airWayBill
            : fieldreport[dataIndex].billOfLaden;
        }
      }
    },

    { name: "fileId", label: "Job ID", options: { filter: true, sort: true } },
    {
      name: "id",
      hide: true,
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false
      }
    },
    {
      name: "agentStartedBy",
      label: "Processing By",
      options: { filter: true, sort: true }
    },
    {
      name: "agentStartedDate",
      label: "Date/Time Processed",
      options: { filter: true, sort: true }
    }
  ];
  const columnPending = [
    {
      name: "billOfLaden",
      label: "BOL/MAWB",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: dataIndex => {
          return unprocessed[dataIndex].billOfLaden === null
            ? unprocessed[dataIndex].airWayBill
            : unprocessed[dataIndex].billOfLaden;
        }
      }
    },
    { name: "fileId", label: "Job ID", options: { filter: true, sort: true } },
    {
      name: "id",
      hide: true,
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false
      }
    },
    {
      name: "expectedTimeOfArrival",
      label: "Exptected Time of Arrival",
      options: { filter: true, sort: true }
    },
    {
      name: "jobCreatedDate",
      label: "Date/Time of Creation",
      options: { filter: true, sort: true }
    }
  ];
  const crossedContainers = [
    {
      name: "containerId",
      label: "Container ID",
      options: { filter: true, sort: true }
    },
    {
      name: "containerSize",
      label: "Container Size",
      options: { filter: true, sort: true }
    },
    {
      name: "id",
      hide: true,
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false
      }
    },
    {
      name: "isLoaded",
      label: "Container Loaded",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: dataIndex => {
          return green[dataIndex].isLoaded === true ? "Yes" : "NO";
        }
      }
    }
  ];
  const notCrossed = [
    {
      name: "containerId",
      label: "Container ID",
      options: { filter: true, sort: true }
    },
    {
      name: "containerSize",
      label: "Container Size",
      options: { filter: true, sort: true }
    },
    {
      name: "id",
      hide: true,
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false
      }
    },
    // {
    //   name: "isLoaded",
    //   label: "Container Loaded",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRenderLite: dataIndex => {
    //       return green[dataIndex].isLoaded === true ? "Yes" : "NO";
    //     }
    //   }
    // }
  ];
  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: "firstName",
      direction: "asc"
    }
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 1,
              paddingTop: 1,
              fontSize: "2pt",
              borderBottom: "2px solid black",
              backgroundColor: "#033237",
              zIndex: 5
            }
          },
          paddingBottom: 2,
          marginBottom: 8,
          paddingTop: 1,
          backgroundColor: "#033237"
        }
      }
    });

  return (
    <div className="mt-4">
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {stats.map(item =>
            <div
              key={item.id}
              className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6">
              <dt>
                <div className="absolute rounded-md bg-[#033237] p-3">
                  <item.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">
                  {item.name}
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline ">
                <p className="text-2xl font-semibold text-gray-900">
                  {item.stat}
                </p>
                <p
                  className={classNames(
                    item.changeType === "increase"
                      ? "text-green-600"
                      : "text-red-600",
                    "ml-2 flex items-baseline text-sm font-semibold"
                  )}>
                  {item.changeType === "increase"
                    ? <ArrowNarrowUpIcon
                        className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                        aria-hidden="true"
                      />
                    : <ArrowNarrowDownIcon
                        className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                        aria-hidden="true"
                      />}
                  <span className="sr-only">
                    {" "}{item.changeType === "increase"
                      ? "Increased"
                      : "Decreased"}{" "}
                    by{" "}
                  </span>
                  {item.change}%
                </p>
              </dd>
            </div>
          )}
        </dl>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist">
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 1
                    ? "text-white bg-green-600"
                    : "text-black" + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist">
                Proccessing Jobs
              </a>
            </li>
           
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 3
                    ? "text-white bg-green-600"
                    : "text-black" + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist">
                Crossed Containers
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 4
                    ? "text-white bg-green-600"
                    : "text-black" + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(4);
                }}
                data-toggle="tab"
                href="#link4"
                role="tablist">
                Uncrossed Containers
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-lg rounded">
            <div className="px-4 py-5 flex-auto">
              <div className>
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <CacheProvider value={muiCache}>
                    <ThemeProvider theme={getMuiTheme}>
                      <MUIDataTable
                        data={
                          fieldreport // title={`Processed ${fieldreport.length} out of ${countAllJobs.length}`}
                        }
                        columns={columns}
                        options={options}
                        style={{ marginTop: "10px", zIndex: "z-10" }}
                      />
                    </ThemeProvider>
                  </CacheProvider>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <CacheProvider value={muiCache}>
                    <ThemeProvider theme={getMuiTheme}>
                      <MUIDataTable
                        data={unprocessed}
                        columns={columnPending}
                        options={options}
                        style={{ marginTop: "10px", zIndex: "z-10" }}
                      />
                    </ThemeProvider>
                  </CacheProvider>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <CacheProvider value={muiCache}>
                    <ThemeProvider theme={getMuiTheme}>
                      <MUIDataTable
                        data={green}
                        columns={crossedContainers}
                        options={options}
                        style={{ marginTop: "10px", zIndex: "z-10" }}
                      />
                    </ThemeProvider>
                  </CacheProvider>
                </div>
                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                  <CacheProvider value={muiCache}>
                    <ThemeProvider theme={getMuiTheme}>
                      <MUIDataTable
                        data={red}
                        columns={notCrossed}
                        options={options}
                        style={{ marginTop: "10px", zIndex: "z-10" }}
                      />
                    </ThemeProvider>
                  </CacheProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldAgentReport;
