import { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'
import { Visibility } from '@mui/icons-material'
import {useKingsooService} from '../../../hooks/useKingsooServices'

//Firebase Hook
// import KingsooService from '../../../../hooks/kingsooServices'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: '#ffffff' }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export default function Modal({ id, setConsigneeId }) {
  const [open, setOpen] = useState(false)
  const [consigneeName, setConsigneeName] = useState('')
  const [consigneeType, setConsigneeType] = useState('')
  const [prefix, setPrefix] = useState('')
  const [rcNumber, setRcNumber] = useState('')
  const [consigneeCategory, setConsigneeCategory] = useState('')
  const [businessCategory, setBusinessCategory] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [businessAddress, setBusinessAddress] = useState('')
  const [altPhone, setAltPhone] = useState('')
  const [wareHouseAddress, setWareHouseAddress] = useState('')
  const [companyEmail, setCompanyEmail] = useState('')
  const [tin, setTin] = useState('')
  const [registeredCompanyAddress, setRegisteredCompanyAddress] = useState('')
  const [AltCompanyPhone, setAltCompanyPhone] = useState('')
  const [companyPhone, setCompanyPhone] = useState('')
  const [contactName, setContactName] = useState('')
  const [jobOwner, setJobOwner] = useState('')
  const [jobOwnerContact, setJobOwnerContact] = useState('')

  const {getConsignee} = useKingsooService()

  const [fullWidth] = useState(true);

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {

    const detail = async () => {
      try {
        const docSnap = await getConsignee(id)

        setConsigneeName(docSnap.data().consigneeName)
        setCompanyPhone(docSnap.data().companyPhone)
        setConsigneeType(docSnap.data().consigneeType)
        setPrefix(docSnap.data().prefix)
        setRcNumber(docSnap.data().rcNumber)
        setConsigneeCategory(docSnap.data().consigneeCategory)
        setBusinessCategory(docSnap.data().businessCategory)
        setCompanyAddress(docSnap.data().companyAddress)
        setPhone(docSnap.data().phone)
        setBusinessAddress(docSnap.data().businessAddress)
        setAltPhone(docSnap.data().altPhone)
        setCompanyEmail(docSnap.data().companyEmail)
        setWareHouseAddress(docSnap.data().wareHouseAddress)
        setRegisteredCompanyAddress(docSnap.data().registeredCompanyAddress)
        setAltCompanyPhone(docSnap.data().AltCompanyPhone)
        setContactName(docSnap.data().contactName)
        setTin(docSnap.data().tin)
        setJobOwner(docSnap.data().jobOwner)
        setJobOwnerContact(docSnap.data().jobOwnerContact)
      } catch (error) {
        // console.log(error)
      }
    }
    // console.log('na u know', id)
    if (id !== undefined || id !== '') {
      detail()
      // console.log('id', id)
    }
  }, [id])

  return (
    <div>
      <div onClick={handleClickOpen}>
        <Visibility
          style={{
            width: '20px',
            height: '20px',
            color: 'black',
          }}
        />
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={fullWidth}
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          sx={{ backgroundColor: '#033237', color: '#ffffff' }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <b className="text-2xl capitalize">
            {consigneeName}
          </b>
        </BootstrapDialogTitle>
        <Grid container sm={12}>
          <Grid item sm={6} sx={{ pl: 2, pt: 4, mr: 4 }}>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  Consignee Name: {" "}
                </b>
                {consigneeName}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  Category: {" "}
                </b>
                {consigneeCategory}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  Business Type: {" "}
                </b>
                {businessCategory}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  Registered Address: {" "}
                </b>
                {registeredCompanyAddress}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  Warehouse Address: {" "}
                </b>
                {wareHouseAddress}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  Alternative Phone Number: {" "}
                </b>
                {altPhone}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  Prefix: {" "}
                </b>
                {prefix}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  Contact Phone Number: {" "}
                </b>
                {phone}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  Contact Alternate Phone: {" "}
                </b>
                {AltCompanyPhone}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  Comapny Type: {" "}
                </b>
                {consigneeType}
              </li>
            </Typography>
          </Grid>
          <Grid item sm={4} sx={{ pl: 2, pt: 4 }}>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  RC/BN Number: {" "}
                </b>
                {rcNumber}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  Business Address: {" "}
                </b>
                {businessAddress}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  Business Category: {" "}
                </b>
                {businessCategory}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  Company Phone Number: {" "}
                </b>
                {companyPhone}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  Email Address: {" "}
                </b>
                {companyEmail}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  Contact Person’s Name: {" "}
                </b>
                {contactName}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  TIN: {" "}
                </b>
                {tin}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  Contact Address: {" "}
                </b>
                {companyAddress}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  Job Owner: {" "}
                </b>
                {jobOwner}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              <li>
                <b className="text-sm">
                  Job Owner's Contact: {" "}
                </b>
                {jobOwnerContact}
              </li>
            </Typography>
          </Grid>
        </Grid>
      </BootstrapDialog>
    </div>
  )
}
