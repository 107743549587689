import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

//Firebase Hook
import {useKingsooService} from '../../../../hooks/useKingsooServices'

import { Visibility } from "@mui/icons-material";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose
        ? <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500]
            }}>
            <CloseIcon sx={{ color: "#ffffff" }} />
          </IconButton>
        : null}
    </DialogTitle>
  );
};

export default function Modal({ id }) {
  const [open, setOpen] = useState(false);
  const [billOfLaden, setBillOfLaden] = useState("");
  const [fileId, setFileId] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [consigneeId, setConsigneeId] = useState("");
  const [clientId, setClientId] = useState("");
  const [containerInfo, setContainerInfo] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [bulkCargo, setBulkCargo] = useState("");
  const [request, setRequest] = useState("");
  const [paymentValue, setPaymentValue] = useState("");
  const [quantity, setQuantity] = useState("");
  const [amount, setAmount] = useState("");
  const [requester, setRequester] = useState("");
  // const [file, setFile] = useState('')
  const [description, setDescription] = useState("");
  const [date, setDate] = useState();
  const [requestId, setRequestId] = useState("");
  const [status, setStatus] = useState("Pending");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const {getFnj} = useKingsooService()

  useEffect(
    () => {
      const detail = async () => {
        try {
          const docSnap = await getFnj(id);

          setBillOfLaden(docSnap.data().billOfLaden);
          setFileId(docSnap.data().fileId);
          setPaymentType(docSnap.data().paymentType);
          setConsigneeId(docSnap.data().consigneeId);
          setClientId(docSnap.data().clientId);
          setContainerInfo(docSnap.data().containerInfo);
          setInvoiceNo(docSnap.data().invoiceNo);
          setBulkCargo(docSnap.data().bulkCargo);
          setRequest(docSnap.data().request);
          setPaymentValue(docSnap.data().paymentValue);
          setQuantity(docSnap.data().quantity);
          setAmount(docSnap.data().amount);
          setRequester(docSnap.data().requester);
          // setFile(docSnap.data().file)
          setDescription(docSnap.data().description);
          setDate(docSnap.data().date);
          setRequestId(docSnap.data().requestId);
          setStatus(docSnap.data().status);
        } catch (error) {
          // console.log(error)
        }
      };
      if (id !== undefined || id !== "") {
        detail();
      }
    },
    [id]
  );
  const ngn = Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN"
  });
  return (
    <div>
      <div className="text-emerald-900" onClick={handleClickOpen}>
        <Visibility
          style={{
            width: "20px",
            height: "20px",
            color: "#033237"
          }}
        />
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <BootstrapDialogTitle
          sx={{ backgroundColor: "#033237", color: "#ffffff" }}
          id="customized-dialog-title"
          onClose={handleClose}>
          {requestId} : {requester}
        </BootstrapDialogTitle>
        <Grid container sm={12}>
          <Grid item sm={6} sx={{ pl: 2, pt: 4, mr: 4 }}>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Payment Type: {paymentType}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Job File Id: {fileId}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Bill of Lading/Master Airway Bill: {billOfLaden}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Consignee: {consigneeId}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Client: {clientId}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Number Of Containers: {containerInfo}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Invoice No: {invoiceNo}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Bulk Cargo: {bulkCargo}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Amount: {amount}
            </Typography>
          </Grid>
          <Grid item sm={4} sx={{ pl: 2, pt: 4 }}>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Request For: {request}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Payment Value: {ngn.format(paymentValue)}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Requester: {requester}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Description: {description}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Date: {date}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Status: {status}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Request Id: {requestId}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              Quantity: {quantity}
            </Typography>
          </Grid>
        </Grid>
      </BootstrapDialog>
    </div>
  );
}
