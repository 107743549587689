import { useNavigate } from 'react-router-dom'
// @mui material components
import Grid from '@mui/material/Grid'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'

import ComplexStatisticsCard from 'customs/Cards/StatisticsCards/ComplexStatisticsCard'

//Firebase Hook
import { useCollection } from '../../../hooks/useCollection'

function Analytics() {
  // Action buttons for the BookingCard
  const navigate = useNavigate()
  const { documents } = useCollection('users')
  const { documents: njr } = useCollection('nonJobRelated')
  const { documents: jr } = useCollection('jobRelatedForm')
  const approved1 = njr.filter(
    (ap) =>
      ap.status === 'Declined' &&
      (ap.department === 'Admin & Human Resource' ||
        ap.department === 'Admin Staff')
  )
  const approved2 = jr.filter(
    (ap) =>
      ap.status === 'Declined' &&
      (ap.department === 'Admin & Human Resource' ||
        ap.department === 'Admin Staff')
  )
  const pending1 = jr.filter(
    (ap) =>
      // ap.status !== 'Approved' &&
      ap.status === 'Pending' &&
      (ap.department === 'Admin & Human Resource' ||
        ap.department === 'Admin Staff')
  )
  const pending2 = njr.filter(
    (ap) =>
      // ap.status !== 'Approved' &&
      ap.status === 'Pending' &&
      (ap.department === 'Admin & Human Resource' ||
        ap.department === 'Admin Staff')
  )

  const declined1 = njr.filter((ap) => ap.status === 'Declined')
  const declined2 = jr.filter((ap) => ap.status === 'Declined')

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/human-resources/employee-management/all-employees', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="secondary"
                  icon="group"
                  title="Employees"
                  users={documents.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/human-resources/financial-request/all-requests', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  icon="group"
                  title="Financial Requests"
                  users="0"
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/human-resources/financial-request/pending-request', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="group"
                  title="Pending Requests"
                  users={pending1.length + pending2.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  '/human-resources/financial-request/approved-request',
                  {
                    replace: true,
                  }
                )
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="group"
                  title="Approved Requests"
                  users={approved1.length + approved2.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  '/human-resources/financial-request/declined-request',
                  {
                    replace: true,
                  }
                )
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="error"
                  icon="group"
                  title="Declined Requests"
                  users={declined1.length + declined2.length}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  )
}

export default Analytics
