import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";

import ComplexStatisticsCard from "customs/Cards/StatisticsCards/ComplexStatisticsCard";

//Firebase Hooks
import { useCollection } from "../../../hooks/useCollection";

function Home() {
  let navigate = useNavigate();
  const { documents: fieldAgents } = useCollection("jobs");
  const { documents: terminal } = useCollection("jobs");
  const { documents: dispatch } = useCollection("jobs");
  const { documents: alerts } = useCollection("jobs");
  const { documents: users } = useCollection("users");
  const { documents: consignee } = useCollection("consignee");
  const { documents: datas } = useCollection("clients");
  const { documents: njr } = useCollection("nonJobRelated");
  const { documents: jr } = useCollection("jobRelatedForm");


  const filterFalsy = array => array
      .filter(Boolean)
      .map(item => (Array.isArray(item) ? filterFalsy(item) : item));

  const fieldreport = fieldAgents.filter(agent => agent.agentStarted === false && agent.preAlertstatus !== "COMPLETED" && agent.preAlertstatus !== "TERMINATED");
  // const terminalUseCase = terminal.map(
  //   el =>

  //     el.vetCompleted !== null &&

  //     el.preAlertstatus !== "COMPLETED" &&
  //     el.preAlertstatus !== "TERMINATED"
  // );
  // const tUC = filterFalsy(terminalUseCase);
  // const toc = tUC;
  const terminalCase = terminal.map(job =>
    job.containerInfo.map(el => el.containerDirection)
  );

  const term = filterFalsy(terminalCase);
  const terminalReport = term.flat(1);
  // console.log(terminalReport);
  const tp = dispatch.filter(tps => tps.agentStarted === true && tps.preAlertstatus !== "COMPLETED" && tps.preAlertstatus !== "TERMINATED");

  const approved1 = njr.filter(ap => ap.status === "Approved");
  const approved2 = jr.filter(ap => ap.status === "Approved");
  const pending1 = jr.filter(ap => ap.status === "COO");
  const pending2 = njr.filter(ap => ap.status === "MD");
  const incomplete1 = jr.filter(
    ap => ap.status !== "Approved" && ap.status !== "Declined"
  );
  const incomplete2 = njr.filter(
    ap => ap.status !== "Approved" && ap.status !== "Declined"
  );
  const declined1 = njr.filter(ap => ap.status === "Declined");
  const declined2 = jr.filter(ap => ap.status === "Declined");
  const alert = alerts.filter(alert => alert.preAlertstatus === "PENDING");
  const pending = alerts.filter(
    alert => alert.preAlertstatus === "IN PROGRESS"
  );
  const complete = alerts.filter(alert => alert.preAlertstatus === "COMPLETED");
  const pendingNew = alerts.filter(
    alert =>
      alert.preAlertstatus === "IN PROGRESS" && alert.jobType === "Export"
  );

  // Action buttons for the BookingCard
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  "/managing-director/employee-management/all-employees",
                  {
                    replace: false
                  }
                )}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  icon="group"
                  title="Employees"
                  users={users.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate("/managing-director/client-management/consignee", {
                  replace: false
                })}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="secondary"
                  icon="group"
                  title="Consignees"
                  users={consignee.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate("/managing-director/client-management/client", {
                  replace: false
                })}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="group"
                  title="Clients"
                  users={datas.length}
                  size="12px"
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate("/managing-director/job-management/pre-alert-jobs", {
                  replace: true
                })}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="work"
                  title="Pre Alert Jobs"
                  users={alert.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate("/managing-director/job-management/pending-job", {
                  replace: false
                })}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="work_history"
                  color="dark"
                  title="Pending Jobs"
                  count=""
                  users={pending.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate("/managing-director/job-management/completed-job", {
                  replace: false
                })}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  icon="work_off"
                  title="Completed Jobs"
                  users={complete.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate("/managing-director/job-management/export-job", {
                  replace: true
                })}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="file_upload"
                  title="Export Jobs"
                  users={pendingNew.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate("/managing-director/financial-request/all-requests", {
                  replace: false
                })}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="move_to_inbox"
                  title="All Requests"
                  users={njr.length + jr.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  "/managing-director/financial-request/approved-request",
                  {
                    replace: false
                  }
                )}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="check_circle_outline"
                  title="Approved Requests"
                  users={approved1.length + approved2.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  "/managing-director/financial-request/incomplete-requests",
                  {
                    replace: false
                  }
                )}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="hourglass_bottom"
                  title="Incomplete Requests"
                  users={incomplete1.length + incomplete2.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  "/managing-director/financial-request/pending-request",
                  {
                    replace: false
                  }
                )}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="hourglass_bottom"
                  title="Pending Requests"
                  users={pending1.length + pending2.length}
                  color="warning"
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  "/managing-director/financial-request/declined-request",
                  {
                    replace: false
                  }
                )}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="error"
                  icon="cancel"
                  title="Declined Requests"
                  users={declined1.length + declined2.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate("/managing-director/report/field-agents-report", {
                  replace: false
                })}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="supportAgentIcon"
                  title="Field Agents Pending"
                  users={fieldreport.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate("/managing-director/report/dispatch-report", {
                  replace: false
                })}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="hourglass_bottom"
                  title="Dispatch pending Jobs"
                  users={tp.length}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="hourglass_bottom"
                  title="Terminal Pending"
                  users={terminalReport.length}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="hourglass_bottom"
                  title="Containers Gated In"
                  users={0}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="hourglass_bottom"
                  title="Containers Gated Out"
                  users={0}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Home;
