import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormFields from "layouts/pages/account/components/FormField";
import selectData from "./data";

// NewUser page components
import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import { Paper, CircularProgress } from "@mui/material";
import MDButton from "components/MDButton";
// import './style.css'

//Firebase Hook
import { useFirestore } from "../../../hooks/useFirestore";
import {useKingsooService} from "../../../hooks/useKingsooServices";

// Alert Message
import PopSuccess from "views/PopMessage/PopSuccess";

function CreateConsignee() {
  const [reset, setReset] = useState(false);
  const [consigneeName, setConsigneeName] = useState("");
  const [consigneeType, setConsigneeType] = useState("");
  const [prefix, setPrefix] = useState("");
  const [rcNumber, setRcNumber] = useState("");
  const [consigneeCategory, setConsigneeCategory] = useState("");
  const [businessCategory, setBusinessCategory] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [altPhone, setAltPhone] = useState("");
  const [wareHouseAddress, setWareHouseAddress] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [tin, setTin] = useState("");
  const [registeredCompanyAddress, setRegisteredCompanyAddress] = useState("");
  const [AltCompanyPhone, setAltCompanyPhone] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [contactName, setContactName] = useState("");
  const [jobOwner, setJobOwner] = useState("");
  const [jobOwnerContact, setJobOwnerContact] = useState("");

  const { id } = useParams();
  let navigate = useNavigate();
  const {getConsignee, updateConsignee} = useKingsooService()


  useEffect(
    () => {
      const detail = async () => {
        try {
          const docSnap = await getConsignee(id);
          // console.log(docSnap.data())
          setConsigneeName(docSnap.data().consigneeName);
          setCompanyPhone(docSnap.data().companyPhone);
          setConsigneeType(docSnap.data().consigneeType);
          setPrefix(docSnap.data().prefix);
          setRcNumber(docSnap.data().rcNumber);
          setConsigneeCategory(docSnap.data().consigneeCategory);
          setBusinessCategory(docSnap.data().businessCategory);
          setCompanyAddress(docSnap.data().companyAddress);
          setPhone(docSnap.data().phone);
          setBusinessAddress(docSnap.data().businessAddress);
          setAltPhone(docSnap.data().altPhone);
          setCompanyEmail(docSnap.data().companyEmail);
          setWareHouseAddress(docSnap.data().wareHouseAddress);
          setRegisteredCompanyAddress(docSnap.data().registeredCompanyAddress);
          setAltCompanyPhone(docSnap.data().AltCompanyPhone);
          setContactName(docSnap.data().contactName);
          setTin(docSnap.data().tin);
          setJobOwner(docSnap.data().jobOwner);
          setJobOwnerContact(docSnap.data().jobOwnerContact);
        } catch (error) {
          console.log(error);
        }
      };
      // console.log('na u know', id)
      if ((id !== undefined || id !== "") && id !== ":id") {
        detail();
        // console.log('id', id)
      }
    },
    [id]
  );

  const { addDocument, message, isPending } = useFirestore("consignee");

  const handleSubmit = async e => {
    e.preventDefault();

    const newConsignee = {
      consigneeName,
      consigneeType,
      prefix,
      rcNumber,
      consigneeCategory,
      businessCategory,
      companyAddress,
      phone,
      businessAddress,
      altPhone,
      wareHouseAddress,
      companyEmail,
      tin,
      contactName,
      companyPhone,
      AltCompanyPhone,
      registeredCompanyAddress,
      jobOwner,
      jobOwnerContact
    };

    try {
      if ((id !== undefined || id !== "") && id !== ":id") {
        await updateConsignee(id, newConsignee);
        // setStaffId('')
        setReset(true);
        if (reset) {
          e.target.reset();
        }
      } else {
        await addDocument(newConsignee);
        if (message) {
          e.target.reset();
        }
      }
    } catch (error) {
      // console.log('msg', error.message)
    }
  };

  useEffect(
    () => {
      if (message || reset) {
        setTimeout(() => {
          navigate("/documentation/client-management/consignee", {
            replace: true
          });
        }, 5000);
      }
    },
    [message, reset, navigate]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
        <MDBox mt={5} component="form" onSubmit={handleSubmit}>
          <MDTypography
            variant="h5"
            fontWeight="bold"
            sx={{ borderBottom: 1, pb: 1, pt: 4 }}>
            Consignee Details
          </MDTypography>
          <MDBox mt={1.625}>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Consignee's Name{" "}
                </MDTypography>
                <FormFields
                  value={consigneeName}
                  name="CompanyName"
                  placeholder="Enter company's name"
                  onChange={e => setConsigneeName(e.target.value)}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Consignee Type{" "}
                </MDTypography>
                <MDBox>
                  <Autocomplete
                    defaultValue="Select Company Type"
                    value={consigneeType}
                    options={selectData.consigneeType}
                    onChange={(e, newValue) => setConsigneeType(newValue)}
                    renderInput={params =>
                      <FormFields
                        {...params}
                        InputLabelProps={{ shrink: true }}
                      />}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Client Prefix{" "}
                </MDTypography>
                <FormFields
                  value={prefix}
                  name="CompanyPrefix"
                  placeholder="Enter Company Prefix"
                  onChange={e => setPrefix(e.target.value)}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>RC/BN Number </MDTypography>
                <FormFields
                  value={rcNumber}
                  name="RN/BN"
                  placeholder="Enter RC/BN number"
                  onChange={e => setRcNumber(e.target.value)}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Consignee Category{" "}
                </MDTypography>
                <MDBox>
                  <Autocomplete
                    defaultValue="Select Employment Type"
                    value={consigneeCategory}
                    options={selectData.consigneeCategory}
                    onChange={(e, newValue) => setConsigneeCategory(newValue)}
                    renderInput={params =>
                      <FormFields
                        {...params}
                        InputLabelProps={{ shrink: true }}
                      />}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Business Category{" "}
                </MDTypography>
                <MDBox>
                  <Autocomplete
                    defaultValue="Select Employment Type"
                    value={businessCategory}
                    options={selectData.businessCategory}
                    onChange={(e, newValue) => setBusinessCategory(newValue)}
                    renderInput={params =>
                      <FormFields
                        {...params}
                        InputLabelProps={{ shrink: true }}
                      />}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Tax Identification Number{" "}
                </MDTypography>
                <FormFields
                  value={tin}
                  name="TaxIdentificationNumber"
                  placeholder="Enter TIN Number"
                  type="number"
                  fullWidthn
                  onChange={e => setTin(e.target.value)}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Company Phone Number
                </MDTypography>
                <FormFields
                  value={companyPhone}
                  name="CompanyPhoneNumber"
                  placeholder="Enter Phone Number"
                  type="tel"
                  onChange={e => setCompanyPhone(e.target.value)}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Alternative Company Phone Number{" "}
                </MDTypography>
                <FormFields
                  value={AltCompanyPhone}
                  name="CompanyPhoneNumber"
                  placeholder="Enter Phone Number"
                  type="tel"
                  onChange={e => setAltCompanyPhone(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Registered Company Address{" "}
                </MDTypography>
                <FormFields
                  value={companyAddress}
                  name="RegisteredCompanyAddress"
                  placeholder="Enter Company Address"
                  onChange={e => setCompanyAddress(e.target.value)}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Business Address
                </MDTypography>
                <FormFields
                  value={businessAddress}
                  name="BusinessAddress"
                  type="text"
                  placeholder="Enter Business Address"
                  onChange={e => setBusinessAddress(e.target.value)}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Ware House Address
                </MDTypography>
                <FormFields
                  value={wareHouseAddress}
                  name="WareHouseAddress"
                  placeholder="Enter Ware House Address"
                  onChange={e => setWareHouseAddress(e.target.value)}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  type="text"
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Company Email Address{" "}
                </MDTypography>
                <FormFields
                  value={companyEmail}
                  name="CompanyEmailAddress"
                  placeholder="Enter Company Email Address"
                  onChange={e => setCompanyEmail(e.target.value)}
                // type="email"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Job Owner </MDTypography>
                <FormFields
                  className="capitalize"
                  value={jobOwner}
                  name="JobOwner"
                  placeholder="Enter Job Owner"
                  onChange={e => setJobOwner(e.target.value)}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Job Owner's Contact{" "}
                </MDTypography>
                <FormFields
                  value={jobOwnerContact}
                  name="JobOwnerContact"
                  placeholder="Enter Job Owner's Contact"
                  type="tel"
                  onChange={e => setJobOwnerContact(e.target.value)}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={5}>
            <MDTypography
              variant="h5"
              fontWeight="bold"
              sx={{ borderBottom: 1, pb: 1, pt: 4 }}>
              Contact Person Details
            </MDTypography>
            <MDBox mt={1.625}>
              <Grid container spacing={4} mb={4}>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Contact Name
                  </MDTypography>
                  <FormFields
                    value={contactName}
                    name="ContactName"
                    placeholder="Enter name"
                    fullWidth
                    onChange={e => setContactName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Phone Number
                  </MDTypography>
                  <FormFields
                    value={phone}
                    name="PhoneNumber"
                    placeholder="Enter phone number"
                    type="tel"
                    fullWidth
                    onChange={e => setPhone(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Alternate Phone Number
                  </MDTypography>
                  <FormFields
                    value={altPhone}
                    name="PhoneNumber"
                    placeholder="Enter phone number"
                    type="tel"
                    fullWidth
                    onChange={e => setAltPhone(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Email Address
                  </MDTypography>
                  <FormFields
                    value={registeredCompanyAddress}
                    name="EmailAddress"
                    placeholder="Enter email address"
                    fullWidth
                    onChange={e => setRegisteredCompanyAddress(e.target.value)}
                    inputProps={{ sx: { textTransform: "lowercase" } }}
                  />
                </Grid>
              </Grid>
            </MDBox>
            <MDBox
              mt={2}
              width="100%"
              display="flex"
              justifyContent="right"
              pl={4}>
              {!isPending &&
                ((id !== undefined || id !== "") && id !== ":id"
                  ? <MDButton
                    type="submit"
                    variant="gradient"
                    color="dark"
                    sx={{ width: "180px" }}>
                    Update Consginee
                  </MDButton>
                  : <MDButton
                    type="submit"
                    variant="gradient"
                    color="dark"
                    sx={{ width: "180px" }}>
                    Create Consginee
                  </MDButton>)}
              {isPending &&
                <MDButton
                  variant="gradient"
                  color="dark"
                  disabled
                  sx={{ width: "180px" }}>
                  <CircularProgress color="white" size={20} />
                </MDButton>}
            </MDBox>
          </MDBox>
        </MDBox>
      </Paper>
      {message && <PopSuccess message={"Consignee Created Successfully"} />}
    </DashboardLayout>
  );
}

export default CreateConsignee;
