import { useNavigate } from 'react-router-dom'

// @mui material components
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
// import MDTypography from "components/MDTypography";

import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'

import ComplexStatisticsCard from 'customs/Cards/StatisticsCards/ComplexStatisticsCard'

//Firebase Hooks
import { useCollection } from '../../../hooks/useCollection'
import { useAuthContext } from 'hooks/useAuthContext'
import PDF from 'components/PDFViewer'

function Home() {
  let navigate = useNavigate()

  const { documents: alerts } = useCollection('jobs')
  const { documents: managers } = useCollection('jobManager')
  const { documents } = useCollection('consignee')
  const { documents: datas } = useCollection('clients')

  const { documents: njr } = useCollection('nonJobRelated')
  const { documents: jr } = useCollection('jobRelatedForm')
  const { user } = useAuthContext()

  const approved1 = njr.filter(
    (ap) => ap.status === 'Approved' && ap.email === user.email
  )
  const approved2 = jr.filter(
    (ap) => ap.status === 'Approved' && ap.email === user.email
  )
  const pending1 = jr.filter(
    (ap) =>
      (ap.status !== 'Approved' || ap.status !== 'Declined') &&
      ap.email === user.email
  )
  const pending2 = njr.filter(
    (ap) =>
      (ap.status !== 'Approved' || ap.status !== 'Declined') &&
      ap.email === user.email
  )
  const declined1 = njr.filter(
    (ap) => ap.status === 'Declined' && ap.email === user.email
  )
  const declined2 = jr.filter(
    (ap) => ap.status === 'Declined' && ap.email === user.email
  )

  const alert = alerts.filter((alert) => alert.preAlertstatus === 'PENDING')
  const pending = alerts.filter(
    (alert) =>
      alert.preAlertstatus === 'IN PROGRESS' && alert.jobType !== 'Export'
  )
  const pendingNew = alerts.filter(
    (alert) =>
      alert.preAlertstatus === 'IN PROGRESS' && alert.jobType === 'Export'
  )
  const complete = alerts.filter(
    (alert) => alert.preAlertstatus === 'COMPLETED'
  )

  // Action buttons for the BookingCard
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  '/monitoring-compliance/job-management/pre-alert-jobs',
                  {
                    replace: true,
                  }
                )
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="work"
                  title="Pre Alert Jobs"
                  users={alert.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  '/monitoring-compliance/job-management/pending-job-files',
                  {
                    replace: true,
                  }
                )
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="work_history"
                  title="Pending Jobs"
                  users={pending.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/monitoring-compliance/job-management/export-job', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="work_history"
                  title="Export Jobs"
                  users={pendingNew.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  '/monitoring-compliance/job-management/completed-job-files',
                  {
                    replace: true,
                  }
                )
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="work_off"
                  title="Completed Jobs"
                  users={complete.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/monitoring-compliance/job-management/job-manager', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person"
                  title="Job Managers"
                  users={managers.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/monitoring-compliance/m&c/consignee', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="secondary"
                  icon="group"
                  title="Consignees"
                  users={documents.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/monitoring-compliance/m&c/client', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="error"
                  icon="group"
                  title="Client"
                  users={datas.length}
                  size="12px"
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/monitoring-compliance/request-status/approved', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="check_circle_outline"
                  title="Approved Requests"
                  users={approved1.length + approved2.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/monitoring-compliance/request-status/pending', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="hourglass_bottom"
                  title="Pending Requests"
                  users={pending1.length + pending2.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/monitoring-compliance/request-status/declined', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="error"
                  icon="cancel"
                  title="Declined Requests"
                  users={declined1.length + declined2.length}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {/* <PDF/> */}
      </MDBox>
    </DashboardLayout>
  )
}

export default Home
