import Icon from "@mui/material/Icon";
import Home from "./Home";
import Approved from './Approved'
import Pending from './Pending'
import Declined from './Declined'
import JobRelated from "./MakeRequest/JobRelated";
import NonJobRelated from "./MakeRequest/NonJobRelated";
import PendingJobTable from "./PendingJobTable";
import PendingJobModal from "./PendingJobTable/Modal/PendingJobModal";
import CompletedJobTable from "./CompletedJobTable";
import AirwayBillModal from "./PendingJobTable/Modal/AirWayBillModal";
import AllRequest from "./AllRequest"

const routes = [
  {
    type: "singleRoute",
    singleRoute: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "dashboard",
    component: <Home />
  },
  { type: "divider", key: "divider-0" },

  {
    type: "title",
    title: "Finance and Accounting",
    key: "Finance"
  },
  {
    type: "collapse",
    name: "Financial Requests",
    key: "financial-requests",
    icon: <Icon fontSize="small">reorder</Icon>,
    collapse: [
      {
        name: "Approved",
        key: "approved",
        route: "request-status/approved",
        icon: <Icon fontSize="small">check_circle_outline</Icon>,
        component: <Approved />
      },
      {
        name: "Pending",
        key: "pending",
        route: "request-status/pending",
        icon: <Icon fontSize="small">hourglass_bottom</Icon>,
        component: <Pending />
      },
      {
        name: "Declined",
        key: "declined",
        route: "request-status/declined",
        icon: <Icon fontSize="small">cancel</Icon>,
        component: <Declined />
      }
    ]
  },
  {
    type: "collapse",
    name: "Job File Management",
    key: "job-management",
    icon: <Icon fontSize="small">folder</Icon>,
    collapse: [
      {
        name: "Pending Job Files",
        key: "pending-job-files",
        route: "job-management/pending-job",
        icon: <Icon fontSize="small">work_history</Icon>,
        component: <PendingJobTable />
      },
      {
        name: "Completed Job Files",
        key: "completed-job-files",
        route: "job-management/completed-job",
        icon: <Icon fontSize="small">work_off</Icon>,
        component: <CompletedJobTable />
      }
    ]
  },
  {
    type: "collapse",
    name: "Make Request",
    key: "make-request",
    icon: <Icon fontSize="small">queue</Icon>,
    collapse: [
      {
        name: "Job Related",
        key: "job-related",
        route: "make-request/job-related",
        icon: <Icon fontSize="small">content_paste</Icon>,
        component: <JobRelated />
      },
      {
        name: "Non Job Related",
        key: "non-job-related",
        route: "make-request/non-job-related",
        icon: <Icon fontSize="small">content_paste_off</Icon>,
        component: <NonJobRelated />
      }
    ]
  },
  {
    type: "hiddenRoute",
    name: "PendingJobs",
    key: "pending-jobs",
    route: "job-management/pending-jobs/:id",
    icon: <Icon fontSize="medium">company</Icon>,
    component: <PendingJobModal />
  },
  {
    type: "hiddenRoute",
    name: "PendingJobs",
    key: "pending-jobs",
    route: "job-management/pending-jobs-airwybill/:id",
    icon: <Icon fontSize="medium">company</Icon>,
    component: <AirwayBillModal />
  },
  {
    type: "hiddenRoute",
    name: "All Requests",
    key: "all-request",
    route: "financial-request/all-requests",
    icon: <Icon fontSize="small">view_list</Icon>,
    component: <AllRequest/>
  }
];

export default routes;
