import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import React from "react";
import TerminalReport from "./table";

const GateInReportMain = () => {
  const [openTab, setOpenTab] = React.useState(1);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="flex flex-wrap">
        <div className="w-full">
          
          <div className="relative flex flex-col w-full min-w-0 mb-6 break-words bg-white rounded shadow-lg">
            <div className="flex-auto px-4 py-5">
              <div className>
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <TerminalReport />
                </div>
                {/* <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <DispatchReportChart />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default GateInReportMain;
