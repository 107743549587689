import { CircularProgress, Grid, Paper, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import { useSignup } from "hooks/useSignup";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {useKingsooService} from '../../../hooks/useKingsooServices'
import PopSuccess from "views/PopMessage/PopSuccess";
import PopError from "views/PopMessage/PopError";

export const EmployeeAuthentication = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("123456");
  const [photoURL, setPhotoURL] = useState("");
  const [displayName, setDisplayName] = useState("");
  const { signup, error, isPending, success } = useSignup();

  const { id } = useParams();
  let navigate = useNavigate();

  const {getUser} = useKingsooService()

  useEffect(
    () => {
      const detail = async () => {
        try {
          const docSnap = await getUser(id);

          setEmail(docSnap.data().workEmail);
          setDisplayName(docSnap.data().id);
          setPhotoURL(docSnap.data().employeeUnit);
        } catch (error) {
          // console.log(docSnap)
        }
      };
      if (id !== undefined || id !== "") {
        detail();
      }
    },
    [id]
  );

  const handleSubmit = e => {
    e.preventDefault();
    signup(email, password, displayName, photoURL);
    if (success) {
      e.target.reset();
    }
  };

  useEffect(
    () => {
      if (success) {
        setTimeout(() => {
          navigate("/human-resources/employee-management/all-employees", {
            replace: true
          });
        }, 5000);
      }
    },
    [success, navigate]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox component="form" onSubmit={handleSubmit} noValidate>
        <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
          <MDBox>
            <MDTypography
              variant="h5"
              fontWeight="bold"
              sx={{ borderBottom: 1, pb: 1, pt: 4 }}>
              Employee Login Credentials
            </MDTypography>
          </MDBox>
          {error && <PopError message={error} />}
          <MDBox mt={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Employee Unit{" "}
                </MDTypography>
                <MDBox>
                  <TextField
                    name="EmployeeUnit"
                    value={photoURL}
                    fullWidth
                    placeholder="Employee Unit"
                    onChange={e => setPhotoURL(e.target.value)}
                    disabled
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={4}>
                <MDTypography sx={{ fontSize: 13 }}>Email </MDTypography>
                <TextField
                  name="EmployeeEmail"
                  value={email}
                  fullWidth
                  placeholder="Enter Email"
                  onChange={e => setEmail(e.target.value)}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MDTypography sx={{ fontSize: 13 }}>Password</MDTypography>
                <TextField
                  name="password"
                  type="password"
                  fullWidth
                  value={password}
                  placeholder="Input Password"
                  onChange={e => setPassword(e.target.value)}
                  disabled
                />
              </Grid>
            </Grid>
            <MDBox sx={{ pt: 6, display: "flex", justifyContent: "flex-end" }}>
              {!isPending &&
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="dark"
                  sx={{ width: "170px" }}>
                  Create Account
                </MDButton>}
              {isPending &&
                <MDButton
                  variant="gradient"
                  color="dark"
                  disabled
                  sx={{ width: "170px" }}>
                  <CircularProgress color="white" size={20} />
                </MDButton>}
            </MDBox>
          </MDBox>
        </Paper>
      </MDBox>
      {success &&
        <PopSuccess
          message={
            "Employee Authentication created successfully, follow the intructions sent to your email address to complete authentication"
          }
        />}
    </DashboardLayout>
  );
};
