// @mui material components
import Grid from '@mui/material/Grid'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import FormFields from 'layouts/pages/account/components/FormField'

// page components
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import { Paper, MenuItem, Select } from '@mui/material'
import { useState, useEffect } from 'react'
// import './style.css'

//Firebase Hook
// import { useFirestore } from '../../../hooks/useFirestore'
import {useKingsooService} from '../../../../hooks/useKingsooServices'

import { useNavigate, useParams } from 'react-router-dom'

// Alert Message
// import PopSuccess from '../../PopMessage/Success'

function PendingJobForm() {
  const [containerInfo, setContainerInfo] = useState([
    {
      containerSize: '',
      security: '',
      gateOutDate: '',
      destination: '',
      gateOutTruckdriverNo: '',
      gateOutTruckdriverName: '',
      gateOutSecurity: '',
    },
  ])
  // const [waybillNumber, setWaybillNumber] = useState('')

  const { id } = useParams()
  const navigate = useNavigate()
  const {getJob, updateTerminateJob} = useKingsooService()

  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await getJob(id)
        // console.log(docSnap.data())
        setContainerInfo(docSnap.data().containerInfo)
        //   setBillOfLaden(docSnap.data().airWayBill)
        //   setPreAlertImage(docSnap.data().preALertImage)
      } catch {
        //   console.log('error')
      }
    }
    // console.log('na u know', id)
    if ((id !== undefined || id !== '') && id !== ':id') {
      detail()
    }
  }, [id])

  const handleSubmit = async (e) => {
    e.preventDefault()
    // console.log(containerInfo)
    try {
      await updateTerminateJob(id, { containerInfo })
      navigate('/gate/gate-in-out')
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleFormChange = (index, e) => {
    let data = [...containerInfo]
    data[index][e.target.name] = e.target.value
    setContainerInfo(data)
  }
  // const container = containerInfo.filter((con) => con.gateStatus !== 'Gate-In')
  // console.log(container)
  // const handleChange = (newValue) => {
  //   setValue(newValue)
  // }
  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
          <MDBox mt={5} component="form" onSubmit={handleSubmit}>
            {containerInfo.map((con, index) => {
              return (
                <MDBox mt={5}>
                  <MDTypography
                    variant="h5"
                    fontWeight="bold"
                    sx={{ borderBottom: 1, pb: 1, pt: 4 }}
                  >
                    Container {index + 1} Inward Details
                  </MDTypography>
                  {con.gateStatus !== 'Gate-Out' && (
                    <MDBox mt={1.625}>
                      <Grid container spacing={3} mb={4}>
                        <Grid item xs={12} sm={4}>
                          <MDTypography sx={{ fontSize: 13 }}>
                            Waybill Number{' '}
                          </MDTypography>
                          <FormFields
                            value={con.waybillNumber}
                            onChange={(e) => handleFormChange(index, e)}
                            name="waybillNumber"
                            placeholder="Enter Waybill Number"
                            inputProps={{
                              style: { textTransform: 'uppercase' },
                            }}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MDTypography sx={{ fontSize: 13 }}>
                            Container Size
                          </MDTypography>
                          <FormFields
                            name="ContainerSize"
                            value={con.containerSize}
                            onChange={(e) => handleFormChange(index, e)}
                            placeholder="Autogenerated"
                            inputProps={{
                              style: { textTransform: 'capitalize' },
                            }}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MDTypography sx={{ fontSize: 13 }}>
                            Container Number{' '}
                          </MDTypography>
                          <FormFields
                            name="ContainerNumber"
                            value={con.containerId}
                            onChange={(e) => handleFormChange(index, e)}
                            placeholder="Enter Container Number"
                            inputProps={{
                              style: { textTransform: 'uppercase' },
                            }}
                            disabled
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3} mb={4}></Grid>
                      <Grid container spacing={3} mb={4}>
                        <Grid item xs={12} sm={4}>
                          <MDTypography sx={{ fontSize: 13 }}>
                            Destination{' '}
                          </MDTypography>
                          <FormFields
                            value={con.destination}
                            onChange={(e) => handleFormChange(index, e)}
                            name="destination"
                            placeholder="Enter Destination"
                            inputProps={{
                              style: { textTransform: 'capitalize' },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MDTypography sx={{ fontSize: 13 }}>
                            Driver's Name{' '}
                          </MDTypography>
                          <FormFields
                            value={con.gateOutTruckdriverName}
                            onChange={(e) => handleFormChange(index, e)}
                            name="gateOutTruckdriverName"
                            placeholder="Enter Driver's Name"
                            type="text"
                            inputProps={{
                              style: { textTransform: 'capitalize' },
                            }}
                            disabled={con.gateOutTruckdriverName && 'disabled'}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MDTypography sx={{ fontSize: 13 }}>
                            Driver's Contact
                          </MDTypography>
                          <FormFields
                            value={con.gateOutTruckdriverNo}
                            onChange={(e) => handleFormChange(index, e)}
                            name="gateOutTruckdriverNo"
                            placeholder="Enter Driver's Contact"
                            type="tel"
                            inputProps={{
                              style: { textTransform: 'capitalize' },
                            }}
                            disabled={con.gateOutTruckdriverNo && 'disabled'}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MDTypography sx={{ fontSize: 13 }}>
                            Security on Seat{' '}
                          </MDTypography>
                          <FormFields
                            value={con.security}
                            onChange={(e) => handleFormChange(index, e)}
                            name="gateOutSecurity"
                            placeholder="Enter Security on Seat"
                            inputProps={{
                              style: { textTransform: 'capitalize' },
                            }}
                            disabled={con.gateOutSecurity && 'disabled'}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MDTypography sx={{ fontSize: 13 }}>
                            Date{' '}
                          </MDTypography>
                          <input
                            type="date"
                            name="gateOutDate"
                            value={con.gateOutDate}
                            onChange={(e) => handleFormChange(index, e)}
                            className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            disabled={con.gateOutDate && 'disabled'}
                          />
                        </Grid>
                        {con.gateStatus === 'Gate-In' &&
                        con.gateStatus !== '' ? (
                          <Grid item xs={12} sm={4}>
                            <MDTypography sx={{ fontSize: 13 }}>
                              Gate Out{' '}
                            </MDTypography>
                            <Select
                              labelId="demo-simple-select-autowidth-label"
                              id="demo-simple-select-autowidth"
                              value={con.gateInDate}
                              onChange={(e) => handleFormChange(index, e)}
                              fullWidth
                              label="Gate"
                              name="gateStatus"
                              className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                           "
                            >
                              <MenuItem value="Gate-Out">Yes</MenuItem>
                            </Select>
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={4}>
                            <MDTypography sx={{ fontSize: 13 }}>
                              Not Yet Gated In{' '}
                            </MDTypography>
                            <FormFields
                              value={con.gateStatus}
                              disabled
                              // onChange={(e) => handleFormChange(index, e)}
                              // name="security"
                              // placeholder="Enter Security on Seat"
                              inputProps={{
                                style: { textTransform: 'capitalize' },
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </MDBox>
                  )}
                </MDBox>
              )
            })}
            <MDBox
              mt={2}
              width="100%"
              display="flex"
              justifyContent="right"
              pl={4}
              fontSize="14px"
            >
              <MDButton
                type="submit"
                variant="gradient"
                color="dark"
                sx={{ width: '180px' }}
              >
                Gate Out
              </MDButton>
            </MDBox>
          </MDBox>
        </Paper>
      </DashboardLayout>
    </div>
  )
}

export default PendingJobForm
