import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

// @mui material components
import Grid from '@mui/material/Grid'
import Autocomplete from '@mui/material/Autocomplete'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import FormFields from 'layouts/pages/account/components/FormField'
import selectData from './data'

// page components
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import { Paper, CircularProgress } from '@mui/material'
// import './style.css'

//Firebase Hook
import { useFirestore } from '../../../hooks/useFirestore'
import {useKingsooService} from '../../../hooks/useKingsooServices'

// Alert Message
import PopSuccess from 'views/PopMessage/PopSuccess'

function ClientDetails() {
  const [reset, setReset] = useState(false)
  const [clientName, setClientName] = useState('')
  const [clientType, setClientType] = useState('')
  const [prefix, setPrefix] = useState('')
  const [rcNumber, setRcNumber] = useState('')
  const [clientCategory, setClientCategory] = useState('')
  const [businessCategory, setBusinessCategory] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [businessAddress, setBusinessAddress] = useState('')
  const [altPhone, setAltPhone] = useState('')
  const [wareHouseAddress, setWareHouseAddress] = useState('')
  const [companyEmail, setCompanyEmail] = useState('')
  const [tin, setTin] = useState('')
  const [registeredCompanyAddress, setRegisteredCompanyAddress] = useState('')
  const [contactName, setContactName] = useState('')
  const [companyPhone, setCompanyPhone] = useState('')
  const [AltCompanyPhone, setAltCompanyPhone] = useState('')
  const [jobOwner, setJobOwner] = useState('')
  const [jobOwnerContact, setJobOwnerContact] = useState('')

  const { id } = useParams()
  let navigate = useNavigate()
  const {getClient,updateClient } = useKingsooService()

  const detail = async () => {
    try {
      const docSnap = await getClient(id)

      setClientName(docSnap.data().clientName)
      setPhone(docSnap.data().phone)
      setClientType(docSnap.data().clientType)
      setPrefix(docSnap.data().prefix)
      setRcNumber(docSnap.data().rcNumber)
      setClientCategory(docSnap.data().clientCategory)
      setBusinessCategory(docSnap.data().businessCategory)
      setCompanyAddress(docSnap.data().companyAddress)
      setBusinessAddress(docSnap.data().businessAddress)
      setAltPhone(docSnap.data().altPhone)
      setCompanyEmail(docSnap.data().companyEmail)
      setWareHouseAddress(docSnap.data().wareHouseAddress)
      setTin(docSnap.data().tin)
      setJobOwner(docSnap.data().jobOwner)
      setJobOwnerContact(docSnap.data().jobOwnerContact)
      setContactName(docSnap.data().contactName)
      setCompanyPhone(docSnap.data().companyPhone)
      setRegisteredCompanyAddress(docSnap.data().registeredCompanyAddress)
      setAltCompanyPhone(docSnap.data().altCompanyPhone)
    } catch (error) {
      // console.log(error)
    }
  }

  useEffect(() => {
    if (id !== undefined || id !== '') {
      detail()
    }
  }, [id])

  const { addDocument, message, isPending } = useFirestore('clients')

  const handleSubmit = async (e) => {
    e.preventDefault()
    const newClient = {
      clientName,
      clientType,
      prefix,
      rcNumber,
      clientCategory,
      businessCategory,
      companyAddress,
      phone,
      businessAddress,
      altPhone,
      AltCompanyPhone,
      wareHouseAddress,
      companyEmail,
      tin,
      jobOwner,
      jobOwnerContact,
      companyPhone,
      registeredCompanyAddress,
      contactName
    }
    try {
      if ((id !== undefined || id !== '') && id !== ':id') {
        await updateClient(id, newClient)
        // setStaffId('')
        setReset(true)
        if (reset) {
          e.target.reset()
        }
      } else {
        await addDocument(newClient)
        if (message) {
          e.target.reset()
        }
      }
    } catch (error) {
      // console.log('msg', error.message)
    }
    if (message) {
      e.target.reset()
    }
  }

  if (message || reset) {
    navigate('/monitoring-compliance/m&c/client', { replace: true })
  }

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
          <MDBox mt={5} component="form" onSubmit={handleSubmit}>
            <MDTypography
              variant="h5"
              fontWeight="bold"
              sx={{ borderBottom: 1, pb: 1, pt: 4 }}
            >
              Client Details
            </MDTypography>
            <MDBox mt={1.625}>
              <Grid container spacing={3} mb={4}>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Client's Name{' '}
                  </MDTypography>
                  <FormFields
                    value={clientName}
                    name="CompanyName"
                    placeholder="Enter Company's Name"
                    onChange={(e) => setClientName(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Client Type{' '}
                  </MDTypography>
                  <MDBox>
                    <Autocomplete
                      // defaultValue="Select Company Type"
                      value={clientType}
                      options={selectData.clientType}
                      onChange={(e, newValue) => setClientType(newValue)}
                      renderInput={(params) => (
                        <FormFields
                          {...params}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Client Prefix{' '}
                  </MDTypography>
                  <FormFields
                    value={prefix}
                    name="CompanyPrefix"
                    placeholder="Enter Company Prefix"
                    onChange={(e) => setPrefix(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} mb={4}>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    RC/BN Number{' '}
                  </MDTypography>
                  <FormFields
                    value={rcNumber}
                    name="RN/BN"
                    placeholder="Enter RC/BN number"
                    onChange={(e) => setRcNumber(e.target.value)}
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Client Category{' '}
                  </MDTypography>
                  <Autocomplete
                    // defaultValue="Select Client Category"
                    value={clientCategory}
                    options={selectData.clientCategory}
                    onChange={(e, newValue) => setClientCategory(newValue)}
                    renderInput={(params) => (
                      <FormFields
                        {...params}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Business Category{' '}
                  </MDTypography>
                  <Autocomplete
                    // defaultValue="Select Business Category"
                    value={businessCategory}
                    options={selectData.businessCategory}
                    onChange={(e, newValue) => setBusinessCategory(newValue)}
                    renderInput={(params) => (
                      <FormFields
                        {...params}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} mb={4}>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Tax Identification Number{' '}
                  </MDTypography>
                  <FormFields
                    value={tin}
                    name="TaxIdentificationNumber"
                    placeholder="Enter TIN Number"
                    type="number"
                    fullWidthn
                    onChange={(e) => setTin(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Company Phone Number
                  </MDTypography>
                  <FormFields
                    value={phone}
                    name="CompanyPhoneNumber"
                    placeholder="Enter Phone Number"
                    type="number"
                    onChange={(e) => setPhone(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Alternative Company Phone Number{' '}
                  </MDTypography>
                  <FormFields
                    value={altPhone}
                    name="CompanyPhoneNumber"
                    placeholder="Enter Phone Number"
                    type="number"
                    onChange={(e) => setAltPhone(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} mb={4}>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Registered Company Address{' '}
                  </MDTypography>
                  <FormFields
                    value={companyAddress}
                    name="RegisteredCompanyAddress"
                    placeholder="Enter Company Address"
                    onChange={(e) => setCompanyAddress(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Business Address
                  </MDTypography>
                  <FormFields
                    value={businessAddress}
                    name="BusinessAddress"
                    placeholder="Enter Business Address"
                    onChange={(e) => setBusinessAddress(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Ware House Address
                  </MDTypography>
                  <FormFields
                    value={wareHouseAddress}
                    name="WareHouseAddress"
                    placeholder="Enter Ware House Address"
                    onChange={(e) => setWareHouseAddress(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} mb={4}>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Company Email Address{' '}
                  </MDTypography>
                  <FormFields
                    value={companyEmail}
                    name="CompanyEmail"
                    placeholder="Enter Ware House Address"
                    onChange={(e) => setCompanyEmail(e.target.value)}
                    inputProps={{ style: { textTransform: 'lowercase' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>Job Owner </MDTypography>
                  <FormFields
                    value={jobOwner}
                    name="JobOwner"
                    placeholder="Enter Job Owner"
                    onChange={(e) => setJobOwner(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Job Owner's Contact{' '}
                  </MDTypography>
                  <FormFields
                    value={jobOwnerContact}
                    name="JobOwnerContact"
                    placeholder="Enter Job Owner's Contact"
                    type="number"
                    onChange={(e) => setJobOwnerContact(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={5}>
              <MDTypography
                variant="h5"
                fontWeight="bold"
                sx={{ borderBottom: 1, pb: 1, pt: 4 }}
              >
                Contact Person Details
              </MDTypography>
              <MDBox mt={1.625}>
                <Grid container spacing={4} mb={4}>
                  <Grid item xs={12} sm={4}>
                    <MDTypography sx={{ fontSize: 13 }}>
                      Contact Name
                    </MDTypography>
                    <FormFields
                      value={contactName}
                      name="ContactName"
                      placeholder="Enter name"
                      fullWidth
                      onChange={(e) => setContactName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MDTypography sx={{ fontSize: 13 }}>
                      Phone Number
                    </MDTypography>
                    <FormFields
                      value={companyPhone}
                      name="PhoneNumber"
                      placeholder="Enter phone number"
                      type="number"
                      fullWidth
                      onChange={(e) => setCompanyPhone(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MDTypography sx={{ fontSize: 13 }}>
                      Alternate Phone Number
                    </MDTypography>
                    <FormFields
                      value={AltCompanyPhone}
                      name="PhoneNumber"
                      placeholder="Enter phone number"
                      type="number"
                      fullWidth
                      onChange={(e) => setAltCompanyPhone(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4}>
                    <MDTypography sx={{ fontSize: 13 }}>
                      Email Address
                    </MDTypography>
                    <FormFields
                      value={registeredCompanyAddress}
                      name="EmailAddress"
                      placeholder="Enter email address"
                      fullWidth
                      onChange={(e) =>
                        setRegisteredCompanyAddress(e.target.value)
                      }
                      inputProps={{ style: { textTransform: 'lowercase' } }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox
                mt={2}
                width="100%"
                display="flex"
                justifyContent="right"
                pl={4}
                fontSize="14px"
              >
                {!isPending &&
                  ((id !== undefined || id !== '') && id !== ':id' ? (
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="dark"
                      sx={{ width: '180px' }}
                    >
                      Update
                    </MDButton>
                  ) : (
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="dark"
                      sx={{ width: '180px' }}
                    >
                      Create Client
                    </MDButton>
                  ))}
                {isPending && (
                  <MDButton
                    variant="gradient"
                    color="dark"
                    disabled
                    sx={{ width: '180px' }}
                  >
                    <CircularProgress color="white" size={20} />
                  </MDButton>
                )}
              </MDBox>
            </MDBox>
          </MDBox>
        </Paper>
      </DashboardLayout>
      {message && <PopSuccess />}
    </div>
  )
}

export default ClientDetails
