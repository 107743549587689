import Icon from "@mui/material/Icon";
import Home from "./Home";
import Approved from './Approved'
import Pending from './Pending'
import Declined from './Declined'
import JobRelated from "./MakeRequest/JobRelated";
import NonJobRelated from "./MakeRequest/NonJobRelated";


const routes = [
    {
        type: "singleRoute",
        singleRoute: "Dashboard",
        key: "dashboard",
        icon: <Icon fontSize="medium">dashboard</Icon>,
        route: "dashboard",
        component: <Home />,
    },
    { type: "divider", key: "divider-0" },

    {
        type: 'title',
        title: 'Finance and Accounting',
        key: 'Finance',
    },
    {
        type: 'collapse',
        name: 'Financial Requests',
        key: 'financial-requests',
        icon: <Icon fontSize="small">reorder</Icon>,
        collapse: [
            {
                name: "Approved",
                key: "approved",
                route: "request-status/approved",
                icon: <Icon fontSize="small">check_circle_outline</Icon>,
                component: <Approved />,
            },
            {
                name: "Pending",
                key: "pending",
                route: "request-status/pending",
                icon: <Icon fontSize="small">hourglass_bottom</Icon>,
                component: <Pending />,
            },
            {
                name: "Declined",
                key: "declined",
                route: "request-status/declined",
                icon: <Icon fontSize="small">cancel</Icon>,
                component: <Declined />,
            },
        ],
    },
    {
        type: 'collapse',
        name: 'Make Request',
        key: 'make-request',
        icon: <Icon fontSize="small">queue</Icon>,
        collapse: [
            {
                name: 'Job Related',
                key: 'job-related',
                route: 'make-request/job-related',
                icon: <Icon fontSize="small">content_paste</Icon>,
                component: <JobRelated />,
            },
            {
                name: 'Non Job Related',
                key: 'non-job-related',
                route: 'make-request/non-job-related',
                icon: <Icon fontSize="small">content_paste_off</Icon>,
                component: <NonJobRelated />,
            },
        ]
    },

];

export default routes;
