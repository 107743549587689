import { useState } from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import React from "react";
import {
  ArrowNarrowDownIcon,
  ArrowNarrowUpIcon,
  CursorClickIcon,
  FolderAddIcon,
  FolderOpenIcon,
  UsersIcon
} from "@heroicons/react/outline";

//Firebase Hook
import { useCollection } from "hooks/useCollection";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const PayOfficerReport = () => {
  const [responsive] = useState("horizontal");
  const [tableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight] = useState("");
  const [searchBtn] = useState(true);
  const [downloadBtn] = useState(true);
  const [printBtn] = useState(true);
  const [viewColumnBtn] = useState(true);
  const [filterBtn] = useState(true);
  const [userId, setUserId] = useState("");
  const { documents: payofficer } = useCollection("jobRelatedForm");
  const { documents: payOfficerNon } = useCollection("nonJobRelated");
  const [openTab, setOpenTab] = React.useState(1);
  // const { documents: allJobs } = useCollection("jobs");
  const payofficerReport = payOfficerNon.filter(
    f => f.status === "Pay Officer"
  );
  const payofficerNonReport = payofficer.filter(
    f => f.status === "Pay Officer"
  );
  const paidTotal = payofficer.filter(f => f.paid === "full");
  const nonJobRelaedPaid = payOfficerNon.filter(f => f.paid === "full");
  const approvedPayment = paidTotal.concat(nonJobRelaedPaid);
  const fullyPaid = paidTotal.length + nonJobRelaedPaid.length;
  const total = payofficer.length + payOfficerNon.length;

  const result = payofficerNonReport.concat(payofficerReport);
  const resultTotal = result.length;
  const halfTotal = payofficer.filter(f => f.paid === "partial");
  const halfPay = payOfficerNon.filter(f => f.paid === "partial");
  const resultPercentage = 100 * fullyPaid / total;
  const pendingPercentage = 100 * resultTotal / total;
  const partialPayment = halfTotal.length + halfPay.length;
  const incomplete = halfPay.concat(halfPay);
  // console.log(typeof financeReport)
  const payment = approvedPayment.map(function(amount) {
    return parseInt(amount.paymentValue);
  });
  const incompletePayment = incomplete.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const values = Object.values(payment);
  const incompletePaid = Object.values(incompletePayment);
  const sum = values.reduce((sum, value) => {
    return sum + value;
  }, 0);
  const partial = incompletePaid.reduce((sum, value) => {
    return sum + value;
  }, 0);
  let ngn = Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN"
  });
  const stats = [
    {
      id: 1,
      name: "Total Request",
      stat: total.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
      icon: UsersIcon,
      change: "",
      changeType: "increase"
    },
    {
      id: 2,
      name: "financial Request Fully Paid",
      stat: fullyPaid
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
      icon: FolderOpenIcon,
      change: resultPercentage.toFixed(2),
      changeType: fullyPaid < total / 2 ? "decrease" : "increase"
    },
    {
      id: 5,
      name: "financial Request Partially Paid",
      stat: partialPayment
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
      icon: FolderOpenIcon,
      changeType: partialPayment <= 0 ? "decrease" : "increase"
    },
    {
      id: 3,
      name: "Pending request",
      stat: resultTotal
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
      icon: FolderAddIcon,
      change: pendingPercentage.toFixed(2),
      changeType: resultTotal >= 20 ? "decrease" : "increase"
    },
    {
      id: 4,
      name: "Total Fully Paid ",
      stat: ngn.format(sum),
      icon: CursorClickIcon,
      changeType: null
    },
    {
      id: 4,
      name: "Total Partial Paid ",
      stat: ngn.format(partial),
      icon: CursorClickIcon,
      changeType: null
    }
  ]; // change: pendingPercentage.toFixed(2),
  // change: resultPercentage.toFixed(2),
  // changeType: payOfficer !== total / 2 ? "decrease" : "increase"
  // change: pendingPercentage.toFixed(2),
  // changeType: financeAllJobs.length > 0 ? "decrease" : "increase"
  // {
  //   id: 4,
  //   name: "Financial Equivalent Approved",
  //   stat: ngn.format(sum),
  //   icon: CursorArrowRaysIcon, // change: pendingPercentage.toFixed(2),
  //   changeType: null
  // }

  const columns = [
    {
      name: "requestId",
      label: "File Number",
      options: { filter: true, sort: true }
    },
    // { name: "fileId", label: "Job ID", options: { filter: true, sort: true } },
    {
      name: "id",
      hide: true,
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false
      }
    },
    {
      name: "paymentValue",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: dataIndex => {
          let val = approvedPayment[dataIndex].paymentValue;
          let ngn = Intl.NumberFormat("en-NG", {
            style: "currency",
            currency: "NGN"
          });
          return ngn.format(val);
        }
      }
    },
    {
      name: "date",
      label: "Date Created",
      options: { filter: true, sort: true }
    },
    {
      name: "email",
      label: "Requester",
      options: { filter: true, sort: true }
    },
    {
      name: "description",
      label: "Description",
      options: { filter: true, sort: true }
    }
    // {
    //   name: "description",
    //   label: "Description",
    //   options: { filter: true, sort: true }
    // }
  ];
  const columnsPending = [
    {
      name: "requestId",
      label: "File Number",
      options: { filter: true, sort: true }
    },
    // { name: "fileId", label: "Job ID", options: { filter: true, sort: true } },
    {
      name: "id",
      hide: true,
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false
      }
    },
    {
      name: "paymentValue",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: dataIndex => {
          let val = result[dataIndex].paymentValue;
          let ngn = Intl.NumberFormat("en-NG", {
            style: "currency",
            currency: "NGN"
          });
          return ngn.format(val);
        }
      }
    },
    {
      name: "date",
      label: "Date Created",
      options: { filter: true, sort: true }
    },
    {
      name: "email",
      label: "Requester",
      options: { filter: true, sort: true }
    },
    {
      name: "description",
      label: "Description",
      options: { filter: true, sort: true }
    }
    // {
    //   name: "description",
    //   label: "Description",
    //   options: { filter: true, sort: true }
    // }
  ];

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    textLabels: {
      body: {
        noMatch: "Sorry no records have been generated"
      }
    },
    sortOrder: {
      name: "firstName",
      direction: "asc"
    }
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiToolbar: {
          styleOverrides: {
            root: {
              // color: "red"
              textTransform: "capitalize"
            }
          }
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 1,
              paddingTop: 1,
              fontSize: "2pt",
              borderBottom: "2px solid black",
              backgroundColor: "#033237"
            }
          },
          paddingBottom: 2,
          marginBottom: 8,
          paddingTop: 1,
          backgroundColor: "#033237"
        }
      }
    });

  return (
    <div className="mt-4">
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {stats.map(item =>
            <div
              key={item.id}
              className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6">
              <dt>
                <div className="absolute rounded-md bg-[#033237] p-3">
                  <item.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">
                  {item.name}
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline ">
                <p className="text-2xl font-semibold text-gray-900">
                  {item.stat}
                </p>
                <p
                  className={classNames(
                    item.changeType === "increase"
                      ? "text-green-600"
                      : "text-red-600",
                    "ml-2 flex items-baseline text-sm font-semibold"
                  )}>
                  {item.changeType == "increase"
                    ? <ArrowNarrowUpIcon
                        className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                        aria-hidden="true"
                      />
                    : <ArrowNarrowDownIcon
                        className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                        aria-hidden="true"
                      />}
                  <span className="sr-only">
                    {" "}{item.changeType === "increase"
                      ? "Increased"
                      : "Decreased"}{" "}
                    by{" "}
                  </span>
                  {item.change}%
                </p>
              </dd>
            </div>
          )}
        </dl>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist">
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 1
                    ? "text-white bg-green-600"
                    : "text-black" + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist">
                Proccessed
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 2
                    ? "text-white bg-green-600"
                    : "text-black" + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist">
                Pending Jobs
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-lg rounded">
            <div className="px-4 py-5 flex-auto">
              <div className>
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <CacheProvider value={muiCache}>
                    <ThemeProvider theme={getMuiTheme}>
                      <MUIDataTable
                        data={
                          approvedPayment // title={`Processed ${fieldreport.length} out of ${countAllJobs.length}`} // title={}
                        }
                        columns={columns}
                        options={options}
                        style={{ marginTop: "10px" }}
                      />
                    </ThemeProvider>
                  </CacheProvider>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <CacheProvider value={muiCache}>
                    <ThemeProvider theme={getMuiTheme}>
                      <MUIDataTable
                        data={
                          result // title={`Processed ${fieldreport.length} out of ${countAllJobs.length}`} // title={}
                        }
                        columns={columnsPending}
                        options={options}
                        style={{ marginTop: "10px" }}
                      />
                    </ThemeProvider>
                  </CacheProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayOfficerReport;
