import { useState } from 'react'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import MUIDataTable from 'mui-datatables'
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { Button } from '@mui/material'

// Firebase Hook
import { useCollection } from '../../../hooks/useCollection'
import { Link, useNavigate } from 'react-router-dom'
import { Payments, Visibility } from '@mui/icons-material'

const muiCache = createCache({
  key: 'mui-datatables',
  prepend: true,
})

const PendingJobTable = () => {
  const { documents } = useCollection('jobs')

  const [responsive] = useState('horizontal')
  const [tableBodyHeight] = useState('auto')
  const [tableBodyMaxHeight] = useState('')
  const [searchBtn] = useState(true)
  const [downloadBtn] = useState(true)
  const [printBtn] = useState(true)
  const [viewColumnBtn] = useState(true)
  const [filterBtn] = useState(true)

  const navigate = useNavigate()

  const data = documents.filter((data) => data.preAlertstatus === 'IN PROGRESS')

  const columns = [
    {
      name: 'fileId',
      label: 'File ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'id',
      label: 'Id',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'consigneeId',
      label: 'Consignee',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: 'center',
        }),
      },
    },
    {
      name: 'importType',
      label: 'Import Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'airWayBill',
      label: 'MAWB',
      hide: true,
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'billOfLaden',
      label: 'BOL/MAWB',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta.rowData, '......')
          return (
            <div>
              {tableMeta.rowData[4]} {tableMeta.rowData[5]}
            </div>
          )
        },
      },
    },
    {
      name: 'containerInfo',
      label: 'Containers',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta.rowData, '......')
          return <div>{tableMeta.rowData[6].length}</div>
        },
      },
    },
    {
      name: 'expectedTimeOfArrival',
      label: 'ETA',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const date1 = new Date(value)
          const date2 = new Date()
          const diffTime = Math.abs(date2 - date1)
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

          // return <Button>{diffDays + ' days'}</Button>

          if (date2 > date1) {
            return (
              <Button
                style={{
                  background: 'black',
                  color: 'white',
                  textTransform: 'none',
                  fontSize: 13,
                  width: '80px',
                }}
              >
                {-diffDays + ' days'}
              </Button>
            )
          } else {
            if (diffDays > 14) {
              return (
                <Button
                  style={{
                    background: 'green',
                    color: 'white',
                    textTransform: 'none',
                    fontSize: 13,
                    width: '80px',
                  }}
                >
                  {diffDays + ' days'}
                </Button>
              )
            } else if (diffDays >= 7) {
              return (
                <Button
                  style={{
                    background: 'orange',
                    color: 'white',
                    textTransform: 'none',
                    fontSize: 13,
                    width: '80px',
                  }}
                >
                  {diffDays + ' days'}
                </Button>
              )
            } else if (diffDays >= 1) {
              return (
                <Button
                  style={{
                    background: 'red',
                    color: 'white',
                    textTransform: 'none',
                    fontSize: '13px',
                    fontWeight: 'bold',
                    width: '80px',
                  }}
                >
                  {diffDays + ' days'}
                </Button>
              )
            } else {
              return (
                <Button
                  style={{
                    background: 'red',
                    color: 'white',
                    textTransform: 'none',
                    fontSize: '13px',
                    fontWeight: 'bold',
                    width: '80px',
                  }}
                >
                  Today
                </Button>
              )
            }
          }
        },
      },
    },
    {
      name: 'jobManagerId',
      label: 'Job Manager',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'preAlertstatus',
      label: 'Job Status',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: 'flex', gap: '.8rem' }}>
              <button
                style={{
                  border: 'none',
                  background: 'transparent',
                  marginRight: '20px',
                  cursor: 'pointer',
                }}
              >
                <Link
                  to={`/staff/job-management/pending-jobs/${tableMeta.rowData[1]}`}
                >
                  <Visibility
                    style={{
                      width: '20px',
                      height: '20px',
                      color: 'black',
                    }}
                  />
                </Link>
              </button>
              <button
                style={{
                  border: 'none',
                  background: 'transparent',
                  marginRight: '20px',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  navigate(
                    `/staff/make-request/job-related/${tableMeta.rowData[1]}`
                  )
                }
              >
                <Payments
                  style={{
                    width: '20px',
                    height: '20px',
                    color: 'black',
                  }}
                />
              </button>
            </div>
          )
        },
      },
    },
  ]

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: 'fileId',
      direction: 'desc',
    },
  }

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 2,
              marginBottom: 8,
              paddingTop: 1,
              backgroundColor: '#033237',
            },
          },
        },

        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              '&:nth-child(odd)': {
                //backgroundColor: "#f6f6f6"
              },
              fontSize: '12px',
            },
          },
        },
      },
    })

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="mt-4">
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              title={'Pending Job Table'}
              data={data}
              columns={columns}
              options={options}
              style={{ marginTop: '10px' }}
            />
          </ThemeProvider>
        </CacheProvider>
      </div>
    </DashboardLayout>
  )
}

export default PendingJobTable
