import { useState, useEffect, useRef } from "react";
import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import React from "react";

//Firebase Hook
import { useCollection } from "hooks/useCollection";
import {
  ArrowNarrowDownIcon,
  ArrowNarrowUpIcon,
  CursorClickIcon,
  FolderOpenIcon,
  UsersIcon
} from "@heroicons/react/outline";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const FinanceReport = () => {
  const [responsive] = useState("horizontal");
  const [tableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight] = useState("");
  const [searchBtn] = useState(true);
  const [downloadBtn] = useState(true);
  const [printBtn] = useState(true);
  const [viewColumnBtn] = useState(true);
  const [filterBtn] = useState(true);
  const [userId, setUserId] = useState("");
  const { documents: finance } = useCollection("jobRelatedForm");
  const { documents } = useCollection("jobRelatedForm");
  const { documents: payOfficer } = useCollection("jobRelatedForm");
  const { documents: financeNon } = useCollection("nonJobRelated");
  const { documents: payOfficerN } = useCollection("nonJobRelated");
  const [openTab, setOpenTab] = React.useState(1);
  // const { documents: allJobs } = useCollection("jobs");
  const financeReport = finance.filter(f => f.status === "Finance");
  const averageReport = payOfficer.filter(ar => ar.status === "Pay Officer");
  const averageNonReport = payOfficerN.filter(
    anr => anr.status === "Pay Officer"
  );
  const financeNonReport = financeNon.filter(f => f.status === "Finance");

  // const countAllJobs = allJobs.filter(
  //   alls => alls.preAlertstatus === "IN PROGRESS" && alls.jobType !== "Export"
  // );

  const financeAllJobs = financeReport.concat(financeNonReport);
  const payOfficerResult = averageNonReport.length + averageReport.length;
  const total = finance.length + financeNon.length;
  const approvedPay = averageReport.concat(averageNonReport);
  const resultPercentage = 100 * payOfficerResult / total;
  const pendingPercentage = 100 * financeReport.length / total;
  const requestApprovedAvg = total / 2;

  console.log(payOfficerResult)

  const payment = approvedPay.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const values = Object.values(payment);
  const sum = values.reduce((sum, value) => {
    return sum + value;
  }, 0);

  let ngn = Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN"
  });

  // const sum = approvedPay.reduce((accumulator, value) => {
  //   return accumulator + value;
  // }, 0);

  const stats = [
    {
      id: 1,
      name: "Total Request",
      stat: total.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
      icon: UsersIcon,
      change: "",
      changeType: "increase"
    },
    {
      id: 2,
      name: "Request Approved",
      stat: payOfficerResult
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
      icon: FolderOpenIcon,
      change: resultPercentage.toFixed(2),
      changeType:
        payOfficerResult <= requestApprovedAvg ? "decrease" : "increase"
    },
    {
      id: 3,
      name: "Pending request",
      stat: financeAllJobs.length,
      icon: CursorClickIcon,
      change: pendingPercentage.toFixed(2),
      changeType: financeAllJobs.length > 20 ? "decrease" : "increase"
    },
    {
      id: 4,
      name: "Financial Equivalent Approved",
      stat: ngn.format(sum),
      icon: CursorClickIcon,
      changeType: null
    }
  ]; // change: pendingPercentage.toFixed(2),
  // console.log(payment);
  const columns = [
    {
      name: "requestId",
      label: "File Number",
      options: { filter: true, sort: true }
    },
    // { name: "fileId", label: "Job ID", options: { filter: true, sort: true } },
    {
      name: "id",
      hide: true,
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false
      }
    },
    {
      name: "paymentValue",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: dataIndex => {
          let val = financeAllJobs[dataIndex].paymentValue;
          let ngn = Intl.NumberFormat("en-NG", {
            style: "currency",
            currency: "NGN"
          });
          return ngn.format(val);
        }
      }
    },
    {
      name: "date",
      label: "Date Created",
      options: { filter: true, sort: true }
    },
    {
      name: "email",
      label: "Requester",
      options: { filter: true, sort: true }
    },
    {
      name: "description",
      label: "Description",
      options: { filter: true, sort: true }
    }
  ];
  const columnsPending = [
    {
      name: "requestId",
      label: "File Number",
      options: { filter: true, sort: true }
    },
    // { name: "fileId", label: "Job ID", options: { filter: true, sort: true } },
    {
      name: "id",
      hide: true,
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false
      }
    },
    {
      name: "paymentValue",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: dataIndex => {
          let val = approvedPay[dataIndex].paymentValue;
          let ngn = Intl.NumberFormat("en-NG", {
            style: "currency",
            currency: "NGN"
          });
          return ngn.format(val);
        }
      }
    },
    {
      name: "date",
      label: "Date Created",
      options: { filter: true, sort: true }
    },
    {
      name: "email",
      label: "Requester",
      options: { filter: true, sort: true }
    },
    {
      name: "description",
      label: "Description",
      options: { filter: true, sort: true }
    }
  ];

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    textLabels: {
      body: {
        noMatch: "Sorry no records have been generated"
      }
    },
    sortOrder: {
      name: "firstName",
      direction: "asc"
    }
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiToolbar: {
          styleOverrides: {
            root: {
              // color: "red"
              textTransform: "capitalize"
            }
          }
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 1,
              paddingTop: 1,
              fontSize: "2pt",
              borderBottom: "2px solid black",
              backgroundColor: "#033237"
            }
          },
          paddingBottom: 2,
          marginBottom: 8,
          paddingTop: 1,
          backgroundColor: "#033237"
        }
      }
    });

  return (
    <div className="mt-4">
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {stats.map(item =>
            <div
              key={item.id}
              className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6">
              <dt>
                <div className="absolute rounded-md bg-[#033237] p-3">
                  <item.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">
                  {item.name}
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline ">
                <p className="text-2xl font-semibold text-gray-900">
                  {item.stat}
                </p>
                <p
                  className={classNames(
                    item.changeType === "increase"
                      ? "text-green-600"
                      : "text-red-600",
                    "ml-2 flex items-baseline text-sm font-semibold"
                  )}>
                  {item.changeType == "increase"
                    ? <ArrowNarrowUpIcon
                        className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                        aria-hidden="true"
                      />
                    : <ArrowNarrowDownIcon
                        className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                        aria-hidden="true"
                      />}
                  <span className="sr-only">
                    {" "}{item.changeType === "increase"
                      ? "Increased"
                      : "Decreased"}{" "}
                    by{" "}
                  </span>
                  {item.change}%
                </p>
              </dd>
            </div>
          )}
        </dl>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist">
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 1
                    ? "text-white bg-green-600"
                    : "text-black" + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist">
                Proccessed
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 2
                    ? "text-white bg-green-600"
                    : "text-black" + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist">
                Pending Jobs
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-lg rounded">
            <div className="px-4 py-5 flex-auto">
              <div className>
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <CacheProvider value={muiCache}>
                    <ThemeProvider theme={getMuiTheme}>
                      <MUIDataTable
                        data={approvedPay}
                        columns={columnsPending}
                        options={options}
                        style={{ marginTop: "10px" }}
                      />
                    </ThemeProvider>
                  </CacheProvider>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <CacheProvider value={muiCache}>
                    <ThemeProvider theme={getMuiTheme}>
                      <MUIDataTable
                        data={financeAllJobs}
                        columns={columns}
                        options={options}
                        style={{ marginTop: "10px" }}
                      />
                    </ThemeProvider>
                  </CacheProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceReport;
