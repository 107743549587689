const selectData = {
  employeeDept: [
    "Executive Management",
    "Freight Forwarding",
    "Marine",
    "Compliance Business Development",
    "Corperate and Commercial",
    "Finance & Accounting",
    "Transport & Logistics",
    "Admin & Human Resources"
  ],
  employeeDesig: [
    "Managing Director",
    "Executive Director",
    "Chief Operating Officer",
    "Head Of Department",
    "Staff"
  ]
};

export default selectData;
