import { useState } from 'react'
import { Link } from 'react-router-dom'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import MUIDataTable from 'mui-datatables'
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

//style
import './Prealert.css'

// Firebase
import { useCollection } from '../../../hooks/useCollection'

const muiCache = createCache({
  key: 'mui-datatables',
  prepend: true,
})

const PreAlertTable = () => {
  const [responsive] = useState('standard')
  const [tableBodyHeight] = useState('auto')
  const [tableBodyMaxHeight] = useState('')
  const [searchBtn] = useState(true)
  const [downloadBtn] = useState(true)
  const [printBtn] = useState(true)
  const [viewColumnBtn] = useState(true)
  const [filterBtn] = useState(true)

  const { documents } = useCollection('jobs')

  const data = documents.filter((data) => data.preAlertstatus === 'PENDING')

  const columns = [
    {
      name: 'billOfLaden',
      label: 'BOL/MAWB',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta.rowData, '......')
          return (
            <div>
              {tableMeta.rowData[2]} {tableMeta.rowData[3]}
            </div>
          )
        },
      },
    },
    {
      name: 'id',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'billOfLaden',
      label: 'BOL',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'airWayBill',
      label: 'MAWB',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'importType',
      label: 'Import Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'jobType',
      label: 'Job Type',
      options: {
        // display: true,
        // viewColumns: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'preAlertCreatedAt',
      label: 'Date / Time Created',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'userId',
      label: 'Created By',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'preAlertstatus',
      label: 'Job Status',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {tableMeta.rowData[5] === 'Export' ? (
                <button
                  style={{
                    fontSize: '13px',
                    padding: '5px',
                    border: 'none',
                    borderRadius: '8px',
                    backgroundColor: '#033237',
                    width: '100%',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                >
                  <Link
                    to={`/monitoring-compliance/job-management/pre-alert-export/${tableMeta.rowData[1]}`}
                    style={{ textDecoration: 'none', color: '#FFF' }}
                  >
                    Create Job
                  </Link>
                </button>
              ) : (
                <button
                  style={{
                    fontSize: '13px',
                    padding: '5px',
                    border: 'none',
                    borderRadius: '8px',
                    backgroundColor: '#033237',
                    width: '100%',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                >
                  {tableMeta.rowData[4] === 'Sea Freight' ? (
                    <Link
                      to={`/monitoring-compliance/job-management/pre-alert-jobs-form/${tableMeta.rowData[1]}`}
                      style={{ textDecoration: 'none', color: '#FFF' }}
                    >
                      Create Job
                    </Link>
                  ) : (
                    <Link
                      to={`/monitoring-compliance/job-management/airway-bill-form/${tableMeta.rowData[1]}`}
                      style={{ textDecoration: 'none', color: '#FFF' }}
                    >
                      Create Job
                    </Link>
                  )}
                </button>
              )}
            </div>
          )
        },
      },
    },
  ]

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    filter: filterBtn,
    filterType: 'dropdown',
    rowHover: false,
    responsive,
    tableBodyHeight,

    tableBodyMaxHeight,
  }

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 2,
              marginBottom: 8,
              paddingTop: 1,
              backgroundColor: '#033237',
            },
          },
        },

        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              '&:nth-child(odd)': {
                //backgroundColor: "#f6f6f6"
              },
              fontSize: '12px',
            },
          },
        },
      },
    })

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="mt-4">
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              title={'Pre-Alert Jobs'}
              data={data ? data : 'No Pending Jobs'}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </CacheProvider>
      </div>
    </DashboardLayout>
  )
}

export default PreAlertTable
