export const selectData = {
  paymentType: ["Statutory", "Non Statutory"],

  headers: [
    "Kalmar Expenses",
    "Recruitment Process",
    "Guest House (Rent)",
    "Vehicles Maintenance",
    "Staff Welfare/Community",
    "Office Furniture",
    "Water",
    "Transport Local",
    "Uniforms",
    "Medical Bill",
    "Utility Bills",
    "Staff Training",
    "Legal & Proffessional Fees",
    "Security",
    "Newspapers & Journals",
    "Stationery",
    "Cleaning Materials",
    "Diesel & Fuel",
    "Toiletries"
  ]
};
