/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";

// NewUser layout schemas for form and form feilds
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
// import Check from "@mui/icons-material/Check";
import PersonIcon from "@mui/icons-material/Person";
import StepConnector, {
  stepConnectorClasses
} from "@mui/material/StepConnector";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PendingIcon from "@mui/icons-material/Pending";
import initialValues from "./schemas/initialValues";
import form from "./schemas/form";
import validations from "./schemas/validations";
import Profile from "./components/Profile/index";
import Socials from "./components/Bank & Pension/index";
import Address from "./components/Employee Details/index";
import UserInfo from "./components/UserInfo/index";
import { Paper } from "@mui/material";
import "./style.css";
import { useCollection } from "hooks/useCollection";
// Firebase Hook
import { useFirestore } from "../../../hooks/useFirestore";

function getSteps() {
  return [
    "Personal Data",
    "Employment Details",
    "Bank and Pension",
    "Additional Data"
  ];
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 40
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "green"
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)"
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1
  }
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "white",
  width: 70,
  height: 70,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
  }),
  ...(ownerState.completed && {
    backgroundColor: "#73CF63"
  }),
  ...(ownerState.icon && {
    width: 70,
    height: 70
  })
}));

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool.isRequired,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool.isRequired,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node.isRequired
};

function ColorlibStepIcon(props) {
  const { active, completed } = props;

  const icons = {
    1: <PersonIcon />,
    2: <BusinessCenterIcon />,
    3: <AccountBalanceIcon />,
    4: <PendingIcon />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <UserInfo formData={formData} />;
    case 1:
      return <Address formData={formData} />;
    case 2:
      return <Socials formData={formData} />;
    case 3:
      return <Profile formData={formData} />;
    default:
      return null;
  }
}

function NewUser() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { addDocument, message } = useFirestore("users");
  let navigate = useNavigate();
  const { documents } = useCollection("users");
  const [staffNum, setStaffNum] = useState("");
  const sleep = ms =>
    new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    await sleep(1000);
    // alert(JSON.stringify(values, null, 2));
    actions.setSubmitting(false);
    actions.resetForm();
    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
      addDocument(values);
      // console.log('handlesubmit', values)
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  if (message) {
    navigate("/human-resources/employee-management/all-employees", {
      replace: true
    });
  }
  useEffect(() => {
    setStaffNum(`KGM/00${documents.length + 1}`);
    // console.log(staffNum);
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Paper sx={{ pb: 6, boxShadow: 2 }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 2 }}>
          <Grid item xs={12} lg={12}>
            <Formik
              enableReinitialize
              initialValues={{
                firstName: "",
                otherName: "",
                lastName: "",
                staffId: staffNum,
                gender: "",
                maritalStatus: "",
                workEmail: "",
                phoneNumber: "",
                dateOfBirth: "",
                nationality: "",
                stateOfOrigin: "",
                lga: "",
                currentAddress: "",
                permanentAddress: "",
                academicQualification: "",
                awardingQualification: "",
                awardingInstitution: "",
                dateOfGraduation: "",
                academicQualification1: "",
                awardingInstitution1: "",
                dateOfGraduation1: "",
                employeeType: "",
                employeeDepartment: "",
                employeeUnit: "",
                employeeDesignation: "",
                employeeConfirmation: "",
                employeeLocation: "",
                dateOfEmployment: "",
                dateOfExit: "",
                grossSalary: "",
                refereeFirstName: "",
                refereeLastName: "",
                refereePhoneNumber: "",
                refereeAddress: "",
                refereeFirstName1: "",
                refereeLastName1: "",
                refereePhoneNumber1: "",
                refereeAddress1: "",
                bankName: "",
                bankAccountNumber: "",
                pensionManager: "",
                pensionNumber: "",
                spouseFirstName: "",
                spouseLastName: "",
                spousePhoneNumber: "",
                spouseEmailAddress: "",
                numberOfChildren: "",
                nextOfKinFirstName: "",
                nextOfKin:"",
                nextOfKinLastName: "",
                nextOfKinPermanentAddress: "",
                nextOfKinPhoneNumber: "",
                nextOfKinAddress: "",
                emergencyFirstName: "",
                emergencyLastName: "",
                emergencyPhoneNumber: "",
                emergencyFirstName1: "",
                emergencyLastName1: "",
                emergencyPhoneNumber1: ""
              }}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}>
              {({ values, errors, touched, setFieldValue, isSubmitting }) =>
                <Form id={formId} autoComplete="off">
                  <div className="stepper">
                    <Stepper
                      alternativeLabel
                      activeStep={activeStep}
                      connector={<ColorlibConnector />}
                      spacing={8}
                      style={{ background: "transparent", boxShadow: "none" }}>
                      {steps.map(label =>
                        <Step key={label}>
                          <StepLabel StepIconComponent={ColorlibStepIcon}>
                            <div className="text-black">
                              {label}
                            </div>
                          </StepLabel>
                        </Step>
                      )}
                    </Stepper>
                  </div>

                  <MDBox p={3}>
                    <MDBox>
                      {getStepContent(activeStep, {
                        values,
                        touched,
                        formField,
                        errors,
                        setFieldValue
                      })}
                      <MDBox
                        mt={2}
                        width="100%"
                        display="flex"
                        justifyContent="right"
                        pl={4}>
                        {activeStep === 0
                          ? <MDBox />
                          : <MDButton
                              style={{ marginRight: 50 }}
                              variant="gradient"
                              color="light"
                              onClick={handleBack}>
                              back
                            </MDButton>}
                        <MDButton
                          disabled={isSubmitting}
                          type="submit"
                          variant="gradient"
                          color="dark">
                          {isLastStep ? "send" : "next"}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Form>}
            </Formik>
          </Grid>
        </Grid>
      </Paper>
    </DashboardLayout>
  );
}

export default NewUser;
