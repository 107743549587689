import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import MUIDataTable from 'mui-datatables'
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

import {useKingsooService} from '../../../hooks/useKingsooServices'

const muiCache = createCache({
  key: 'mui-datatables',
  prepend: true,
})

const Inwarded = () => {
  const [responsive] = useState('horizontal')
  const [tableBodyHeight] = useState('auto')
  const [tableBodyMaxHeight] = useState('')
  const [searchBtn] = useState(true)
  const [downloadBtn] = useState(true)
  const [printBtn] = useState(true)
  const [viewColumnBtn] = useState(true)
  const [filterBtn] = useState(true)
  const [containerInfo, setContainerInfo] = useState([])

  const { id } = useParams()
  const {getJob} = useKingsooService()

  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await getJob(id)
        // console.log(docSnap.data())
        setContainerInfo(docSnap.data().containerInfo)
      } catch {
        //   console.log('error')
      }
    }
    // console.log('na u know', id)
    if ((id !== undefined || id !== '') && id !== ':id') {
      detail()
    }
  }, [id])

  const container = containerInfo.filter((con) => con.gateStatus === 'Gate-Out')
  console.log('ok', containerInfo)

  const columns = [
    {
      name: 'wayBillNo',
      label: 'Waybill',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'containerSize',
      label: 'Size',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'containerId',
      label: 'No.',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'categoryOfTruck',
      label: 'Truck',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'security',
      //   label: 'Security',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'gateOutTruckdriverName',
      label: 'Driver Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'gateOutTruckdriverNo',
      label: 'Contact',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'gateOutDate',
      label: 'date',
      options: {
        filter: true,
        sort: true,
      },
    },
  ]
  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
  }

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 1,
              paddingTop: 1,
              backgroundColor: '#033237',
            },
          },
        },

        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              '&:nth-child(odd)': {
                //backgroundColor: "#f6f6f6"
              },
              fontSize: '12px',
            },
          },
        },
      },
    })

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="mt-4">
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              title={'Awaiting Outward Terminal'}
              data={container}
              columns={columns}
              options={options}
              style={{ marginTop: '10px' }}
            />
          </ThemeProvider>
        </CacheProvider>
      </div>
    </DashboardLayout>
  )
}

export default Inwarded
