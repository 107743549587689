import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import { Field } from "formik";
import selectData from "../data";
import FormField from "../FormField";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import {
  EMC,
  Compliance,
  Commercial,
  Transport,
  Operation,
  Finance,
  Admin,
  Marine
} from "../../Units";

//Firebase Hook
import { useCollection } from "hooks/useCollection";
import { Typography } from '@mui/material'

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="₦"
    />
  );
});
NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const EmployeeDetails = ({ formData }) => {
  const { setFieldValue } = formData;
  const [field, setField] = useState("");
  const { documents } = useCollection("users");
  const [selected, setSelected] = useState("");

  const changeSelectOptionHandler = e => {
    setSelected(e.target.value);
    setFieldValue("employeeDepartment", e.target.value);
  };

  /** Type variable to store different array for different dropdown */
  let type = null;

  /** This will be used to create set of options that user will see */
  let options = null;

  if (selected === "Executive Management Office") {
    type = EMC;
  } else if (selected === "Monitoring & Compliance") {
    type = Compliance;
  } else if (selected === "Commercial") {
    type = Commercial;
  } else if (selected === "Transport & Logistics") {
    type = Transport;
  } else if (selected === "Operations") {
    type = Operation;
  } else if (selected === "Finance and Accounting") {
    type = Finance;
  } else if (selected === "Admin & Human Resource") {
    type = Admin;
  } else if (selected === "Marine") {
    type = Marine;
  }
  if (type) {
    options = type.map(el =>
      <option key={el}>
        {el}
      </option>
    );
  }

  useEffect(() => {
    setField(`KGM/00${documents.length + 1}`);
  }, [documents]);

  return (
    <MDBox sx={{ pl: 3, pr: 3, pb: 6 }}>
      <MDBox>
        <Typography variant="h5" sx={{ borderBottom: 1, pb: 1, pt: 4 }}>
          Employee Data
        </Typography>
      </MDBox>
      <MDBox mt={4}>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant="subtitle2">Staff ID*</Typography>
            <FormField name="staffId" value={field} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant="subtitle2">Employment Type*</Typography>
            <MDBox>
              <Field
                className="mt-1 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                as="select"
                name="employeeType">
                {selectData.employeeType.map(person =>
                  <option>
                    {person}
                  </option>
                )}
              </Field>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant="subtitle2">
              Employee Department*{" "}
            </Typography>
            <MDBox>
              <select
                className="mt-1 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                name={"employeeDepartment"}
                onChange={changeSelectOptionHandler}>
                {selectData.employeeDepartment.map(department =>
                  <option>
                    {department}
                  </option>
                )}
              </select>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={3} mb={3}>

          <Grid item xs={12} sm={4} md={4}>
            <Typography variant="subtitle2">Employee Unit*</Typography>
            <MDBox>
              <Field
                className="mt-1 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                as="select"
                name="employeeUnit">
                {options}
              </Field>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant="subtitle2">
              Employee Location*{" "}
            </Typography>
            <MDBox>
              <Field
                className="mt-1 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                as="select"
                name="employeeLocation">
                {selectData.employeeLocation.map(person =>
                  <option>
                    {person}
                  </option>
                )}
              </Field>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant="subtitle2">Employee Confirmation*</Typography>
            <MDBox>
              <Field
                className="mt-1 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                as="select"
                name="employeeConfirmation">
                {selectData.employeeConfirmation.map(person =>
                  <option>
                    {person}
                  </option>
                )}
              </Field>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography sx={{ fontSize: 15 }}>
              Date Of Employment*
            </Typography>
            <input
              onChange={(e, value) => {
                console.log(e.target.value);
                setFieldValue("dateOfEmployment", e.target.value);
              }}
              type="date"
              className="border border-gray-300 text-gray-900 text-base 
              rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
              dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography sx={{ fontSize: 15 }}>Date Of Exit</Typography>
            <div className="picker">
              <input
                onChange={(e, value) => {
                  console.log(e.target.value);
                  setFieldValue("dateOfExit", e.target.value);
                }}
                type="date"
                className="border border-gray-300 text-gray-900 text-base 
                rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              />
            </div>
          </Grid>
        </Grid>
        <Grid container mb={3}>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant="subtitle2">Gross Salary</Typography>
            <FormField
              name="grossSalary"
              id="formatted-numberformat-input"
              placeholder="Enter Gross Salary"
              InputProps={{ inputComponent: NumberFormatCustom } // onChange={handleChange}
              }
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container mb={3} />
        <MDBox>
          <Typography variant="h5" sx={{ borderBottom: 1, pb: 1, pt: 4 }}>
            Referees Details
          </Typography>
        </MDBox>
        <MDBox sx={{ mt: 3 }}>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Referee's First Name </Typography>
              <FormField
                name="refereeFirstName"
                placeholder="Enter Referee FIrst Name"
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Referee's Last Name</Typography>
              <FormField
                name="refereeLastName"
                placeholder="Enter Referee Last Name"
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">
                Referee's Phone Number
              </Typography>
              <FormField
                name="refereePhoneNumber"
                type="tel"
                max="14"
                placeholder="Enter Referee Phone Number"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="subtitle2">Referee's Address</Typography>
              <FormField
                name="refereeAddress"
                placeholder="Enter Referee Address"
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Typography variant="h5" sx={{ borderBottom: 1, pb: 1, pt: 4 }}>
            Referees Details
          </Typography>
        </MDBox>
        <MDBox sx={{ mt: 3 }}>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Referee's First Name </Typography>
              <FormField
                name="refereFirstName1"
                placeholder="Enter Referee First Name"
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Referee's Last Name</Typography>
              <FormField
                name="refereeLastName1"
                placeholder="Enter Referee Last Name"
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">
                Referee's Phone Number
              </Typography>
              <FormField
                name="refereePhoneNumber1"
                placeholder="Enter Referee Phone Number"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="subtitle2">Referee's Address</Typography>
              <FormField
                name="refereeAddress1"
                placeholder="Enter Referee Address"
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default EmployeeDetails;
