import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { useCollection } from "hooks/useCollection";

ChartJS.register(ArcElement, Tooltip, Legend);

const PayOfficerReportChart = () => {
  const { documents: payofficer } = useCollection("jobRelatedForm");
  const { documents: payOfficerNon } = useCollection("nonJobRelated");

  const payofficerReport = payOfficerNon.filter(
    f => f.status === "Pay Officer"
  );
  const payofficerNonReport = payofficer.filter(
    f => f.status === "Pay Ofiicer"
  );
  const paidTotal = payofficer.filter(f => f.paid === "full");
  const halfTotal = payofficer.filter(f => f.paid === "partial");
  const halfPay = payOfficerNon.filter(f => f.paid === "partial");
  const fullyPaid = paidTotal.length;
  const partialPayment = halfTotal.length + halfPay.length;
  const jobRelated = payofficerReport.length;
  const nonJobRelated = payofficerNonReport.length;
  const allRequest = payofficer.length + payOfficerNon.length;
  const result = payofficerNonReport.concat(payofficerReport).length;
  const data = {
    labels: [
      "Total Requests",
      "Partial Payment",
      "Full Payment",
      "Pending Request"
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [allRequest, partialPayment, fullyPaid, result],
        backgroundColor: [
          "#033237",
          "orange",
          "green",
          "red",
         
        ], //   borderColor: [
        //     "rgba(255, 99, 132, 1)",
        //     "rgba(54, 162, 235, 1)",
        //     "rgba(255, 206, 86, 1)",
        //     "rgba(75, 192, 192, 1)",
        //     "rgba(153, 102, 255, 1)",
        //     "rgba(255, 159, 64, 1)"
        //   ],
        borderWidth: 1
      }
    ]
  };
  return (
    <Box
      sx={{
        paddingTop: 2,
        marginLeft: "auto",
        marginRight: "auto",
        width: 500,
        alignContent: "center",
        justifyContent: "center"
      }}>
      <Pie data={data} />
    </Box>
  );
};

export default PayOfficerReportChart;
