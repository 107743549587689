import Icon from '@mui/material/Icon'

import Home from './Home/index'
import Approved from './Approved'
import Pending from './Pending'
import ApproveForm from './ApproveForm'
import NonJob from './ApproveForm/NonJob'
import AllRequest from "./AllRequest"
const routes = [
  {
    type: "singleRoute",
    singleRoute: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "dashboard",
    component: <Home />
  },
  { type: "divider", key: "divider-0" },

  {
    type: "title",
    title: "FREIGHT FORWARDING",
    key: "HOD"
  },
  {
    type: "collapse",
    name: "Financial Requests",
    key: "financial-requests",
    icon: <Icon fontSize="small">reorder</Icon>,
    collapse: [
      {
        name: "Approved",
        key: "approved",
        route: "request-status/approved",
        icon: <Icon fontSize="small">check_circle_outline</Icon>,
        component: <Approved />
      },
      {
        name: "Pending",
        key: "pending",
        route: "request-status/pending",
        icon: <Icon fontSize="small">hourglass_bottom</Icon>,
        component: <Pending />
      },
      {
        type: "hiddenRoute",
        name: "All Requests",
        key: "all-request",
        route: "financial-request/all-requests",
        icon: <Icon fontSize="small">view_list</Icon>,
        component: <AllRequest />
      }
    ]
  },
  {
    type: "hiddenRoute",
    name: "Form",
    key: "approved-form",
    route: "request-status/approved-form/:id",
    icon: <Icon fontSize="small">hourglass_bottom</Icon>,

    component: <ApproveForm />
  },
  {
    type: "hiddenRoute",
    name: "Form",
    key: "approved-form",
    route: "request-status/approved-njr/:id",
    icon: <Icon fontSize="small">hourglass_bottom</Icon>,

    component: <NonJob />
  }
];

export default routes
