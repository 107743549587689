import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Autocomplete,
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import FormFields from 'layouts/pages/account/components/FormField'
import React from 'react'
// import selectData from './data'
import MDButton from 'components/MDButton'
import { format } from 'date-fns'

//Modal
import Modal from './Modal'

//Hooks
import {useKingsooService} from '../../../../hooks/useKingsooServices'
import { useCollection } from 'hooks/useCollection'

const EditExport = () => {
  const [billOfLaden, setBillOfLaden] = useState('')
  const [airWaysBill, setAirWayBill] = useState('')
  const [companyId, setCompanyId] = useState('')
  const [consigneeId, setConsigneeId] = useState('')
  const [pickup, setPickup] = useState('')
  const [pickupAddress, setPickupAddress] = useState('')
  const [destination, setDestination] = useState('')
  const [packages, setPackages] = useState('')
  const [totalWeight, setTotalWeight] = useState('')
  const [descriptionOfGoods, setDescriptionOfGoods] = useState('')
  const [reset, setReset] = useState('')
  const [portOfDischarge, setPortOfDischarge] = useState('')
  const [quantity, setQuantity] = useState('')
  const [jobId, setJobId] = useState('')

  let navigate = useNavigate()

  const { id } = useParams()
  const { documents } = useCollection('clients')
  const { documents: datas } = useCollection('consignee')
  const {getJob, airWayBill} = useKingsooService()

  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await getJob(id)
        // console.log(docSnap.data())
        setBillOfLaden(docSnap.data().billOfLaden)
        setAirWayBill(docSnap.data().airWayBill)
        setCompanyId(docSnap.data().companyId)
        setConsigneeId(docSnap.data().consigneeId)
        setPickup(docSnap.data().pickup)
        setPickupAddress(docSnap.data().pickupAddress)
        setDestination(docSnap.data().destination)
        setPackages(docSnap.data().packages)
        setTotalWeight(docSnap.data().totalWeight)
        setDescriptionOfGoods(docSnap.data().descriptionOfGoods)
        setPortOfDischarge(docSnap.data().portOfDischarge)
        setQuantity(docSnap.data().quantity)
      } catch {
        console.log('error')
      }
    }
    // console.log('na u know', id)
    if ((id !== undefined || id !== '') && id !== ':id') {
      detail()
    }
  }, [id])

  const airwaybill = async (e) => {
    e.preventDefault()
    try {
      await airWaysBill(id, {
        airWayBill: airWaysBill,
        billOfLaden,
        companyId,
        pickup,
        consigneeId,
        pickupAddress,
        quantity,
        portOfDischarge,
        destination,
        packages,
        totalWeight,
        descriptionOfGoods,
      })
      setReset(true)
      if (reset) {
        e.target.reset()
      }
    } catch (error) {
      console.log(error.message)
    }
  }
  if (reset) {
    navigate('/monitoring-compliance/job-management/export-job', {
      replace: true,
    })
  }

  const getJobId = (id) => {
    // console.log('the id', id)
    setJobId(id)
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="mt-4">
        <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
          <Box component="form" onSubmit={airwaybill}>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ borderBottom: 1, pb: 1, pt: 4 }}
            >
              Export Form
            </Typography>
            <Box mt={2}>
              <Grid container spacing={4}>
                {airWaysBill ? (
                  <Grid item xs={12} sm={4}>
                    <Typography fontSize={16} marginBottom={1}>
                      Air Way Bill
                    </Typography>
                    <TextField
                      id="airline"
                      fullWidth
                      onChange={(e) => setAirWayBill(e.target.value)}
                      value={airWaysBill}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={4}>
                    <Typography fontSize={16} marginBottom={1}>
                      Bill Of Lading
                    </Typography>
                    <TextField
                      id="airline"
                      fullWidth
                      onChange={(e) => setBillOfLaden(e.target.value)}
                      value={billOfLaden}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={4}>
                  <Typography fontSize={16} marginBottom={1}>
                    Select Client
                  </Typography>
                  <Autocomplete
                    id="import-type"
                    value={companyId}
                    options={documents.map((document) => document.clientName)}
                    onChange={(e, newValue) => {
                      setCompanyId(newValue)
                    }}
                    renderInput={(params) => (
                      <FormFields
                        {...params}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontSize={16} marginBottom={1}>
                    Select Consignee
                  </Typography>
                  <Autocomplete
                    id="import-type"
                    value={consigneeId}
                    options={datas.map((data) => data.consigneeName)}
                    onChange={(e, newValue) => {
                      setConsigneeId(newValue)
                    }}
                    renderInput={(params) => (
                      <FormFields
                        {...params}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontSize={16} marginBottom={1}>
                    Port Of Discharge
                  </Typography>
                  <TextField
                    fullWidth
                    onChange={(e) => setPortOfDischarge(e.target.value)}
                    value={portOfDischarge}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontSize={16} marginBottom={1}>
                    Destination Country
                  </Typography>
                  <TextField
                    id="no-of-packages"
                    fullWidth
                    onChange={(e) => setDestination(e.target.value)}
                    value={destination}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontSize={16} marginBottom={1}>
                    Pickup Contact
                  </Typography>
                  <TextField
                    id="no-of-packages"
                    fullWidth
                    onChange={(e) => setPickup(e.target.value)}
                    value={pickup}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontSize={16} marginBottom={1}>
                    Pickup Address
                  </Typography>
                  <TextField
                    id="no-of-packages"
                    fullWidth
                    onChange={(e) => setPickupAddress(e.target.value)}
                    value={pickupAddress}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontSize={16} marginBottom={1}>
                    Package Reference
                  </Typography>
                  <TextField
                    id="no-of-packages"
                    fullWidth
                    onChange={(e) => setPackages(e.target.value)}
                    value={packages}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontSize={16} marginBottom={1}>
                    Total weight of package (kg)
                  </Typography>
                  <TextField
                    id="total-weight"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setTotalWeight(e.target.value)}
                    value={totalWeight}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontSize={16} marginBottom={1}>
                    Quantity
                  </Typography>
                  <TextField
                    id="total-weight"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setQuantity(e.target.value)}
                    value={quantity}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize={16} marginBottom={1}>
                    Description of Goods
                  </Typography>
                  <TextField
                    id="description-of-goods"
                    fullWidth
                    onChange={(e) => setDescriptionOfGoods(e.target.value)}
                    value={descriptionOfGoods}
                    multiline
                    rows={6}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                pb: 1,
                pt: 4,
                pl: 3,
                pr: 3,
                mt: 2,
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '2rem',
              }}
            >
              <MDButton variant="gradient" color="dark" type="submit">
                Update
              </MDButton>
            </Box>
          </Box>
          <button
            className="absolute bg-transparent mr-2 right-30 bottom-20"
            onClick={(e) => getJobId(id)}
          >
            <Modal id={jobId} setJobId={setJobId} />
          </button>
        </Paper>
      </div>
    </DashboardLayout>
  )
}

export default EditExport
