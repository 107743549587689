import { EyeIcon } from '@heroicons/react/solid'
import { Paper, Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import {useKingsooService} from '../../../../hooks/useKingsooServices'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const PendingJobModal = () => {
  const [expectedTimeOfArrival, setExpectedTimeOfArrival] = useState()
  const [billOfLaden, setBillOfLaden] = useState('')
  const [companyId, setCompanyId] = useState('')
  const [consigneeId, setConsigneeId] = useState('')
  const [jobManagerId, setJobManagerId] = useState('')
  const [importType, setImportType] = useState('')
  const [scacOrAirline, setScacOrAirline] = useState('')
  const [freightType, setFreightType] = useState('')
  const [preAlertstatus, setPreAlertStatus] = useState('')
  const [fileId, setFileId] = useState('')
  const [preAlertCreatedAt, setPreAlertCreatedAt] = useState('')
  const [jobCreatedDate, setJobCreatedDate] = useState('')
  const [preALertImage, setPreAlertImage] = useState('')
  const [packages, setPackages] = useState('')
  const [totalWeight, setTotalWeight] = useState('')
  const [descriptionOfGoods, setDescriptionOfGoods] = useState('')
  const [airBill, setAirBill] = useState('')
  const [thirdParty, setThirdParty] = useState('')
  const [thirdPartyPayment, setThirdPartyPayment] = useState('')
  const [airWayBill, setAirWayBill] = useState('')
  const [paarImage, setPaarImage] = useState('')

  const [paarValue, setPaarValue] = useState('')

  const [commercialInvoiceImage, setCommercialInvoiceImage] = useState('')
  const [commercialInvoiceValue, setCommercialInvoiceValue] = useState('')

  const [shippingInvoiceImage, setShippingInvoiceImage] = useState('')
  const [shippingInvoiceValue, setShippingInvoiceValue] = useState('')
  const [thirdPartyPayComp, setThirdPartyPayComp] = useState('')
  const [shedRelExitComp, setShedRelExitComp] = useState('')
  const [airCargoDelivered, setAirCargoDelivered] = useState('')
  const [dtiCapturing, setDtiCapturing] = useState('')
  const [airReqForTruck, setAirReqForTruck] = useState('')
  const [shedRelease, setShedRelease] = useState('')
  const [docToFieldOfficer, setDocToFieldOfficer] = useState('')
  const [shipmentReturn, setShipmentReturn] = useState('')
  const [airDutyCompleted, setAirDutyCompleted] = useState('')
  const [airExamConcluded, setAirExamConcluded] = useState('')
  const [airReortCompleted, setAirReportCompleted] = useState('')
  const [airCargoReleased, setAirCargoReleased] = useState('')

  const [sdgFile, setSDGFile] = useState('')

  const { id } = useParams()
  const {getJob} = useKingsooService()

  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await getJob(id)
        setBillOfLaden(docSnap.data().billOfLaden)
        setBillOfLaden(docSnap.data().airWayBill)
        setCompanyId(docSnap.data().companyId)
        setConsigneeId(docSnap.data().consigneeId)
        setJobManagerId(docSnap.data().jobManagerId)
        setImportType(docSnap.data().importType)
        setScacOrAirline(docSnap.data().scacOrAirline)
        setFreightType(docSnap.data().freightType)
        setPreAlertStatus(docSnap.data().preAlertstatus)
        setFileId(docSnap.data().fileId)
        setExpectedTimeOfArrival(docSnap.data().expectedTimeOfArrival)
        setPreAlertCreatedAt(docSnap.data().preAlertCreatedAt)
        setJobCreatedDate(docSnap.data().jobCreatedDate)
        setPackages(docSnap.data().packages)
        setTotalWeight(docSnap.data().totalWeight)
        setDescriptionOfGoods(docSnap.data().descriptionOfGoods)
        setPreAlertImage(docSnap.data().preALertImage)
        setPaarImage(docSnap.data().paarImage)
        setAirBill(docSnap.data().airBill)
        setThirdParty(docSnap.data().thirdParty)
        setThirdPartyPayment(docSnap.data().thirdPartyPayment)
        setAirWayBill(docSnap.data().airWayBill)
        setPaarValue(docSnap.data().paarValue)
        setCommercialInvoiceImage(docSnap.data().commercialInvoiceImage)
        setCommercialInvoiceValue(docSnap.data().commercialInvoiceValue)
        setShippingInvoiceImage(docSnap.data().shippingInvoiceImage)
        setShippingInvoiceValue(docSnap.data().shippingInvoiceValue)
        setSDGFile(docSnap.data().sdgFile)
        setThirdPartyPayComp(docSnap.data().thirdPartyPayComp)
        setShedRelExitComp(docSnap.data().shedRelExitComp)
        setAirReqForTruck(docSnap.data().airReqForTruck)
        setDtiCapturing(docSnap.data().dtiCapturing)
        setAirCargoDelivered(docSnap.data().airCargoDelivered)
        setShedRelease(docSnap.data().shedRelease)
        setDocToFieldOfficer(docSnap.data().docToFieldOfficer)
        setShipmentReturn(docSnap.data().shipmentReturn)
        setAirDutyCompleted(docSnap.data().airDutyCompleted)
        setAirExamConcluded(docSnap.data().airExamConcluded)
        setAirReportCompleted(docSnap.data().airReortCompleted)
        setAirCargoReleased(docSnap.data().airCargoReleased)

        // setContainerInfo(docSnap.data().containerInfo)
      } catch (error) {
        //console.log(error)
      }
    }
    // console.log('na u know', id)
    if (id !== undefined || id !== '') {
      detail()
      // console.log('id', id)
    }
  }, [id])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
        <MDBox
          className="md:flex-row"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
          container
          spacing={2}
        >
          <MDBox>
            <MDTypography
              variant="h5"
              fontWeight="bold"
              sx={{ borderBottom: 1, pb: 1, pt: 4 }}
            >
              JOB FILE - {companyId}
            </MDTypography>
          </MDBox>
          <Grid container mb={4} sx={{ ml: 2, mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>FILE ID:</b> {fileId}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Client:</b> {companyId}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>IMPORT TYPE:</b> {importType}
                </li>
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>JOB MANAGER:</b> {jobManagerId}
                </li>
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>SCAC / AIRLINE:</b> {scacOrAirline}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>FREIGHT TYPE:</b> {freightType}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>CONSIGNEE ID:</b> {consigneeId}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  {' '}
                  <b>Air Way Bill:</b> {billOfLaden}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>ETA:</b> {expectedTimeOfArrival}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>JOB STATUS:</b> {preAlertstatus}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>No Of Packages:</b> {packages}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Total Weight Of Package:</b> {totalWeight}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Description Of Goods:</b> {descriptionOfGoods}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Cargo has been delivered?:</b>{' '}
                  {airCargoDelivered ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Request for Truck:</b> {airReqForTruck ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Shed release/ Exit completed:</b>{' '}
                  {shedRelease ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Third Party Payment Completed:</b>{' '}
                  {thirdPartyPayComp ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Customs Release of Cargo/Exit:</b>{' '}
                  {airCargoReleased ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Exam Report, Inspection Act completed:</b>{' '}
                  {airReortCompleted ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Physical Exam Concluded:</b>{' '}
                  {airExamConcluded ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Duty PAyment Completed:</b>{' '}
                  {airDutyCompleted ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Shipment is Return & Repair:</b>{' '}
                  {shipmentReturn ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Required documents given to field officer:</b>{' '}
                  {docToFieldOfficer ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Customs and Shed Release:</b> {shedRelease ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Required documents given to DTI:</b>{' '}
                  {dtiCapturing ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>DATE & TIME OF JOB PRE-ALERT:</b> {preAlertCreatedAt}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>DATE & TIME OF JOB CREATION:</b> {jobCreatedDate}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Third Party Payment Value:</b> {thirdPartyPayment}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Commercial Invoice Value:</b> {commercialInvoiceValue}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Agency Receipt Value:</b> {shippingInvoiceValue}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Paar Value:</b> {paarValue}
                </li>
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={6}>
              {preALertImage && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>AIR WAY BILL:</b>
                    </li>
                  </MDTypography>
                  <a
                    href={preALertImage}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-4"
                  >
                    <button
                      type="button"
                      className="relative inline-flex darkGreen  items-center  px-4 py-2 rounded-md border border-gray-300  text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <EyeIcon
                        className="mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      View document
                    </button>
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {airBill && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>SECOND AIR WAY BILL:</b>
                    </li>
                  </MDTypography>
                  <a
                    href={airBill}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-4"
                  >
                    <button
                      type="button"
                      className="relative inline-flex darkGreen  items-center  px-4 py-2 rounded-md border border-gray-300  text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <EyeIcon
                        className="mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      View document
                    </button>
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {commercialInvoiceImage && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>COMMERCIAL INVOICE:</b>
                    </li>
                  </MDTypography>
                  <a
                    href={commercialInvoiceImage}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-4"
                  >
                    <button
                      type="button"
                      className="relative inline-flex darkGreen  items-center  px-4 py-2 rounded-md border border-gray-300  text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <EyeIcon
                        className="mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      View document
                    </button>
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {shippingInvoiceImage && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>AGENCY RECEIPT:</b>
                    </li>
                  </MDTypography>
                  <a
                    href={shippingInvoiceImage}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-4"
                  >
                    <button
                      type="button"
                      className="relative inline-flex darkGreen  items-center  px-4 py-2 rounded-md border border-gray-300  text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <EyeIcon
                        className="mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      View document
                    </button>
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {thirdParty && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>THIRD PARTY RECEIPT:</b>
                    </li>
                  </MDTypography>
                  <a
                    href={thirdParty}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-4"
                  >
                    <button
                      type="button"
                      className="relative inline-flex darkGreen  items-center  px-4 py-2 rounded-md border border-gray-300  text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <EyeIcon
                        className="mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      View document
                    </button>
                  </a>
                </MDBox>
              )}
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              {airWayBill && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>AIR WAY BILL:</b>
                    </li>
                  </MDTypography>
                  <a
                    href={airWayBill}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-4"
                  >
                    <button
                      type="button"
                      className="relative inline-flex darkGreen  items-center  px-4 py-2 rounded-md border border-gray-300  text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <EyeIcon
                        className="mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      View document
                    </button>
                  </a>
                </MDBox>
              )}
            </Grid> */}
            <Grid item xs={12} sm={6}>
              {paarImage && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>PAAR IMAGE:</b>
                    </li>
                  </MDTypography>
                  <a
                    href={paarImage}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-4"
                  >
                    <button
                      type="button"
                      className="relative inline-flex darkGreen  items-center  px-4 py-2 rounded-md border border-gray-300  text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <EyeIcon
                        className="mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      View document
                    </button>
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {sdgFile && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>SDG:</b>
                    </li>
                  </MDTypography>
                  <a
                    href={sdgFile}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-4"
                  >
                    <button
                      type="button"
                      className="relative inline-flex darkGreen  items-center  px-4 py-2 rounded-md border border-gray-300  text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <EyeIcon
                        className="mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      View document
                    </button>
                  </a>
                </MDBox>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </Paper>
    </DashboardLayout>
  )
}

export default PendingJobModal
