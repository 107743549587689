import { useState } from 'react'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import MUIDataTable from 'mui-datatables'
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

// Modal
import Modal from './Modal'

//Firebase Hook
import { useCollection } from '../../../hooks/useCollection'

const muiCache = createCache({
  key: 'mui-datatables',
  prepend: true,
})

const CompletedJobs = () => {
  const [responsive] = useState('horizontal')
  const [tableBodyHeight] = useState('auto')
  const [tableBodyMaxHeight] = useState('')
  const [searchBtn] = useState(true)
  const [downloadBtn] = useState(true)
  const [printBtn] = useState(true)
  const [viewColumnBtn] = useState(true)
  const [filterBtn] = useState(true)

  const { documents } = useCollection('jobs')

  const data = documents.filter((data) => data.preAlertstatus === 'closed')

  const columns = [
    {
      name: 'paarValue',
      label: 'File ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'consigneeId',
      label: 'Consignee',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'importType',
      label: 'Import Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'billOfLaden',
      label: 'BOL/MAWB',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'containerNo',
      label: 'Containers',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'expectedTimeOfArrival',
      label: 'ETA',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'jobManagerId',
      label: 'Job Manager',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'preAlertstatus',
      label: 'Job Status',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <button
                style={{
                  background: 'transparent',
                  border: 'none',
                }}
                onClick={() => console.log(value, tableMeta)}
              >
                <Modal />
              </button>
            </div>
          )
        },
      },
    },
  ]

   const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
     rowHover: false,
    filter: filterBtn,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
   
  }

const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 2,
              marginBottom:8,
              paddingTop: 1,
                backgroundColor: "#033237",
              
              
            },
          },
        },
        
       
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              '&:nth-child(odd)': {
                //backgroundColor: "#f6f6f6"
              },
              fontSize: '12px',
            },
          },
        },
      },
    }
    )

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme}>
          <MUIDataTable
            title={'Closed Job Table'}
            data={data}
            columns={columns}
            options={options}
            style={{ marginTop: '10px' }}
          />
        </ThemeProvider>
      </CacheProvider>
    </DashboardLayout>
  )
}

export default CompletedJobs
