import { useState } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Modal
import Modal from "./Modal";
import { Edit } from "@mui/icons-material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

//Firebase Hook
import { useCollection } from "hooks/useCollection";
// import DeleteModal from "../DeleteEmployee/DeleteModal";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

const CompletedJobFile = () => {
  const [responsive] = useState("horizontal");
  const [tableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight] = useState("");
  const [searchBtn] = useState(true);
  const [downloadBtn] = useState(true);
  const [printBtn] = useState(true);
  const [viewColumnBtn] = useState(true);
  const [filterBtn] = useState(true);
  const [userId, setUserId] = useState("");

  const { documents } = useCollection("users");

  const getUserId = id => {
    // console.log('the id', id)
    setUserId(id);
  };

  const columns = [
    {
      name: "firstName",
      label: "Employee Name",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "id",
      hide: true,
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false
      }
    },
    {
      name: "staffId",
      label: "Staff ID",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "employeeDepartment",
      label: "Department",
      options: {
        filter: true,
        sort: true
      }
    },

    {
      name: "phoneNumber",
      label: "Contact",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "workEmail",
      label: "Email Address",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex", gap: ".5rem" }}>
              <button
                style={{
                  border: "none",
                  background: "transparent",
                  cursor: "pointer"
                }}>
                <Link
                  to={`/human-resources/employee-management/employee-authentication/${tableMeta
                    .rowData[1]}`}
                  style={{ textDecoration: "none", color: "#FFF" }}>
                  <AdminPanelSettingsIcon
                    sx={{ width: "20px", height: "20px", color: "#033237" }}
                  />
                </Link>
              </button>
              <button
                style={{ background: "transparent", border: "none" }}
                onClick={e => getUserId(tableMeta.rowData[1])}>
                <Modal setUserId={setUserId} id={userId} />
              </button>
              <button
                style={{
                  border: "none",
                  background: "transparent",
                  marginRight: "2px",
                  cursor: "pointer"
                }}>
                <Link
                  to={`/human-resources/employee-management/edit-employees/${tableMeta
                    .rowData[1]}`}
                  style={{ textDecoration: "none", color: "#FFF" }}>
                  <Edit
                    sx={{ width: "20px", height: "20px", color: "#033237" }}
                  />
                </Link>
              </button>
              {/* <button
                style={{
                  border: "none",
                  background: "transparent",
                  cursor: "pointer"
                }}
                onClick={e => getUserId(tableMeta.rowData[1])}>
                <DeleteModal id={userId} setUserId={setUserId} />
              </button> */}
            </div>
          );
        }
      }
    }
  ];

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: "firstName",
      direction: "asc"
    }
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 1,
              paddingTop: 1,
              fontSize: "2pt",
              borderBottom: "2px solid black",
              backgroundColor: "#033237",
              zIndex: 10
            }
          },
          paddingBottom: 2,
          marginBottom: 8,
          paddingTop: 1,
          backgroundColor: "#033237"
        }
      }
    });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="mt-4">
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              title={"All Employees"}
              data={documents}
              columns={columns}
              options={options}
              style={{ marginTop: "10px" }}
            />
          </ThemeProvider>
        </CacheProvider>
        {/* <Command/> */}
      </div>
    </DashboardLayout>
  );
};

export default CompletedJobFile;
