import Icon from "@mui/material/Icon";
import GateInOutTable from "./GateInOut/GateInOutTable";

import Home from "./Home/index";
import InwardedContainer from "./InwardedContainer";
import Inwarded from "./InwardedContainer/Inwarded";
import OutwardedContainer from "./OutwardedContainer";
import Outwarded from "./OutwardedContainer/Outwarded";
import PendingTable from "./PendingJobs/PendingTable";
import PendingJobForm from "./PendingJobs/PendingJobForm";
import GateInOutForm from "./GateInOut/GateInOutForm";
import GateIn from "./GateIn";

const routes = [
  {
    type: "singleRoute",
    singleRoute: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: 'dashboard',
    component: <Home />,
  },
  { type: "divider", key: "divider-0" },
  {
    type: 'singleRoute',
    singleRoute: 'Gate-In',
    key: 'Gate In',
    route: 'inwarded-containers',
    icon: <Icon fontSize="small">person_add_alt</Icon>,
    component: <PendingTable />,
  },
  {
    type: 'hiddenRoute',
    singleRoute: 'Inwarded',
    key: 'inwarded',
    route: 'inwarded-containers/inward/:id',
    icon: <Icon fontSize="small">person_add_alt</Icon>,
    component: <Inwarded />,
  },
  {
    type: 'hiddenRoute',
    singleRoute: 'Outwarded',
    key: 'outwarded',
    route: 'outwarded-containers/outward/:id',
    icon: <Icon fontSize="small">person_add_alt</Icon>,
    component: <Outwarded />,
  },
  {
    type: 'singleRoute',
    singleRoute: 'Gate-Out',
    key: 'gate-out',
    route: 'gate-in-out',
    icon: <Icon fontSize="small">person_add_alt</Icon>,
    component: <GateInOutTable />,
  },
  {
    type: 'singleRoute',
    singleRoute: 'Outwarded Containers',
    key: 'outwarded-containers',
    route: 'outwarded-containers',
    icon: <Icon fontSize="small">person_add_alt</Icon>,
    component: <OutwardedContainer />,
  },
  {
    type: 'hiddenRoute',
    key: 'pending-job-form',
    route: 'pending-job-form/:id',
    component: <PendingJobForm />,
  },
  {
    type: 'hiddenRoute',
    key: 'gate-in-out-form',
    route: 'gate-in-out-form/:id',
    component: <GateInOutForm />,
  },
];

export default routes;
