import Icon from "@mui/material/Icon";

import Home from "./Home/index";
import ConsigneeTable from "./ConsigneeTable";
import ClientTable from "./ClientTable";
import PendingJobTable from "./PendingJobTable";
import CompletedJobTable from "./CompletedJobTable";
// import JobRelated from './MakeRequest/JobRelated'
// import NonJobRelated from './MakeRequest/NonJobRelated'
import AllRequest from "./AllRequest";
import PendingRequests from "./PendingRequests";
import ApprovedRequests from "./ApprovedRequests";
import DeclinedRequests from "./DeclinedRequests";
import EmployeeTable from "./EmployeeTable";
import PreAlertJobTable from "./PreAlertJobTable";
import PendingJobModal from "./PendingJobTable/Modal/PendingJobModal";
import JobRelated from "./MakeRequest/JobRelated";
import NonJobRelated from "./MakeRequest/NonJobRelated";
import IncompleteRequests from "./IncompleteRequests";
import FieldAgentReport from "./Reports/FieldAgentReport/table";
import DispatchReport from "./Reports/DispatchReport/table";
import FinanceReport from "./Reports/FinanceReport/table";
import PayOfficerReport from "./Reports/PayOfficer/table";
import PayOfficerReportChart from "./Reports/PayOfficer";
import PayOfficerReportMain from "./Reports/PayOfficer/main";
import FinanceReportMain from "./Reports/FinanceReport";
import DispatchReportMain from "./Reports/DispatchReport/main";
import FieldAgentReportMain from "./Reports/FieldAgentReport/main";
import TotalContainers from "./Reports/containers";
import TerminalReportMain from "./Reports/TerminalReport/main";
import GateInReportMain from "./Reports/GateInReport/main";
import GateOutReportMain from "./Reports/GateOutReport/main";

const routes = [
  {
    type: "singleRoute",
    singleRoute: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "dashboard",
    component: <Home />
  },
  { type: "divider", key: "divider-0" },

  {
    type: "title",
    title: "FREIGHT FORWARDING",
    key: "HOD"
  },
  {
    type: "collapse",
    name: "Employee Management",
    key: "employee-management",
    icon: <Icon fontSize="small">assignment_ind</Icon>,
    collapse: [
      {
        name: "All Employees",
        key: "all-employees",
        route: "employee-management/all-employees",
        icon: <Icon fontSize="small">group</Icon>,
        component: <EmployeeTable />
      }
    ]
  },
  {
    type: "collapse",
    name: "Client Management",
    key: "client-management",
    icon: <Icon fontSize="small">groups</Icon>,
    collapse: [
      {
        name: "Consignee",
        key: "consignee",
        route: "client-management/consignee",
        icon: <Icon fontSize="small">group</Icon>,
        component: <ConsigneeTable />
      },
      {
        name: "Client",
        key: "client",
        route: "client-management/client",
        icon: <Icon fontSize="small">group</Icon>,
        component: <ClientTable />
      }
    ]
  },
  {
    type: "collapse",
    name: "Job File Management",
    key: "job-management",
    icon: <Icon fontSize="small">folder</Icon>,
    collapse: [
      {
        name: "Pre-Alert Jobs",
        key: "pre-alert-jobs",
        route: "job-management/pre-alert-jobs",
        icon: <Icon fontSize="small">work</Icon>,
        component: <PreAlertJobTable />
      },
      {
        name: "Pending Job Files",
        key: "pending-job-files",
        route: "job-management/pending-job",
        icon: <Icon fontSize="small">work_history</Icon>,
        component: <PendingJobTable />
      },
      {
        name: "Completed Job Files",
        key: "completed-job-files",
        route: "job-management/completed-job",
        icon: <Icon fontSize="small">work_off</Icon>,
        component: <CompletedJobTable />
      }
    ]
  },
  {
    type: "collapse",
    name: "Finance Management",
    key: "financial-request",
    icon: <Icon fontSize="small">monetization_on</Icon>,
    collapse: [
      {
        name: "All Requests",
        key: "all-request",
        route: "financial-request/all-requests",
        icon: <Icon fontSize="small">view_list</Icon>,
        component: <AllRequest />
      },
      {
        name: "Incomplete Requests",
        key: "incomplete-request",
        route: "financial-request/incomplete-requests",
        icon: <Icon fontSize="small">view_list</Icon>,
        component: <IncompleteRequests />
      },
      {
        name: "Pending Requests",
        key: "pending-request",
        route: "financial-request/pending-request",
        icon: <Icon fontSize="small">hourglass_bottom</Icon>,
        component: <PendingRequests />
      },
      {
        name: "Approved Requests",
        key: "approved-request",
        route: "financial-request/approved-request",
        icon: <Icon fontSize="small">check_circle_outline</Icon>,
        component: <ApprovedRequests />
      },
      {
        name: "Declined Requests",
        key: "declined-request",
        route: "financial-request/declined-request",
        icon: <Icon fontSize="small">cancel</Icon>,
        component: <DeclinedRequests />
      }
    ]
  },
  // {
  //   type: "collapse",
  //   name: "Make Request",
  //   key: "make-request",
  //   icon: <Icon fontSize="small">queue</Icon>,
  //   collapse: [
  //     {
  //       name: "Job Related",
  //       key: "job-related",
  //       route: "make-request/job-related",
  //       icon: <Icon fontSize="small">content_paste</Icon>,
  //       component: <JobRelated />
  //     },
  //     {
  //       name: "Non Job Related",
  //       key: "non-job-related",
  //       route: "make-request/non-job-related",
  //       icon: <Icon fontSize="small">content_paste_off</Icon>,
  //       component: <NonJobRelated />
  //     }
  //   ]
  // },
  {
    type: "hiddenRoute",
    name: "PendingJobs",
    key: "pending-jobs",
    route: "job-management/pending-jobs/:id",
    icon: <Icon fontSize="medium">company</Icon>,
    component: <PendingJobModal />
  },
  {
    type: "collapse",
    name: "Reports",
    key: "report",
    icon: <Icon fontSize="small">folder</Icon>,
    collapse: [
      {
        name: "Total Containers",
        key: "total-containers",
        route: "report/total-containers",
        icon: <Icon fontSize="small">work_off</Icon>,
        component: <TotalContainers />
      },
      {
        name: "Field Agents Report",
        key: "field-agents-report",
        route: "report/field-agents-report",
        icon: <Icon fontSize="small">work_off</Icon>,
        component: <FieldAgentReportMain />
      },
      {
        name: "Dispatch Report",
        key: "dispatch-report",
        route: "report/dispatch-report",
        icon: <Icon fontSize="small">work_off</Icon>,
        component: <DispatchReportMain />
      },
      {
        name: "Terminal Report",
        key: "terminal-report",
        route: "report/terminal-report",
        icon: <Icon fontSize="small">work_off</Icon>,
        component: <TerminalReportMain />
      },
      {
        name: "Gated In",
        key: "gate-in",
        route: "report/gate-in-report",
        icon: <Icon fontSize="small">work_off</Icon>,
        component: <GateInReportMain />
      },
      {
        name: "Gated Out",
        key: "gate-out",
        route: "report/gate-out-report",
        icon: <Icon fontSize="small">work_off</Icon>,
        component: <GateOutReportMain />
      },
      {
        name: "Finance Report",
        key: "finance-report",
        route: "report/finance-report",
        icon: <Icon fontSize="small">work_off</Icon>,
        component: <FinanceReportMain />
      },
      {
        name: "Pay Officer Report",
        key: "pay-officer-report",
        route: "report/pay-officer-report",
        icon: <Icon fontSize="small">work_off</Icon>,
        component: <PayOfficerReportMain />
      }
    ]
  }
];

export default routes;
