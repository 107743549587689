import { useState, useEffect } from 'react'
import { appAuth } from '../firebase/config'
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
} from 'firebase/auth'

export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [isPending, setIsPending] = useState(false)

  const signup = async (email, password, displayName, photoURL) => {
    setError(null)
    setIsPending(true)
    setSuccess(null)

    try {
      // signup
      await createUserWithEmailAndPassword(appAuth, email, password).then(
        (userCred) => {
          updateProfile(userCred.user, { displayName, photoURL })
        }
      )
      await sendPasswordResetEmail(appAuth, email)

      setSuccess('User Created Successfully')
      setIsPending(false)
      setError(null)

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
    } catch (err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { signup, error, isPending, success }
}
