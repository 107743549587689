import { Autocomplete, Button, Grid, Paper } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import TextField from '@mui/material/TextField'
import { EyeIcon } from '@heroicons/react/solid'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import FormFields from 'layouts/pages/account/components/FormField'
import { useState, useEffect } from 'react'
import selectData from './data'

import './PreAlertJob.css'
import MDButton from 'components/MDButton'

import { useParams, useNavigate } from 'react-router-dom'
import { format } from 'date-fns'

// Modal
import Modal from './Modal'

// Firebase Hook
import { useCollection } from 'hooks/useCollection'
import {useKingsooService} from '../../../../hooks/useKingsooServices'

import { Add } from '@mui/icons-material'
import Completed from './Modal/completed'

const EditSeaJob = () => {
  const [reset, setReset] = useState(false)
  const [expectedTimeOfArrival, setExpectedTimeOfArrival] = useState(
    format(new Date(), 'yyyy-MM-dd')
  )
  const [containerInfo, setContainerInfo] = useState([
    { containerId: '', containerSize: '' },
  ])

  const [billOfLaden, setBillOfLaden] = useState('')
  const [preALertImage, setPreAlertImage] = useState('')
  const [companyId, setCompanyId] = useState('')
  const [consigneeId, setConsigneeId] = useState('')
  const [jobManagerId, setJobManagerId] = useState('')
  const [jobType, setJobType] = useState('')
  const [scacOrAirline, setScacOrAirline] = useState('')
  const [freightType, setFreightType] = useState('')
  const [jobId, setJobId] = useState('')

  let navigate = useNavigate()
  const {getJob, editSeaJob} = useKingsooService()

  const { id } = useParams()
  const { documents } = useCollection('clients')
  const { documents: datas } = useCollection('consignee')
  const { documents: jobs } = useCollection('jobManager')

  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await getJob(id)
        // console.log(docSnap.data())
        setBillOfLaden(docSnap.data().billOfLaden)
        setPreAlertImage(docSnap.data().preALertImage)
        setContainerInfo(docSnap.data().containerInfo)
        setExpectedTimeOfArrival(docSnap.data().expectedTimeOfArrival)
        setCompanyId(docSnap.data().companyId)
        setConsigneeId(docSnap.data().consigneeId)
        setJobManagerId(docSnap.data().jobManagerId)
        setJobType(docSnap.data().jobType)
        setScacOrAirline(docSnap.data().scacOrAirline)
        setFreightType(docSnap.data().freightType)
      } catch {
        console.log('error')
      }
    }
    // console.log('na u know', id)
    if ((id !== undefined || id !== '') && id !== ':id') {
      detail()
    }
  }, [id])

  const handleFormChange = (index, e) => {
    let data = [...containerInfo]
    data[index][e.target.name] = e.target.value
    setContainerInfo(data)
  }

  const addFields = () => {
    let newfield = { containerId: '', containerSize: '' }

    setContainerInfo([...containerInfo, newfield])
  }

  const removeFields = (index) => {
    let data = [...containerInfo]
    data.splice(index, 1)
    setContainerInfo(data)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      await editSeaJob(id, {
        billOfLaden,
        containerInfo,
        expectedTimeOfArrival,
        companyId,
        jobManagerId,
        consigneeId,
        jobType,
        scacOrAirline,
        freightType,
      })
      setReset(true)
      if (reset) {
        e.target.reset()
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  if (reset) {
    navigate('/monitoring-compliance/job-management/pending-job-files', {
      replace: true,
    })
  }

  const getJobId = (id) => {
    // console.log('the id', id)
    setJobId(id)
  }
  return (
    <div style={{ position: 'relative' }}>
      <DashboardLayout>
        <DashboardNavbar />
        <Paper sx={{ pb: 6, boxShadow: 2 }}>
          <MDBox mt={5} component="form" onSubmit={handleSubmit}>
            <MDTypography
              variant="h5"
              fontWeight="bold"
              sx={{ borderBottom: 1, pb: 1, pt: 4, pl: 3, pr: 3 }}
            >
              Job Details
            </MDTypography>
            <MDBox sx={{ pb: 1, pt: 4, pl: 3, pr: 3, mt: 1.625 }}>
              <Grid container spacing={4} mb={4}>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Bill of Lading
                  </MDTypography>
                  <FormFields
                    value={billOfLaden}
                    name="BOFL"
                    onChange={(e) => setBillOfLaden(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Upload Bill of Lading/Air Waybill
                  </MDTypography>
                  <span className="relative z-0 darkGreen  shadow-sm w-full mx-auto rounded-md">
                    <a
                      href={preALertImage}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        type="button"
                        className="relative inline-flex darkGreen  items-center  px-4 py-3 rounded-md border border-gray-300  text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                      >
                        <EyeIcon
                          className="-ml-1 mr-2 h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                        View Document
                      </button>
                    </a>
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={4} mb={4}>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Expected Time of Arrival (ETA)
                  </MDTypography>
                  <div className="picker">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        className="birth"
                        value={expectedTimeOfArrival}
                        onChange={(newValue) => {
                          setExpectedTimeOfArrival(
                            format(new Date(newValue), 'yyyy-MM-dd')
                          )
                        }}
                        fullWidth
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>Client</MDTypography>
                  <Autocomplete
                    defaultValue="Select Client"
                    value={companyId}
                    options={documents.map((document) => document.clientName)}
                    onChange={(e, newValue) => {
                      setCompanyId(newValue)
                    }}
                    renderInput={(params) => <FormFields {...params} />}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>Consignee</MDTypography>
                  <Autocomplete
                    defaultValue="Select Consignee"
                    value={consigneeId}
                    options={datas.map((data) => data.consigneeName)}
                    onChange={(e, newValue) => {
                      setConsigneeId(newValue)
                    }}
                    renderInput={(params) => (
                      <FormFields
                        {...params}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} mb={4}>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>Job Manager</MDTypography>
                  <Autocomplete
                    defaultValue="Select Job Manager"
                    value={jobManagerId}
                    options={jobs.map((job) => job.name)}
                    onChange={(e, newValue) => {
                      setJobManagerId(newValue)
                    }}
                    renderInput={(params) => (
                      <FormFields
                        {...params}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>Job Type</MDTypography>
                  <Autocomplete
                    defaultValue="Select Job Type"
                    value={jobType}
                    onChange={(e, newValue) => {
                      setJobType(newValue)
                    }}
                    options={selectData.jobType}
                    renderInput={(params) => (
                      <FormFields
                        {...params}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} mb={4}>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    SCAC / Airline
                  </MDTypography>
                  <FormFields
                    name="SCAC"
                    placeholder="Enter SCAC or Airline"
                    value={scacOrAirline}
                    onChange={(e) => setScacOrAirline(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Type of Freight
                  </MDTypography>
                  <Autocomplete
                    defaultValue="Select Freight Type"
                    value={freightType}
                    options={selectData.freight}
                    onChange={(e, newVal) => {
                      setFreightType(newVal)
                    }}
                    renderInput={(params) => (
                      <FormFields
                        {...params}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </MDBox>

            <MDTypography
              variant="h5"
              fontWeight="bold"
              sx={{ borderBottom: 1, pb: 1, pt: 4, pl: 3, pr: 3 }}
            >
              Container Details
            </MDTypography>

            <MDBox sx={{ pb: 1, pt: 4, pl: 3, pr: 3, mt: 1.625 }}>
              {containerInfo.map((input, index) => {
                return (
                  <Grid
                    sx={{ display: 'flex', alignItems: 'center' }}
                    key={index}
                  >
                    <Grid item xs={12} sm={4} mb={3} mr={5}>
                      <MDTypography sx={{ fontSize: 13 }}>
                        Container Size
                      </MDTypography>
                      <select
                        name="containerSize"
                        onChange={(e) => handleFormChange(index, e)}
                        value={input.containerSize}
                        className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
                      >
                        {selectData.containerSize.map((container) => (
                          <option>{container}</option>
                        ))}
                      </select>
                    </Grid>
                    <Grid item xs={12} sm={4} mb={3} mr={4}>
                      <MDTypography sx={{ fontSize: 13 }}>
                        Container Number
                      </MDTypography>
                      <FormFields
                        name="containerId"
                        placeholder="Enter Container Number"
                        onChange={(e) => handleFormChange(index, e)}
                        inputProps={{ style: { textTransform: 'capitalize' } }}
                        value={input.containerId}
                      />
                    </Grid>
                    <MDBox item xs={12} sm={2}>
                      <MDButton
                        size="small"
                        style={{
                          fontSize: '12px',
                          width: '70px',
                        }}
                        color="error"
                        variant="gradient"
                        onClick={() => removeFields(index)}
                      >
                        Remove
                      </MDButton>
                    </MDBox>
                  </Grid>
                )
              })}
            </MDBox>
            <MDBox ml={3}>
              <Button
                style={{
                  width: '5px',
                  height: '5px',
                  fontSize: '12px',
                  background: 'black',
                  color: 'white',
                }}
                size="small"
                onClick={addFields}
              >
                <Add
                  style={{
                    width: '20px',
                    height: '20px',
                    marginBottom: '3px',
                  }}
                />{' '}
                Add
              </Button>
            </MDBox>

            <MDBox
              sx={{
                pb: 1,
                pt: 4,
                pl: 3,
                pr: 3,
                mt: 2,
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '2rem',
              }}
            >
              <MDButton variant="gradient" color="dark" type="submit">
                Update
              </MDButton>
            </MDBox>
          </MDBox>

          <button
            className="absolute bg-transparent mr-2 right-36 bottom-20"
            onClick={(e) => getJobId(id)}
          >
            <Modal id={jobId} setJobId={setJobId} />
          </button>
          {/* <button
            className="absolute bg-transparent mr-2 right-36 bottom-20"
            onClick={(e) => getJobId(id)}
          >
            <Completed id={jobId} setJobId={setJobId} />
          </button> */}
        </Paper>
      </DashboardLayout>
    </div>
  )
}

export default EditSeaJob
