/* eslint-disable react/no-unescaped-entities */
// prop-type is a library for typechecking of props
// import PropTypes from "prop-types";

// @mui material components
import Grid from '@mui/material/Grid'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
// NewUser page components
import { Typography } from '@mui/material'
import FormField from '../FormField/index'
import selectData from '../data'
import { Field } from 'formik'
function Profile() {
  // const { formField, values } = formData;
  // const { publicEmail, bio } = formField;
  // const { publicEmail: publicEmailV, bio: bioV } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <Typography
          variant="h5"
          sx={{ borderBottom: 1, pb: 1, pt: 4 }}
        >
          Spouse Details
        </Typography>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">First Name* </Typography>
            <FormField
              name="spouseFirstName"
              placeholder="Enter First Name"
              inputProps={{ style: { textTransform: 'capitalize' } }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Last Name </Typography>
            <FormField
              name="spouseLastName"
              placeholder="Enter Last Name"
              inputProps={{ style: { textTransform: 'capitalize' } }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Phone Number </Typography>
            <FormField
              name="spousePhoneNumber"
              placeholder="Enter Spouse Phone Number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Email Address </Typography>
            <FormField
              name="spouseEmailAddress"
              placeholder="Enter Spouse Email Address"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Number of Children </Typography>
            <MDBox>
              <Field
                className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
                as="select"
                name="numberOfChildren"
              >
                {selectData.numberOfChildren.map((person) => (
                  <option>{person}</option>
                ))}
              </Field>
            </MDBox>
          </Grid>
        </Grid>
        <Typography
          variant="h5"
          sx={{ borderBottom: 1, pb: 1, pt: 4 }}
        >
          Next of Kin Details
        </Typography>
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">
              Next Of Kin's First Name{' '}
            </Typography>
            <FormField
              name="nextFirstName"
              placeholder="Enter Next Of Kin First Name"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Last Name </Typography>
            <FormField
              name="nextLastName"
              placeholder="Enter Next Of Kin Last Name"
              inputProps={{ style: { textTransform: 'capitalize' } }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Phone Number </Typography>
            <FormField
              type="tel"
              name="nextPhoneNumber"
              placeholder="Enter Next Of Kin Phone Number"
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="subtitle2">
              Next of Kin Address{' '}
            </Typography>
            <FormField
              name="nextPermanentAddress"
              placeholder="Enter Permanent Address"
              inputProps={{ style: { textTransform: 'capitalize' } }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">
              Next Of Kin's Relationship{' '}
            </Typography>
            <Field
              className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
              as="select"
              name="nextOfKin"
            >
              {selectData.nextOfKin.map((person) => (
                <option>{person}</option>
              ))}
            </Field>
          </Grid>
        </Grid>
        <Typography
          variant="h5"
          sx={{ borderBottom: 1, pb: 1, pt: 4 }}
        >
          Emergency Contact Details
        </Typography>
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">First Name </Typography>
            <FormField
              name="emergencyFirstName"
              placeholder="Enter First Name"
              inputProps={{ style: { textTransform: 'capitalize' } }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Last Name </Typography>
            <FormField
              name="emergencyLastName"
              placeholder="Enter Last Name"
              inputProps={{ style: { textTransform: 'capitalize' } }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Phone Number </Typography>
            <FormField
              name="emergencyPhoneNumber"
              placeholder="Enter Phone Number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">First Name </Typography>
            <FormField
              name="emergencyFirstName1"
              placeholder="Enter First Name"
              inputProps={{ style: { textTransform: 'capitalize' } }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Last Name </Typography>
            <FormField
              name="emergencyLastName1"
              placeholder="Enter Last Name"
              inputProps={{ style: { textTransform: 'capitalize' } }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Phone Number </Typography>
            <FormField
              name="emergencyPhoneNumber1"
              placeholder="Enter Phone Number"
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  )
}

// typechecking props for Profile
// Profile.propTypes = {
//   formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
// };

export default Profile
