// import { db } from '../firebase'
import { appFirestore, appStorage } from "../firebase/config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

import {
  collection,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

const staffCollectionRef = collection(appFirestore, "c");
const kingsooFinance = collection(appFirestore, "jobRelatedForm");
const kingsooFinance2 = collection(appFirestore, "nonJobRelated");

export const useKingsooService = () => {
  const addStaff = (newStaff) => {
    return addDoc(staffCollectionRef, newStaff);
  };

  const addFinance = ({
    billOfLaden,
    fileId,
    consigneeId,
    clientId,
    containerInfo,
    paymentValue,
    paymentType,
    invoiceNo,
    bulkCargo,
    request,
    email,
    department,
    file,
    description,
    date,
    requestId,
    status,
    quantity,
    amount,
  }) => {
    const img = ref(appStorage, `Finance/${new Date()}/${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      // console.log('uploaded')
      getDownloadURL(snapshot.ref).then((url) => {
        return addDoc(kingsooFinance, {
          billOfLaden,
          fileId,
          consigneeId,
          clientId,
          containerInfo,
          paymentValue,
          paymentType,
          invoiceNo,
          bulkCargo,
          request,
          email,
          department,
          file: url,
          description,
          date,
          requestId,
          status,
          quantity,
          amount,
        });
      });
    });
  };
  const addFinance2 = ({
    quantity,
    amount,
    paymentValue,
    request,
    description,
    date,
    email,
    department,
    requestId,
    status,
    file,
  }) => {
    const img = ref(appStorage, `Finance/${new Date()}/${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      // console.log('uploaded')
      getDownloadURL(snapshot.ref).then((url) => {
        return addDoc(kingsooFinance2, {
          quantity,
          amount,
          paymentValue,
          request,
          description,
          date,
          email,
          department,
          requestId,
          status,
          file: url,
        });
      });
    });
  };
  //Human Resource
  const addHumanResource = ({
    quantity,
    amount,
    paymentValue,
    header,
    request,
    description,
    date,
    email,
    department,
    requestId,
    status,
    file,
  }) => {
    const img = ref(appStorage, `Finance/${new Date()}/${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      // console.log('uploaded')
      getDownloadURL(snapshot.ref).then((url) => {
        return addDoc(kingsooFinance2, {
          quantity,
          amount,
          paymentValue,
          header,
          request,
          description,
          date,
          email,
          department,
          requestId,
          status,
          file: url,
        });
      });
    });
  };

  const editSeaJob = (id, updatedJob) => {
    const kingsoo = doc(appFirestore, "jobs", id);
    return updateDoc(kingsoo, updatedJob);
  };
  const updateConsignee = (id, updatedStaff) => {
    const kingsoo = doc(appFirestore, "consignee", id);
    return updateDoc(kingsoo, updatedStaff);
  };
  const updateClient = (id, newClient) => {
    const kingsoo = doc(appFirestore, "clients", id);
    return updateDoc(kingsoo, newClient);
  };
  const updateJobManager = (id, update) => {
    const kingsoo = doc(appFirestore, "jobManager", id);
    return updateDoc(kingsoo, update);
  };

  const updateJNR = (id, updatedStaff) => {
    const kingsoo = doc(appFirestore, "nonJobRelated", id);
    return updateDoc(kingsoo, updatedStaff);
  };

  const updateFNJR = (id, updatedStaff) => {
    const kingsoo = doc(appFirestore, "jobRelatedForm", id);
    return updateDoc(kingsoo, updatedStaff);
  };

  const updateUser = (id, updatedStaff) => {
    const kingsoo = doc(appFirestore, "users", id);
    return updateDoc(kingsoo, updatedStaff);
  };

  const updatePJob = (id, updatedStaff) => {
    const kingsoo = doc(appFirestore, "users", id);
    return updateDoc(kingsoo, updatedStaff);
  };

  const updateTerminateJob = (id, updated) => {
    const kingsoo = doc(appFirestore, "jobs", id);
    return updateDoc(kingsoo, updated);
  };

  const airWayBill = (id, updated) => {
    const kingsoo = doc(appFirestore, "jobs", id);
    return updateDoc(kingsoo, updated);
  };

  const updateEta = (id, updated) => {
    const kingsoo = doc(appFirestore, "jobs", id);
    return updateDoc(kingsoo, updated);
  };

  const updateFormM = (id, { formMImage: file, formMValue }) => {
    const img = ref(appStorage, `jobs/${id}/FormM/${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const staffDoc = doc(appFirestore, "jobs", id);
        return updateDoc(staffDoc, {
          // formMDate,
          formMImage: url,
          formMValue,
        });
      });
    });
  };

  const updateParty = (id, { thirdPartyPayment, thirdParty: file }) => {
    const img = ref(appStorage, `jobs/${id}/FormM/${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const staffDoc = doc(appFirestore, "jobs", id);
        return updateDoc(staffDoc, {
          thirdPartyPayment,
          thirdParty: url,
        });
      });
    });
  };

  const updatePaar = (id, { paarImage: file, paarValue }) => {
    const img = ref(appStorage, `jobs/${id}/Paar/${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const staffDoc = doc(appFirestore, "jobs", id);
        return updateDoc(staffDoc, {
          // paarReceivalDate,
          paarImage: url,
          paarValue,
        });
      });
    });
  };

  const updateAccessment = (
    id,
    { customDutyReceiptImage: file, customDutyReceiptValue }
  ) => {
    const img = ref(appStorage, `jobs/${id}/CustomDuty /${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const staffDoc = doc(appFirestore, "jobs", id);
        return updateDoc(staffDoc, {
          // customDutyReceiptDate,
          customDutyReceiptImage: url,
          customDutyReceiptValue,
        });
      });
    });
  };

  const  updateAnlca = (id, { anlcaImage: file, anlcaValue }) => {
    const img = ref(appStorage, `jobs/${id}/Anlca /${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const staffDoc = doc(appFirestore, "jobs", id);
        return updateDoc(staffDoc, {
          // anlcaDate,
          anlcaImage: url,
          anlcaValue,
        });
      });
    });
  };

  const updateNafdac = (id, { nafdacCertImage: file, nafdacCertValue }) => {
    const img = ref(appStorage, `jobs/${id}/Nafdac /${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const staffDoc = doc(appFirestore, "jobs", id);
        return updateDoc(staffDoc, {
          // nafdacCertDate,
          nafdacCertImage: url,
          nafdacCertValue,
        });
      });
    });
  };

  const updateCommercial = (
    id,
    {
      // commercialInvoiceDate,
      commercialInvoiceImage: file,
      commercialInvoiceValue,
    }
  ) => {
    const img = ref(appStorage, `jobs/${id}/Commercial/${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const staffDoc = doc(appFirestore, "jobs", id);
        return updateDoc(staffDoc, {
          // commercialInvoiceDate,
          commercialInvoiceImage: url,
          commercialInvoiceValue,
        });
      });
    });
  };

  const updateSoncap = (id, { soncapCertImage: file, soncapCertValue }) => {
    const img = ref(appStorage, `jobs/${id}/Soncap/${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const staffDoc = doc(appFirestore, "jobs", id);
        return updateDoc(staffDoc, {
          // soncapCertDate,
          soncapCertImage: url,
          soncapCertValue,
        });
      });
    });
  };

  const updateTerminal = (
    id,
    { terminalInvoiceImage: file, terminalInvoiceValue }
  ) => {
    const img = ref(appStorage, `jobs/${id}/Terminal/${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const staffDoc = doc(appFirestore, "jobs", id);
        return updateDoc(staffDoc, {
          // terminalInvoiceDate,
          terminalInvoiceImage: url,
          terminalInvoiceValue,
        });
      });
    });
  };

  const updateCcvo = (id, { ccvoFormC16Image: file, ccvoFormC16Value }) => {
    const img = ref(appStorage, `jobs/${id}/Ccvo/${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const staffDoc = doc(appFirestore, "jobs", id);
        return updateDoc(staffDoc, {
          // ccvoFormC16Date,
          ccvoFormC16Image: url,
          ccvoFormC16Value,
        });
      });
    });
  };

  const updateWork = (id, { workOrder: file, workOrderValue }) => {
    const img = ref(appStorage, `jobs/${id}/work/${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const staffDoc = doc(appFirestore, "jobs", id);
        return updateDoc(staffDoc, {
          workOrder: url,
          workOrderValue,
        });
      });
    });
  };

  const updateBill = (id, { billLading: file }) => {
    const img = ref(appStorage, `jobs/${id}/billLading/${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const staffDoc = doc(appFirestore, "jobs", id);
        return updateDoc(staffDoc, {
          billLading: url,
        });
      });
    });
  };

  const updateAir = (id, { airBill: file }) => {
    const img = ref(appStorage, `jobs/${id}/billLading/${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const staffDoc = doc(appFirestore, "jobs", id);
        return updateDoc(staffDoc, {
          airBill: url,
        });
      });
    });
  };

  const updateExport = (id, { exportBill: file }) => {
    const img = ref(appStorage, `jobs/${id}/billLading/${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const staffDoc = doc(appFirestore, "jobs", id);
        return updateDoc(staffDoc, {
          exportBill: url,
        });
      });
    });
  };

  const updateNotice = (id, { accessment: file, accessmentValue }) => {
    const img = ref(appStorage, `jobs/${id}/notice/${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const staffDoc = doc(appFirestore, "jobs", id);
        return updateDoc(staffDoc, {
          // assessmentNoticeDate: accessmentDate,
          assessmentImage: url,
          assessmentNoticeValue: accessmentValue,
        });
      });
    });
  };

  const updateSdg = (id, { sdgFile: file, sdgDate }) => {
    const img = ref(appStorage, `jobs/${id}/sdg/${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const staffDoc = doc(appFirestore, "jobs", id);
        return updateDoc(staffDoc, {
          sdgFile: url,
          sdgDate,
        });
      });
    });
  };

  const updateShipping = (
    id,
    { shippingInvoiceDate, shippingInvoiceImage: file, shippingInvoiceValue }
  ) => {
    const img = ref(appStorage, `jobs/${id}/Shipping/${file.name}`);
    uploadBytes(img, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const staffDoc = doc(appFirestore, "jobs", id);
        return updateDoc(staffDoc, {
          // shippingInvoiceDate,
          shippingInvoiceImage: url,
          shippingInvoiceValue,
        });
      });
    });
  };

  const updateJob = async (
    id,
    {
      containerInfo,
      paarReceivalDate,
      expectedTimeOfArrival,
      isPaarAvailable,
      companyId,
      jobManagerId,
      consigneeId,
      jobType,
      // preALertImage: file1,
      scacOrAirline,
      freightType,
      paarValue,
      paarImage: file,
      preAlertstatus,
      fileId,
      jobCreatedDate,
    }
  ) => {
    // console.log(file)
    const img = ref(appStorage, `jobs/${id}/paarImage/${file.name}`);

    uploadBytes(img, file).then((snapshot) => {
      // console.log('uploaded')
      getDownloadURL(snapshot.ref).then((url) => {
        // const img1 = ref(appStorage, `jobs/${id}/preALertImage/${file.name}`)
        // uploadBytes(img, file1).then((snapshot) => {
        //   // console.log('uploaded')
        //   getDownloadURL(snapshot.ref).then((url1) => {
        const staffDoc = doc(appFirestore, "jobs", id);
        return updateDoc(staffDoc, {
          containerInfo,
          paarReceivalDate,
          expectedTimeOfArrival,
          isPaarAvailable,
          companyId,
          jobManagerId,
          consigneeId,
          jobType,
          // preALertImage: url1,
          scacOrAirline,
          freightType,
          paarValue,
          paarImage: url,
          preAlertstatus,
          fileId,
          jobCreatedDate,
        });
      });
      // })
      // })
    });
  };

  const deleteConsignee = (id) => {
    const kingsoo = doc(appFirestore, "consignee", id);
    return deleteDoc(kingsoo);
  };

  const deleteJobManager = (id) => {
    const kingsoo = doc(appFirestore, "jobManager", id);
    return deleteDoc(kingsoo);
  };
  const  deleteEmployee = (id) => {
    const kingsoo = doc(appFirestore, "users", id);
    return deleteDoc(kingsoo);
  };

  const deleteClient = (id) => {
    const kingsoo = doc(appFirestore, "clients", id);
    return deleteDoc(kingsoo);
  };

  const getConsignee = (id) => {
    const kingsoo = doc(appFirestore, "consignee", id);
    return getDoc(kingsoo);
  };
  const  getJobManager = (id) => {
    const kingsoo = doc(appFirestore, "jobManager", id);
    return getDoc(kingsoo);
  };
  const getJob = (id) => {
    const kingsoo = doc(appFirestore, "jobs", id);
    return getDoc(kingsoo);
  };
  const getClient = (id) => {
    const kingsoo = doc(appFirestore, "clients", id);
    return getDoc(kingsoo);
  };
  const getUser = (id) => {
    const kingsoo = doc(appFirestore, "users", id);
    return getDoc(kingsoo);
  };
  const getFnj = (id) => {
    const kingsoo = doc(appFirestore, "jobRelatedForm", id);
    return getDoc(kingsoo);
  };
  const getjnr = (id) => {
    const kingsoo = doc(appFirestore, "nonJobRelated", id);
    return getDoc(kingsoo);
  };

  return {
    addStaff,
    addFinance,
    addFinance2,
    addHumanResource,
    editSeaJob,
    updateConsignee,
    updateClient,
    updateJobManager,
    updateJNR,
    updateFNJR,
    updateUser,
    updatePJob,
    updateTerminateJob,
    airWayBill,
    updateEta,
    updateFormM,
    updateParty,
    updatePaar,
    updateAccessment,
    updateAnlca,
    updateNafdac,
    updateCommercial,
    updateSoncap,
    updateTerminal,
    updateCcvo,
    updateWork,
    updateBill,
    updateAir,
    updateExport,
    updateNotice,
    updateSdg,
    updateShipping,
    updateJob,
    deleteConsignee,
    deleteJobManager,
    deleteEmployee,
    deleteClient,
    getConsignee,
    getJobManager,
    getJob,
    getClient,
    getFnj,
    getjnr
  };
};
