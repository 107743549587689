import { Autocomplete, Grid, Paper, TextField } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import FormFields from 'layouts/pages/account/components/FormField'
import React from 'react'
import { selectData } from './data'

const NonJobRelated = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
        <MDBox mt={5} component="form">
          <MDTypography
            variant="h5"
            fontWeight="bold"
            sx={{ borderBottom: 1, pb: 1, pt: 4 }}
          >
            Non Job Related Financial Request Form
          </MDTypography>
          <MDBox mt={1.625}>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Payment Value{' '}
                </MDTypography>
                <FormFields
                  name="PaymentValue"
                  placeholder="N0.00"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Request For{' '}
                </MDTypography>
                <MDBox>
                  <Autocomplete
                    defaultValue="Select reason for payment"
                    options={selectData.paymentType}
                    renderInput={(params) => (
                      <FormFields
                        {...params}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Description{' '}
                </MDTypography>
                <TextField
                  fullWidth
                  placeholder='Enter your description here...'
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '2rem',
            }}
          >
            <MDButton
              style={{ fontSize: '12px' }}
              variant="gradient"
              color="dark"
              type="submit"
            >
              Submit Request
            </MDButton>
          </MDBox>
        </MDBox>
      </Paper>
    </DashboardLayout>
  )
}

export default NonJobRelated