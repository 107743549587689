import { useNavigate } from 'react-router-dom'

// @mui material components
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
// import MDTypography from "components/MDTypography";

//Firebase Hooks
import { useCollection } from '../../../hooks/useCollection'

import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'

import ComplexStatisticsCard from 'customs/Cards/StatisticsCards/ComplexStatisticsCard'


function Home() {
  const { documents: alerts } = useCollection('jobs')
  let navigate = useNavigate()
  const pending = alerts.filter(
    (alert) => alert.preAlertstatus === 'IN PROGRESS'
  )

  // Action buttons for the BookingCard
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/terminal/job-table', {
                  replace: false,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  icon="train"
                  title="Terminal Table"
                  users="0"
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/terminal/client-management/consignee', {
                  replace: false,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="secondary"
                  icon="group"
                  title="Outward Containers"
                  users="0"
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/terminal/request-status/approved', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="check_circle_outline"
                  title="Approved Requests"
                  users={0}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/terminal/request-status/pending', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="hourglass_bottom"
                  title="Pending Requests"
                  users={0}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/terminal/request-status/declined', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="error"
                  icon="cancel"
                  title="Declined Requests"
                  users={0}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/terminal/job-management/pending-job', {
                  replace: false,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="work_history"
                  color="dark"
                  title="Pending Jobs"
                  count=""
                  users={pending.length}
                  percentage={{
                    color: 'dark',
                    amount: 15,
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  )
}

export default Home
