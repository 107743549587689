import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Paper, Typography } from "@mui/material";
import { useKingsooService } from "../../../../hooks/useKingsooServices";
import { format } from "date-fns";
import { EyeIcon } from "@heroicons/react/solid";
import FormFields from "views/HR/new-staff/components/FormField/FormField";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import PopSuccess from "../../Message/Success";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="₦"
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function FormUpdate() {
  const [expectedTimeOfArrival, setExpectedTimeOfArrival] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [billOfLaden, setBillOfLaden] = useState("");
  const [airWayBill, setAirWayBill] = useState("");
  const [preALertImage, setPreAlertImage] = useState("");
  const [isPaarAvailable, setIsPaarAvailable] = useState("Yes");
  const [jobManagerId, setJobManagerId] = useState("");
  const [paarImage, setPaarImage] = useState("");
  const [paarReceivalDate, setPaarReceivalDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [paarValue, setPaarValue] = useState("");
  const [paar, setPaar] = useState("");
  const [formMImage, setFormMImage] = useState("");
  const [formMDate, setFormMDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [formMValue, setFormMValue] = useState("");
  const [formM, setFormM] = useState("");
  const [customDutyReceiptDate, setCustomDutyReceiptDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [customDutyReceiptImage, setCustomDutyReceiptImage] = useState("");
  const [customDutyReceiptValue, setCustomDutyReceiptValue] = useState("");
  const [customDutyReceipt, setCustomDutyReceipt] = useState("");
  const [anlcaImage, setAnlcaImage] = useState("");
  const [anlcaDate, setAnlcaDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [anlcaValue, setAnlcaValue] = useState("");
  const [anlca, setAnlca] = useState("");
  const [nafdacCertDate, setNafdacCertDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [nafdacCertImage, setNafdacCertImage] = useState("");
  const [nafdacCertValue, setNafdacCertValue] = useState("");
  const [nafdacCert, setNafdacCert] = useState("");
  const [commercialInvoiceDate, setCommercialInvoiceDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [commercialInvoiceImage, setCommercialInvoiceImage] = useState("");
  const [commercialInvoiceValue, setCommercialInvoiceValue] = useState("");
  const [commercialInvoice, setCommercialInvoice] = useState("");
  const [soncapCertDate, setSoncapCertDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [soncapCertImage, setSoncapCertImage] = useState("");
  const [soncapCertValue, setSoncapCertValue] = useState("");
  const [soncapCert, setSoncapCert] = useState("");
  const [ccvoFormC16Date, setCcvoFormC16Date] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [ccvoFormC16Image, setCcvoFormC16Iamge] = useState("");
  const [ccvoFormC16Value, setCcvoFormC16Value] = useState("");
  const [ccvoFormC16, setCcvoFormC16] = useState("");
  const [terminalInvoiceDate, setTerminalInvoiceDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [terminalInvoiceImage, setTerminalInvoiceImage] = useState("");
  const [terminalInvoiceValue, setTerminalInvoiceValue] = useState("");
  const [terminalInvoice, setTerminalInvoice] = useState("");
  const [shippingInvoiceDate, setShippingInvoiceDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [shippingInvoiceImage, setShippingInvoiceImage] = useState("");
  const [shippingInvoiceValue, setShippingInvoiceValue] = useState("");
  const [shippingInvoice, setShippingInvoice] = useState("");
  const [billLading, setBillLading] = useState("");
  const [accessment, setAccessment] = useState("");
  const [accessmentDate, setAccessmentDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [accessmentValue, setAccessmentValue] = useState("");
  const [sdgFile, setSDGFile] = useState("");
  const [sdgDate, setSdgDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [success, setSuccess] = useState("");


  const {
    getJob,
    updateEta,
    updateFormM,
    updateBill,
    updateNotice,
    updateCommercial,
    updateSdg,
    updateAccessment,
    updateNafdac,
    updateAnlca,
    updateSoncap,
    updateCcvo,
    updateTerminal,
    updatePaar,
    updateShipping,
  } = useKingsooService();

  // const { documents: jb } = useCollection('jobManager')
  // const jbs = jb.map((document) => document.name)

  // console.log('client', client)

  const formMInfo = async (e) => {
    e.preventDefault();

    try {
      await updateFormM(id, {
        // formMDate,
        formMImage,
        formMValue,
      });
      setFormMValue("");
      setFormMImage("okay");
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };
  const notice = async (e) => {
    e.preventDefault();

    try {
      await updateNotice(id, {
        accessment,
        // accessmentDate,
        accessmentValue,
      });
      setAccessment("");
      setAccessmentValue("");
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };

  const sdg = async (e) => {
    e.preventDefault();

    try {
      await updateSdg(id, {
        // sdgDate,
        sdgFile,
      });
      setSDGFile("");
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };

  const bill = async (e) => {
    e.preventDefault();

    try {
      await updateBill(id, {
        billLading,
      });
      setBillLading("");
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };

  const customInfo = async (e) => {
    e.preventDefault();

    try {
      await updateAccessment(id, {
        // customDutyReceiptDate,
        customDutyReceiptImage,
        customDutyReceiptValue,
      });
      setCustomDutyReceiptImage("");
      setCustomDutyReceiptValue("");
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };

  const anlcaInfo = async (e) => {
    e.preventDefault();

    try {
      await updateAnlca(id, {
        // anlcaDate,
        anlcaImage,
        anlcaValue,
      });
      setAnlcaImage("");
      setAnlcaValue("");
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };

  const nafdacInfo = async (e) => {
    e.preventDefault();

    try {
      await updateNafdac(id, {
        // nafdacCertDate,
        nafdacCertImage,
        nafdacCertValue,
      });
      setNafdacCertImage("");
      setNafdacCertValue("");
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };

  const commercialInfo = async (e) => {
    e.preventDefault();

    try {
      await updateCommercial(id, {
        // commercialInvoiceDate,
        commercialInvoiceImage,
        commercialInvoiceValue,
      });
      setCommercialInvoiceImage("");
      setCommercialInvoiceValue("");
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };

  const soncapInfo = async (e) => {
    e.preventDefault();

    try {
      await updateSoncap(id, {
        // soncapCertDate,
        soncapCertImage,
        soncapCertValue,
      });
      setSoncapCertImage("");
      setSoncapCertValue("");
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };

  const ccvoInfo = async (e) => {
    e.preventDefault();

    try {
      await updateCcvo(id, {
        // ccvoFormC16Date,
        ccvoFormC16Image,
        ccvoFormC16Value,
      });
      setCcvoFormC16Value("");
      setCcvoFormC16Iamge("");
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };

  const terminalInfo = async (e) => {
    e.preventDefault();

    try {
      await updateTerminal(id, {
        // terminalInvoiceDate,
        terminalInvoiceImage,
        terminalInvoiceValue,
      });
      setTerminalInvoiceValue("");
      setTerminalInvoiceImage("");
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };

  const paarInfo = async (e) => {
    e.preventDefault();

    try {
      await updatePaar(id, {
        // paarReceivalDate,
        paarImage,
        paarValue,
      });
      setPaarImage("");
      setPaarValue("");
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };

  const shippingInfo = async (e) => {
    e.preventDefault();

    try {
      await updateShipping(id, {
        // shippingInvoiceDate,
        shippingInvoiceImage,
        shippingInvoiceValue,
      });
      setShippingInvoiceImage("");
      setShippingInvoiceValue("");
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };

  const eta = async (e) => {
    e.preventDefault();
    try {
      await updateEta(id, {
        expectedTimeOfArrival,
      });
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };

  const { id } = useParams();

  // let navigate = useNavigate();

  const detail = async () => {
    try {
      const docSnap = await getJob(id);
      setBillOfLaden(docSnap.data().billOfLaden);
      setAirWayBill(docSnap.data().airWayBill);
      setPaarImage(docSnap.data().paarImage);
      setIsPaarAvailable(docSnap.data().isPaarAvailable);
      setJobManagerId(docSnap.data().jobManagerId);
      setPreAlertImage(docSnap.data().preALertImage);
      setAnlca(docSnap.data().anlcaImage);
      setFormM(docSnap.data().formMImage);
      setCustomDutyReceipt(docSnap.data().customDutyReceiptImage);
      setNafdacCert(docSnap.data().nafdacCertImage);
      setCommercialInvoice(docSnap.data().commercialInvoiceImage);
      setSoncapCert(docSnap.data().soncapCertImage);
      setCcvoFormC16(docSnap.data().ccvoFormC16Image);
      setTerminalInvoice(docSnap.data().terminalInvoiceImage);
      setShippingInvoice(docSnap.data().shippingInvoiceImage);
      setExpectedTimeOfArrival(docSnap.data().expectedTimeOfArrival);
      setPaar(docSnap.data().paarValue);
      // setcreationDate(docSnap.date().jobCreatedDate)
      // console.log(docSnap.data())
    } catch (error) {
      // console.log(error)
    }
  };

  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await getJob(id);
        setBillOfLaden(docSnap.data().billOfLaden);
        setAirWayBill(docSnap.data().airWayBill);
        setPaarImage(docSnap.data().paarImage);
        setIsPaarAvailable(docSnap.data().isPaarAvailable);
        setJobManagerId(docSnap.data().jobManagerId);
        setPreAlertImage(docSnap.data().preALertImage);
        setAnlca(docSnap.data().anlcaImage);
        setFormM(docSnap.data().formMImage);
        setCustomDutyReceipt(docSnap.data().customDutyReceiptImage);
        setNafdacCert(docSnap.data().nafdacCertImage);
        setCommercialInvoice(docSnap.data().commercialInvoiceImage);
        setSoncapCert(docSnap.data().soncapCertImage);
        setCcvoFormC16(docSnap.data().ccvoFormC16Image);
        setTerminalInvoice(docSnap.data().terminalInvoiceImage);
        setShippingInvoice(docSnap.data().shippingInvoiceImage);
        setExpectedTimeOfArrival(docSnap.data().expectedTimeOfArrival);
        setPaar(docSnap.data().paarImage);
        // setcreationDate(docSnap.date().jobCreatedDate)
        // console.log(docSnap.data())
      } catch (error) {
        // console.log(error)
      }
    };
    // console.log('na u know', id)
    if ((id !== undefined || id !== "") && id !== ":id") {
      detail();
      // console.log('id', id)
    }
  }, [id]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <React.Fragment>
        <Paper>
          <div className="mx-6">
            <Grid container spacing={3} className="pt-8">
              {billOfLaden && (
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle2" gutterBottom>
                    Bill Of Lading
                  </Typography>
                  <FormFields
                    name="billOfLaden"
                    value={billOfLaden}
                    fullWidth
                    disabled
                  />
                </Grid>
              )}
              {airWayBill && (
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle2" gutterBottom>
                    Air Way Bill
                  </Typography>
                  <FormFields
                    name="billOfLaden"
                    value={airWayBill}
                    fullWidth
                    disabled
                  />
                </Grid>
              )}
              <Grid container item xs={12} sm={4}>
                <Typography variant="subtitle2" gutterBottom>
                  Expected Time Of Arrival
                </Typography>

                <input
                  type="date"
                  name="expectedTimeOfArrival"
                  className="border border-gray-300 text-gray-900 text-base 
                  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={expectedTimeOfArrival}
                  onChange={(e) => setExpectedTimeOfArrival(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2" gutterBottom>
                  Job Manager
                </Typography>
                <input
                  type="text"
                  name="jobManager"
                  className="border border-gray-300 text-gray-900 text-base 
                  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={jobManagerId}
                  // onChange={(e) => setExpectedTimeOfArrival(e.target.value)}
                  disabled
                />
                {/* <select
                  name="jobManageId"
                  className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
                >
                  {jbs.map((jb) => (
                    <option selected={jobManagerId === jb ? true : ''}>
                      {jb}
                    </option>
                  ))}
                </select> */}
              </Grid>
              {preALertImage && (
                <Grid item xs={12} sm={3}>
                  <Typography variant="subtitle2" gutterBottom>
                    Bill Of Lading/ Air Waybill
                  </Typography>
                  <span className="relative z-0 darkGreen  shadow-sm w-full mx-auto rounded-md">
                    <a
                      href={preALertImage}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        type="button"
                        className="relative inline-flex darkGreen  items-center  px-4 py-3 rounded-md border border-gray-300  text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                      >
                        <EyeIcon
                          className="-ml-1 mr-2 h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                        View Document
                      </button>
                    </a>
                  </span>
                </Grid>
              )}
              <div className=" container mt-12 mx-6">
                <Grid
                  container
                  spacing={3}
                  mb={3}
                  mt="1"
                  component="form"
                  onSubmit={eta}
                >
                  <Grid item xs={12} sm={4}>
                    <Typography sx={{ fontSize: 15 }}>
                      Expected Time Of Arrival
                    </Typography>

                    <input
                      type="date"
                      name="expectedTimeOfArrival"
                      className="border border-gray-300 text-gray-900 text-base 
                  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={expectedTimeOfArrival}
                      onChange={(e) => setExpectedTimeOfArrival(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} mt={0.7}>
                    <button
                      type="submit"
                      className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Submit
                    </button>
                  </Grid>
                </Grid>
              </div>

              <div className=" container mt-12 mx-12">
                <h2 className="pb-12  -mx-6 text-xl font-extrabold">
                  Upload Documents
                </h2>

                <Grid container spacing={3} mb={3} mt="1">
                  {/* {isPaarAvailable === 'No' ||
                    (isPaarAvailable !== 'N/R' && !paar && ( */}
                  <Grid
                    container
                    component="form"
                    onSubmit={bill}
                    spacing={4}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>
                        Bill Of Lading
                      </Typography>
                      <input
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        id=""
                        type="file"
                        onChange={(e) => setBillLading(e.target.files[0])}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    component="form"
                    onSubmit={sdg}
                    spacing={4}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>Upload SGD</Typography>
                      <input
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        id=""
                        type="file"
                        onChange={(e) => setSDGFile(e.target.files[0])}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Date Received
                      </Typography>
                      <input
                        type="date"
                        value={sdgDate}
                        disabled
                        className="border border-gray-300 text-gray-900 text-base 
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        onChange={(e) => setSdgDate(e.target.value)}
                      />
                    </Grid> */}

                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={4}
                    component="form"
                    onSubmit={commercialInfo}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>
                        Commercial Invoice
                      </Typography>
                      <input
                        type="file"
                        name="commercialInvoiceFile"
                        id=""
                        onChange={(e) =>
                          setCommercialInvoiceImage(e.target.files[0])
                        }
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Date Received
                      </Typography>
                      <input
                        type="date"
                        value={commercialInvoiceDate}
                        disabled
                        onChange={(e) =>
                          setCommercialInvoiceDate(e.target.value)
                        }
                        className="border border-gray-300 text-gray-900 text-base 
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Commercial Invoice Value
                      </Typography>
                      <FormFields
                        name="numberformat"
                        id="formatted-numberformat-input"
                        placeholder="Enter Amount"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) =>
                          setCommercialInvoiceValue(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    component="form"
                    onSubmit={soncapInfo}
                    spacing={4}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>SONCAP Cert</Typography>
                      <input
                        type="file"
                        name="soncapFile"
                        id=""
                        onChange={(e) => setSoncapCertImage(e.target.files[0])}
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Date Received
                      </Typography>
                      <input
                        type="date"
                        onChange={(e) => setSoncapCertDate(e.target.value)}
                        value={soncapCertDate}
                        disabled
                        className="border border-gray-300 text-gray-900 text-base 
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        SONCAP Value
                      </Typography>
                      <FormFields
                        name="numberformat"
                        id="formatted-numberformat-input"
                        placeholder="Enter Amount"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) => setSoncapCertValue(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    component="form"
                    onSubmit={paarInfo}
                    spacing={4}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>Upload Paar</Typography>
                      <input
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        id=""
                        type="file"
                        onChange={(e) => setPaarImage(e.target.files[0])}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Date Received
                      </Typography>
                      <input
                        type="date"
                        value={paarReceivalDate}
                        disabled
                        className="border border-gray-300 text-gray-900 text-base 
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        onChange={(e) => setPaarReceivalDate(e.target.value)}
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>Paar Value</Typography>
                      <FormFields
                        name="numberformat"
                        id="formatted-numberformat-input"
                        placeholder="Enter Amount"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) => setPaarValue(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid>
                  {/* ))} */}

                  <Grid
                    container
                    component="form"
                    onSubmit={notice}
                    spacing={4}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>
                        Upload Acessment Notice
                      </Typography>
                      <input
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        id=""
                        type="file"
                        onChange={(e) => setAccessment(e.target.files[0])}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Date Received
                      </Typography>
                      <input
                        type="date"
                        value={accessmentDate}
                        disabled
                        className="border border-gray-300 text-gray-900 text-base 
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        onChange={(e) => setAccessmentDate(e.target.value)}
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Acessment Notice Value
                      </Typography>
                      <FormFields
                        name="numberformat"
                        id="formatted-numberformat-input"
                        placeholder="Enter Amount"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) => setAccessmentValue(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid>

                  {/* {!customDutyReceipt && ( */}
                  <Grid
                    container
                    component="form"
                    onSubmit={customInfo}
                    spacing={4}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>
                        Custom Duty Receipt
                      </Typography>
                      <input
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        id=""
                        type="file"
                        onChange={(e) =>
                          setCustomDutyReceiptImage(e.target.files[0])
                        }
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Date Received
                      </Typography>
                      <input
                        type="date"
                        disabled
                        value={customDutyReceiptDate}
                        className="border border-gray-300 text-gray-900 text-base 
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        onChange={(e) =>
                          setCustomDutyReceiptDate(e.target.value)
                        }
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Custom Duty Value
                      </Typography>
                      <FormFields
                        name="numberformat"
                        id="formatted-numberformat-input"
                        placeholder="Enter Amount"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) =>
                          setCustomDutyReceiptValue(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    component="form"
                    onSubmit={nafdacInfo}
                    spacing={4}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>
                        Agency receipts
                      </Typography>
                      {/* <div className="mt-1 flex  rounded-md shadow-sm border border-gray-300" {...getRootProps()}> */}
                      <input
                        type="file"
                        name=""
                        id=""
                        onChange={(e) => setNafdacCertImage(e.target.files[0])}
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Date Received
                      </Typography>
                      <input
                        type="date"
                        value={shippingInvoiceDate}
                        disabled
                        onChange={(e) => setShippingInvoiceDate(e.target.value)}
                        className="border border-gray-300 text-gray-900 text-base 
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Agency receipts Value
                      </Typography>
                      <FormFields
                        name="numberformat"
                        id="formatted-numberformat-input"
                        placeholder="Enter Amount"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) => setNafdacCertValue(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={4}
                    component="form"
                    onSubmit={shippingInfo}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>
                        Shipping Invoice
                      </Typography>
                      <input
                        type="file"
                        name=""
                        id=""
                        onChange={(e) =>
                          setShippingInvoiceImage(e.target.files[0])
                        }
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Date Received
                      </Typography>
                      <input
                        type="date"
                        onChange={(e) => setTerminalInvoiceDate(e.target.value)}
                        value={terminalInvoiceDate}
                        disabled
                        className="border border-gray-300 text-gray-900 text-base 
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Shipping Invoice Value
                      </Typography>
                      <FormFields
                        name="numberformat"
                        id="formatted-numberformat-input"
                        placeholder="Enter Amount"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) =>
                          setShippingInvoiceValue(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid>
                  {/* )} */}
                  {/* {!formM && ( */}
                  <Grid
                    container
                    component="form"
                    onSubmit={terminalInfo}
                    spacing={4}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>
                        Terminal Invoice
                      </Typography>
                      <input
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        type="file"
                        name=""
                        id=""
                        onChange={(e) =>
                          setTerminalInvoiceImage(e.target.files[0])
                        }
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Date Received
                      </Typography>
                      <input
                        type="date"
                        value={formMDate}
                        disabled
                        onChange={(e) => setFormMDate(e.target.value)}
                        className="border border-gray-300 text-gray-900 text-base 
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Terminal Invoice Value
                      </Typography>
                      <FormFields
                        name="numberformat"
                        id="formatted-numberformat-input"
                        placeholder="Enter Amount"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) =>
                          setTerminalInvoiceValue(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid>
                  {/* )} */}
                  {/* {!anlca && ( */}
                  {/* <Grid
                    container
                    spacing={4}
                    component="form"
                    onSubmit={anlcaInfo}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>ANLCA</Typography>
                      <input
                        type="file"
                        name=""
                        id=""
                        onChange={(e) => setAnlcaImage(e.target.files[0])}
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>ANLCA Value</Typography>
                      <FormFields
                        name="numberformat"
                        id="formatted-numberformat-input"
                        placeholder="Enter Amount"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        // name=""
                        onChange={(e) => setAnlcaValue(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid> */}
                  {/* )} */}
                  {/* {!nafdacCert && ( */}
                  {/*<Grid
                    container
                    component="form"
                    onSubmit={nafdacInfo}
                    spacing={4}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>
                        NAFDAC Certificate
                      </Typography>
                      <input
                        type="file"
                        name=""
                        id=""
                        onChange={(e) => setNafdacCertImage(e.target.files[0])}
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        NAFDAC Cert Value
                      </Typography>
                      <FormFields
                        name="numberformat"
                        id="formatted-numberformat-input"
                        placeholder="Enter Amount"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        // name=""
                        onChange={(e) => setNafdacCertValue(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid>*/}
                  {/* <Grid
                    container
                    component="form"
                    onSubmit={ccvoInfo}
                    spacing={4}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>
                        CCVO Form C16
                      </Typography>
                      <input
                        type="file"
                        name=""
                        id=""
                        onChange={(e) => setCcvoFormC16Iamge(e.target.files[0])}
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Date Received
                      </Typography>
                      <input
                        type="date"
                        onChange={(e) => setCcvoFormC16Date(e.target.value)}
                        value={ccvoFormC16Date}
                        disabled
                        className="border border-gray-300 text-gray-900 text-base 
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        CCVO Form C16 Value
                      </Typography>
                      <FormFields
                        name="numberformat"
                        id="formatted-numberformat-input"
                        placeholder="Enter Amount"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) => setCcvoFormC16Value(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid> */}
                  {/* )} */}
                  {/* {!terminalInvoice && ( */}

                  {/* )} */}
                  {/* {!shippingInvoice && ( */}

                  {/* )} */}
                </Grid>
              </div>
            </Grid>
          </div>
        </Paper>
        {success && <PopSuccess message={success} />}
      </React.Fragment>
    </DashboardLayout>
  );
}
