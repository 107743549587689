import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Autocomplete,
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import FormFields from 'layouts/pages/account/components/FormField'
import React from 'react'
// import selectData from './data'
import MDButton from 'components/MDButton'
import { format } from 'date-fns'

//Modal
import Modal from './Modal'

//Hooks
import {useKingsooService} from '../../../../hooks/useKingsooServices'
import { useCollection } from 'hooks/useCollection'

const PreAlertExport = () => {
  const [fileId, setFileId] = useState('')
  const [companyId, setCompanyId] = useState('')
  const [consigneeId, setConsigneeId] = useState('')
  const [pickup, setPickup] = useState('')
  const [pickupAddress, setPickupAddress] = useState('')
  const [destination, setDestination] = useState('')
  const [packages, setPackages] = useState('')
  const [totalWeight, setTotalWeight] = useState('')
  const [descriptionOfGoods, setDescriptionOfGoods] = useState('')
  const [reset, setReset] = useState('')
  const [preAlertstatus] = useState('IN PROGRESS')
  const [portOfDischarge, setPortOfDischarge] = useState('')
  const [quantity, setQuantity] = useState('')
  const [jobCreatedDate] = useState(
    format(new Date(), "MM/dd/yyyy 'at' h:mm a")
  )
  const [jobId, setJobId] = useState('')

  let navigate = useNavigate()
  const {airWayBill} = useKingsooService()

  const { id } = useParams()
  const { documents } = useCollection('clients')
  const { documents: datas } = useCollection('consignee')
  // const { documents: jobs } = useCollection('jobManager')
  const { documents: cd } = useCollection('jobs')

  const airwaybill = async (e) => {
    e.preventDefault()
    try {
      await airWayBill(id, {
        fileId,
        companyId,
        pickup,
        consigneeId,
        pickupAddress,
        quantity,
        portOfDischarge,
        destination,
        packages,
        totalWeight,
        descriptionOfGoods,
        preAlertstatus,
        jobCreatedDate,
      })
      setReset(true)
      if (reset) {
        e.target.reset()
      }
    } catch (error) {
      console.log(error.message)
    }
  }
  if (reset) {
    navigate('/monitoring-compliance/job-management/export-job', {
      replace: true,
    })
  }

  const date = format(new Date(), 'yyyyMMdd')
  const code = cd.filter(
    (data) =>
      data.preAlertstatus !== 'PENDING' &&
      data.preAlertstatus !== 'TERMINATED' 

  )
    const payment = code.map(function (cd) {
       return parseInt(cd.fileId?.slice(-4));
  });

  const values = Object.values(payment);
  // const sliced = parsed.slice(-4)
  let biggest = values[0];

  for (var i = 0; i < values.length; i++) {
    if (biggest < values[i]) {
      biggest = values[i];
    }
  }
  console.log(biggest);
  useEffect(() => {
    if (cd) {
      setFileId(`KGM/${date}/0${biggest + 1}`)
     
    }
  }, [cd, date, code])

  const getJobId = (id) => {
    // console.log('the id', id)
    setJobId(id)
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="mt-4">
        <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
          <Box component="form" onSubmit={airwaybill}>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ borderBottom: 1, pb: 1, pt: 4 }}
            >
              Export Form
            </Typography>
            <Box mt={2}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <Typography fontSize={16} marginBottom={1}>
                    Select Client
                  </Typography>
                  <Autocomplete
                    id="import-type"
                    options={documents.map((document) => document.clientName)}
                    onChange={(e, newValue) => {
                      setCompanyId(newValue)
                    }}
                    renderInput={(params) => (
                      <FormFields
                        {...params}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontSize={16} marginBottom={1}>
                    Select Consignee
                  </Typography>
                  <Autocomplete
                    id="import-type"
                    options={datas.map((data) => data.consigneeName)}
                    onChange={(e, newValue) => {
                      setConsigneeId(newValue)
                    }}
                    renderInput={(params) => (
                      <FormFields
                        {...params}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontSize={16} marginBottom={1}>
                    Port Of Discharge
                  </Typography>
                  <TextField
                    fullWidth
                    onChange={(e) => setPortOfDischarge(e.target.value)}
                    value={portOfDischarge}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontSize={16} marginBottom={1}>
                    Destination Country
                  </Typography>
                  <TextField
                    id="no-of-packages"
                    fullWidth
                    onChange={(e) => setDestination(e.target.value)}
                    value={destination}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontSize={16} marginBottom={1}>
                    Pickup Contact
                  </Typography>
                  <TextField
                    id="no-of-packages"
                    fullWidth
                    onChange={(e) => setPickup(e.target.value)}
                    value={pickup}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontSize={16} marginBottom={1}>
                    Pickup Address
                  </Typography>
                  <TextField
                    id="no-of-packages"
                    fullWidth
                    onChange={(e) => setPickupAddress(e.target.value)}
                    value={pickupAddress}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontSize={16} marginBottom={1}>
                    Package Reference
                  </Typography>
                  <TextField
                    id="no-of-packages"
                    fullWidth
                    onChange={(e) => setPackages(e.target.value)}
                    value={packages}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontSize={16} marginBottom={1}>
                    Total weight of package (kg)
                  </Typography>
                  <TextField
                    id="total-weight"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setTotalWeight(e.target.value)}
                    value={totalWeight}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontSize={16} marginBottom={1}>
                    Quantity
                  </Typography>
                  <TextField
                    id="total-weight"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setQuantity(e.target.value)}
                    value={quantity}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize={16} marginBottom={1}>
                    Description of Goods
                  </Typography>
                  <TextField
                    id="description-of-goods"
                    fullWidth
                    onChange={(e) => setDescriptionOfGoods(e.target.value)}
                    value={descriptionOfGoods}
                    multiline
                    rows={6}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                pb: 1,
                pt: 4,
                pl: 3,
                pr: 3,
                mt: 2,
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '2rem',
              }}
            >
              <MDButton variant="gradient" color="dark" type="submit">
                Create
              </MDButton>
            </Box>
          </Box>
          <button
            className="absolute bg-transparent mr-2 right-30 bottom-20"
            onClick={(e) => getJobId(id)}
          >
            <Modal id={jobId} setJobId={setJobId} />
          </button>
        </Paper>
      </div>
    </DashboardLayout>
  )
}

export default PreAlertExport
