import { EyeIcon } from '@heroicons/react/solid'
import { Paper, Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import {useKingsooService} from '../../../hooks/useKingsooServices'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const PendingExportModal = () => {
  const [fileId, setFileId] = useState('')
  const [companyId, setCompanyId] = useState('')
  const [consigneeId, setConsigneeId] = useState('')
  const [pickup, setPickup] = useState('')
  const [pickupAddress, setPickupAddress] = useState('')
  const [destination, setDestination] = useState('')
  const [packages, setPackages] = useState('')
  const [totalWeight, setTotalWeight] = useState('')
  const [descriptionOfGoods, setDescriptionOfGoods] = useState('')
  const [preAlertstatus, setPreAlertStatus] = useState('')
  const [portOfDischarge, setPortOfDischarge] = useState('')
  const [quantity, setQuantity] = useState('')
  const [jobCreatedDate, setJobCreatedDate] = useState('')
  const [billOfLaden, setBillOfLaden] = useState('')
  const [exportBill, setExportBill] = useState('')
  const [preALertImage, setPreAlertImage] = useState('')
  const [paarImage, setPaarImage] = useState('')
  const [paarValue, setPaarValue] = useState('')
  const [commercialInvoiceImage, setCommercialInvoiceImage] = useState('')
  const [commercialInvoiceValue, setCommercialInvoiceValue] = useState('')
  const [customDutyReceiptImage, setCustomDutyReceiptImage] = useState('')
  const [customDutyReceiptValue, setCustomDutyReceiptValue] = useState('')
  const [workOrder, setWorkOrder] = useState('')
  const [workOrderValue, setWorkOrderValue] = useState('')
  const [accessment, setAccessment] = useState('')
  const [accessmentValue, setAccessmentValue] = useState('')
  const [exAgentDocPro, setExAgentDocPro] = useState('')
  const [exJobRted, setExJobRated] = useState('')
  const [exShipTermPayment, setExShipTermPayment] = useState('')
  const [exVesselArrival, setExVesselArrival] = useState('')
  const [exConsignmentTransit, setExConsignmentTransit] = useState('')
  const [exFinalDestination, setExFinalDestination] = useState('')
  const [exApplicationLetter, setExApplication] = useState('')
  const [exExaminedConsignment, setExExaminedConsignment] = useState('')
  const [exCustomRelease, setExCustomRelease] = useState('')
  const [exDispatchConsignComp, setExDispatchConsignComp] = useState('')
  const [exShipReturn, setExShipReturn] = useState('')
  const {getJob} = useKingsooService()

  const { id } = useParams()

  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await getJob(id)
        setBillOfLaden(docSnap.data().billOfLaden)
        setBillOfLaden(docSnap.data().airWayBill)
        setCompanyId(docSnap.data().companyId)
        setConsigneeId(docSnap.data().consigneeId)
        setPreAlertStatus(docSnap.data().preAlertstatus)
        setFileId(docSnap.data().fileId)
        setJobCreatedDate(docSnap.data().jobCreatedDate)
        setPackages(docSnap.data().packages)
        setTotalWeight(docSnap.data().totalWeight)
        setDescriptionOfGoods(docSnap.data().descriptionOfGoods)
        setPreAlertImage(docSnap.data().preALertImage)
        setPaarImage(docSnap.data().paarImage)
        setPaarValue(docSnap.data().paarValue)
        setCommercialInvoiceImage(docSnap.data().commercialInvoiceImage)
        setCommercialInvoiceValue(docSnap.data().commercialInvoiceValue)
        setPickup(docSnap.data().pickup)
        setPickupAddress(docSnap.data().pickupAddress)
        setDestination(docSnap.data().destination)
        setPortOfDischarge(docSnap.data().portOfDischarge)
        setQuantity(docSnap.data().quantity)
        setExportBill(docSnap.data().exportBill)
        setCustomDutyReceiptImage(docSnap.data().customDutyReceiptImage)
        setCustomDutyReceiptValue(docSnap.data().customDutyReceiptValue)
        setWorkOrder(docSnap.data().workOrder)
        setWorkOrderValue(docSnap.data().workOrderValue)
        setAccessment(docSnap.data().accessment)
        setAccessmentValue(docSnap.data().accessmentValue)
        setExAgentDocPro(docSnap.data().exAgentDocPro)
        setExJobRated(docSnap.data().exJobRted)
        setExShipTermPayment(docSnap.data().exShipTermPayment)
        setExVesselArrival(docSnap.data().exVesselArrival)
        setExConsignmentTransit(docSnap.data().exConsignmentTransit)
        setExFinalDestination(docSnap.data().exFinalDestination)
        setExApplication(docSnap.data().exApplicationLetter)
        setExExaminedConsignment(docSnap.data().exExaminedConsignment)
        setExCustomRelease(docSnap.data().exCustomRelease)
        setExDispatchConsignComp(docSnap.data().exDispatchConsignComp)
        setExShipReturn(docSnap.data().exShipReturn)

        // setContainerInfo(docSnap.data().containerInfo)
      } catch (error) {
        //console.log(error)
      }
    }
    // console.log('na u know', id)
    if (id !== undefined || id !== '') {
      detail()
      // console.log('id', id)
    }
  }, [id])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
        <MDBox
          className="md:flex-row"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
          container
          spacing={2}
        >
          <MDBox>
            <MDTypography
              variant="h5"
              fontWeight="bold"
              sx={{ borderBottom: 1, pb: 1, pt: 4 }}
            >
              JOB FILE - {companyId}
            </MDTypography>
          </MDBox>
          <Grid container mb={4} sx={{ ml: 2, mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>FILE ID:</b> {fileId}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Client:</b> {companyId}
                </li>
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>CONSIGNEE ID:</b> {consigneeId}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  {' '}
                  <b>Bill Of Lading:</b> {billOfLaden}
                </li>
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>JOB STATUS:</b> {preAlertstatus}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>No Of Packages:</b> {packages}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Total Weight Of Package:</b> {totalWeight}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Description Of Goods:</b> {descriptionOfGoods}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Pickup Contact:</b> {pickup}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Pickup Address:</b> {pickupAddress}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Destination Country:</b> {destination}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Relevant documents given to Field Officer:</b>{' '}
                  {exAgentDocPro ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Job Has been Rated:</b> {exJobRted ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Payment Of Shipping and Terminal:</b>{' '}
                  {exShipTermPayment ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Arrival of Vessel Confirmed:</b>{' '}
                  {exVesselArrival ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Consignment on Transit:</b>{' '}
                  {exConsignmentTransit ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Consignment has reached its final destination:</b>{' '}
                  {exFinalDestination ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Have You Received Application-To_Export letter:</b>{' '}
                  {exApplicationLetter ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Has Consignment Been Examined:</b>{' '}
                  {exExaminedConsignment ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Has Consignment Been examined:</b>{' '}
                  {exExaminedConsignment ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Consignment Released By Custom:</b>{' '}
                  {exCustomRelease ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Consignment Dispatch Completed:</b>{' '}
                  {exDispatchConsignComp ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Shipment is repair and Return:</b>{' '}
                  {exShipReturn ? 'Yes' : 'No'}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Port Of Discharge</b> {portOfDischarge}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Quantity:</b> {quantity}
                </li>
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>DATE & TIME OF JOB CREATION:</b> {jobCreatedDate}
                </li>
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Commercial Invoice Value:</b> {commercialInvoiceValue}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Duty Value:</b> {customDutyReceiptValue}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Work Order Value:</b> {workOrderValue}
                </li>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Assessment Value:</b> {accessmentValue}
                </li>
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                <li>
                  <b>Paar Value:</b> {paarValue}
                </li>
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={6}>
              {preALertImage && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>AIR WAY BILL:</b>
                    </li>
                  </MDTypography>
                  <a
                    href={preALertImage}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-4"
                  >
                    <button
                      type="button"
                      className="relative inline-flex darkGreen  items-center  px-4 py-2 rounded-md border border-gray-300  text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <EyeIcon
                        className="mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      View document
                    </button>
                  </a>
                </MDBox>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              {commercialInvoiceImage && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>COMMERCIAL INVOICE:</b>
                    </li>
                  </MDTypography>
                  <a
                    href={commercialInvoiceImage}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-4"
                  >
                    <button
                      type="button"
                      className="relative inline-flex darkGreen  items-center  px-4 py-2 rounded-md border border-gray-300  text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <EyeIcon
                        className="mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      View document
                    </button>
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {exportBill && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>AGENCY RECEIPT:</b>
                    </li>
                  </MDTypography>
                  <a
                    href={exportBill}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-4"
                  >
                    <button
                      type="button"
                      className="relative inline-flex darkGreen  items-center  px-4 py-2 rounded-md border border-gray-300  text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <EyeIcon
                        className="mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      View document
                    </button>
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {customDutyReceiptImage && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>Duty:</b>
                    </li>
                  </MDTypography>
                  <a
                    href={customDutyReceiptImage}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-4"
                  >
                    <button
                      type="button"
                      className="relative inline-flex darkGreen  items-center  px-4 py-2 rounded-md border border-gray-300  text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <EyeIcon
                        className="mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      View document
                    </button>
                  </a>
                </MDBox>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              {paarImage && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>PAAR IMAGE:</b>
                    </li>
                  </MDTypography>
                  <a
                    href={paarImage}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-4"
                  >
                    <button
                      type="button"
                      className="relative inline-flex darkGreen  items-center  px-4 py-2 rounded-md border border-gray-300  text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <EyeIcon
                        className="mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      View document
                    </button>
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {workOrder && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>Work Order:</b>
                    </li>
                  </MDTypography>
                  <a
                    href={workOrder}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-4"
                  >
                    <button
                      type="button"
                      className="relative inline-flex darkGreen  items-center  px-4 py-2 rounded-md border border-gray-300  text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <EyeIcon
                        className="mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      View document
                    </button>
                  </a>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {accessment && (
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <MDTypography sx={{ fontSize: '15px' }} mt={0.5} gutterBottom>
                    <li>
                      <b>Assessment:</b>
                    </li>
                  </MDTypography>
                  <a
                    href={accessment}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-4"
                  >
                    <button
                      type="button"
                      className="relative inline-flex darkGreen  items-center  px-4 py-2 rounded-md border border-gray-300  text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <EyeIcon
                        className="mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      View document
                    </button>
                  </a>
                </MDBox>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </Paper>
    </DashboardLayout>
  )
}

export default PendingExportModal
