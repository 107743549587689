import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import React from "react";
import {
  Paper,
  CircularProgress,
  Grid,
  Autocomplete,
  TextField
} from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormFields from "layouts/pages/account/components/FormField";
import { selectData } from "./data";
import { format } from "date-fns";
import PropTypes from "prop-types";

// Firebase Hooks
import {useKingsooService} from "../../../hooks/useKingsooServices";
import { useCollection } from "hooks/useCollection";
import { useAuthContext } from "../../../hooks/useAuthContext";

import NumberFormat from "react-number-format";
import PopSuccess from "views/PopMessage/PopSuccess";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="₦"
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const JobRelated = () => {
  const [isPending, setIsPending] = useState(false);
  const [reset, setReset] = useState(false);
  const [billOfLaden, setBillOfLaden] = useState("");
  const [fileId, setFileId] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [consigneeId, setConsigneeId] = useState("");
  const [clientId, setClientId] = useState("");
  const [containerInfo, setContainerInfo] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [bulkCargo, setBulkCargo] = useState("");
  const [request, setRequest] = useState("");
  const [paymentValue, setPaymentValue] = useState("");
  const [quantity, setQuantity] = useState("");
  const [amount, setAmount] = useState("");
  // const [requester, setRequester] = useState('')
  const [file, setFile] = useState("");
  const [description, setDescription] = useState("");
  const [date] = useState(format(new Date(), "MM/dd/yyyy 'at' h:mm a"));
  const [requestId, setRequestId] = useState("");
  const [status, setStatus] = useState("COO");

  const { documents } = useCollection("jobRelatedForm");
  const { user } = useAuthContext();

  const { id } = useParams();
  const navigate = useNavigate();
  const payment = documents.map(function(amount) {
    return parseInt(amount.requestId.slice(-4));
  });

  const values = Object.values(payment);
  // const sliced = parsed.slice(-4)
  let biggest = values[0];

  for (var i = 0; i < values.length; i++) {
    if (biggest < values[i]) {
      biggest = values[i];
    }
  }

  const {getJob, addFinance} = useKingsooService()

  useEffect(
    () => {
      const detail = async () => {
        try {
          const docSnap = await getJob(id);
          setBillOfLaden(docSnap.data().billOfLaden);
          setBillOfLaden(docSnap.data().airWayBill);
          setFileId(docSnap.data().fileId);
          setConsigneeId(docSnap.data().consigneeId);
          setClientId(docSnap.data().companyId);
          setContainerInfo(docSnap.data().containerInfo.length);
        } catch (error) {
          //console.log()
        }
      };
      // console.log('na u know', id)
      if ((id !== undefined || id !== "") && id !== ":id") {
        detail();
        // console.log('id', id)
      }
    },
    [id]
  );

  const finDate = format(new Date(), "yyyyMMdd");

  useEffect(
    () => {
      if (documents) {
        setRequestId(`FJR/${finDate}/0${biggest + 1}`);
      }
      if (user.photoURL === "Admin Staff") {
        setStatus("Pending");
      } else if (user.photoURL === "Transport & Logistics") {
        setStatus("Pending");
      } else {
        setStatus("COO");
      }
    },
    [documents, date, user, finDate]
  );

  const total = quantity * amount;

  const handleSubmit = async e => {
    e.preventDefault();
    setIsPending(true);
    const email = user.email;
    const department = user.photoURL;

    try {
      await addFinance({
        billOfLaden,
        fileId,
        consigneeId,
        clientId,
        containerInfo,
        paymentValue,
        paymentType,
        invoiceNo,
        bulkCargo,
        request,
        email,
        department,
        file,
        description,
        date,
        requestId,
        status,
        quantity,
        amount
      });
      setReset(true);
      if (reset) {
        e.target.reset();
      }
    } catch (error) {
      //console.log()
    }
  };

  useEffect(
    () => {
      if (reset) {
        setTimeout(() => {
          navigate(
            "/head-of-department-safety/financial-request/pending-request",
            {
              replace: true
            }
          );
        }, 1000);
      }
    },
    [reset, navigate]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
        <MDBox mt={5} component="form" onSubmit={handleSubmit}>
          <MDTypography
            variant="h5"
            fontWeight="bold"
            sx={{ borderBottom: 1, pb: 1, pt: 4 }}>
            Job Related Financial Request Form
          </MDTypography>
          <MDBox mt={1.625}>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Select Payment Type{" "}
                </MDTypography>
                <MDBox>
                  <Autocomplete
                    placeholder="Select Payment Type"
                    options={selectData.paymentType}
                    value={paymentType}
                    required
                    onChange={(e, newValue) => setPaymentType(newValue)}
                    renderInput={params =>
                      <FormFields
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        required={paymentType.length === 0}
                      />}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Job File ID </MDTypography>
                <MDBox>
                  <FormFields
                    name="fileId"
                    placeholder="Job File Id"
                    value={fileId}
                    onChange={e => setFileId(e.target.value)}
                    required
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Bill of Lading/Master Airway Bill{" "}
                </MDTypography>
                <FormFields
                  name="BOL"
                  placeholder="Enter BOL"
                  value={billOfLaden}
                  onChange={e => setBillOfLaden(e.target.value)}
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Consignee </MDTypography>
                <FormFields
                  name="Consignee"
                  placeholder="Auto Generated"
                  value={consigneeId}
                  onChange={e => setConsigneeId(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Client </MDTypography>
                <MDBox>
                  <FormFields
                    name="Client"
                    placeholder="Auto Generated"
                    value={clientId}
                    onChange={e => setClientId(e.target.value)}
                    required
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Number of containers{" "}
                </MDTypography>
                <FormFields
                  name="Containers"
                  placeholder="Auto Generated"
                  value={containerInfo}
                  onChange={e => setContainerInfo(e.target.value)}
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Invoice Number{" "}
                </MDTypography>
                <MDBox>
                  <FormFields
                    name="InvoiceNumber"
                    placeholder="Enter Invoice Number"
                    onChange={e => setInvoiceNo(e.target.value)}
                    required
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Bulk Cargo </MDTypography>
                <MDBox>
                  <FormFields
                    name="BulkCargo"
                    placeholder="Enter Bulk Cargo"
                    onChange={e => setBulkCargo(e.target.value)}
                    required
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Request For </MDTypography>
                <FormFields
                  name="RequestFor"
                  placeholder="Select Request For"
                  onChange={e => setRequest(e.target.value)}
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Quantity </MDTypography>
                <TextField
                  type="number"
                  onChange={e => setQuantity(e.target.value)}
                  required
                  value={quantity}
                  fullWidth
                  placeholder="Enter Quantity"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Amount </MDTypography>
                <FormFields
                  name="numberformat"
                  id="formatted-numberformat-input"
                  placeholder="Enter Payment Value"
                  required
                  InputProps={{
                    inputComponent: NumberFormatCustom
                  }}
                  variant="outlined"
                  onChange={e => setAmount(e.target.value)}
                  value={amount}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Payment Value{" "}
                </MDTypography>
                <MDBox>
                  <FormFields
                    name="numberformat"
                    id="formatted-numberformat-input"
                    placeholder="Enter Payment Value"
                    InputProps={{
                      inputComponent: NumberFormatCustom
                    }}
                    variant="outlined"
                    onChange={e => setPaymentValue(e.target.value)}
                    value={total}
                    disabled
                  />
                </MDBox>
              </Grid>
              {/* <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Requester </MDTypography>
                <MDBox>
                  <FormFields
                    name="ProFormaInvoice"
                    placeholder="Requester"
                    onChange={(e) => setRequester(e.target.value)}
                  />
                </MDBox>
              </Grid> */}
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Upload Pro Forma Invoice{" "}
                </MDTypography>
                <input
                  type="file"
                  name="paarImage"
                  className="block w-full text-sm text-gray-900 px-2 py-2.5
                  border border-gray-300 rounded-lg cursor-pointer dark:text-gray-400 
                  focus:outline-none dark:border-gray-600"
                  onChange={e => setFile(e.target.files[0])}
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12}>
                <MDTypography sx={{ fontSize: 13 }}>Description </MDTypography>
                <TextField
                  fullWidth
                  placeholder="Enter your description here..."
                  multiline
                  required
                  rows={4}
                  onChange={e => setDescription(e.target.value)}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "2rem"
            }}>
            {!isPending &&
              <MDButton
                style={{ fontSize: "12px" }}
                variant="gradient"
                color="dark"
                type="submit">
                Submit Request
              </MDButton>}
            {isPending &&
              <MDButton
                variant="gradient"
                color="dark"
                disabled
                sx={{ width: "180px" }}>
                <CircularProgress color="white" size={20} />
              </MDButton>}
          </MDBox>
        </MDBox>
      </Paper>
      {reset && <PopSuccess />}
    </DashboardLayout>
  );
};

export default JobRelated;
