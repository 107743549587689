import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import { useCollection } from "hooks/useCollection";
import React from "react";
import { useState } from "react";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

export default function GateIn() {
  const { documents: jobs } = useCollection("jobs");
  const [responsive] = useState("horizontal");
  const [tableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight] = useState("");
  const [searchBtn] = useState(true);
  const [downloadBtn] = useState(true);
  const [printBtn] = useState(true);
  const [viewColumnBtn] = useState(true);
  const [filterBtn] = useState(true);
  const tp = jobs.filter(
    tps =>
      tps.agentStarted === true &&
      tps.preAlertstatus !== "COMPLETED" &&
      tps.preAlertstatus !== "TERMINATED" &&
      tps.freightType === "Transfer"
  );
  const iscrosseds = tp.map(job => job.containerInfo);
  const result2 = iscrosseds.flat(1);
  const green = result2.filter(
    el => el.isLoaded === true && el.gateStatus !== "Gate-In" && el.gateStatus !== "Gate-Out"
  );
console.log(green)
  const loadedContainer = [
    {
      name: "containerId",
      label: "Container ID",
      options: { filter: true, sort: true }
    },
    {
      name: "containerSize",
      label: "Container Size",
      options: { filter: true, sort: true }
    },
    {
      name: "id",
      hide: true,
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false
      }
    },
    // {
    //   name: "isLoaded",
    //   label: "Container Loaded",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRenderLite: dataIndex => {
    //       return green[dataIndex].isLoaded === true ? "Yes" : "NO";
    //     }
    //   }
    // },
    // {
    //   name: "gateStatus",
    //   label: "Loaded By",
    //   options: { filter: true, sort: true }
    // },
    {
      name: "loadedDate",
      label: "Date Loaded",
      options: { filter: true, sort: true }
    },
    {
      name: "categoryOfTruck",
      label: "Truck Category",
      options: { filter: true, sort: true }
    }
  ];
  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    textLabels: { body: { noMatch: "Sorry no records have been generated" } },
    sortOrder: { name: "firstName", direction: "asc" }
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 1,
              paddingTop: 1,
              fontSize: "2pt",
              borderBottom: "2px solid black",
              backgroundColor: "#033237"
            }
          },
          paddingBottom: 2,
          marginBottom: 8,
          paddingTop: 1,
          backgroundColor: "#033237"
        }
      }
    });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme}>
          <MUIDataTable
            data={green}
            columns={loadedContainer}
            options={options}
            style={{ marginTop: "10px", zIndex: "z-10" }}
          />
        </ThemeProvider>
      </CacheProvider>
    </DashboardLayout>
  );
}
