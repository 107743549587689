import React from "react";

import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { useEffect, useState } from "react";

import { useCollection } from "hooks/useCollection";
import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import { format, lastDayOfMonth } from "date-fns";
import { Fragment } from "react";
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Tab,
  Transition
} from "@headlessui/react";

import { Combobox } from "@headlessui/react";
import { CheckIcon, FolderOpenIcon } from "@heroicons/react/outline";

const tracker = [
  { id: 1, name: "Kalmar Expenses" },
  { id: 2, name: "Recruitment Process" },
  { id: 3, name: "Guest House (Rent)" },
  { id: 4, name: "Vehicles Maintenance" },
  { id: 5, name: "Staff Welfare/Community" },
  { id: 6, name: "Office Furniture" },
  { id: 7, name: "Water" },
  { id: 8, name: "Transport Local" },
  { id: 9, name: "Uniforms" },
  { id: 10, name: "Medical Bill" },
  { id: 11, name: "Utility Bills" },
  { id: 12, name: "Staff Training" },
  { id: 13, name: "Legal & Proffessional Fees" },
  { id: 14, name: "Security" },
  { id: 15, name: "Newspapers & Journals" },
  { id: 16, name: "Stationery" },
  { id: 17, name: "Cleaning Materials" },
  { id: 18, name: "Diesel & Fuel" },
  { id: 19, name: "Toiletries" }
  // More users...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const today = new Date();
const firstDateOfMonth = format(today, "MM/01/yyyy");
const lastDateOfMonth = format(lastDayOfMonth(today), "MM/dd/yyyy");

console.log(firstDateOfMonth);
const ExpenseTracker = () => {
  const [responsive] = useState("horizontal");
  const [tableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight] = useState("");
  const [searchBtn] = useState(true);
  const [downloadBtn] = useState(true);
  const [printBtn] = useState(true);
  const [viewColumnBtn] = useState(true);
  const [filterBtn] = useState(true);
  const muiCache = createCache({ key: "mui-datatables", prepend: true });
  const { documents } = useCollection("nonJobRelated");
  const [openTab, setOpenTab] = React.useState(1);
  const [query, setQuery] = useState("");

  const datas = documents.filter(
    data =>
      data.status !== "Declined" &&
      data.header &&
      (data.department === "Admin & Human Resource" ||
        data.department === "Admin Staff")
  );

  const kalmar = datas.filter(
    el =>
      el.header === "Kalmar Expenses" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );
  const df = datas.filter(
    el =>
      el.header === "Diesel & Fuel" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );
  const rp = datas.filter(
    el =>
      el.header === "Recruitment Process" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );
  const gh = datas.filter(
    el =>
      el.header === "Guest House (Rent)" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );
  const vm = datas.filter(
    el =>
      el.header === "Vehicles Maintenance" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );
  const sw = datas.filter(
    el =>
      el.header === "Staff Welfare/Community" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );
  const of = datas.filter(
    el =>
      el.header === "Office Furniture" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );
  const h20 = datas.filter(
    el =>
      el.header === "Water" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );
  const tl = datas.filter(
    el =>
      el.header === "Transport Local" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );
  const uniforms = datas.filter(
    el =>
      el.header === "Uniforms" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );
  const medicalB = datas.filter(
    el =>
      el.header === "Medical Bill" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );
  const UtilityB = datas.filter(
    el =>
      el.header === "Utility Bills" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );
  const st = datas.filter(
    el =>
      el.header === "Staff Training" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );
  const legal = datas.filter(
    el =>
      el.header === "Legal & Proffessional Fees" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );
  const sct = datas.filter(
    el =>
      el.header === "Security" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );
  const nj = datas.filter(
    el =>
      el.header === "Newspapers & Journals" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );
  const sn = datas.filter(
    el =>
      el.header === "Stationery" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );
  const cm = datas.filter(
    el =>
      el.header === "Cleaning Materials" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );
  const toilet = datas.filter(
    el =>
      el.header === "Toiletries" &&
      el.date >= firstDateOfMonth &&
      el.date <= lastDateOfMonth
  );

  function changeFunc() {}

  //   const x = kalmar.map(el => el.date);
  //   useEffect(() => {
  //     hanldeChange()
  //   })

  //   const hanldeChange = () => {
  //     if (x >= firstDateOfMonth && x <= lastDateOfMonth) {
  //       // something
  //       return console.log(x);
  //     } else {
  //       console.log("NOT WORKING");
  //     }
  // }

  const payment = kalmar.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const values = Object.values(payment);
  const sum = values.reduce((sum, value) => {
    return sum + value;
  }, 0);

  let ngn = Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN"
  });
  const diesel = df.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const dieselValues = Object.values(diesel);
  const dieselSum = dieselValues.reduce((sum, value) => {
    return sum + value;
  }, 0);

  const recruit = rp.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const recruitValues = Object.values(recruit);
  const recruitSum = recruitValues.reduce((sum, value) => {
    return sum + value;
  }, 0);
  const guest = gh.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const guestValues = Object.values(guest);
  const guestSum = guestValues.reduce((sum, value) => {
    return sum + value;
  }, 0);
  const vehicles = vm.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const vehiclesValues = Object.values(vehicles);
  const vehiclesSum = vehiclesValues.reduce((sum, value) => {
    return sum + value;
  }, 0);
  const staffWelfare = sw.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const staffValues = Object.values(staffWelfare);
  const staffSum = staffValues.reduce((sum, value) => {
    return sum + value;
  }, 0);
  const officeWelfare = of.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const officeValues = Object.values(officeWelfare);
  const officeSum = officeValues.reduce((sum, value) => {
    return sum + value;
  }, 0);

  const water = h20.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const waterValues = Object.values(water);
  const waterSum = waterValues.reduce((sum, value) => {
    return sum + value;
  }, 0);
  const transport = tl.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const transportValues = Object.values(transport);
  const transportSum = transportValues.reduce((sum, value) => {
    return sum + value;
  }, 0);
  const uniform = uniforms.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const uniformValues = Object.values(uniform);
  const uniformSum = uniformValues.reduce((sum, value) => {
    return sum + value;
  }, 0);

  const medical = medicalB.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const medicalValues = Object.values(medical);
  const medicalSum = medicalValues.reduce((sum, value) => {
    return sum + value;
  }, 0);
  const Utility = UtilityB.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const UtilityValues = Object.values(Utility);
  const UtilitySum = UtilityValues.reduce((sum, value) => {
    return sum + value;
  }, 0);
  const staffTraining = st.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const staffTraningValues = Object.values(staffTraining);
  const staffTraningSum = staffTraningValues.reduce((sum, value) => {
    return sum + value;
  }, 0);
  const legals = legal.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const legalValues = Object.values(legals);
  const legalSum = legalValues.reduce((sum, value) => {
    return sum + value;
  }, 0);
  const security = sct.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const securityValues = Object.values(security);
  const securitySum = securityValues.reduce((sum, value) => {
    return sum + value;
  }, 0);


  const news = nj.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const newsValues = Object.values(news);
  const newsSum = newsValues.reduce((sum, value) => {
    return sum + value;
  }, 0);


  const stationery = sn.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const stationeryValues = Object.values(stationery);
  const stationerySum = stationeryValues.reduce((sum, value) => {
    return sum + value;
  }, 0);

  const clean = cm.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const cleanValues = Object.values(clean);
  const cleaningSum = cleanValues.reduce((sum, value) => {
    return sum + value;
  }, 0);

  const toiletries = toilet.map(function(amount) {
    return parseInt(amount.paymentValue);
  });

  const toiletValues = Object.values(toilet);
  const toiletriesSum = toiletValues.reduce((sum, value) => {
    return sum + value;
  }, 0);

  // const test = documents.filter(el => el.header === dum)
  //   console.log(test);
  console.log(kalmar.date);
  const stats = [
    {
      id: 1,
      name: "Kalmar Expenses",
      stat: ngn.format(sum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    },
    {
      id: 2,
      name: "Diesel & Fuel",
      stat: ngn.format(dieselSum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    },
    {
      id: 3,
      name: "Recruitment Process",
      stat: ngn.format(recruitSum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    },
    {
      id: 4,
      name: "Guest House (Rent)",
      stat: ngn.format(guestSum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    },
    {
      id: 4,
      name: "Vehicle Maintenance",
      stat: ngn.format(vehiclesSum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    },
    {
      id: 5,
      name: "Staff Welfare/Community",
      stat: ngn.format(staffSum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    },
    {
      id: 6,
      name: "Office Furniture",
      stat: ngn.format(officeSum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    },
    {
      id: 7,
      name: "Water",
      stat: ngn.format(waterSum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    },
    {
      id: 7,
      name: "Transport Local",
      stat: ngn.format(transportSum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    },
    {
      id: 19,
      name: "Uniforms",
      stat: ngn.format(uniformSum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    },
    {
      id: 8,
      name: "Medical Bill",
      stat: ngn.format(medicalSum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    },
    {
      id: 9,
      name: "Utility Bills",
      stat: ngn.format(UtilitySum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    },
    {
      id: 10,
      name: "Staff Training",
      stat: ngn.format(staffTraningSum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    },
    {
      id: 11,
      name: "Legal & Proffessional Fees",
      stat: ngn.format(waterSum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    },
    {
      id: 12,
      name: "Security",
      stat: ngn.format(securitySum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    },
    {
      id: 13,
      name: "Newspaper & Journals",
      stat: ngn.format(newsSum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    },
    {
      id: 14,
      name: "Stationery",
      stat: ngn.format(stationerySum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    },
    {
      id: 15,
      name: "Cleaning Materials",
      stat: ngn.format(cleaningSum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    },
    {
      id: 16,
      name: "Toiletries",
      stat: ngn.format(toiletriesSum),
      icon: FolderOpenIcon,
      change: 0,
      changeType: "increase"
    }
  ];
  const columns = [
    {
      name: "requestId",
      label: "Request ID",
      options: { filter: true, sort: true }
    },
    {
      name: "id",
      label: "Id",
      hide: true,
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "paymentValue",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: dataIndex => {
          let val = kalmar[dataIndex].paymentValue;
          let ngn = Intl.NumberFormat("en-NG", {
            style: "currency",
            currency: "NGN"
          });
          return ngn.format(val);
        }
      }
    },
    {
      name: "email",
      label: "Requester",
      options: { filter: true, sort: true }
    },
    {
      name: "date",
      label: "Date & Time",
      options: { filter: true, sort: true }
    },
    {
      name: "description",
      label: "Description",
      options: { filter: true, sort: true }
    }, //   name: "request", // {
    //   label: "Request for",
    //   options: { filter: true, sort: true }
    // },
    { name: "header", label: "Header", options: { filter: true, sort: true } }
  ];
  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: "requestId",
      direction: "desc"
    }
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 1,
              paddingTop: 1,
              backgroundColor: "#033237"
            }
          }
        },

        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              "&:nth-child(odd)": {
                //backgroundColor: "#f6f6f6"
              },
              fontSize: "12px"
            }
          }
        }
      }
    });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {stats.map(item =>
            <div
              key={item.id}
              className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6">
              <dt>
                <div className="absolute rounded-md bg-[#033237] p-3">
                  <item.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">
                  {item.name}
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline ">
                <p className="text-2xl font-semibold text-gray-900">
                  {item.stat}
                </p>
              </dd>
            </div>
          )}
        </dl>
      </div>
      {/* <div className="py-10">
        <div className="max-w-xs">
          <select
            onChange={e => setOpenTab(3)}
            name="filter"
            className="mt-1 block w-full  bg-white rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue="Kalmar Expenses">
            {tracker.map(el =>
              <option value={el.id}>
                {el.name}
              </option>
            )}

          </select>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full">
            <ul
              className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
              role="tablist">
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 1
                      ? "text-white bg-green-600"
                      : "text-black" + "-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist">
                  Kalmar Expenses
                </a>
              </li>

              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 3
                      ? "text-white bg-green-600"
                      : "text-black" + "-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(3);
                  }}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist">
                  Recruitment Process
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 4
                      ? "text-white bg-green-600"
                      : "text-black" + "-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(4);
                  }}
                  data-toggle="tab"
                  href="#link4"
                  role="tablist">
                  Guest Home (Rent)
                </a>
              </li>
            </ul>
            <div className="relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-lg rounded">
              <div className="px-4 py-5 flex-auto">
                <div className>
                  <div
                    className={openTab === 1 ? "block" : "hidden"}
                    id="link1">
                    <CacheProvider value={muiCache}>
                      <ThemeProvider theme={getMuiTheme}>
                        <MUIDataTable
                          data={kalmar}
                          columns={columns}
                          options={options}
                        />
                      </ThemeProvider>
                    </CacheProvider>
                  </div>
                  <div
                    className={openTab === 2 ? "block" : "hidden"}
                    id="link2">
                    <CacheProvider value={muiCache}>
                      <ThemeProvider theme={getMuiTheme}>
                        <MUIDataTable
                          data={kalmar}
                          columns={columns}
                          options={options}
                        />
                      </ThemeProvider>
                    </CacheProvider>
                  </div>
                  <div
                    className={openTab === 3 ? "block" : "hidden"}
                    id="link3">
                    <CacheProvider value={muiCache}>
                      <ThemeProvider theme={getMuiTheme}>
                        <MUIDataTable
                          data={kalmar}
                          columns={columns}
                          options={options}
                        />
                      </ThemeProvider>
                    </CacheProvider>
                  </div>
                  <div
                    className={openTab === 4 ? "block" : "hidden"}
                    id="link4">
                    <CacheProvider value={muiCache}>
                      <ThemeProvider theme={getMuiTheme}>
                        <MUIDataTable
                          data={kalmar}
                          columns={columns}
                          options={options}
                        />
                      </ThemeProvider>
                    </CacheProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </DashboardLayout>
  );
};

export default ExpenseTracker;
