import React, { useRef } from 'react';
import { LocalPrintshop, Print } from '@mui/icons-material';
import { Box, Dialog, DialogActions, DialogContent, Grid, TextField } from '@mui/material'
import { useState } from 'react';
import logo from '../../../../assets/images/logo.png'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { useEffect } from 'react';
import {useKingsooService} from '../../../../hooks/useKingsooServices'
import { useReactToPrint } from 'react-to-print';


const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
    props,
    ref
) {
    const { onChange, ...other } = props

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                })
            }}
            thousandSeparator
            isNumericString
            prefix="₦"
        />
    )
})

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}

const PrintModal = ({ id }) => {
    const [open, setOpen] = useState(false);
    const [fullWidth] = useState(true);

    const [billOfLaden, setBillOfLaden] = useState('')
    const [fileId, setFileId] = useState('')
    const [paymentType, setPaymentType] = useState('')
    const [consigneeId, setConsigneeId] = useState('')
    const [clientId, setClientId] = useState('')
    const [containerInfo, setContainerInfo] = useState('')
    const [invoiceNo, setInvoiceNo] = useState('')
    const [bulkCargo, setBulkCargo] = useState('')
    const [request, setRequest] = useState('')
    const [paymentValue, setPaymentValue] = useState('')
    const [quantity, setQuantity] = useState('')
    const [email, setEmail] = useState('')
    const [description, setDescription] = useState('')
    const [date, setDate] = useState()
    const [requestId, setRequestId] = useState('')

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const {getFnj} = useKingsooService()

    useEffect(() => {
        const detail = async () => {
            try {
                const docSnap = await getFnj(id)

                setBillOfLaden(docSnap.data().billOfLaden)
                setFileId(docSnap.data().fileId)
                setPaymentType(docSnap.data().paymentType)
                setConsigneeId(docSnap.data().consigneeId)
                setClientId(docSnap.data().clientId)
                setContainerInfo(docSnap.data().containerInfo)
                setInvoiceNo(docSnap.data().invoiceNo)
                setBulkCargo(docSnap.data().bulkCargo)
                setRequest(docSnap.data().request)
                setPaymentValue(docSnap.data().paymentValue)
                setQuantity(docSnap.data().quantity)
                setDescription(docSnap.data().description)
                setDate(docSnap.data().date)
                setRequestId(docSnap.data().requestId)
                setEmail(docSnap.data().email)
            } catch (error) {
                // console.log(error)
            }
        }
        if (id !== undefined || id !== '') {
            detail()
        }
    }, [id])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <div
                className='text-emerald-900'
                onClick={handleClickOpen}
            >
                <LocalPrintshop
                    style={{
                        width: '20px',
                        height: '20px',
                        color: '#033237',
                    }}
                />
            </div>
            <Dialog
                fullWidth={fullWidth}
                maxWidth="md"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent
                    ref={componentRef}
                >
                    <Box>
                        <div className='flex flex-row'>
                            <img
                                className='h-20'
                                src={logo}
                                alt="logo"
                            />
                            <div>
                                <h1 className='text-teal-800 text-2xl font-bold'>Kingsoo Group</h1>
                                <p className='text-sm text-gray-800'>76B Trans-Amadi Industrial Layout Rd, <br /> Rumuola 500102, Port Harcourt</p>
                            </div>
                        </div>
                        <Grid container spacing={4} mt={6}>
                            <Grid item xs={4}>
                                <Box>
                                    <h1 className='text-base mb-2'>Payment Type</h1>
                                    <TextField
                                        disabled
                                        variant='standard'
                                        fullWidth
                                        value={paymentType}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <h1 className='text-base mb-2'>Job File ID</h1>
                                <TextField
                                    disabled
                                    variant='standard'
                                    fullWidth
                                    name="fileId"
                                    value={fileId}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <h1 className='text-base mb-2'>Bill Of Laden</h1>
                                <TextField
                                    disabled
                                    variant='standard'
                                    fullWidth
                                    name="BOL"
                                    value={billOfLaden}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <h1 className='text-base mb-2'>Consignee</h1>
                                <TextField
                                    disabled
                                    variant='standard'
                                    fullWidth
                                    name="Consignee"
                                    value={consigneeId}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <h1 className='text-base mb-2'>Client</h1>
                                <TextField
                                    disabled
                                    variant='standard'
                                    fullWidth
                                    name="Client"
                                    value={clientId}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <h1 className='text-base mb-2'>Container</h1>
                                <TextField
                                    disabled
                                    variant='standard'
                                    fullWidth
                                    name="Containers"
                                    value={containerInfo}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <h1 className='text-base mb-2'>Invoice Number</h1>
                                <TextField
                                    disabled
                                    variant='standard'
                                    fullWidth
                                    name="InvoiceNumber"
                                    value={invoiceNo}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <h1 className='text-base mb-2'>Bulk Cargo</h1>
                                <TextField
                                    disabled
                                    variant='standard'
                                    fullWidth
                                    name="BulkCargo"
                                    value={bulkCargo}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <h1 className='text-base mb-2'>Request For</h1>
                                <TextField
                                    disabled
                                    variant='standard'
                                    fullWidth
                                    name="RequestFor"
                                    value={request}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <h1 className='text-base mb-2'>Request ID</h1>
                                <TextField
                                    disabled
                                    variant='standard'
                                    fullWidth
                                    name="RequestFor"
                                    value={requestId}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <h1 className='text-base mb-2'>Requester</h1>
                                <TextField
                                    disabled
                                    variant='standard'
                                    fullWidth
                                    name="RequestFor"
                                    value={email}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <h1 className='text-base mb-2'>Date</h1>
                                <TextField
                                    disabled
                                    variant='standard'
                                    fullWidth
                                    name="RequestFor"
                                    value={date}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <h1 className='text-base mb-2'>Quantity</h1>
                                <TextField
                                    disabled
                                    variant='standard'
                                    type="number"
                                    value={quantity}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <h1 className='text-base mb-2'>Payment Value</h1>
                                <TextField
                                    disabled
                                    variant='standard'
                                    fullWidth
                                    name="numberformat"
                                    id="formatted-numberformat-input"
                                    value={paymentValue}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <h1 className='text-base mb-2'>Description</h1>
                                <TextField
                                    disabled
                                    variant='standard'
                                    fullWidth
                                    multiline
                                    value={description}
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <button
                        className='ring-1 ring-red-300 text-red-400 px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-1 focus:ring-red-500 focus:ring-offset-2 uppercase'
                        onClick={handleClose}
                    >
                        No, Close
                    </button>
                    <button
                        className='bg-teal-800 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-teal-600 focus:outline-none focus:ring-2 uppercase'
                        onClick={handlePrint}
                    >
                        <Print /> Print Form
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default PrintModal