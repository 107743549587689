import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { useCollection } from "hooks/useCollection";

ChartJS.register(ArcElement, Tooltip, Legend);

const FieldAgentReportChart = () => {
  const { documents: fieldAgents } = useCollection("jobs");
  const { documents: allJobs } = useCollection("jobs");
  const fieldreport = fieldAgents.filter(
    agent => agent.agentStarted === true && agent.preAlertstatus !== "COMPLETED"
  );
  const unprocessed = fieldAgents.filter(
    agent =>
      agent.agentStarted === false &&
      agent.preAlertstatus !== "COMPLETED" &&
      agent.preAlertstatus !== "TERMINATED"
  );
  // &&
  // agent.jobType !== "Export"
  const countAllJobs = allJobs.filter(
    alls =>
      alls.preAlertstatus === "IN PROGRESS" &&
      alls.preAlertstatus !== "COMPLETED" &&
      alls.preAlertstatus !== "TERMINATED"
  );

  const pending = unprocessed.length;
  const all = countAllJobs.length;
  const processed = fieldreport.length;

  const data = {
    labels: ["Total Jobs", "Processed Jobs", "Pending Jobs"],
    datasets: [
      {
        label: "# of Votes",
        data: [all, processed, pending],
        backgroundColor: ["#033237", "orange", "red"], //   borderColor: [
        //     "rgba(255, 99, 132, 1)",
        //     "rgba(54, 162, 235, 1)",
        //     "rgba(255, 206, 86, 1)",
        //     "rgba(75, 192, 192, 1)",
        //     "rgba(153, 102, 255, 1)",
        //     "rgba(255, 159, 64, 1)"
        //   ],
        borderWidth: 1
      }
    ]
  };
  return (
    <Box
      sx={{
        paddingTop: 2,
        marginLeft: "auto",
        marginRight: "auto",
        width: 500,
        alignContent: "center",
        justifyContent: "center"
      }}>
      <Pie data={data} />
    </Box>
  );
};

export default FieldAgentReportChart;
