import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Grid,
  Paper,
  TextField,
  CircularProgress
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import FormFields from "layouts/pages/account/components/FormField";
import React from "react";
import { selectData } from "./data";
import { format } from "date-fns";
import PropTypes from "prop-types";

//Firebase Hooks
import { useFirestore } from "hooks/useFirestore";
import { useCollection } from "hooks/useCollection";
import { useAuthContext } from "../../../hooks/useAuthContext";

import NumberFormat from "react-number-format";
import PopSuccess from "views/PopMessage/PopSuccess";
import {useKingsooService} from "../../../hooks/useKingsooServices";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="₦"
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const NonJobRelated = () => {
  const [reset, setReset] = useState(false);
  const [paymentValue, setPaymentValue] = useState("");
  const [request, setRequest] = useState("");
  const [quantity, setQuantity] = useState("");
  const [amount, setAmount] = useState("");
  // const [requester, setRequester] = useState('')
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");
  const [date] = useState(format(new Date(), "MM/dd/yyyy 'at' h:mm a"));
  const [requestId, setRequestId] = useState("");
  const [status, setStatus] = useState("COO");

  const { documents } = useCollection("nonJobRelated");
  const { user } = useAuthContext();

  const { isPending } = useFirestore("nonJobRelated");
  const navigate = useNavigate();

  const finDate = format(new Date(), "yyyyMMdd");
  const payment = documents.map(function(amount) {
    return parseInt(amount.requestId.slice(-4));
  });

  const values = Object.values(payment);
  // const sliced = parsed.slice(-4)
  let biggest = values[0];

  for (var i = 0; i < values.length; i++) {
    if (biggest < values[i]) {
      biggest = values[i];
    }
  }
  useEffect(
    () => {
      if (documents) {
        setRequestId(`FNJR/${finDate}/0${biggest + 1}`);
      }
      if (user.photoURL === "Admin Staff") {
        setStatus("Pending");
      } else if (user.photoURL === "Transport & Logistics") {
        setStatus("Pending");
      } else {
        setStatus("COO");
      }
    },
    [documents, finDate, user]
  );

  const {addFinance2} = useKingsooService()

  const total = quantity * amount;
  const handleSubmit = async e => {
    e.preventDefault();
    const email = user.email;
    const department = user.photoURL;

    try {
      await addFinance2({
        quantity,
        amount,
        paymentValue,
        request,
        description,
        date,
        email,
        department,
        requestId,
        status,
        file
      });
      setReset(true);
      if (reset) {
        e.target.reset();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(
    () => {
      if (reset) {
        setTimeout(() => {
          navigate(
            "/head-of-department-operations/financial-request/pending-request",
            {
              replace: true
            }
          );
        }, 5000);
      }
    },
    [reset, navigate]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
        <MDBox mt={5} component="form" onSubmit={handleSubmit}>
          <MDTypography
            variant="h5"
            fontWeight="bold"
            sx={{ borderBottom: 1, pb: 1, pt: 4 }}>
            Non Job Related Financial Request Form
          </MDTypography>
          <MDBox mt={1.625}>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Quantity </MDTypography>
                <TextField
                  type="number"
                  onChange={e => setQuantity(e.target.value)}
                  required
                  value={quantity}
                  fullWidth
                  placeholder="Enter Quantity"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Amount </MDTypography>
                <FormFields
                  name="numberformat"
                  id="formatted-numberformat-input"
                  placeholder="Enter Payment Value"
                  required
                  InputProps={{
                    inputComponent: NumberFormatCustom
                  }}
                  variant="outlined"
                  onChange={e => setAmount(e.target.value)}
                  value={amount}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Payment Value{" "}
                </MDTypography>
                <FormFields
                  name="numberformat"
                  id="formatted-numberformat-input"
                  placeholder="Enter Payment Value"
                  disabled
                  InputProps={{
                    inputComponent: NumberFormatCustom
                  }}
                  variant="outlined"
                  value={total}
                  onChange={e => setPaymentValue(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Request For </MDTypography>
                <MDBox>
                  <Autocomplete
                    value={request}
                    options={selectData.paymentType}
                    onChange={(e, newValue) => setRequest(newValue)}
                    renderInput={params =>
                      <FormFields
                        {...params}
                        InputLabelProps={{ shrink: true, require: true }}
                        required={request.length === 0}
                      />}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Upload Pro Forma Invoice{" "}
                </MDTypography>
                <input
                  type="file"
                  name="paarImage"
                  className="block w-full text-sm text-gray-900 px-2 py-2.5
                  border border-gray-300 rounded-lg cursor-pointer dark:text-gray-400 
                  focus:outline-none dark:border-gray-600"
                  onChange={e => setFile(e.target.files[0])}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12}>
                <MDTypography sx={{ fontSize: 13 }}>Description </MDTypography>
                <TextField
                  required
                  fullWidth
                  placeholder="Enter your description here..."
                  multiline
                  rows={4}
                  onChange={e => setDescription(e.target.value)}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "2rem"
            }}>
            {!isPending &&
              <MDButton
                style={{ fontSize: "12px" }}
                variant="gradient"
                color="dark"
                type="submit">
                Submit Request
              </MDButton>}
            {isPending &&
              <MDButton
                variant="gradient"
                color="dark"
                disabled
                sx={{ width: "180px" }}>
                <CircularProgress color="white" size={20} />
              </MDButton>}
          </MDBox>
        </MDBox>
      </Paper>
      {reset && <PopSuccess />}
    </DashboardLayout>
  );
};

export default NonJobRelated;
