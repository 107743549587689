import { useNavigate } from 'react-router-dom'

// @mui material components
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'

import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'

import ComplexStatisticsCard from 'customs/Cards/StatisticsCards/ComplexStatisticsCard'

//Firebase Hooks
import { useCollection } from '../../../hooks/useCollection'

function Home() {
  let navigate = useNavigate()

  const { documents: njr } = useCollection('nonJobRelated')
  const { documents: jr } = useCollection('jobRelatedForm')

  const approved1 = njr.filter(
    (ap) =>
      ap.status === 'Approved' &&
      (ap.department === 'Transport & Logistics' ||
        ap.department === 'Transport & Logistics(H.O.D)')
  )
  const approved2 = jr.filter(
    (ap) =>
      ap.status === 'Approved' &&
      (ap.department === 'Transport & Logistics' ||
        ap.department === 'Transport & Logistics(H.O.D)')
  )
  const pending1 = jr.filter(
    (ap) =>
      ap.status !== 'Approved' &&
      ap.status !== 'Declined' &&
      (ap.department === 'Transport & Logistics' ||
        ap.department === 'Transport & Logistics(H.O.D)')
  )
  const pending2 = njr.filter(
    (ap) =>
      ap.status !== 'Approved' &&
      ap.status !== 'Declined' &&
      (ap.department === 'Transport & Logistics' ||
        ap.department === 'Transport & Logistics(H.O.D)')
  )
  const declined1 = njr.filter(
    (ap) =>
      ap.status === 'Declined' &&
      (ap.department === 'Transport & Logistics' ||
        ap.department === 'Transport & Logistics(H.O.D)')
  )
  const declined2 = jr.filter(
    (ap) =>
      ap.status === 'Declined' &&
      (ap.department === 'Transport & Logistics' ||
        ap.department === 'Transport & Logistics(H.O.D)')
  )

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  '/head-of-department-transport/request-status/approved',
                  {
                    replace: true,
                  }
                )
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="check_circle_outline"
                  title="Approved Requests"
                  users={approved1.length + approved2.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  '/head-of-department-transport/request-status/pending',
                  {
                    replace: true,
                  }
                )
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="hourglass_bottom"
                  title="Pending Requests"
                  users={pending1.length + pending2.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  '/head-of-department-transport/request-status/declined',
                  {
                    replace: true,
                  }
                )
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="error"
                  icon="cancel"
                  title="Declined Requests"
                  users={declined1.length + declined2.length}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  )
}

export default Home
