import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import { Autocomplete, Grid, Paper, Alert, CircularProgress } from '@mui/material'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import FormFields from 'layouts/pages/account/components/FormField'
import selectData from './data'
import MDButton from 'components/MDButton'

//Firebase Hook
import { useFirestore } from '../../../hooks/useFirestore'
import {useKingsooService} from '../../../hooks/useKingsooServices'
import PopSuccess from 'views/PopMessage/PopSuccess'

function JobFileManagement() {
  const [reset, setReset] = useState(false)
  const [name, setName] = useState('')
  const [employeeDept, setEmployeeDept] = useState('')
  const [employeeDesig, setEmployeeDesig] = useState('')
  const [email, setEmail] = useState('')
  const [staffId, setStaffId] = useState('')
  const [contact, setContact] = useState('')

  const { id } = useParams()

  let navigate = useNavigate()

  const {getJobManager, updateJobManager} = useKingsooService()

  const detail = async () => {
    try {
      const docSnap = await getJobManager(id)
      setName(docSnap.data().name)
      setEmployeeDept(docSnap.data().employeeDept)
      setEmployeeDesig(docSnap.data().employeeDesig)
      setEmail(docSnap.data().email)
      setStaffId(docSnap.data().staffId)
      setContact(docSnap.data().contact)
    } catch {
      console.log('error')
    }
  }

  useEffect(() => {
    // console.log('na u know', id)
    if ((id !== undefined || id !== '') && id !== ':id') {
      detail()
    }
  }, [id])

  const { addDocument, message, isError, isPending } = useFirestore('jobManager')

  const handleSubmit = async (e) => {
    e.preventDefault()

    const newJobManager = {
      name,
      employeeDept,
      employeeDesig,
      email,
      staffId,
      contact,
    }

    try {
      if ((id !== undefined || id !== '') && id !== ':id') {
        await updateJobManager(id, newJobManager)
        setReset(true)
      } else {
        await addDocument(newJobManager)
      }
    } catch (error) { }
  }

  useEffect(() => {
    if (message || reset) {
      setTimeout(() => {
        navigate('/monitoring-compliance/job-management/job-manager', {
          replace: true,
        })
      }, 5000)
    }
  }, [message, reset, navigate])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2, height: '100vh' }}>
        <MDBox mt={5} component="form" onSubmit={handleSubmit}>
          <MDTypography
            variant="h5"
            fontWeight="bold"
            sx={{ borderBottom: 1, pb: 1, pt: 4 }}
          >
            Job Manager Details
          </MDTypography>
          {isError && (
            <Alert severity="error" className="alert">
              Something went wrong
            </Alert>
          )}
          <MDBox mt={1.625}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Name</MDTypography>
                <FormFields
                  value={name}
                  name="Name"
                  placeholder="Enter name"
                  onChange={(e) => setName(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Employee Department
                </MDTypography>
                <Autocomplete
                  value={employeeDept}
                  defaultValue="Select Employee Department"
                  options={selectData.employeeDept}
                  onChange={(e, newValue) => setEmployeeDept(newValue)}
                  renderInput={(params) => (
                    <FormFields
                      {...params}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Employee Designation
                </MDTypography>
                <Autocomplete
                  value={employeeDesig}
                  defaultValue="Select Employee Designation"
                  options={selectData.employeeDesig}
                  onChange={(e, newValue) => setEmployeeDesig(newValue)}
                  renderInput={(params) => (
                    <FormFields
                      {...params}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={3} mb={2}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Email Address</MDTypography>
                <FormFields
                  value={email}
                  name="Email"
                  placeholder="Enter email address"
                  onChange={(e) => setEmail(e.target.value)}
                  inputProps={{ style: { textTransform: 'lowercase' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Staff ID</MDTypography>
                <FormFields
                  value={staffId}
                  name="StaffID"
                  placeholder="Enter staff ID"
                  onChange={(e) => setStaffId(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Contact</MDTypography>
                <FormFields
                  value={contact}
                  name="Contact"
                  placeholder="Enter phone number"
                  onChange={(e) => setContact(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
            </Grid>
            <MDBox
              mt={4}
              width="100%"
              display="flex"
              justifyContent="right"
              pl={4}
            >
              {!isPending &&
                ((id !== undefined || id !== '') && id !== ':id' ? (
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="dark"
                    sx={{ width: '180px' }}
                  >
                    Update Manager
                  </MDButton>
                ) : (
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="dark"
                    sx={{ width: '180px' }}
                  >
                    Create Manager
                  </MDButton>
                ))}
              {isPending && (
                <MDButton
                  variant="gradient"
                  color="dark"
                  disabled
                  sx={{ width: '180px' }}
                >
                  <CircularProgress color="white" size={20} />
                </MDButton>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </Paper>
      {message && <PopSuccess />}
    </DashboardLayout>
  )
}

export default JobFileManagement
