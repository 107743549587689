import { Grid, Paper, Typography } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import React, { useState, useEffect } from 'react'
import FormFields from '../components/FormField/FormField'
import selectData from '../components/data'
import { useParams } from 'react-router-dom'
import MDButton from 'components/MDButton'

import { useNavigate } from 'react-router-dom'

import {useKingsooService} from '../../../../hooks/useKingsooServices'
import NumberFormat from 'react-number-format'
import PropTypes from "prop-types";

// import FormField from '../components/FormField'

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="₦"
    />
  );
});
NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const EditNewStaff = () => {
  const [firstName, setFirstName] = useState('')
  const [otherName, setOtherName] = useState('')
  const [lastName, setLastName] = useState('')
  const [maritalStatus, setMaritalStatus] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [currentAddress, setCurrentAddress] = useState('')
  const [employeeType, setEmployeeType] = useState('')
  const [employeeUnit, setEmployeeUnit] = useState('')
  const [employeeDesignation, setEmployeeDesignation] = useState('')
  const [employeeConfirmation, setEmployeeConfirmation] = useState('')
  const [employeeLocation, setEmployeeLocation] = useState('')
  const [bankName, setBankName] = useState('')
  const [bankAccountNumber, setBankAccountNumber] = useState('')
  const [pensionManager, setPensionManager] = useState('')
  const [pensionNumber, setPensionNumber] = useState('')
  const [nextOfKinFirstName, setNextOfKinFirstName] = useState('')
  const [nextOfKinLastName, setNextOfKinLastName] = useState('')
  const [nextOfKinPhoneNumber, setNextOfKinPhoneNumber] = useState('')
  const [nextOfKinPermanentAddress, setNextOfKinPermanentAddress] = useState('')
  const [nextOfKin, setNextOfKin] = useState('')
  const [emergencyFirstName, setEmergencyFirstName] = useState('')
  const [emergencyLastName, setEmergencyLastName] = useState('')
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState('')
  const [gender, setGender] = useState('')
  const [workEmail, setWorkEmail] = useState('')
  const [nationality, setNationality] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [awardingInstitution, setAwardingInstitution] = useState('')
  const [dateOfGraduation, setDateOfGraduation] = useState('')
  const [dateOfEmployment, setDateOfEmployment] = useState('')
  const [grossSalary, setGrossSalary] = useState('')
  const [refereeFirstName, setRefereeFirstName] = useState('')
  const [refereeLastName, setRefereeLastName] = useState('')
  const [refereePhoneNumber, setRefereePhoneNumber] = useState('')
  const [refereeAddress, setRefereeAddress] = useState('')
  const [spouseFirstName, setSpouseFirstName] = useState('')
  const [spouseLastName, setSpouseLastName] = useState('')
  const [spousePhoneNumber, setSpousePhoneNumber] = useState('')
  const [spouseEmailAddress, setSpouseEmailAddress] = useState('')
  const [numberOfChildren, setNumberOfChildren] = useState('')
  const [reset, setReset] = useState(false)

  const { id } = useParams()
  const {getUser, updateUser} = useKingsooService()
  const navigate = useNavigate()


  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await getUser(id)
        setFirstName(docSnap.data().firstName)
        setOtherName(docSnap.data().otherName)
        setLastName(docSnap.data().lastName)
        setMaritalStatus(docSnap.data().maritalStatus)
        setPhoneNumber(docSnap.data().phoneNumber)
        setCurrentAddress(docSnap.data().currentAddress)
        setEmployeeType(docSnap.data().employeeType)
        setEmployeeUnit(docSnap.data().employeeUnit)
        setEmployeeDesignation(docSnap.data().employeeDesignation)
        setEmployeeConfirmation(docSnap.data().employeeConfirmation)
        setEmployeeLocation(docSnap.data().employeeLocation)
        setBankName(docSnap.data().bankName)
        setBankAccountNumber(docSnap.data().bankAccountNumber)
        setPensionManager(docSnap.data().pensionManager)
        setNextOfKinFirstName(docSnap.data().nextOfKinFirstName)
        setNextOfKinLastName(docSnap.data().nextOfKinLastName)
        setNextOfKinPhoneNumber(docSnap.data().nextOfKinPhoneNumber)
        setNextOfKinPermanentAddress(docSnap.data().nextOfKinPermanentAddress)
        setNextOfKin(docSnap.data().nextOfKin)
        setEmergencyFirstName(docSnap.data().emergencyFirstName)
        setEmergencyLastName(docSnap.data().emergencyLastName)
        setEmergencyPhoneNumber(docSnap.data().emergencyPhoneNumber)
        setWorkEmail(docSnap.data().workEmail)
        setGender(docSnap.data().gender)
        setNationality(docSnap.data().nationality)
        setDateOfBirth(docSnap.data().dateOfBirth)
        setAwardingInstitution(docSnap.data().awardingInstitution)
        setDateOfGraduation(docSnap.data().dateOfGraduation)
        setDateOfEmployment(docSnap.data().dateOfEmployment)
        setGrossSalary(docSnap.data().grossSalary)
        setRefereeFirstName(docSnap.data().refereeFirstName)
        setRefereeLastName(docSnap.data().refereeLastName)
        setRefereePhoneNumber(docSnap.data().refereePhoneNumber)
        setRefereeAddress(docSnap.data().refereeAddress)
        setSpouseFirstName(docSnap.data().spouseFirstName)
        setSpouseLastName(docSnap.data().spouseLastName)
        setSpousePhoneNumber(docSnap.data().spousePhoneNumber)
        setSpouseEmailAddress(docSnap.data().spouseEmailAddress)
        setNumberOfChildren(docSnap.data().numberOfChildren)
      } catch (error) {
        //console.log(error)
      }
    }
    // console.log('na u know', id)
    if (id !== undefined || id !== '') {
      detail()
      // console.log('id', id)
    }
  }, [id])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const user = {
      firstName,
      lastName,
      otherName,
      maritalStatus,
      phoneNumber,
      currentAddress,
      employeeType,
      employeeUnit,
      employeeDesignation,
      employeeConfirmation,
      employeeLocation,
      bankName,
      bankAccountNumber,
      pensionManager,
      nextOfKinFirstName,
      emergencyFirstName,
      emergencyLastName,
      emergencyPhoneNumber,
      awardingInstitution,
      dateOfBirth,
      gender,
      workEmail,
      nationality,
      dateOfGraduation,
      dateOfEmployment,
      grossSalary,
      refereeFirstName,
      refereeLastName,
      refereePhoneNumber,
      refereeAddress,
      spouseFirstName,
      spouseLastName,
      spousePhoneNumber,
      spouseEmailAddress,
      numberOfChildren,
      nextOfKinLastName,
      nextOfKinPhoneNumber,
      nextOfKinPermanentAddress,
      nextOfKin
    }

    try {
      if ((id !== undefined || id !== '') && id !== ':id') {
        updateUser(id, user)
        // setStaffId('')
        if (reset) {
          e.target.reset()
        }
        setReset(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (reset) {
    navigate('/human-resources/employee-management/all-employees', {
      replace: true,
    })
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
        <MDBox mt={5} component="form" onSubmit={handleSubmit}>
          <MDTypography
            variant="h5"
            fontWeight="bold"
            sx={{ borderBottom: 1, pb: 1, pt: 4 }}
          >
            BIO DATA
          </MDTypography>
          <MDBox mt={1.625}>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>First Name</MDTypography>
                <FormFields
                  value={firstName}
                  placeholder="Enter First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Other Name</MDTypography>
                <FormFields
                  value={otherName}
                  placeholder="Enter First Name"
                  onChange={(e) => setOtherName(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Last Name</MDTypography>
                <FormFields
                  value={lastName}
                  placeholder="Enter First Name"
                  onChange={(e) => setLastName(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Marital Status
                </MDTypography>
                <select
                  onChange={(e) => setMaritalStatus(e.target.value)}
                  value={maritalStatus}
                  className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
                >
                  <option selected>Select Marital Status</option>
                  <option
                    value="single"
                    selected={maritalStatus === 'Single' ? true : false}
                  >
                    Single
                  </option>
                  <option
                    value="married"
                    selected={maritalStatus === 'Married' ? true : false}
                  >
                    Married
                  </option>
                </select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Phone Number</MDTypography>
                <FormFields
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter Phone Number"
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Current Address
                </MDTypography>
                <FormFields
                  value={currentAddress}
                  placeholder="Enter Current Address"
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                  onChange={(e) => setCurrentAddress(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <Typography sx={{ fontSize: 13 }}>Gender</Typography>
                <div className="gender">
                  <MDBox className="selector">
                    <select
                      onChange={(e) => setGender(e.target.value)}
                      className="mt-1 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      value={gender}
                    >
                      <option value="" disabled selected>
                        Select gender
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </MDBox>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography sx={{ fontSize: 13 }}>Work Email </Typography>
                <FormFields
                  value={workEmail}
                  onChange={(e) => setWorkEmail(e.target.value)}
                  placeholder="Enter Email Address"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Nationality</MDTypography>
                <FormFields
                  value={nationality}
                  placeholder="Enter First Name"
                  onChange={(e) => setNationality(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <Typography sx={{ fontSize: 13 }}>Date Of Birth</Typography>
                <input
                  type="date"
                  // name="expectedTimeOfArrival"
                  className="border border-gray-300 text-gray-900 text-base 
                  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}

                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography sx={{ fontSize: 13 }}>
                  Accademic Qualifications{" "}
                </Typography>
                <select
                  className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
                  as="select"
                  name="academicQualification1">
                  <option disabled selected value="">
                    Academic Qualification
                  </option>

                  <option>HND</option>
                  <option>OND</option>

                  <option>BSC</option>
                  <option>BED</option>
                </select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography sx={{ fontSize: 13 }}>Awarding Institution </Typography>
                <select
                  value={awardingInstitution}
                  onChange={(e) => setAwardingInstitution(e.target.value)}
                  name="awardingUniversity"
                  className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
                >
                  {selectData.universities.map(university =>
                    <option>
                      {university}
                    </option>
                  )}
                </select>
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <Typography sx={{ fontSize: 13 }}>Date Of Graduation</Typography>
                <input
                  type="date"
                  // name="expectedTimeOfArrival"
                  className="border border-gray-300 text-gray-900 text-base 
                  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={dateOfGraduation}
                  onChange={(e) => setDateOfGraduation(e.target.value)}

                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography sx={{ fontSize: 13 }}>Date Of Employement</Typography>
                <input
                  type="date"
                  // name="expectedTimeOfArrival"
                  className="border border-gray-300 text-gray-900 text-base 
                  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={dateOfEmployment}
                  onChange={(e) => setDateOfEmployment(e.target.value)}

                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Typography sx={{ fontSize: 13 }}>Gross Salary</Typography>
                <FormFields
                  value={grossSalary}
                  onChange={(e) => setGrossSalary(e.target.value)}
                  id="formatted-numberformat-input"
                  placeholder="Enter Gross Salary"
                  InputProps={{ inputComponent: NumberFormatCustom } // onChange={handleChange}
                  }
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={5}>
            <MDTypography
              variant="h5"
              fontWeight="bold"
              sx={{ borderBottom: 1, pb: 1, pt: 4 }}
            >
              EMPLOYEMENT DETAILS
            </MDTypography>
          </MDBox>
          <MDBox mt={1.625}>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Employment Type
                </MDTypography>
                <select
                  onChange={(e) => setEmployeeType(e.target.value)}
                  className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
                >
                  {selectData.employeeType.map((employee) => (
                    <option selected={employeeType === employee ? true : false}>
                      {employee}
                    </option>
                  ))}
                </select>
              </Grid>
              {/* <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Employment Department
                </MDTypography>
                <select
                  onChange={(e) => setEmployeeUnit(e.target.value)}
                  className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
                >
                  {selectData.employeeDepartment.map((person) => (
                    <option selected={employeeUnit === person ? true : false}>
                      {person}
                    </option>
                  ))}
                </select>
              </Grid> */}
              {/* <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Employment Designation
                </MDTypography>
                <select
                  onChange={(e) => setEmployeeDesignation(e.target.value)}
                  className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
                >
                  {selectData.employeedesignation.map((person) => (
                    <option
                      selected={employeeDesignation === person ? true : false}
                    >
                      {person}
                    </option>
                  ))}
                </select>
              </Grid> */}
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Employment Confirmation
                </MDTypography>
                <select
                  onChange={(e) => setEmployeeConfirmation(e.target.value)}
                  className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
                >
                  {selectData.employeeConfirmation.map((person) => (
                    <option
                      selected={employeeConfirmation === person ? true : false}
                    >
                      {person}
                    </option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Employment Location
                </MDTypography>
                <select
                  onChange={(e) => setEmployeeLocation(e.target.value)}
                  className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
                >
                  {selectData.employeeLocation.map((person) => (
                    <option
                      selected={employeeLocation === person ? true : false}
                    >
                      {person}
                    </option>
                  ))}
                </select>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={5}>
            <MDTypography
              variant="h5"
              fontWeight="bold"
              sx={{ borderBottom: 1, pb: 1, pt: 4 }}
            >
              REFEREES DETAILS
            </MDTypography>
          </MDBox>
          <MDBox mt={1.625}>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Referee's First Name</MDTypography>
                <FormFields
                  value={refereeFirstName}
                  placeholder="Enter Referee's First Name"
                  onChange={(e) => setRefereeFirstName(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Referee's Last Name</MDTypography>
                <FormFields
                  value={refereeLastName}
                  placeholder="Enter Referee's Last Name"
                  onChange={(e) => setRefereeLastName(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Referee's Phone Number</MDTypography>
                <FormFields
                  value={refereePhoneNumber}
                  placeholder="Enter Referee's Phone Number"
                  type='tel'
                  onChange={(e) => setRefereePhoneNumber(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Referee's Address</MDTypography>
                <FormFields
                  value={refereeAddress}
                  type='email'
                  placeholder="Enter Referee's Address"
                  onChange={(e) => setRefereeAddress(e.target.value)}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={5}>
            <MDTypography
              variant="h5"
              fontWeight="bold"
              sx={{ borderBottom: 1, pb: 1, pt: 4 }}
            >
              SPOUSE DETAILS
            </MDTypography>
          </MDBox>
          <MDBox mt={1.625}>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>First Name</MDTypography>
                <FormFields
                  value={spouseFirstName}
                  placeholder="Enter First Name"
                  onChange={(e) => setSpouseFirstName(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Last Name</MDTypography>
                <FormFields
                  value={spouseLastName}
                  placeholder="Enter Last Name"
                  onChange={(e) => setSpouseLastName(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Phone Number</MDTypography>
                <FormFields
                  value={spousePhoneNumber}
                  placeholder="Enter Phone Number"
                  type='tel'
                  onChange={(e) => setSpousePhoneNumber(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Address</MDTypography>
                <FormFields
                  value={spouseEmailAddress}
                  type='email'
                  placeholder="Enter Email Address"
                  onChange={(e) => setSpouseEmailAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography sx={{ fontSize: 13 }}>Number of Children </Typography>
                <MDBox>
                  <select
                    onChange={(e) => setNumberOfChildren(e.target.value)}
                    className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
                  >
                    {selectData.numberOfChildren.map((children) => (
                      <option>
                        {children}
                      </option>
                    ))}
                  </select>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={5}>
            <MDTypography
              variant="h5"
              fontWeight="bold"
              sx={{ borderBottom: 1, pb: 1, pt: 4 }}
            >
              BANK DETAILS
            </MDTypography>
          </MDBox>
          <MDBox mt={1.625}>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Bank Name</MDTypography>
                <select
                  onChange={(e) => setBankName(e.target.value)}
                  className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
                >
                  {selectData.banks.map((bank) => (
                    <option selected={bankName === bank ? true : false}>
                      {bank}
                    </option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Bank Account</MDTypography>
                <FormFields
                  value={bankAccountNumber}
                  placeholder="Enter Bank Account"
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                  onChange={(e) => setBankAccountNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Pension Manager
                </MDTypography>
                <FormFields
                  value={pensionManager}
                  placeholder="Enter Pension Manager"
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                  onChange={(e) => setPensionManager(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Pension Number
                </MDTypography>
                <FormFields
                  value={pensionNumber}
                  placeholder="Enter Pension Number"
                  onChange={(e) => setPensionNumber(e.target.value)}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={5}>
            <MDTypography
              variant="h5"
              fontWeight="bold"
              sx={{ borderBottom: 1, pb: 1, pt: 4 }}
            >
              ADDITIONAL DATA
            </MDTypography>
          </MDBox>
          <MDBox mt={1.625}>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Next of Kin First Name</MDTypography>
                <FormFields
                  value={nextOfKinFirstName}
                  placeholder="Enter Next of Kin First Name"
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                  onChange={(e) => setNextOfKinFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Next of Kin Last Name</MDTypography>
                <FormFields
                  value={nextOfKinLastName}
                  placeholder="Enter Next of Kin Last Name"
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                  onChange={(e) => setNextOfKinLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Next of Kin Number</MDTypography>
                <FormFields
                  value={nextOfKinPhoneNumber}
                  placeholder="Enter Next of Kin Phone Number"
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                  onChange={(e) => setNextOfKinPhoneNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Next of Kin Address</MDTypography>
                <FormFields
                  value={nextOfKinPermanentAddress}
                  placeholder="Enter Next of Email Address"
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                  onChange={(e) => setNextOfKinPermanentAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Next of Kin Relationship</MDTypography>
                <select
                  onChange={(e) => setNextOfKin(e.target.value)}
                  className="mt-0 block w-full pl-3 pr-10 py-3 border text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
                >
                  {selectData.nextOfKin.map((kin) => (
                    <option selected={nextOfKin === kin ? true : false}>
                      {kin}
                    </option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Emergency Contact First Name{' '}
                </MDTypography>
                <FormFields
                  value={emergencyFirstName}
                  name="emergencyFirstName"
                  placeholder="Enter First Name"
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                  onChange={(e) => setEmergencyFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Emergency Contact Last Name{' '}
                </MDTypography>
                <FormFields
                  value={emergencyLastName}
                  name="emergencyLastName"
                  placeholder="Enter Last Name"
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                  onChange={(e) => setEmergencyLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Emergency Contact Phone Number{' '}
                </MDTypography>
                <FormFields
                  value={emergencyPhoneNumber}
                  name="emergencyPhoneNumber"
                  placeholder="Enter Phone Number"
                  onChange={(e) => setEmergencyPhoneNumber(e.target.value)}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox sx={{ pt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <MDButton type="submit" variant="gradient" color="dark">
              Update User
            </MDButton>
          </MDBox>
        </MDBox>
      </Paper>
    </DashboardLayout>
  )
}

export default EditNewStaff
