import * as React from 'react';
import { styled } from '@mui/system';
import {Tabs as BaseTabs} from '@mui/base/Tabs';
import {TabsList as BaseTabsList} from '@mui/base/TabsList';
import {TabPanel as BaseTabPanel} from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import {Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import StatutoryTable from './StatutoryTable';
import NonStatutoryTable from './NonStatutoryTable ';
import NonJobRelatedTable from './NonJobRelatedTable';
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'customs/Navbars/DashboardNavbar';


const green = {
    50: '#e7fbfe',
    100: '#C2E0FF',
    200: '#86edf9',
    300: '#66B2FF',
    400: '#e7fbfe',
    500: '#032c30',
    600: '#054248',
    700: '#032c30',
    800: '#004C99',
    900: '#003A75',
};

const TabMain = styled(BaseTab)`
  font-family: IBM Plex Sans, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${green[600]};
  }

  &:focus {
    color: #fff;
    border-radius: 3px;
    outline: 2px solid ${green[200]};
    outline-offset: 2px;
  }

  &.${tabClasses.selected} {
    background-color: ${green[50]};
    color: ${green[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(BaseTabsList)`
  min-width: 320px;
  background-color: ${green[500]};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

export default function TabsCustomized() {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <BaseTabs defaultValue={0}>
                <TabsList
                    sx={{
                        width: "100%",
                        marginTop: 3,
                    }}>
                    <TabMain>Statutory Job Related Request</TabMain>
                    <TabMain>Non-Statutory Job Related Request</TabMain>
                    <TabMain>Non-Job Related Request</TabMain>
                </TabsList>
                <TabPanel value={0}><StatutoryTable /></TabPanel>
                <TabPanel value={1}><NonStatutoryTable /></TabPanel>
                <TabPanel value={2}><NonJobRelatedTable /></TabPanel>
            </BaseTabs>
        </DashboardLayout>
    );
}