import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { useState } from "react";

import { useCollection } from "hooks/useCollection";

const NonJobRelatedTable = () => {
  const [responsive] = useState("horizontal");
  const [tableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight] = useState("");
  const [searchBtn] = useState(true);
  const [downloadBtn] = useState(true);
  const [printBtn] = useState(true);
  const [viewColumnBtn] = useState(true);
  const [filterBtn] = useState(true);

  const { documents: data } = useCollection("nonJobRelated");

  const documents = data.filter(data => data.status === "Declined");

  const muiCache = createCache({
    key: "mui-datatables",
    prepend: true
  });

  const columns = [
    {
      name: "requestId",
      label: "Request ID",
      options: { filter: true, sort: true }
    },
    {
      name: "id",
      label: "Id",
      hide: true,
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "email",
      label: "Requester",
      options: { filter: true, sort: true }
    },
    {
      name: "date",
      label: "Date & Time",
      options: { filter: true, sort: true }
    },
    {
      name: "paymentValue",
      label: "Value",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: dataIndex => {
          let val = documents[dataIndex].paymentValue;
          let ngn = Intl.NumberFormat("en-NG", {
            style: "currency",
            currency: "NGN"
          });
          return ngn.format(val);
        }
      }
    },
    {
      name: "request",
      label: "Request for",
      options: { filter: true, sort: true }
    },
    { name: "status", label: "Status", options: { filter: true, sort: true } }
  ];
  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: "requestId",
      direction: "asc"
    }
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 1,
              paddingTop: 1,
              backgroundColor: "#033237"
            }
          }
        },

        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              "&:nth-child(odd)": {
                //backgroundColor: "#f6f6f6"
              },
              fontSize: "12px"
            }
          }
        }
      }
    });
  return (
    <div>
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme}>
          <MUIDataTable data={documents} columns={columns} options={options} />
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
};

export default NonJobRelatedTable;
