import { useNavigate } from 'react-router-dom'

// @mui material components
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
// import MDTypography from "components/MDTypography";

import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'

import ComplexStatisticsCard from 'customs/Cards/StatisticsCards/ComplexStatisticsCard'

//Firebase Hooks
import { useCollection } from '../../../hooks/useCollection'

function Home() {
  let navigate = useNavigate()

  const { documents: alerts } = useCollection('jobs')
  const { documents: users } = useCollection('users')
  const { documents: consignee } = useCollection('consignee')
  const { documents: datas } = useCollection('clients')
  const { documents: njr } = useCollection('nonJobRelated')
  const { documents: jr } = useCollection('jobRelatedForm')

  const approved1 = njr.filter((ap) => ap.status === 'Approved')
  const approved2 = jr.filter((ap) => ap.status === 'Approved')
  const pending1 = jr.filter((ap) => ap.status === 'COO')
  const pending2 = njr.filter((ap) => ap.status === 'COO')
  const declined1 = njr.filter((ap) => ap.status === 'Declined')
  const declined2 = jr.filter((ap) => ap.status === 'Declined')
  const alert = alerts.filter((alert) => alert.preAlertstatus === 'PENDING')
  const pending = alerts.filter(
    (alert) => alert.preAlertstatus === 'IN PROGRESS'
  )
  const complete = alerts.filter(
    (alert) => alert.preAlertstatus === 'COMPLETED'
  )
  const pendingNew = alerts.filter(
    (alert) =>
      alert.preAlertstatus === 'IN PROGRESS' && alert.jobType === 'Export'
  )
  // Action buttons for the BookingCard
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  '/chief-operating-officer/employee-management/all-employees',
                  {
                    replace: false,
                  }
                )
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  icon="group"
                  title="Employees"
                  users={users.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  '/chief-operating-officer/client-management/consignee',
                  {
                    replace: false,
                  }
                )
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="secondary"
                  icon="group"
                  title="Consignees"
                  users={consignee.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/chief-operating-officer/client-management/client', {
                  replace: false,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="group"
                  title="Clients"
                  users={datas.length}
                  size="12px"
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  '/chief-operating-officer/job-management/pre-alert-jobs',
                  {
                    replace: true,
                  }
                )
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="work"
                  title="Pre Alert Jobs"
                  users={alert.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  '/chief-operating-officer/job-management/pending-job',
                  {
                    replace: false,
                  }
                )
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="work_history"
                  color="dark"
                  title="Pending Jobs"
                  count=""
                  users={pending.length}
                  percentage={{
                    color: 'dark',
                    amount: 15,
                  }}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  '/chief-operating-officer/job-management/completed-job',
                  {
                    replace: false,
                  }
                )
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  icon="work_off"
                  title="Completed Jobs"
                  users={complete.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/chief-operating-officer/job-management/export-job', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="file_upload"
                  title="Export Jobs"
                  users={pendingNew.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  '/chief-operating-officer/financial-request/all-requests',
                  {
                    replace: false,
                  }
                )
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="move_to_inbox"
                  title="All Requests"
                  users={njr.length + jr.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  '/chief-operating-officer/financial-request/approved-request',
                  {
                    replace: false,
                  }
                )
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="check_circle_outline"
                  title="Approved Requests"
                  users={approved1.length + approved2.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  '/chief-operating-officer/financial-request/pending-request',
                  {
                    replace: false,
                  }
                )
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="hourglass_bottom"
                  title="Pending Requests"
                  users={pending1.length + pending2.length}
                  color="warning"
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate(
                  '/chief-operating-officer/financial-request/declined-request',
                  {
                    replace: false,
                  }
                )
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="error"
                  icon="cancel"
                  title="Declined Requests"
                  users={declined1.length + declined2.length}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  )
}

export default Home
