// prop-types is a library for typechecking of props
import MDInput from "components/MDInput";

function FormFields({ ...rest }) {
  return <MDInput variant="outlined" className="text1" fullWidth InputLabelProps={{ shrink: true }} {...rest} />;
}

// Setting default values for the props of FormFields

export default FormFields;
