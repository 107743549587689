import { useState } from 'react'
import { Link } from 'react-router-dom'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import MUIDataTable from 'mui-datatables'
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { Button } from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'

// Firebase Hook
import { useCollection } from '../../../hooks/useCollection'
import { Visibility } from '@mui/icons-material'

const muiCache = createCache({
  key: 'mui-datatables',
  prepend: true,
})

const ExportJobTable = () => {
  const { documents } = useCollection('jobs')

  const [responsive] = useState('horizontal')
  const [tableBodyHeight] = useState('auto')
  const [tableBodyMaxHeight] = useState('')
  const [searchBtn] = useState(true)
  const [downloadBtn] = useState(true)
  const [printBtn] = useState(true)
  const [viewColumnBtn] = useState(true)
  const [filterBtn] = useState(true)
  // const [jobId, setJobId] = useState('')

  const data = documents.filter(
    (data) => data.preAlertstatus === 'IN PROGRESS' && data.jobType === 'Export'
  )

  // const getJobId = (id) => {
  //   // console.log('the id', id)
  //   setJobId(id)
  // }

  const columns = [
    {
      name: 'fileId',
      label: 'File ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'id',
      label: 'Id',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'consigneeId',
      label: 'Consignee',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: 'center',
        }),
      },
    },

    {
      name: 'companyId',
      label: 'Client',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: 'center',
        }),
      },
    },
    {
      name: 'exportType',
      label: 'Export Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'pickupAddress',
      label: 'Pickup Address',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: 'center',
        }),
      },
    },
    {
      name: 'destination',
      label: 'Destination',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: 'center',
        }),
      },
    },
    {
      name: 'preAlertstatus',
      label: 'Job Status',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: 'flex', gap: '.8rem' }}>
              <button
                style={{
                  border: 'none',
                  background: 'transparent',
                  cursor: 'pointer',
                }}
              >
                <Link
                  to={`/chief-operating-officer/job-management/pending-export-jobs/${tableMeta.rowData[1]}`}
                >
                  <Visibility
                    style={{
                      width: '20px',
                      height: '20px',
                      color: 'black',
                    }}
                  />
                </Link>
              </button>
              {/*<button
                style={{
                  fontSize: '13px',
                  border: 'none',
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                }}
              >
                <Link
                  to={`/chief-operating-officer/job-management/export-form/${tableMeta.rowData[1]}`}
                  style={{ textDecoration: 'none', color: 'green' }}
                >
                  <EditIcon />
                </Link>
              </button>*/}
            </div>
          )
        },
      },
    },
  ]

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,

    tableBodyMaxHeight,
    sortOrder: {
      name: 'fileId',
      direction: 'desc',
    },
  }

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 2,
              marginBottom: 8,
              paddingTop: 1,
              backgroundColor: '#033237',
            },
          },
        },

        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              '&:nth-child(odd)': {
                //backgroundColor: "#f6f6f6"
              },
              fontSize: '12px',
            },
          },
        },
      },
    })

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="mt-4">
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              title={'Pending Export Job'}
              data={data ? data : 'No Pending Jobs'}
              columns={columns}
              options={options}
              style={{ marginTop: '10px' }}
            />
          </ThemeProvider>
        </CacheProvider>
      </div>
    </DashboardLayout>
  )
}

export default ExportJobTable
