// eslint-disable-next-line

// import firebase from 'firebase/app'
// import 'firebase/firestore'
// import 'firebase/auth'
// import 'firebase/storage'

import { initializeApp } from 'firebase/app'
import { getFirestore } from '@firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyBx8Djc9JOqWaPj16zjAj2ycgC0aB7fgO4',
  authDomain: 'kingsoo-app-6c3c8.firebaseapp.com',
  projectId: 'kingsoo-app-6c3c8',
  storageBucket: 'kingsoo-app-6c3c8.appspot.com',
  messagingSenderId: '3944162233',
  appId: '1:3944162233:web:a43c1aa6d7d28796ddcc68',
  measurementId: 'G-SXKX314BDZ',
}

// Init firebase
const app = initializeApp(firebaseConfig)

// init service
const appFirestore = getFirestore(app)
const appAuth = getAuth(app)
const appStorage = getStorage(app)

// timestamp
const timestamp = appFirestore.timestamp

export { appFirestore, appAuth, appStorage, timestamp }
