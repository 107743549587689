// import { useState } from "react";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// import samplePDF from "../../assets/booking-confirm.pdf";
// const PDF = () => {
//   const [selectedImage, setSelectedImage] = useState();
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);

//   // This function will be triggered when the file field change
//   const imageChange = e => {
//     if (e.target.files && e.target.files.length > 0) {
//       setSelectedImage(e.target.files[0]);
//     }
//   };

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };

//   const goToPrevPage = () =>
//     setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

//   const goToNextPage = () =>
//     setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

//   return (
//     <div>
//       <div className="flex text-sm text-gray-600">
//         <label
//           htmlFor="file-upload"
//           className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
//           <span>Click here to Choose Image</span>
//           <input
//             id="file-upload"
//             name="file-upload"
//             onChange={
//               imageChange // accept="image/*"
//             }
//             type="file"
//             className="sr-only"
//           />
//         </label>
//       </div>
//       <nav>
//         <button onClick={goToPrevPage}>Prev</button>
//         <button onClick={goToNextPage}>Next</button>
//         <p>
//           Page {pageNumber} of {numPages}
//         </p>
//       </nav>
//       {/* {!selectedImage &&
//         <Document
//           file={URL.createObjectURL(
//            setSelectedImage
//           )}
//           onLoadSuccess={onDocumentLoadSuccess}>
//           <Page pageNumber={pageNumber} />
//         </Document>} */}
//     </div>
//   );
// };

// export default PDF;

import { Container } from "@mui/material";
import React from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import './style.css'
class PDF extends React.Component {
  state = {
    file: null,
    numPages: 0,
    pageNumber: 1
  };

  onFileChange = event => {
    this.setState({
      file: event.target.files[0]
    });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  nextPage = () => {
    const currentPageNumber = this.state.pageNumber;
    let nextPageNumber;

    if (currentPageNumber + 1 > this.state.numPages) {
      nextPageNumber = 1;
    } else {
      nextPageNumber = currentPageNumber + 1;
    }

    this.setState({
      pageNumber: nextPageNumber
    });
  };

  render() {
    const { pageNumber, numPages } = this.state;

    return (
      <div>
        <br />

        <form>
          <input type="file" onChange={this.onFileChange} />
        </form>
          <div>
            <Document
              file={this.state.file}
              onLoadSuccess={this.onDocumentLoadSuccess}
              noData={<h4>Please select a file</h4>}>
              <Page pageNumber={pageNumber} />
            </Document>
            <button onClick={this.nextPage}>Next</button>
            {this.state.file
              ? <p>
                  Page {pageNumber} of {numPages}
                </p>
              : null}
          </div>
        
      </div>
    );
  }
}

export default PDF;

