const form = {
  formId: "new-user-form",
  formField: {
    firstName: {
      name: "firstName",
      label: "First Name*",
      requiredErrorMsg: "First Name Is Required"
    },
    otherName: {
      name: "otherName",
      label: "Other Name"
    },
    lastName: {
      name: "lastName",
      label: "Last Name* ",
      requiredErrorMsg: "Last Name Is Required"
    },
    gender: {
      name: "gender",
      label: "Gender *",
      requiredErrorMsg: "Select Gender"
    },
    maritalStatus: {
      name: "maritalStatus",
      label: "Marital Status"
    },
    workEmail: {
      name: "workEmail",
      label: "Work Email"
    },
    phoneNumber: {
      name: "phoneNumber",
      label: "Phone Number"
    },
    dateOfBirth: {
      name: "dateOfBirth",
      label: "Date Of Birth"
    },
    nationality: {
      name: "nationality",
      label: "Nationality"
    },
    stateOfOrigin: {
      name: "stateOfOrigin",
      label: "State Of Origin"
    },
    lga: {
      name: "lga",
      label: "Local Government Area"
    },
    currentAddress: {
      name: "currentAddress",
      label: "Current Address"
    },
    permanentAddress: {
      name: "permanentAddress",
      label: "Permanent Address"
    },
    academicQualification: {
      name: "academicQualification",
      label: "Academic Qualification"
    },
    awardingInstitution: {
      name: "awardingInstitution",
      label: "Awarding Institution"
    },
    dateOfGraduation: {
      name: "dateOfGraduation",
      label: "Date Of Graduation"
    },
    academicQualification1: {
      name: "academicQualification1",
      label: "Academic Qualification"
    },
    awardingInstitution1: {
      name: "awardingInstitution1",
      label: "Awarding Institution"
    },
    dateOfGraduation1: {
      name: "dateOfGraduation1",
      label: "Date Of Graduation"
    },
    staffId: {
      name: "staffId",
      label: "Staff ID"
    },
    employmentType: {
      name: "employmentType",
      label: "Employee Type"
    },
    employeeDepartment: {
      name: "employeeDepartment",
      label: "Employee Department"
    },
    employeeUnit: {
      name: "employeeUnit",
      label: "Employee Unit"
    },
    employeeDesignation: {
      name: "employeeDesignation",
      label: "Employee Designation"
    },
    employeeConfirmation: {
      name: "employeeConfirmation",
      label: "Employee Confirmation"
    },
    employeeLocation: {
      name: "employeeLocation",
      label: "EmployeeLocation"
    },
    dateOfEmployment: {
      name: "dateOfEmployment",
      label: "Date Of Employment "
    },
    dateOfExit: {
      name: "dateOfExit",
      label: "Date Of Exit"
    },
    grossSalary: {
      name: "grossSalary",
      label: "Gross Salary"
    },
    refereeFirstName: {
      name: "refereeFirstName",
      label: "Referee's First Name"
    },
    refereeLastName: {
      name: " refereeLastName",
      label: "Referee's Last Name"
    },
    refereePhoneNumber: {
      name: "refereePhoneNumber",
      label: "Referee's Phone Number"
    },
    refereeAddress: {
      name: "refereeAddress",
      label: "Referee's Address"
    },
    refereeFirstName1: {
      name: "refereeFirstName1",
      label: "Referee's First Name"
    },
    refereeLastName1: {
      name: " refereeLastName1",
      label: "Referee's Last Name"
    },
    refereePhoneNumber1: {
      name: "refereePhoneNumber1",
      label: "Referee's Phone Number"
    },
    refereeAddress1: {
      name: "refereeAddress1",
      label: "Referee's Address"
    },
    bankName: {
      name: "bankName",
      label: "Bank Name"
    },
    bankAccountNumber: {
      name: "bankAccountNumber",
      label: "Bank Account Number"
    },
    pensionManager: {
      name: "pensionManager",
      label: "Pension Manager"
    },
    pensionNumber: {
      name: "pensionNumber",
      label: "Pension Number"
    },
    spouseFirstName: {
      name: "spouseFirstName",
      label: "First Name"
    },
    spouseLastName: {
      name: "spouseLastName",
      label: "Last Name"
    },
    spousePhoneNumber: {
      name: "spousePhoneNumber",
      label: "Phone Number"
    },
    spouseEmailAddress: {
      name: "spouseEmailAddress",
      label: "Email Address"
    },
    numOfChildren: {
      name: "numberOfChildren",
      label: "Number Of Children"
    },
    nextOfKinFirstName: {
      name: "nextOfKinFirstName",
      label: " First Name"
    },
    nextOfKinLastName: {
      name: "nextOfKinLastName",
      label: "Last Name"
    },
    nextOfKinPermanentAddress: {
      name: "nextOfKinPermanentAddress",
      label: "Permanent Address"
    },
    nextOfKinPhoneNumber: {
      name: "nextOfKinPhoneNumber",
      label: "Phone Number"
    },
    nextOfKinAddress: {
      name: "nextOfKinAddress",
      label: "Current Address"
    },
    emergencyFirstName: {
      name: "emergencyFirstName",
      label: "First Name"
    },
    emergencyLastName: {
      name: "emergencyLastName",
      label: "Last Name"
    },
    emergencyPhoneNumber: {
      name: "emergencyPhoneNumber",
      label: "Phone Number"
    },
    emergencyFirstName1: {
      name: "emergencyFirstName",
      label: "First Name"
    },
    emergencyLastName1: {
      name: "emergencyLastName",
      label: "Last Name"
    },
    emergencyPhoneNumber1: {
      name: "emergencyPhoneNumber",
      label: "Phone Number"
    }
  }
};

export default form;
