import { Paper, TextField } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
// import {useKingsooService} from '../../../hooks/useKingsooServices'
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'

import { useParams, useNavigate } from 'react-router-dom'

import {useKingsooService} from '../../../hooks/useKingsooServices'

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
      prefix="₦"
    />
  )
})

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const ApproveForm = () => {
  const [billOfLaden, setBillOfLaden] = useState('')
  const [fileId, setFileId] = useState('')
  const [paymentType, setPaymentType] = useState('')
  const [consigneeId, setConsigneeId] = useState('')
  const [clientId, setClientId] = useState('')
  const [containerInfo, setContainerInfo] = useState('')
  const [invoiceNo, setInvoiceNo] = useState('')
  const [bulkCargo, setBulkCargo] = useState('')
  const [request, setRequest] = useState('')
  const [paymentValue, setPaymentValue] = useState('')
  const [requester, setRequester] = useState('')
  const [description, setDescription] = useState('')
  const [date, setDate] = useState()
  const [requestId, setRequestId] = useState('')
  const [status, setStatus] = useState('')
  const [show, setShow] = useState(false)
  const [paid, setPaid] = useState('')
  const [amount, setAmount] = useState('')
  const [partial] = useState('Partial')
  const [payment, setPayment] = useState('')

  const {getFnj} = useKingsooService()

  const { id } = useParams()
  const navigate = useNavigate()

  const detail = async () => {
    try {
      const docSnap = await getFnj(id)

      setBillOfLaden(docSnap.data().billOfLaden)
      setFileId(docSnap.data().fileId)
      setPaymentType(docSnap.data().paymentType)
      setConsigneeId(docSnap.data().consigneeId)
      setClientId(docSnap.data().clientId)
      setContainerInfo(docSnap.data().containerInfo)
      setInvoiceNo(docSnap.data().invoiceNo)
      setBulkCargo(docSnap.data().bulkCargo)
      setRequest(docSnap.data().request)
      setPaymentValue(docSnap.data().paymentValue)
      setRequester(docSnap.data().requester)
      // setFile(docSnap.data().file)
      setDescription(docSnap.data().description)
      setDate(docSnap.data().date)
      setRequestId(docSnap.data().requestId)
      setStatus(docSnap.data().status)
      setPayment(docSnap.data().amount)
    } catch (error) {
      // console.log(error)
    }
  }

  useEffect(() => {
    if (id !== undefined || id !== '') {
      detail()
    }
  }, [id])

  const handleClick = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(!show)
  }

  const {updateFNJR} = useKingsooService()

  const handleSubmit1 = async (e) => {
    e.preventDefault()
    // console.log({ amount, paid: partial })
    try {
      await updateFNJR(id, { amount, paid: partial })
      navigate('/pay-officer/request-status/approved', {
        replace: true,
      })
    } catch (error) {
      console.log(error)
    }
  }
  const fullPayment = async (id) => {
    setPaid('full')
    // setId(id)
    setAmount(paymentValue)
  }

  useEffect(() => {
    if (paid) {
      handleSubmit(id)
    }
  })

  const handleSubmit = async () => {
    try {
      await updateFNJR(id, { paid, amount })
      navigate('/pay-officer/request-status/approved', {
        replace: true,
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
        <MDBox
          sx={{
            display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'space-between',
            flexDirection: 'column',
          }}
          container
          spacing={2}
        >
          <MDBox>
            <MDTypography
              variant="h5"
              fontWeight="bold"
              sx={{ borderBottom: 1, pb: 1, pt: 4 }}
            >
              Confirm Payment
            </MDTypography>
          </MDBox>
          <MDBox
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <MDBox item xs={4} sx={{ pl: 2, pt: 4 }}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                Payment Type: <b>{paymentType}</b>
              </MDTypography>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                Job File Id: <b>{fileId} </b>
              </MDTypography>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                Bill of Lading/Master Airway Bill: <b>{billOfLaden}</b>
              </MDTypography>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                Consignee: <b>{consigneeId}</b>
              </MDTypography>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                Client: <b>{clientId}</b>
              </MDTypography>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                Number Of Containers: <b>{containerInfo}</b>
              </MDTypography>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                Invoice No: <b>{invoiceNo}</b>
              </MDTypography>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                Bulk Cargo: <b>{bulkCargo} </b>
              </MDTypography>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                Balance: <b>{paymentValue - payment} </b>
              </MDTypography>
            </MDBox>
            <MDBox item xs={4} sx={{ pr: 4, pt: 4 }}>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                Request For: <b>{request}</b>
              </MDTypography>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                Payment Value: <b>{paymentValue}</b>
              </MDTypography>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                Requester: <b>{requester}</b>
              </MDTypography>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                Description: <b>{description} </b>
              </MDTypography>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                Date: <b>{date} </b>
              </MDTypography>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                Status: <b>{status} </b>
              </MDTypography>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                Request Id: <b>{requestId} </b>
              </MDTypography>
              <MDTypography
                sx={{ fontSize: '14px', mb: 4, width: '100%' }}
                gutterBottom
              >
                Previous Payment: <b>{payment} </b>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        {!show ? (
          <MDBox
            mt={2}
            sx={{
              display: 'flex',
              justifyContent: 'end',
            }}
            fontSize="14px"
          >
            <MDButton variant="outlined" color="dark" onClick={handleClick}>
              Pay Partial
            </MDButton>
            <MDButton
              variant="gradient"
              color="dark"
              sx={{ ml: 2 }}
              onClick={() => fullPayment()}
            >
              Pay {!payment ? 'full' : 'balance'}
            </MDButton>
          </MDBox>
        ) : (
          <MDBox
            mt={2}
            sx={{
              display: 'flex',
              justifyContent: 'end',
            }}
            component="form"
            onSubmit={handleSubmit1}
            fontSize="14px"
          >
            <TextField
              class="w-full text-gray-700"
              name="numberformat"
              id="formatted-numberformat-input"
              placeholder="Enter Amount"
              onChange={(e) => setAmount(e.target.value)}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              autoFocus
            />
            <MDButton
              variant="outlined"
              color="dark"
              onClick={handleClose}
              size="small"
            >
              Cancel
            </MDButton>
            <MDButton
              type="submit"
              variant="gradient"
              color="dark"
              sx={{ ml: 2 }}
              size="small"
              // onClick={() => handleSubmit()}
            >
              Confirm
            </MDButton>
          </MDBox>
        )}
      </Paper>
    </DashboardLayout>
  )
}

export default ApproveForm
