import { useState } from "react";
import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

//Firebase Hook
import { useCollection } from "hooks/useCollection";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

const GateInOutTable = () => {
  const [responsive] = useState("horizontal");
  const [tableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight] = useState("");
  const [searchBtn] = useState(true);
  const [downloadBtn] = useState(true);
  const [printBtn] = useState(true);
  const [viewColumnBtn] = useState(true);
  const [filterBtn] = useState(true);
  // const [clientId, setClientId] = useState('')

  const { documents } = useCollection("jobs");
  const data = documents.filter(data => {
    if (
      data.preAlertstatus === "IN PROGRESS" &&
      data.freightType === "Transfer" &&
      data.containerInfo
    ) {
      const containerGateStatusValues = data.containerInfo.map(
        info => info.gateStatus
      );
      return containerGateStatusValues.every(status => status === "Gate-In");
    }

    return false;
  });
  console.log(data)
  let filteredData = [];
  function filterContainers(data) {
    return data.filter(item => {
      if (item.containerInfo) {
        const gateStatusValues = item.containerInfo.map(
          info => info.gateStatus
        );
        const allGateStatusIn = gateStatusValues.every(
          status => status === "Gate-In" || status === "Gate-Out"
        );
        const allGateStatusGateIn = gateStatusValues.every(
          status => status === "Gate-Out"
        );

        if (allGateStatusIn && !allGateStatusGateIn) {
          return true;
        }

        if (!allGateStatusIn) {
          return true;
        }

        return false;
      }

      return true;
    });
  }

  filteredData = filterContainers(data);
  console.log(filteredData);
  const columns = [
    {
      name: "airWayBill",
      label: "MAWB",
      hide: true,
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "billOfLaden",
      label: "BOL/MAWB",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta.rowData, '......')
          return (
            <div>
              {tableMeta.rowData[0]} {tableMeta.rowData[1]}
            </div>
          );
        }
      }
    },
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "importType",
      label: "Import Type",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "containerInfo",
      label: "Containers",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta.rowData, '......')
          return (
            <div>
              {tableMeta.rowData[4].length}
            </div>
          );
        }
      }
    },
    {
      name: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Button
                variant="gradient"
                color="dark"
                size="small"
                style={{
                  backgroundColor: "#033237",
                  color: "#ffffff",
                  fontSize: "13px"
                }}>
                <Link to={`/gate/gate-in-out-form/${tableMeta.rowData[2]}`}>
                  Gate-out
                </Link>
              </Button>
            </div>
          );
        }
      }
    }
  ];
  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 1,
              paddingTop: 1,
              backgroundColor: "#033237"
            }
          }
        },

        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              "&:nth-child(odd)": {
                //backgroundColor: "#f6f6f6"
              },
              fontSize: "12px"
            }
          }
        }
      }
    });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="mt-4">
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              title={"Gate In Container"}
              data={filteredData}
              columns={columns}
              options={options}
              style={{ marginTop: "10px" }}
            />
          </ThemeProvider>
        </CacheProvider>
      </div>
    </DashboardLayout>
  );
};

export default GateInOutTable;
