/** 
  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";
import CompletedJobs from "../CompletedJobs";
import ConsigneeTable from "../ConsigneeTable";
import AllEmployee from "../EmployeeTable";
import PendingJobs from "../PendingJobs";
import PreAlertJobs from "../PreAlertJob";
import Home from "./index"
import JobRelated from "../MakeRequest/JobRelated";
import NonJobRelated from "../MakeRequest/NonJobRelated";


const routes = [
  {
    type: "singleRoute",
    singleRoute: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "dashboard",
    component: <Home />,
  },
  { type: "divider", key: "divider-0" },

  {
    type: "title",
    title: "FREIGHT FORWARDING",
    key: "ExecutiveManagement",
  },

  {
    type: "collapse",
    name: "Client Management",
    key: "client-management",
    icon: <Icon fontSize="small">groups</Icon>,
    collapse: [
      {
        name: "Consignees",
        key: "consignees",
        route: "client-management/consignees",
        icon: <Icon fontSize="small">group</Icon>,
        component: <ConsigneeTable />,
      },
      {
        name: "Companies",
        key: "companies",
        route: "client-management/companies",
        icon: <Icon fontSize="small">group</Icon>,
        component: <Home />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Employees Management",
    key: "employees-management",
    icon: <Icon fontSize="small">assignment_ind</Icon>,
    collapse: [
      {
        name: "All Employees",
        key: "all-employees",
        route: "employees-management/all-employees",
        icon: <Icon fontSize="small">group</Icon>,
        component: <AllEmployee />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Job File Management",
    key: "job-file-management",
    icon: <Icon fontSize="small">folder</Icon>,
    collapse: [
      {
        name: "Pre-Alert Jobs",
        key: "pre-alert-jobs",
        route: "job-file-management/pre-alert-jobs",
        icon: <Icon fontSize="small">work</Icon>,
        component: <PreAlertJobs />,
      },
      {
        name: "Pending Jobs Files",
        key: "pending-job-files",
        route: "job-file-management/pending-job-files",
        icon: <Icon fontSize="small">work_history</Icon>,
        component: <PendingJobs />,
      },
      {
        name: "Completed Jobs Files",
        key: "completed-job-files",
        route: "job-file-management/completed-job-files",
        icon: <Icon fontSize="small">work_off</Icon>,
        component: <CompletedJobs />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="small">groups</Icon>,
    collapse: [
      {
        name: "Finance Reports",
        key: "financial-reports",
        route: "reports/financial-reports",
        component: <Home />,
      },
      {
        name: "Transportation Reports",
        key: "transportation-reports",
        route: "reports/transportation-reports",
        icon: <Icon fontSize="small">groups</Icon>,
        component: <Home />,
      },
      {
        name: "Freight Forwarding Reports",
        key: "freight-forwarding-reports",
        route: "reports/freight-forwarding-reports",
        icon: <Icon fontSize="small">groups</Icon>,
        component: <Home />,
      },
      {
        name: "Consignee Reports",
        key: "consignee-reports",
        route: "reports/consignee-reports",
        icon: <Icon fontSize="small">groups</Icon>,
        component: <Home />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Make Request',
    key: 'make-request',
    icon: <Icon fontSize="small">queue</Icon>,
    collapse: [
      {
        name: 'Job Related',
        key: 'job-related',
        route: 'make-request/job-related',
        icon: <Icon fontSize="small">content_paste</Icon>,
        component: <JobRelated />,
      },
      {
        name: 'Non Job Related',
        key: 'non-job-related',
        route: 'make-request/non-job-related',
        icon: <Icon fontSize="small">content_paste_off</Icon>,
        component: <NonJobRelated />,
      },
    ]
  },

  { type: "divider", key: "divider-1" },
];

export default routes;
