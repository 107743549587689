import { useState } from "react";
import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import React from "react";
import { collection, query, where } from "firebase/firestore";
//Firebase Hook
import { useCollection } from "hooks/useCollection";
import {
  ArrowNarrowDownIcon,
  ArrowNarrowUpIcon,
  CursorClickIcon,
  FolderOpenIcon,
  UsersIcon
} from "@heroicons/react/outline";
import { appFirestore } from "../../../firebase/config";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TotalContainers = () => {
  const [responsive] = useState("horizontal");
  const [tableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight] = useState("");
  const [searchBtn] = useState(true);
  const [downloadBtn] = useState(true);
  const [printBtn] = useState(true);
  const [viewColumnBtn] = useState(true);
  const [filterBtn] = useState(true);
  const [userId, setUserId] = useState("");
  const [openTab, setOpenTab] = React.useState(1);
  const { documents: fieldAgents } = useCollection("jobs");
  const { documents: allJobs } = useCollection("jobs");

  const totalJobs = allJobs.filter(
    el =>
      el.preAlertstatus !== "Terminated" && el.preAlertstatus !== "COMPLETED"
  );

  const filterFalsy = array =>
    array
      .filter(Boolean)
      .map(item => (Array.isArray(item) ? filterFalsy(item) : item));

  const iscrossed = totalJobs.map(job =>
    job.containerInfo.map(el => el.isCrossed)
  );

  const truth = filterFalsy(iscrossed);

  const resultTrue = truth.flat(1);
  // const realTruth = truth.filter(el => el === true)
  const real = totalJobs.flat(1);

  const fieldreport = real.filter(
    agent =>
      agent.agentStarted === true &&
      agent.preAlertstatus !== "COMPLETED" &&
      agent.preAlertstatus !== "TERMINATED"
  );
  const iscrosseds = totalJobs.map(job => job.containerInfo);
  const result2 = iscrosseds.flat(1);
  const totalUnLoad = totalJobs.map(el =>
    el.containerInfo.map(el => el.containerId)
  );
  const tResult = result2
  console.log(tResult);
  const columns = [
    {
      name: "containerId",
      label: "Container ID",
      options: { filter: true, sort: true }
    },
    {
      name: "containerSize",
      label: "Container Size",
      options: { filter: true, sort: true }
      },
     {
      name: "isLoaded",
      label: "Container Loaded",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: dataIndex => {
          return tResult[dataIndex].isLoaded === true ? "YES" : "NO";
        }
      }
    },
     {
      name: "returnContainer",
      label: "Container Returned",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: dataIndex => {
          return tResult[dataIndex].containerReturn === true ? "YES" : "NO";
        }
      }
    },
    {
      name: "id",
      hide: true,
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false
      }
    }
  ];

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: "firstName",
      direction: "asc"
    }
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 1,
              paddingTop: 1,
              fontSize: "2pt",
              borderBottom: "2px solid black",
              backgroundColor: "#033237",
              zIndex: 5
            }
          },
          paddingBottom: 2,
          marginBottom: 8,
          paddingTop: 1,
          backgroundColor: "#033237"
        }
      }
    });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme}>
          <MUIDataTable
            data={
              tResult // title={`Processed ${fieldreport.length} out of ${countAllJobs.length}`}
            }
            columns={columns}
            options={options}
            style={{ marginTop: "10px", zIndex: "z-10" }}
          />
        </ThemeProvider>
      </CacheProvider>
    </DashboardLayout>
  );
};

export default TotalContainers;
