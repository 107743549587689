import { useState } from 'react'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import MUIDataTable from 'mui-datatables'
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

//style
import './Prealert.css'

// Firebase
import { useCollection } from '../../../hooks/useCollection'

const muiCache = createCache({
  key: 'mui-datatables',
  prepend: true,
})

const PreAlertJobs = () => {
  const [responsive] = useState('standard')
  const [tableBodyHeight] = useState('auto')
  const [tableBodyMaxHeight] = useState('')
  const [searchBtn] = useState(true)
  const [downloadBtn] = useState(true)
  const [printBtn] = useState(true)
  const [viewColumnBtn] = useState(true)
  const [filterBtn] = useState(true)

  const { documents } = useCollection('jobs')

  const data = documents.filter((data) => data.preAlertstatus === 'PENDING')

  const columns = [
    {
      name: 'airWayBill',
      label: 'BOL/MAWB',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'id',
      hide: true,
      options: {
        display: false,
      },
    },
    {
      name: 'freightType',
      label: 'Freight Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'preAlertCreatedAt',
      label: 'Date / Time Created',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'jobCreatorId',
      label: 'Created By',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'preAlertstatus',
      label: 'Job Status',
      options: {
        filter: true,
        sort: true,
      },
    },
  ]

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: 'fileId',
      direction: 'asc',
    },
  }

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 12,
              marginBottom: 12,
              paddingTop: 8,
              backgroundColor: '#033237',
            },
          },
        },

        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              '&:nth-child(odd)': {
                //backgroundColor: "#f6f6f6"
              },
              fontSize: '12px',
            },
          },
        },
      },
    })

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="mt-4">
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              title={'Pre-Alert Jobs'}
              data={data}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </CacheProvider>
      </div>
    </DashboardLayout>
  )
}

export default PreAlertJobs
