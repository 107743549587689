/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";
import { ClientAuthentication } from "./ClientAuthentication";
import { EmployeeAuthentication } from "./EmployeeAuthentication";
import DataTables from "./EmployeeTable";
import Home from "./Home/index";
import JobRelated from "./MakeRequest/JobRelated";
import NonJobRelated from "./MakeRequest/NonJobRelated";
import NewUser from "./new-staff";
import EditNewStaff from "./new-staff/EditNewStaff";
import AllRequest from "./AllRequest";
import PendingRequests from "./PendingRequests";
import ApprovedRequests from "./ApprovedRequests";
import DeclinedRequests from "./DeclinedRequests";
import ExpenseTracker from "./Report";

const routes = [
  {
    type: "singleRoute",
    singleRoute: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "dashboard",
    component: <Home />
  },
  { type: "divider", key: "divider-0" },

  {
    type: "title",
    title: "Admin & Human Resources",
    key: "HR"
  },
  {
    type: "collapse",
    name: "Employee Management",
    key: "employee-management",
    icon: <Icon fontSize="small">assignment_ind</Icon>,
    collapse: [
      {
        name: "Create Employee",
        key: "add-employees",
        route: "employee-management/add-employees",
        icon: <Icon fontSize="small">person_add_alt</Icon>,
        component: <NewUser />
      },
      {
        name: "All Employees",
        key: "all-employees",
        route: "employee-management/all-employees",
        icon: <Icon fontSize="small">group</Icon>,
        component: <DataTables />
      }
    ]
  },
  {
    type: "collapse",
    name: "Make Request",
    key: "make-request",
    icon: <Icon fontSize="small">queue</Icon>,
    collapse: [
      {
        name: "Job Related",
        key: "job-related",
        route: "make-request/job-related",
        icon: <Icon fontSize="small">content_paste</Icon>,
        component: <JobRelated />
      },
      {
        name: "Non Job Related",
        key: "non-job-related",
        route: "make-request/non-job-related",
        icon: <Icon fontSize="small">content_paste_off</Icon>,
        component: <NonJobRelated />
      }
    ]
  },
  {
    type: "collapse",
    name: "Finance Management",
    key: "financial-request",
    icon: <Icon fontSize="small">monetization_on</Icon>,
    collapse: [
      {
        name: "All Requests",
        key: "all-request",
        route: "financial-request/all-requests",
        icon: <Icon fontSize="small">view_list</Icon>,
        component: <AllRequest />
      },
      {
        name: "Pending Requests",
        key: "pending-request",
        route: "financial-request/pending-request",
        icon: <Icon fontSize="small">hourglass_bottom</Icon>,
        component: <PendingRequests />
      },
      {
        name: "Approved Requests",
        key: "approved-request",
        route: "financial-request/approved-request",
        icon: <Icon fontSize="small">check_circle_outline</Icon>,
        component: <ApprovedRequests />
      },
      {
        name: "Declined Requests",
        key: "declined-request",
        route: "financial-request/declined-request",
        icon: <Icon fontSize="small">cancel</Icon>,
        component: <DeclinedRequests />
      }
    ]
  },
  {
    type: "hiddenRoute",
    key: "add-employees",
    route: "employee-management/edit-employees/:id",
    component: <EditNewStaff />
  },
  {
    type: "hiddenRoute",
    name: "Create Authentication",
    key: "create-authentication",
    route: "employee-management/create-authentication",
    icon: <Icon fontSize="small">admin_panel_settings</Icon>,
    collapse: [
      {
        type: "hiddenRoute",
        name: "Employee Authentication",
        key: "create-employee",
        route: "employee-management/employee-authentication/:id",
        icon: <Icon fontSize="small">verified_user</Icon>,
        component: <EmployeeAuthentication />
      }
    ]
  },
  {
    type: "hiddenRoute",
    name: "Client Authentication",
    key: "create-job-manager",
    route: "employee-management/client-authentication",
    icon: <Icon fontSize="small">verified_user</Icon>,
    component: <ClientAuthentication />
  },
  {
    type: "hiddenRoute",
    name: "Financial Request",
    key: "financial-requests",
    route: "financial-requests",
    icon: <Icon fontSize="small">verified_user</Icon>,
    component: <ClientAuthentication />
  },
  {
    type: "hiddenRoute",
    name: "Pending Requests",
    key: "pending-requests",
    route: "pending-requests",
    icon: <Icon fontSize="small">verified_user</Icon>,
    component: <ClientAuthentication />
  },
  {
    type: "hiddenRoute",
    name: "Approved Requests",
    key: "approved-requests",
    route: "approved-requests",
    icon: <Icon fontSize="small">verified_user</Icon>,
    component: <ClientAuthentication />
  },
  {
    type: "hiddenRoute",
    name: "Declined Requests",
    key: "declined-requests",
    route: "declined-requests",
    icon: <Icon fontSize="small">verified_user</Icon>,
    component: <ClientAuthentication />
  },

  // {
  //   type: "collapse",
  //   name: "Report",
  //   key: "expense-report",
  //   icon: <Icon fontSize="small">monetization_on</Icon>,
  //   collapse: [
  //     {
        
  //       name: "Expense Tracker",
  //       key: "expense-tracker",
  //       route: "expense-report",
  //       icon: <Icon fontSize="small">verified_user</Icon>,
  //       component: <ExpenseTracker />
  //     }
  //   ]
  // },
  { type: "divider", key: "divider-1" }
];

export default routes;
