import { useNavigate } from 'react-router-dom'

// @mui material components
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'

import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'

import ComplexStatisticsCard from 'customs/Cards/StatisticsCards/ComplexStatisticsCard'

//Firebase Hooks
import { useCollection } from '../../../hooks/useCollection'
import { useAuthContext } from '../../../hooks/useAuthContext'

function Home() {
  let navigate = useNavigate()

  const { documents: alerts } = useCollection('jobs')
  const { documents: njr } = useCollection('nonJobRelated')
  const { documents: jr } = useCollection('jobRelatedForm')
  const { user } = useAuthContext()

  const approved1 = njr.filter(
    (ap) => ap.status === 'Approved' && ap.email === user.email
  )
  const approved2 = jr.filter(
    (ap) => ap.status === 'Approved' && ap.email === user.email
  )
  const pending1 = jr.filter(
    (ap) =>
      ap.status !== 'Approved' &&
      ap.status !== 'Declined' &&
      ap.email === user.email
  )
  const pending2 = njr.filter(
    (ap) =>
      ap.status !== 'Approved' &&
      ap.status !== 'Declined' &&
      ap.email === user.email
  )
  const declined1 = njr.filter(
    (ap) => ap.status === 'Declined' && ap.email === user.email
  )
  const declined2 = jr.filter(
    (ap) => ap.status === 'Declined' && ap.email === user.email
  )
  const pending = alerts.filter(
    (alert) => alert.preAlertstatus === 'IN PROGRESS'
  )

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/staff/request-status/approved', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="check_circle_outline"
                  title="Approved Requests"
                  users={approved1.length + approved2.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/staff/request-status/pending', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="hourglass_bottom"
                  title="Pending Requests"
                  users={pending1.length + pending2.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/staff/request-status/declined', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="error"
                  icon="cancel"
                  title="Declined Requests"
                  users={declined1.length + declined2.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/staff/job-management/pending-job', {
                  replace: false,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="work_history"
                  color="dark"
                  title="Pending Jobs"
                  count=""
                  users={pending.length}
                  percentage={{
                    color: 'dark',
                    amount: 15,
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  )
}

export default Home
