import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { firstName, lastName },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [lastName.name]: Yup.string().required(lastName.errorMsg),
    // [gender.name]: Yup.string().required(gender.errorMsg),
  }),
];

export default validations;
