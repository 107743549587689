import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Grid,
  Paper,
  TextField,
  CircularProgress
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import FormFields from "layouts/pages/account/components/FormField";
import React from "react";
import { selectData } from "./data";
import { format } from "date-fns";
import PropTypes from "prop-types";

//Firebase Hooks
import { useFirestore } from "hooks/useFirestore";
import { useCollection } from "hooks/useCollection";
import { useAuthContext } from "../../../hooks/useAuthContext";

import NumberFormat from "react-number-format";
import PopSuccess from "views/PopMessage/PopSuccess";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="₦"
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const NonJobRelated = () => {
  const [paymentValue, setPaymentValue] = useState("");
  const [request, setRequest] = useState("");
  // const [requester, setRequester] = useState('')
  const [description, setDescription] = useState("");
  const [date] = useState(format(new Date(), "MM/dd/yyyy 'at' h:mm a"));
  const [requestId, setRequestId] = useState("");
  const [status] = useState("Pending");

  const { documents } = useCollection("nonJobRelated");
  const { user } = useAuthContext();

  const { addDocument, message, isPending } = useFirestore("nonJobRelated");
  const navigate = useNavigate();

  const finDate = format(new Date(), "yyyyMMdd");
  const payment = documents.map(function(amount) {
    return parseInt(amount.requestId.slice(-4));
  });

  const values = Object.values(payment);
  // const sliced = parsed.slice(-4)
  let biggest = values[0];

  for (var i = 0; i < values.length; i++) {
    if (biggest < values[i]) {
      biggest = values[i];
    }
  }
   console.log(biggest);
  useEffect(
    () => {
      if (documents) {
        setRequestId(`FNJR/${finDate}/0${biggest + 1}`);
      }
    },
    [documents, finDate]
  );

  const handleSubmit = async e => {
    e.preventDefault();
    const email = user.email;
    const department = user.photoURL;

    addDocument({
      paymentValue,
      request,
      description,
      date,
      email,
      department,
      requestId,
      status
    });
    if (message) {
      e.target.reset();
    }
  };

  useEffect(
    () => {
      if (message) {
        setTimeout(() => {
          navigate("/monitoring-compliance/financial-request/pending-request", {
            replace: true
          });
        }, 5000);
      }
    },
    [message, navigate]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
        <MDBox mt={5} component="form" onSubmit={handleSubmit}>
          <MDTypography
            variant="h5"
            fontWeight="bold"
            sx={{ borderBottom: 1, pb: 1, pt: 4 }}>
            Non Job Related Financial Request Form
          </MDTypography>
          <MDBox mt={1.625}>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Payment Value{" "}
                </MDTypography>
                <FormFields
                  name="numberformat"
                  id="formatted-numberformat-input"
                  placeholder="Enter Payment Value"
                  InputProps={{
                    inputComponent: NumberFormatCustom
                  }}
                  variant="outlined"
                  onChange={e => setPaymentValue(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Request For </MDTypography>
                <MDBox>
                  <Autocomplete
                    defaultValue="Select reason for payment"
                    options={selectData.paymentType}
                    onChange={(e, newValue) => setRequest(newValue)}
                    renderInput={params =>
                      <FormFields
                        {...params}
                        InputLabelProps={{ shrink: true }}
                      />}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12}>
                <MDTypography sx={{ fontSize: 13 }}>Description </MDTypography>
                <TextField
                  fullWidth
                  placeholder="Enter your description here..."
                  multiline
                  rows={4}
                  onChange={e => setDescription(e.target.value)}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "2rem"
            }}>
            {!isPending &&
              <MDButton
                style={{ fontSize: "12px" }}
                variant="gradient"
                color="dark"
                type="submit">
                Submit Request
              </MDButton>}
            {isPending &&
              <MDButton
                variant="gradient"
                color="dark"
                disabled
                sx={{ width: "180px" }}>
                <CircularProgress color="white" size={20} />
              </MDButton>}
          </MDBox>
        </MDBox>
      </Paper>
      {message && <PopSuccess />}
    </DashboardLayout>
  );
};

export default NonJobRelated;
