const selectData = {
  containerSize: [
    "Select Container Size",
    "Package",
    "20 feet",
    "40 feet",
    "45 feet"
  ],
  freight: [
    "Direct",
    "Transfer",
  ],
  importType: [
    "Sea Freight",
    "Air freight"
  ],
  jobType: [
    "Import Custom Clearance",
    "Export",
    "Haulage",
    "Personal Effect",
  ]
};

export default selectData;
