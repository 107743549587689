import React from 'react'
import ReactDOM from 'react-dom'
// import { BrowserRouter } from 'react-router-dom'
import App from 'App'

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from 'context'
import { AuthContextProvider } from 'context/AuthContext'

ReactDOM.render(
  <MaterialUIControllerProvider>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </MaterialUIControllerProvider>,
  document.getElementById('root')
)
