import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Grid } from '@mui/material'
import { Visibility } from '@mui/icons-material'

//Firebase Hook
import {useKingsooService} from '../../../../hooks/useKingsooServices'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: '#ffffff' }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export default function Modal({ id, setConsigneeId }) {
  const {getConsignee} = useKingsooService()
  const [open, setOpen] = useState(false)
  const [consigneeName, setConsigneeName] = useState('')
  const [consigneeType, setConsigneeType] = useState('')
  const [prefix, setPrefix] = useState('')
  const [rcNumber, setRcNumber] = useState('')
  const [consigneeCategory, setConsigneeCategory] = useState('')
  const [businessCategory, setBusinessCategory] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [businessAddress, setBusinessAddress] = useState('')
  const [altPhone, setAltPhone] = useState('')
  const [wareHouseAddress, setWareHouseAddress] = useState('')
  const [companyEmail, setCompanyEmail] = useState('')
  const [tin, setTin] = useState('')
  const [registeredCompanyAddress, setRegisteredCompanyAddress] = useState('')
  const [AltCompanyPhone, setAltCompanyPhone] = useState('')
  const [companyPhone, setCompanyPhone] = useState('')
  const [contactName, setContactName] = useState('')

  const detail = async () => {
    try {
      const docSnap = await getConsignee(id)

      setConsigneeName(docSnap.data().consigneeName)
      setCompanyPhone(docSnap.data().companyPhone)
      setConsigneeType(docSnap.data().consigneeType)
      setPrefix(docSnap.data().prefix)
      setRcNumber(docSnap.data().rcNumber)
      setConsigneeCategory(docSnap.data().consigneeCategory)
      setBusinessCategory(docSnap.data().businessCategory)
      setCompanyAddress(docSnap.data().companyAddress)
      setPhone(docSnap.data().phone)
      setBusinessAddress(docSnap.data().businessAddress)
      setAltPhone(docSnap.data().altPhone)
      setCompanyEmail(docSnap.data().companyEmail)
      setWareHouseAddress(docSnap.data().wareHouseAddress)
      setRegisteredCompanyAddress(docSnap.data().registeredCompanyAddress)
      setAltCompanyPhone(docSnap.data().AltCompanyPhone)
      setContactName(docSnap.data().contactName)
      setTin(docSnap.data().tin)
    } catch (error) {
      // console.log(error)
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    // console.log('na u know', id)
    if (id !== undefined || id !== '') {
      detail()
      // console.log('id', id)
    }
  }, [id])

  return (
    <div>
      <div onClick={handleClickOpen}>
        <Visibility
          style={{
            width: '20px',
            height: '20px',
            color: '#033237',
          }}
        />
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          sx={{ backgroundColor: '#033237', color: '#ffffff' }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {consigneeName}
        </BootstrapDialogTitle>
        <Grid container sm={12}>
          <Grid item sm={6} sx={{ pl: 2, pt: 4, mr: 4 }}>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Consignee Name: {consigneeName}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Category: {consigneeCategory}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Business Type: {businessCategory}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Registered Address: {registeredCompanyAddress}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Warehouse Address: {wareHouseAddress}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Alternative Phone Number: {altPhone}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Prefix: {prefix}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Contact Phone Number: {phone}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Contact Alternate Phone: {AltCompanyPhone}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Comapny Type: {consigneeType}
            </Typography>
          </Grid>
          <Grid item sm={4} sx={{ pl: 2, pt: 4 }}>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              RC/BN Number: {rcNumber}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Business Address: {businessAddress}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Business Category: {businessCategory}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Company Phone Number: {companyPhone}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Email Address: {companyEmail}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Contact Person’s Name: {contactName}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              TIN: {tin}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', mb: 4, width: '100%' }}
              gutterBottom
            >
              Contact Address: {companyAddress}
            </Typography>
          </Grid>
        </Grid>
      </BootstrapDialog>
    </div>
  )
}
