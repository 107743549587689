import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

//Firebase Hook
import {useKingsooService} from '../../../../hooks/useKingsooServices'

import { Visibility } from "@mui/icons-material";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose
        ? <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}>
          <CloseIcon sx={{ color: "#ffffff" }} />
        </IconButton>
        : null}
    </DialogTitle>
  );
};

export default function Modal({ id, setUserId }) {
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [otherName, setOtherName] = useState("");
  const [gender, setGender] = useState("");
  const [marritalStatus, setMarritalStatus] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [nationality, setNationality] = useState("");
  const [stateOfOrigin, setStateOfOrigin] = useState("");
  const [lGA, setLGA] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [academicQualification, setAcademicQualification] = useState("");
  const [university, setUniversity] = useState("");
  const [dateOfGraduation, setDateOfGraduation] = useState("");
  const [staffId, setStaffId] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [employeeDepartment, setEmployeeDepartment] = useState("");
  const [employeeUnit, setEmployeeUnit] = useState("");
  const [employeedesignation, setEmployeeDesignation] = useState("");
  const [employeeConfirmation, setEmployeeConfirmation] = useState("");
  const [employeeLocation, setEmployeeLocation] = useState("");
  const [dateOfEmployment, setDateOfEmployement] = useState("");
  const [GrossSalary, setGrossSalary] = useState("");
  const [refereeFirstName, setRefereeFirstName] = useState("");
  const [refereeLastName, setRefereeLastName] = useState("");
  const [refereePhoneNumber, setRefereePhoneNumber] = useState("");
  const [refereeAddress, setRefereeAddress] = useState("");
  const [refereeFirstName1, setRefereeFirstName1] = useState("");
  const [refereeLastName1, setRefereeLastName1] = useState("");
  const [refereePhoneNumber1, setRefereePhoneNumber1] = useState("");
  const [refereeAddress1, setRefereeAddress1] = useState("");
  const [employeeBank, setEmployeeBank] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [pensionManager, setPensionManager] = useState("");
  const [PensionNumber, setPensionNumber] = useState("");
  const [spouseFirstName, setSpouseFirstName] = useState("");
  const [spouseLastName, setSpouseLastName] = useState("");
  const [spousePhoneNumber, setSpousePhoneNumber] = useState("");
  const [spouseEmailAddress, setSpouseEmailAddress] = useState("");
  const [numberOfChildren, setNumberOfChildren] = useState("");
  const [nextFirstName, setNextFirstName] = useState("");
  const [nextLastName, setNextLastName] = useState("");
  const [nextPhoneNumber, setNextPhoneNumber] = useState("");
  const [nextPermanentAddress, setNextPermanentAddress] = useState("");
  const [nextOfKin, setnextOfKin] = useState("");
  const [emergencyFirstName, setEmergencyFirstName] = useState("");
  const [emergencyLastName, setEmergencyLastName] = useState("");
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState("");
  const [emergencyFirstName1, setEmergencyFirstName1] = useState("");
  const [emergencyLastName1, setEmergencyLastName1] = useState("");
  const [emergencyPhoneNumber1, setEmergencyPhoneNumber1] = useState("");

  const [fullWidth] = useState(true);


  const {getUser} = useKingsooService()


  useEffect(
    () => {
      const detail = async () => {
        try {
          const docSnap = await getUser(id);
          setFirstName(docSnap.data().firstName);
          setLastName(docSnap.data().lastName);
          setOtherName(docSnap.data().otherName);
          setGender(docSnap.data().gender);
          setMarritalStatus(docSnap.data().marritalStatus);
          setWorkEmail(docSnap.data().workEmail);
          setPhoneNumber(docSnap.data().phoneNumber);
          setDateOfBirth(docSnap.data().dateOfBirth);
          setNationality(docSnap.data().nationality);
          setStateOfOrigin(docSnap.data().stateOfOrigin);
          setLGA(docSnap.data().lGA);
          setCurrentAddress(docSnap.data().currentAddress);
          setPermanentAddress(docSnap.data().permanentAddress);
          setAcademicQualification(docSnap.data().permanentAddress);
          setUniversity(docSnap.data().university);
          setDateOfGraduation(docSnap.data().dateOfGraduation);
          setStaffId(docSnap.data().staffId);
          setEmployeeType(docSnap.data().employeeType);
          setEmployeeDepartment(docSnap.data().employeeDepartment);
          setEmployeeUnit(docSnap.data().employeeUnit);
          setEmployeeDesignation(docSnap.data().employeedesignation);
          setEmployeeConfirmation(docSnap.data().employeeConfirmation);
          setDateOfEmployement(docSnap.data().dateOfEmployment);
          setEmployeeLocation(docSnap.data().employeeLocation);
          setGrossSalary(docSnap.data().GrossSalary);
          setRefereeFirstName(docSnap.data().refereeFirstName);
          setRefereeLastName(docSnap.data().refereeLastName);
          setRefereePhoneNumber(docSnap.data().refereePhoneNumber);
          setRefereeAddress(docSnap.data().refereeAddress);
          setRefereeFirstName1(docSnap.data().refereeFirstName1);
          setRefereeLastName1(docSnap.data().refereeLastName1);
          setRefereePhoneNumber1(docSnap.data().refereePhoneNumber1);
          setRefereeAddress1(docSnap.data().refereeAddress1);
          setEmployeeBank(docSnap.data().employeeBank);
          setAccountNumber(docSnap.data().accountNumber);
          setPensionManager(docSnap.data().pensionManager);
          setPensionNumber(docSnap.data().PensionNumber);
          setSpouseFirstName(docSnap.data().spouseFirstName);
          setSpouseLastName(docSnap.data().spouseLastName);
          setSpousePhoneNumber(docSnap.data().spousePhoneNumber);
          setSpouseEmailAddress(docSnap.data().spouseEmailAddress);
          setNumberOfChildren(docSnap.data().numberOfChildren);
          setNextFirstName(docSnap.data().nextFirstName);
          setNextLastName(docSnap.data().nextLastName);
          setNextPhoneNumber(docSnap.data().nextPhoneNumber);
          setNextPermanentAddress(docSnap.data().nextPermanentAddress);
          setnextOfKin(docSnap.data().nextOfKin);
          setEmergencyFirstName(docSnap.data().emergencyFirstName);
          setEmergencyLastName(docSnap.data().emergencyLastName);
          setEmergencyPhoneNumber(docSnap.data().emergencyPhoneNumber);
          setEmergencyFirstName1(docSnap.data().emergencyFirstName1);
          setEmergencyLastName1(docSnap.data().emergencyLastName1);
          setEmergencyPhoneNumber1(docSnap.data().emergencyPhoneNumber1);
        } catch (error) {
          // console.log(error)
        }
      };

      // console.log('na u know', id)
      if (id !== undefined || id !== "") {
        detail();
        // console.log('id', id)
      }
    },
    [id]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div sx={{ color: "#033237" }} onClick={handleClickOpen}>
        <Visibility style={{ width: "20px", height: "20px", color: "black" }} />
      </div>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <BootstrapDialogTitle
          sx={{ backgroundColor: "#033237", color: "#ffffff" }}
          id="customized-dialog-title"
          onClose={handleClose}>
          <b className="text-2xl capitalize">
            {lastName} {otherName} {firstName}
          </b>
        </BootstrapDialogTitle>
        <Grid container sm={12}>
          <Grid item sm={6} sx={{ pl: 2, pt: 4, }}>
            <Typography
              sx={{ fontSize: "14px", mb: 4 }}
              gutterBottom>
              <li>
                <b className="text-sm">Employee Name:{" "}</b>
                <span className="capitalize">
                  {firstName} {otherName} {lastName}
                </span>
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Gender: {" "}</b>
                {gender}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Staff ID: {" "}</b>
                {staffId}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Department: {" "}</b>
                {employeeDepartment}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Nationality: {" "}</b>
                {nationality}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Date of birth: {" "}</b>
                {dateOfBirth}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-muted">Permanent Address: {" "}</b>
                {permanentAddress}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Academic Qualifications: {" "}</b>
                {academicQualification}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Employment Type: {" "}</b>
                {employeeType}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Employmee Unit: {" "}</b>
                {employeeUnit}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Gross Salary: {" "}</b>
                {GrossSalary}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Referee's First Name: {" "}</b>
                {refereeFirstName}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Referee's Last Name: {" "}</b>
                {refereeLastName}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Referee's Phone Number: {" "}</b>
                {refereePhoneNumber}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Referee's Address: {" "}</b>
                {refereeAddress}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Bank Name: {" "}</b>
                {employeeBank}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Bank Account Number: {" "}</b>
                {accountNumber}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Pension Manager: {" "}</b>
                {pensionManager}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Pension Number: {" "}</b>
                {PensionNumber}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Spouse First Name: {" "}</b>
                {spouseFirstName}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Spouse Last Name: {" "}</b>
                {spouseLastName}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Spouse Phone Number: {" "}</b>
                {spousePhoneNumber}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Spouse Email Address: {" "}</b>
                {spouseEmailAddress}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Number of Children: {" "}</b>
                {numberOfChildren}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Emergency Contact Name: {" "}</b>
                {emergencyFirstName} {emergencyLastName}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Emergency Contact Number: {" "}</b>
                {emergencyPhoneNumber}
              </li>
            </Typography>
          </Grid>
          <Grid item sm={6} sx={{ pl: 2, pt: 4 }}>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Designation: {" "}</b>
                {employeedesignation}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Contact: {" "}</b>
                {phoneNumber}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Email: {" "}</b>
                {workEmail}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Marrital Status: {" "}</b>
                {marritalStatus}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">State Of Origin: {" "}</b>
                {stateOfOrigin}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">L.G.A: {" "}</b>
                {lGA}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Current Address: {" "}</b>
                {currentAddress}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Awarding Institution: {" "}</b>
                {university}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Date of Graduation: {" "}</b>
                {dateOfGraduation}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Employee Confirmation: {" "}</b>
                {employeeConfirmation}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Employee Location: {" "}</b>
                {employeeLocation}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Date Of Employment: {" "}</b>
                {dateOfEmployment}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">2 Referee's First Name: {" "}</b>
                {refereeFirstName1}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">2 Referee's Last Name: {" "}</b>
                {refereeLastName1}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">2 Referee's Phone Number: {" "}</b>
                {refereePhoneNumber1}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">2 Referee's Address: {" "}</b>
                {refereeAddress1}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Next Of Kin Names: {" "}</b>
                {nextFirstName} {nextLastName}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Next Of Kin Phone No: {" "}</b>
                {nextPhoneNumber}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Next Of Kin Address: {" "}</b>
                {nextPermanentAddress}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Next Of Kin Relationship: {" "}</b>
                {nextOfKin}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Emergency Contact Names: {" "}</b>
                {emergencyFirstName1}{" "}
                {emergencyLastName1}
              </li>
            </Typography>
            <Typography
              sx={{ fontSize: "14px", mb: 4, width: "100%" }}
              gutterBottom>
              <li>
                <b className="text-sm">Emergency Contact Number: {" "}</b>
                {emergencyPhoneNumber1}
              </li>
            </Typography>
          </Grid>
        </Grid>
      </BootstrapDialog>
    </div>
  );
}
