import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { useCollection } from "hooks/useCollection";

ChartJS.register(ArcElement, Tooltip, Legend);

const FinanceReportChart = () => {
  const { documents: finance } = useCollection("jobRelatedForm");
  const { documents: payOfficer } = useCollection("jobRelatedForm");
  const { documents: financeNon } = useCollection("nonJobRelated");
  const { documents: payOfficerN } = useCollection("nonJobRelated");

  // const { documents: allJobs } = useCollection("jobs");
  const financeReport = finance.filter(f => f.status === "Finance");
  const averageReport = payOfficer.filter(ar => ar.status === "Pay Officer");
  const averageNonReport = payOfficerN.filter(anr => anr.status === "Pay Officer");
  const financeNonReport = financeNon.filter(f => f.status === "Finance");

  // const countAllJobs = allJobs.filter(
  //   alls => alls.preAlertstatus === "IN PROGRESS" && alls.jobType !== "Export"
  // );

  const financeAllJobs = financeReport.concat(financeNonReport).length;
  const payOfficerResult = averageNonReport.length + averageReport.length;
  const total = finance.length + financeNon.length;
  const data = {
    labels: [
      "Total Requests",
      "Approved Request",
      "Pending Request"
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [total, payOfficerResult, financeAllJobs],
        backgroundColor: ["#033237", "orange", "red"], //   borderColor: [
        //     "rgba(255, 99, 132, 1)",
        //     "rgba(54, 162, 235, 1)",
        //     "rgba(255, 206, 86, 1)",
        //     "rgba(75, 192, 192, 1)",
        //     "rgba(153, 102, 255, 1)",
        //     "rgba(255, 159, 64, 1)"
        //   ],
        borderWidth: 1
      }
    ]
  };
  return (
    <Box
      sx={{
        paddingTop: 2,
        marginLeft: "auto",
        marginRight: "auto",
        width: 500,
        alignContent: "center",
        justifyContent: "center"
      }}>
      <Pie data={data} />
    </Box>
  );
};

export default FinanceReportChart;
