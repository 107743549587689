import { useState, useEffect } from 'react'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import MUIDataTable from 'mui-datatables'
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { useParams } from 'react-router-dom'
// import { Link } from 'react-router-dom'
// import { Visibility } from '@mui/icons-material'

//Firebase Hook
// import { Button } from '@mui/material'
import {useKingsooService} from '../../../hooks/useKingsooServices'

const muiCache = createCache({
  key: 'mui-datatables',
  prepend: true,
})

const PendingTable = () => {
  const [responsive] = useState('horizontal')
  const [tableBodyHeight] = useState('auto')
  const [tableBodyMaxHeight] = useState('')
  const [searchBtn] = useState(true)
  const [downloadBtn] = useState(true)
  const [printBtn] = useState(true)
  const [viewColumnBtn] = useState(true)
  const [filterBtn] = useState(true)
  const [containerInfo, setContainerInfo] = useState([])

  const {getJob} = useKingsooService()

  const { id } = useParams()
  //   console.log('id', id)

  const detail = async () => {
    try {
      const docSnap = await getJob(id)
      // console.log(docSnap.data())
      setContainerInfo(docSnap.data().containerInfo)
      //   setBillOfLaden(docSnap.data().airWayBill)
      //   setPreAlertImage(docSnap.data().preALertImage)
    } catch {
      //   console.log('error')
    }
  }
  useEffect(() => {
    // console.log('na u know', id)
    if ((id !== undefined || id !== '') && id !== ':id') {
      detail()
    }
  }, [id])

  //   const container = containerInfo.filter((con) => con.gateStatus === 'Gate-In')

  const columns = [
    {
      name: 'consigneeName',
      label: 'Consignee name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'containerId',
      label: 'Container No',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'containerSize',
      label: 'Container Size',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'cNumber',
      label: 'C-number',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'aNumber',
      label: 'A-number',
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //     name: 'Actions',
    //     options: {
    //         customBodyRender: (value, tableMeta, updateValue) => {
    //             return (
    //                 <div>
    //                     <Button
    //                         variant="gradient"
    //                         color="dark"
    //                         size="small"
    //                         style={{
    //                             backgroundColor: '#033237',
    //                             color: '#ffffff',
    //                             fontSize: '13px',
    //                         }}
    //                     >
    //                         <Link
    //                             to={`/gate/outwarded-containers/outward/${tableMeta.rowData[2]}`}
    //                         >
    //                             <Visibility
    //                                 style={{
    //                                     width: '20px',
    //                                     height: '20px',
    //                                     color: '#033237',
    //                                 }}
    //                             />
    //                         </Link>
    //                     </Button>
    //                 </div>
    //             )
    //         },
    //     },
    // },
  ]
  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
  }

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 1,
              paddingTop: 1,
              backgroundColor: '#033237',
            },
          },
        },

        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              '&:nth-child(odd)': {
                //backgroundColor: "#f6f6f6"
              },
              fontSize: '12px',
            },
          },
        },
      },
    })

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="mt-4">
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              title={'Terminal Table'}
              data={containerInfo}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </CacheProvider>
      </div>
    </DashboardLayout>
  )
}

export default PendingTable
