import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import React, { useState, useEffect } from "react";
import { Cancel, Check } from "@mui/icons-material";

import Modal from "./Modal";
import ApproveModal from "./ApproveModal";

// Firebase Hook
import { useCollection } from "hooks/useCollection";
import {useKingsooService} from '../../../hooks/useKingsooServices'
import PrintModal from "./PrintModal";

const NonStatutoryTable = () => {
  const [responsive] = useState("horizontal");
  const [tableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight] = useState("");
  const [searchBtn] = useState(true);
  const [downloadBtn] = useState(true);
  const [printBtn] = useState(true);
  const [viewColumnBtn] = useState(true);
  const [filterBtn] = useState(true);
  const [uid, setUid] = useState("");
  const [status, setStatus] = useState("");
  const [id, setId] = useState("");

  const { documents } = useCollection("jobRelatedForm");
  const data = documents.filter(
    data => data.paymentType === "Non Statutory" && data.status === "COO"
  );

  const handleClick = async id => {
    setStatus("Finance");
    setId(id);
  };
  const click = async id => {
    setStatus("Declined");
    setId(id);
  };

  useEffect(() => {
    if (status) {
      handleSubmit(id);
    }
  });

  const {updateFNJR} = useKingsooService()

  const handleSubmit = async id => {
    try {
      await updateFNJR(id, { status });
    } catch (error) {
      // console.log(error)
    }
  };

  const getId = id => {
    console.log("the id", id);
    setUid(id);
  };

  const muiCache = createCache({
    key: "mui-datatables",
    prepend: true
  });

  const columns = [
    {
      name: "requestId",
      label: "Request ID",
      options: { filter: true, sort: true }
    },
    {
      name: "id",
      label: "Id",
      hide: true,
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "fileId",
      label: "Job File Number",
      options: { filter: true, sort: true }
    },
    {
      name: "email",
      label: "Requester",
      options: { filter: true, sort: true }
    },
    {
      name: "date",
      label: "Date & Time",
      options: { filter: true, sort: true }
    },
    {
      name: "paymentValue",
      label: "Value",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: dataIndex => {
          let val = data[dataIndex].paymentValue;
          let ngn = Intl.NumberFormat("en-NG", {
            style: "currency",
            currency: "NGN"
          });
          return ngn.format(val);
        }
      }
    },
    {
      name: "description",
      label: "Description",
      options: { filter: true, sort: true }
    },
    { name: "status", label: "Status", options: { filter: true, sort: true } },
    {
      name: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta.rowData[7] === "COO") {
            return (
              <div className="flex flex-row gap-2">
                <button
                  style={{ background: "transparent", border: "none" }}
                  onClick={e => getId(tableMeta.rowData[1])}>
                  <Modal id={uid} setUid={setUid} />
                </button>
                <button
                  style={{
                    border: "none",
                    background: "transparent",
                    cursor: "pointer"
                  }}
                  onClick={e => click(tableMeta.rowData[1])}>
                  <Cancel
                    sx={{ width: "20px", height: "20px", color: "red" }}
                  />
                </button>
                <button
                  style={{
                    border: "none",
                    padding: "8px",
                    background: "transparent",
                    cursor: "pointer"
                  }}
                  onClick={e => getId(tableMeta.rowData[1])}>
                  <ApproveModal id={uid} setUid={setUid} />
                </button>
                <button
                  style={{ background: "transparent", border: "none" }}
                  onClick={e => getId(tableMeta.rowData[1])}>
                  <PrintModal id={uid} setUid={setUid} />
                </button>
              </div>
            );
          } else {
            return (
              <div className="flex flex-row gap-4">
                <button
                  style={{ background: "transparent", border: "none" }}
                  onClick={e => getId(tableMeta.rowData[1])}>
                  <Modal id={uid} setUid={setUid} />
                </button>
                <button
                  style={{ background: "transparent", border: "none" }}
                  onClick={e => getId(tableMeta.rowData[1])}>
                  <PrintModal id={uid} setUid={setUid} />
                </button>
              </div>
            );
          }
        }
      }
    }
  ];
  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: "requestId",
      direction: "asc"
    }
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 1,
              paddingTop: 1,
              backgroundColor: "#033237"
            }
          }
        },

        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              "&:nth-child(odd)": {
                //backgroundColor: "#f6f6f6"
              },
              fontSize: "12px"
            }
          }
        }
      }
    });
  return (
    <div>
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
};

export default NonStatutoryTable;
