import { useNavigate } from 'react-router-dom'
// @mui material components
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'

import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'

import ComplexStatisticsCard from 'customs/Cards/StatisticsCards/ComplexStatisticsCard'

//Firebase Hook
import { useCollection } from '../../../hooks/useCollection'

function Analytics() {
  const { documents } = useCollection('consignee')
  const { documents: client } = useCollection('clients')
  const { documents: jobs } = useCollection('jobs')
  const { documents: alerts } = useCollection('jobs')

  const data = jobs.filter((data) => data.preAlertstatus === 'IN PROGRESS')
  const pendingNew = alerts.filter(
    (alert) =>
      alert.preAlertstatus === 'IN PROGRESS' && alert.jobType === 'Export'
  )
  let navigate = useNavigate()
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/documentation/client-management/consignee', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  icon="group"
                  title="Consignees"
                  count=""
                  users={documents.length}
                />
              </MDBox>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/documentation/client-management/client', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="group"
                  title="Clients"
                  count=""
                  users={client.length}
                />
              </MDBox>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/documentation/file-management/pending-job-files', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="work_history"
                  color="success"
                  title="Pending Jobs"
                  count=""
                  users={data.length}
                />
              </MDBox>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/documentation/file-management/completed-job-files', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="work_off"
                  color="primary"
                  title="Completed Jobs"
                  count=""
                  users="0"
                  percentage={{
                    color: 'dark',
                    amount: 15,
                  }}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/documentation/file-management/export-job', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="file_upload"
                  title="Export Jobs"
                  users={pendingNew.length}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/documentation/request-status/approved', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="check_circle_outline"
                  title="Approved Requests"
                  users={0}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/documentation/request-status/pending', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="hourglass_bottom"
                  title="Pending Requests"
                  users={0}
                />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              onClick={() =>
                navigate('/documentation/request-status/declined', {
                  replace: true,
                })
              }
            >
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="error"
                  icon="cancel"
                  title="Declined Requests"
                  users={0}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  )
}

export default Analytics
