import { useState } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { Button } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";

// Firebase Hook
import { useCollection } from "../../../hooks/useCollection";
import { Visibility } from "@mui/icons-material";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

const CompletedJobTable = () => {
  const { documents } = useCollection("jobs");

  const [responsive] = useState("horizontal");
  const [tableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight] = useState("");
  const [searchBtn] = useState(true);
  const [downloadBtn] = useState(true);
  const [printBtn] = useState(true);
  const [viewColumnBtn] = useState(true);
  const [filterBtn] = useState(true);
  // const [jobId, setJobId] = useState('')

  const data = documents.filter(data => data.preAlertstatus === "COMPLETED");

  // const getJobId = (id) => {
  //   // console.log('the id', id)
  //   setJobId(id)
  // }

  const columns = [
    { name: "fileId", label: "File ID", options: { filter: true, sort: true } },
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "consigneeId",
      label: "Consignee",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ align: "center" })
      }
    },
    {
      name: "companyId",
      label: "Client",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ align: "center" })
      }
    },
    {
      name: "importType",
      label: "Import Type",
      options: { filter: true, sort: true }
    },
    {
      name: "airWayBill",
      label: "MAWB",
      hide: true,
      options: { display: false, filter: false, sort: false }
    },
    {
      name: "billOfLaden",
      label: "BOL/MAWB",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta.rowData, '......')
          return (
            <div>
              {tableMeta.rowData[5]} {tableMeta.rowData[6]}
            </div>
          );
        }
      }
    },
    {
      name: "containerInfo",
      label: "Containers",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta.rowData, '......')
          return (
            <div>
              {tableMeta.rowData[7].length}
            </div>
          );
        }
      }
    },
    {
      name: "expectedTimeOfArrival",
      label: "ETA",
      options: {
        display: false,
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const date1 = new Date(value);
          const date2 = new Date();
          const diffTime = Math.abs(date2 - date1);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          // return <Button>{diffDays + ' days'}</Button>

          if (date2 > date1) {
            return (
              <Button
                style={{
                  background: "black",
                  color: "white",
                  textTransform: "none",
                  fontSize: 13,
                  width: "80px"
                }}>
                {-diffDays + " days"}
              </Button>
            );
          } else {
            if (diffDays > 14) {
              return (
                <Button
                  style={{
                    background: "green",
                    color: "white",
                    textTransform: "none",
                    fontSize: 13,
                    width: "80px"
                  }}>
                  {diffDays + " days"}
                </Button>
              );
            } else if (diffDays >= 7) {
              return (
                <Button
                  style={{
                    background: "orange",
                    color: "white",
                    textTransform: "none",
                    fontSize: 13,
                    width: "80px"
                  }}>
                  {diffDays + " days"}
                </Button>
              );
            } else if (diffDays >= 1) {
              return (
                <Button
                  style={{
                    background: "red",
                    color: "white",
                    textTransform: "none",
                    fontSize: "13px",
                    fontWeight: "bold",
                    width: "80px"
                  }}>
                  {diffDays + " days"}
                </Button>
              );
            } else {
              return (
                <Button
                  style={{
                    background: "red",
                    color: "white",
                    textTransform: "none",
                    fontSize: "13px",
                    fontWeight: "bold",
                    width: "80px"
                  }}>
                  Today
                </Button>
              );
            }
          }
        }
      }
    },
    {
      name: "jobManagerId",
      label: "Job Manager",
      options: { filter: true, sort: true }
    },
    {
      name: "preAlertstatus",
      label: "Job Status",
      options: { filter: true, sort: true }
    },
    {
      name: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex", gap: ".8rem" }}>
              {/* <button
                style={{
                  border: "none",
                  background: "transparent",
                  cursor: "pointer"
                }}>
                {tableMeta.rowData[4] === "Sea Freight"
                  ? <Link
                      to={`/finance/job-management/edit-sea-jobs-form/${tableMeta
                        .rowData[1]}`}>
                      <EditIcon />
                    </Link>
                  : <Link
                      to={`/finance/job-management/edit-airway-bill-form/${tableMeta
                        .rowData[1]}`}>
                      <EditIcon />
                    </Link>}
              </button> */}
              <button
                style={{
                  border: "none",
                  background: "transparent",
                  cursor: "pointer"
                }}>
                {tableMeta.rowData[4] === "Sea Freight"
                  ? <Link
                      to={`/finance/job-management/pending-jobs/${tableMeta
                        .rowData[1]}`}>
                      <Visibility
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "black"
                        }}
                      />
                    </Link>
                  : <Link
                      to={`/finance/job-management/pending-jobs-airwybill/${tableMeta
                        .rowData[1]}`}>
                      <Visibility
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "black"
                        }}
                      />
                    </Link>}
              </button>
              {/* <button
                style={{
                  fontSize: "13px",
                  border: "none",
                  backgroundColor: "transparent",
                  cursor: "pointer"
                }}>
                {tableMeta.rowData[4] === "Sea Freight"
                  ? <Link
                      to={`/finance/job-management/pending-job-form/${tableMeta
                        .rowData[1]}`}
                      style={{ textDecoration: "none", color: "green" }}>
                      <UploadIcon />
                    </Link>
                  : <Link
                      to={`/finance/job-management/air-pending-job-form/${tableMeta
                        .rowData[1]}`}
                      style={{ textDecoration: "none", color: "green" }}>
                      <UploadIcon />
                    </Link>}
              </button> */}
            </div>
          );
        }
      }
    }
  ];

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    rowHover: false,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,

    tableBodyMaxHeight,
    sortOrder: {
      name: "fileId",
      direction: "desc"
    }
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 2,
              marginBottom: 8,
              paddingTop: 1,
              backgroundColor: "#033237"
            }
          }
        },

        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              "&:nth-child(odd)": {
                //backgroundColor: "#f6f6f6"
              },
              fontSize: "12px"
            }
          }
        }
      }
    });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="mt-4">
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              title={"Completed Job Table"}
              data={data ? data : "No Pending Jobs"}
              columns={columns}
              options={options}
              style={{ marginTop: "10px" }}
            />
          </ThemeProvider>
        </CacheProvider>
      </div>
    </DashboardLayout>
  );
};

export default CompletedJobTable;
