import { useState } from 'react'
import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import MUIDataTable from 'mui-datatables'
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

// Modal
import Modal from './Modal'

// Firebase Hook
import { useCollection } from '../../../hooks/useCollection'
import DeleteModal from './DeleteConsignee/DeleteModal'

const muiCache = createCache({
  key: 'mui-datatables',
  prepend: true,
})

const ConsigneeTable = () => {
  const { documents } = useCollection('consignee')

  const [responsive] = useState('standard')
  const [tableBodyHeight] = useState('auto')
  const [tableBodyMaxHeight] = useState('')
  const [searchBtn] = useState(true)
  const [downloadBtn] = useState(true)
  const [printBtn] = useState(true)
  const [viewColumnBtn] = useState(true)
  const [filterBtn] = useState(true)
  const [setOpen] = useState(false)
  const [consigneeId, setConsigneeId] = useState('')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const getConsigneeId = (id) => {
    // console.log('the id', id)
    setConsigneeId(id)
  }

  const columns = [
    {
      name: 'consigneeName',
      label: 'Consignee Name',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'id',
      hide: true,
      options: {
        display: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'prefix',
      label: 'Prefix',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'consigneeCategory',
      label: 'Category',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'companyEmail',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'Actions',
      options: {
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button
                style={{
                  border: 'none',
                  background: 'transparent',
                  cursor: 'pointer',
                }}
                onClick={(e) =>
                  getConsigneeId(tableMeta.rowData[1]) && handleClickOpen
                }
              >
                <Modal id={consigneeId} setConsigneeId={setConsigneeId} />
              </button>
              <button
                style={{
                  border: 'none',
                  padding: '8px',
                  background: 'transparent',
                  cursor: 'pointer',
                }}
                onClick={(e) => getConsigneeId(tableMeta.rowData[1])}
              >
                <DeleteModal
                  id={consigneeId}
                  setConsigneeId={setConsigneeId}
                />
              </button>
            </div>
          )
        },
      },
    },
  ]

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: false,
    filter: filterBtn,
    filterType: 'dropdown',
    rowHover: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: 'consigneeName',
      direction: 'asc',
    },
  }

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: 2,
              marginBottom: 8,
              paddingTop: 1,
              backgroundColor: '#033237',
              zIndex: 10
            },
          },
        },

        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              '&:nth-child(odd)': {
                //backgroundColor: "#f6f6f6"
              },
              fontSize: '12px',
            },
          },
        },
      },
    })
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="mt-4">
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              title={'Consignee Table'}
              data={documents}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </CacheProvider>
      </div>
    </DashboardLayout>
  )
}

export default ConsigneeTable
