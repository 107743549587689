import { BrowserRouter, Routes, Route } from 'react-router-dom'
import React from 'react'

import HR from 'views/HR/HRDashboard'
import MC from 'views/M&C/Dashboard'
import './style.css'
import Documentation from 'views/Documentation/Dashboard'
import Login from 'views/authentication/login'
import Admin from 'views/Admin/Home/Dashboard'
import Finance from 'views/Finance/Dashboard'
import COO from 'views/COO/Dashboard'
import MD from 'views/MD/Dashboard'
import ED from 'views/ED/Dashboard'
import PO from 'views/PayOfficer/Dashboard'
import Gate from 'views/Gate/Dashboard'
import ForgotPassword from 'views/authentication/forgotPassword'
import Terminal from 'views/Terminal/Dashboard'
import HODComercial from 'views/HODComercial/Dashboard'
import HODFinance from 'views/HODFinance/Dashboard'
import HODMarines from 'views/HODMarines/Dashboard'
import HODSafey from 'views/HODSafey/Dashboard'
import HODTransport from 'views/HODTransport/Dashboard'
import HODMC from 'views/HODMC/Dashboard'
import HODOperations from 'views/HODOperations/Dashboard'
import Staff from 'views/Staff/Dashboard'
import PA from 'views/PA/Dashboard'
import ResetPassword from 'views/authentication/ResetPassword'

// Hooks
import { useAuthContext } from './hooks/useAuthContext'
import ControlAdmin from 'views/Control/Dashboard'

function App() {
  const { authIsReady, user } = useAuthContext()

  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/human-resources/*"
              element={
                user && user.photoURL === 'Admin & Human Resource' ? (
                  <HR />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/monitoring-compliance/*"
              element={
                user && user.photoURL === 'Monitoring & Compliance' ? (
                  <MC />
                ) : (
                  <Login />
                )
              }
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
              path="/documentation/*"
              element={
                user && user.photoURL === 'Documentation' ? (
                  <Documentation />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/admin/*"
              element={
                user && user.photoURL === 'Managing Director' ? (
                  <Admin />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/finance/*"
              element={
                user && user.photoURL === 'Finance & Accounting' ? (
                  <Finance />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/head-of-department-operations/*"
              element={
                user && user.photoURL === 'Operation(H.O.D)' ? (
                  <HODOperations />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/head-of-department-monitoring-compliance/*"
              element={
                user && user.photoURL === 'Monitoring & Compliance(H.O.D)' ? (
                  <HODMC />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/head-of-department-commercial/*"
              element={
                user && user.photoURL === 'Commercial(H.O.D)' ? (
                  <HODComercial />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/head-of-department-finance/*"
              element={
                user && user.photoURL === 'Finance(H.O.D)' ? (
                  <HODFinance />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/head-of-department-marines/*"
              element={
                user && user.photoURL === 'Marines(H.O.D)' ? (
                  <HODMarines />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/head-of-department-safety/*"
              element={
                user && user.photoURL === 'Safety(H.O.D)' ? (
                  <HODSafey />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/head-of-department-transport/*"
              element={
                user && user.photoURL === 'Transport & Logistics(H.O.D)' ? (
                  <HODTransport />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/chief-operating-officer/*"
              element={
                user && user.photoURL === 'Chief Operating Officer' ? (
                  <COO />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/managing-director/*"
              element={
                user && user.photoURL === 'Managing Director' ? (
                  <MD />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/executive-director/*"
              element={
                user && user.photoURL === 'Executive Director' ? (
                  <ED />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/pay-officer/*"
              element={
                user && user.photoURL === 'Pay Officer' ? <PO /> : <Login />
              }
            />
            <Route
              path="/gate/*"
              element={user && user.photoURL === 'Gate' ? <Gate /> : <Login />}
            />
            <Route
              path="/terminal/*"
              element={
                user && user.photoURL === 'Terminal' ? <Terminal /> : <Login />
              }
            />
            <Route
              path="/personal-assistant/*"
              element={user && user.photoURL === 'PA' ? <PA /> : <Login />}
            />
            <Route
              path="/staff/*"
              element={user && user.photoURL !== '' ? <Staff /> : <Login />}
            />
            <Route
              path="/control/*"
              element={user && user.photoURL === 'Control' ? <ControlAdmin /> : <Login />}
            />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  )
}

export default App
