import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Paper, Typography } from "@mui/material";
import { useKingsooService } from "../../../../hooks/useKingsooServices";
// import { format } from 'date-fns'
import { EyeIcon } from "@heroicons/react/solid";
import FormFields from "views/HR/new-staff/components/FormField/FormField";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import PopSuccess from "../../Message/Success";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="₦"
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function ExportUpdate() {
  const [billOfLaden, setBillOfLaden] = useState("");
  const [airWayBill, setAirWayBill] = useState("");
  const [exportBill, setExportBill] = useState("");
  const [preALertImage, setPreAlertImage] = useState("");
  const [paarImage, setPaarImage] = useState("");

  const [paarValue, setPaarValue] = useState("");
  const {
    updateExport,
    updateAccessment,
    updateNotice,
    updateWork,
    updateCommercial,
    updatePaar
  } = useKingsooService();

  const [commercialInvoiceImage, setCommercialInvoiceImage] = useState("");
  const [commercialInvoiceValue, setCommercialInvoiceValue] = useState("");
  const [customDutyReceiptImage, setCustomDutyReceiptImage] = useState("");
  const [customDutyReceiptValue, setCustomDutyReceiptValue] = useState("");
  const [workOrder, setWorkOrder] = useState("");
  const [workOrderValue, setWorkOrderValue] = useState("");
  const [accessment, setAccessment] = useState("");
  const [accessmentValue, setAccessmentValue] = useState("");
  const [success, setSuccess] = useState("");

  const bill = async (e) => {
    e.preventDefault();

    try {
      await updateExport(id, {
        exportBill,
      });
      setExportBill("");
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };

  const customInfo = async (e) => {
    e.preventDefault();

    try {
      await updateAccessment(id, {
        customDutyReceiptImage,
        customDutyReceiptValue,
      });
      setCustomDutyReceiptImage("");
      setCustomDutyReceiptValue("");
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };

  const notice = async (e) => {
    e.preventDefault();

    try {
      await updateNotice(id, {
        accessment,
        accessmentValue,
      });
      setAccessment("");
      setAccessmentValue("");
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };

  const work = async (e) => {
    e.preventDefault();

    try {
      await updateWork(id, {
        workOrder,
        workOrderValue,
      });
      setWorkOrder("");
      setWorkOrderValue("");
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };

  const commercialInfo = async (e) => {
    e.preventDefault();

    try {
      await updateCommercial(id, {
        commercialInvoiceImage,
        commercialInvoiceValue,
      });
      setCommercialInvoiceImage("");
      setCommercialInvoiceValue("");
      setSuccess("Submitted Successfully");
    } catch (error) {
      console.log(error.message);
    }
  };

  const paarInfo = async (e) => {
    e.preventDefault();

    try {
      await updatePaar(id, {
        paarImage,
        paarValue,
      });
      setPaarImage("");
      setPaarValue("");
      setSuccess("Submitted Successfully");
    } catch (error) {
      // console.log(error.message)
    }
  };

  const { id } = useParams();
  const { getJob } = useKingsooService();
  // let navigate = useNavigate();

  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await getJob(id);

        setBillOfLaden(docSnap.data().billOfLaden);
        setAirWayBill(docSnap.data().airWayBill);
        // setPaarImage(docSnap.data().paarImage)
        // setIsPaarAvailable(docSnap.data().isPaarAvailable)
        // setJobManagerId(docSnap.data().jobManagerId)
        setPreAlertImage(docSnap.data().preALertImage);
        // setExpectedTimeOfArrival(docSnap.data().expectedTimeOfArrival)
        // setPaar(docSnap.data().paarImage)
      } catch (error) {
        // console.log(error)
      }
    };
    // console.log('na u know', id)
    if ((id !== undefined || id !== "") && id !== ":id") {
      detail();
      // console.log('id', id)
    }
  }, [id]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <React.Fragment>
        <Paper>
          <div className="mx-6">
            <Grid container spacing={3} className="pt-8">
              {billOfLaden && (
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle2" gutterBottom>
                    Bill Of Lading
                  </Typography>
                  <FormFields
                    name="billOfLaden"
                    value={billOfLaden}
                    fullWidth
                    disabled
                  />
                </Grid>
              )}
              {airWayBill && (
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle2" gutterBottom>
                    Air Way Bill
                  </Typography>
                  <FormFields
                    name="billOfLaden"
                    value={airWayBill}
                    fullWidth
                    disabled
                  />
                </Grid>
              )}
              {preALertImage && (
                <Grid item xs={12} sm={3}>
                  <Typography variant="subtitle2" gutterBottom>
                    Export File
                  </Typography>
                  <span className="relative z-0 darkGreen  shadow-sm w-full mx-auto rounded-md">
                    <a
                      href={preALertImage}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        type="button"
                        className="relative inline-flex darkGreen  items-center  px-4 py-3 rounded-md border border-gray-300  text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                      >
                        <EyeIcon
                          className="-ml-1 mr-2 h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                        View Document
                      </button>
                    </a>
                  </span>
                </Grid>
              )}

              <div className=" container mt-12 mx-12">
                <h2 className="pb-12  -mx-6 text-xl font-extrabold">
                  Upload Documents
                </h2>

                <Grid container spacing={3} mb={3} mt="1">
                  <Grid
                    container
                    component="form"
                    onSubmit={bill}
                    spacing={4}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>
                        Export Bill Of Lading
                      </Typography>
                      <input
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        id=""
                        type="file"
                        onChange={(e) => setExportBill(e.target.files[0])}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={4}
                    component="form"
                    onSubmit={commercialInfo}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>
                        Commercial Invoice
                      </Typography>
                      <input
                        type="file"
                        name="commercialInvoiceFile"
                        id=""
                        onChange={(e) =>
                          setCommercialInvoiceImage(e.target.files[0])
                        }
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Commercial Invoice Value
                      </Typography>
                      <FormFields
                        name="numberformat"
                        id="formatted-numberformat-input"
                        placeholder="Enter Amount"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) =>
                          setCommercialInvoiceValue(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    component="form"
                    onSubmit={paarInfo}
                    spacing={4}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>Upload Paar</Typography>
                      <input
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        id=""
                        type="file"
                        onChange={(e) => setPaarImage(e.target.files[0])}
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>Paar Value</Typography>
                      <FormFields
                        name="numberformat"
                        id="formatted-numberformat-input"
                        placeholder="Enter Amount"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) => setPaarValue(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    component="form"
                    onSubmit={customInfo}
                    spacing={4}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>
                        Duty Receipt
                      </Typography>
                      <input
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        id=""
                        type="file"
                        onChange={(e) =>
                          setCustomDutyReceiptImage(e.target.files[0])
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>Duty Value</Typography>
                      <FormFields
                        name="numberformat"
                        id="formatted-numberformat-input"
                        placeholder="Enter Amount"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) =>
                          setCustomDutyReceiptValue(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    component="form"
                    onSubmit={notice}
                    spacing={4}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>
                        Upload Assessment Notice
                      </Typography>
                      <input
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        id=""
                        type="file"
                        onChange={(e) => setAccessment(e.target.files[0])}
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Assessment Notice Value
                      </Typography>
                      <FormFields
                        name="numberformat"
                        id="formatted-numberformat-input"
                        placeholder="Enter Amount"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) => setAccessmentValue(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    component="form"
                    onSubmit={work}
                    spacing={4}
                    mb={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ fontSize: 15 }}>
                        Upload Work Order
                      </Typography>
                      <input
                        className="border border-gray-300 text-gray-900 text-base py-2
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        id=""
                        type="file"
                        onChange={(e) => setWorkOrder(e.target.files[0])}
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Typography sx={{ fontSize: 15 }}>
                        Work Order Value
                      </Typography>
                      <FormFields
                        name="numberformat"
                        id="formatted-numberformat-input"
                        placeholder="Enter Amount"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) => setWorkOrderValue(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} mt={0.7}>
                      <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-4 py-1.5
                          darkGreen border border-transparent text-base font-medium
                          rounded-md shadow-sm text-white focus:outline-none focus:ring-2
                          focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Upload
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </div>
        </Paper>
        {success && <PopSuccess message={success} />}
      </React.Fragment>
    </DashboardLayout>
  );
}
