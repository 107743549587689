/** 
  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from '@mui/material/Icon'
import Home from './Home/index'
import CreateClient from './createClient'
import CreateConsignee from './createConsignee'
import PendingJobs from './pendingJobs'
import ClosedJobs from './CompletedJobs/index'
import JobFiles from './jobFiles'
import JobTables from './CreateJobTable'
import ClientTable from './ClientTable'
import JobRelated from './MakeRequest/JobRelated'
import NonJobRelated from './MakeRequest/NonJobRelated'
import Approved from './Approved'
import Pending from './Pending'
import Declined from './Declined'
import ExportJobTable from './Export'
import PendingExportModal from './Export/ExportView'
import AirwayBillModal from './AirwayBillModal'
import PendingJobTable from './PendingJobFiles'
import PendingJobModal from './PendingJobFiles/Modal/PendingJobModal'

const routes = [
  {
    type: 'singleRoute',
    singleRoute: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: 'dashboard',
    component: <Home />,
  },
  { type: 'divider', key: 'divider-0' },

  {
    type: 'title',
    title: 'FREIGHT FORWARDING',
    key: 'Documentation',
  },
  {
    type: 'collapse',
    name: 'Client Management',
    key: 'client-management',
    icon: <Icon fontSize="small">groups</Icon>,
    collapse: [
      {
        name: 'Create Client',
        key: 'create-client',
        route: 'client-management/create-client/:id',
        icon: <Icon fontSize="small">group_add</Icon>,
        component: <CreateClient />,
      },
      {
        name: 'Create Consignee',
        key: 'create-consignee',
        route: 'client-management/create-consignee/:id',
        icon: <Icon fontSize="small">group_add</Icon>,
        component: <CreateConsignee />,
      },
      {
        name: 'Consignee',
        key: 'consignee',
        route: 'client-management/consignee',
        icon: <Icon fontSize="small">group</Icon>,
        component: <JobTables />,
      },
      {
        name: 'Client',
        key: 'client',
        route: 'client-management/client',
        icon: <Icon fontSize="small">group</Icon>,
        component: <ClientTable />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Job File Management',
    key: 'file-management',
    icon: <Icon fontSize="small">folder</Icon>,
    collapse: [
      {
        name: 'Pending Job Files',
        key: 'pending-job-files',
        route: 'file-management/pending-job-files',
        icon: <Icon fontSize="small">work_history</Icon>,
        component: <PendingJobTable />,
      },
      {
        name: 'Completed Job Files',
        key: 'completed-job-files',
        route: 'file-management/completed-job-files',
        icon: <Icon fontSize="small">work_off</Icon>,
        component: <ClosedJobs />,
      },
      {
        name: 'Export Jobs',
        key: 'export-job',
        route: 'file-management/export-job',
        icon: <Icon fontSize="small">file_upload</Icon>,
        component: <ExportJobTable />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Make Request',
    key: 'make-request',
    icon: <Icon fontSize="small">queue</Icon>,
    collapse: [
      {
        name: 'Job Related',
        key: 'job-related',
        route: 'make-request/job-related',
        icon: <Icon fontSize="small">content_paste</Icon>,
        component: <JobRelated />,
      },
      {
        name: 'Non Job Related',
        key: 'non-job-related',
        route: 'make-request/non-job-related',
        icon: <Icon fontSize="small">content_paste_off</Icon>,
        component: <NonJobRelated />,
      },
    ]
  },
  {
    type: 'collapse',
    name: 'Financial Requests',
    key: 'financial-requests',
    icon: <Icon fontSize="small">reorder</Icon>,
    collapse: [
      {
        name: "Approved",
        key: "approved",
        route: "request-status/approved",
        icon: <Icon fontSize="small">check_circle_outline</Icon>,
        component: <Approved />,
      },
      {
        name: "Pending",
        key: "pending",
        route: "request-status/pending",
        icon: <Icon fontSize="small">hourglass_bottom</Icon>,
        component: <Pending />,
      },
      {
        name: "Declined",
        key: "declined",
        route: "request-status/declined",
        icon: <Icon fontSize="small">cancel</Icon>,
        component: <Declined />,
      },
    ],
  },
  {
    type: 'hiddenRoute',
    singleRoute: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: 'file-management/pending-job-files/:id',
    component: <PendingJobModal />,
  },
  {
    type: "hiddenRoute",
    name: "ExportJobs",
    key: "pending-export-jobs",
    route: "'file-management/pending-export-jobs/:id",
    icon: <Icon fontSize="medium">company</Icon>,
    component: <PendingExportModal />
  },
  {
    type: 'hiddenRoute',
    name: 'PendingJobs',
    key: 'pending-jobs',
    route: 'file-management/pending-jobs-airwybill/:id',
    icon: <Icon fontSize="medium">company</Icon>,
    component: <AirwayBillModal />,
  },
  { type: 'divider', key: 'divider-1' },
  // { type: "title", title: "Docs", DTI: "title-docs" },
]

export default routes
