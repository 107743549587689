/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react'

// react-router components
import { useLocation, Route, Routes, Navigate } from 'react-router-dom'

// @mui material components
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

// Kingsoo React routes
import {
    useMaterialUIController,
    setMiniSidenav,
} from 'context'
import logo from 'assets/images/logo.png'
import routes from './routes'

// Kingsoo context
import themeDark from '../../assets/theme-dark'

// Images
import theme from '../../assets/theme'
import Sidenav from '../../customs/Sidenav'

export default function HODComercial() {
    const [controller, dispatch] = useMaterialUIController()
    const {
        miniSidenav,
        direction,
        layout,
        sidenavColor,
        darkMode,
    } = controller
    const [onMouseEnter, setOnMouseEnter] = useState(false)
    const { pathname } = useLocation()

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse)
            }

            if (route.route) {
                return (
                    <Route
                        exact
                        path={route.route}
                        element={route.component}
                        key={route.key}
                    />
                )
            }

            return null
        })

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false)
            setOnMouseEnter(true)
        }
    }

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true)
            setOnMouseEnter(false)
        }
    }

    // Change the openConfigurator state

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute('dir', direction)
    }, [direction])

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
    }, [pathname])

    return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            {layout === 'dashboard' && (
                <>
                    <Sidenav
                        color={sidenavColor}
                        brandName="Kingsoo"
                        brand={logo}
                        routes={routes}
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                    />
                    <Routes>
                        {getRoutes(routes)}
                        <Route
                            exact path="*"
                            element={<Navigate to="dashboard" replace={true} />}
                        />
                    </Routes>
                </>
            )}
        </ThemeProvider>
    )
}
