import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Paper, CircularProgress } from '@mui/material'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import documentationData from './data/data'

import MDButton from 'components/MDButton'
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

// Firebase Hook
import { useFirestore } from 'hooks/useFirestore'
import {useKingsooService} from '../../hooks/useKingsooServices'
import FormFields from 'layouts/pages/account/components/FormField'

import PopSuccess from '../PopMessage/PopSuccess'

const CreateConsignee = () => {
  const [reset, setReset] = useState(false)
  const [consigneeName, setConsigneeName] = useState('')
  const [consigneeType, setConsigneeType] = useState('')
  const [prefix, setPrefix] = useState('')
  const [rcNumber, setRcNumber] = useState('')
  const [consigneeCategory, setConsigneeCategory] = useState('')
  const [businessCategory, setBusinessCategory] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [businessAddress, setBusinessAddress] = useState('')
  const [altPhone, setAltPhone] = useState('')
  const [wareHouseAddress, setWareHouseAddress] = useState('')
  const [companyEmail, setCompanyEmail] = useState('')
  const [tin, setTin] = useState('')
  const [registeredCompanyAddress, setRegisteredCompanyAddress] = useState('')
  const [AltCompanyPhone, setAltCompanyPhone] = useState('')
  const [companyPhone, setCompanyPhone] = useState('')
  const [contactName, setContactName] = useState('')
  const [jobOwner, setJobOwner] = useState('')
  const [jobOwnerContact, setJobOwnerContact] = useState('')

  const { id } = useParams()
  let navigate = useNavigate()
  const {updateConsignee, getConsignee} = useKingsooService()

  const detail = async () => {
    try {
      const docSnap = await getConsignee(id)
      // console.log(docSnap.data())
      setConsigneeName(docSnap.data().consigneeName)
      setCompanyPhone(docSnap.data().companyPhone)
      setConsigneeType(docSnap.data().consigneeType)
      setPrefix(docSnap.data().prefix)
      setRcNumber(docSnap.data().rcNumber)
      setConsigneeCategory(docSnap.data().consigneeCategory)
      setBusinessCategory(docSnap.data().businessCategory)
      setCompanyAddress(docSnap.data().companyAddress)
      setPhone(docSnap.data().phone)
      setBusinessAddress(docSnap.data().businessAddress)
      setAltPhone(docSnap.data().altPhone)
      setCompanyEmail(docSnap.data().companyEmail)
      setWareHouseAddress(docSnap.data().wareHouseAddress)
      setRegisteredCompanyAddress(docSnap.data().registeredCompanyAddress)
      setAltCompanyPhone(docSnap.data().AltCompanyPhone)
      setContactName(docSnap.data().contactName)
      setTin(docSnap.data().tin)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // console.log('na u know', id)
    if (id !== undefined || id !== '') {
      detail()
      // console.log('id', id)
    }
  }, [id])

  const { addDocument, message, isPending } = useFirestore('consignee')

  const handleSubmit = async (e) => {
    e.preventDefault()

    const newConsignee = {
      consigneeName,
      consigneeType,
      prefix,
      rcNumber,
      consigneeCategory,
      businessCategory,
      companyAddress,
      phone,
      businessAddress,
      altPhone,
      wareHouseAddress,
      companyEmail,
      tin,
      contactName,
      companyPhone,
      AltCompanyPhone,
      registeredCompanyAddress,
    }

    try {
      if ((id !== undefined || id !== '') && id !== ':id') {
        await updateConsignee(id, newConsignee)
        // setStaffId('')
        if (message) {
          e.target.reset()
        }
        setReset(true)
      } else {
        await addDocument(newConsignee)
        if (message) {
          e.target.reset()
        }
      }
    } catch (error) {
      console.log('msg', error.message)
    }
  }

  useEffect(() => {
    if (message || reset) {
      setTimeout(() => {
        navigate('/documentation/client-management/consignee', {
          replace: true,
        })
      }, 5000)
    }
  }, [message, reset, navigate])

  // if (message || reset) {
  //   navigate('/documentation/client-management/consignee', { replace: true })
  // }

  const { dropdownData } = documentationData
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox component="form" onSubmit={handleSubmit} noValidate>
        <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
          <MDBox>
            <MDTypography
              variant="h5"
              fontWeight="bold"
              sx={{ borderBottom: 1, pb: 1, pt: 4 }}
            >
              Consignee Details
            </MDTypography>
          </MDBox>
          <MDBox mt={3}>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} md={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Client's Name{' '}
                </MDTypography>
                <TextField
                  value={consigneeName}
                  name="consigneeName"
                  fullWidth
                  placeholder="Enter Consignee Name"
                  onChange={(e) => setConsigneeName(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Consignee Type{' '}
                </MDTypography>
                <MDBox>
                  <Autocomplete
                    defaultValue="Client Type"
                    value={consigneeType}
                    options={dropdownData.type}
                    onChange={(e, newValue) => {
                      setConsigneeType(newValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="consigneeType"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Select Consignee Type"
                      />
                    )}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Consignee Prefix{' '}
                </MDTypography>
                <TextField
                  value={prefix}
                  name="ConsigneePrefix"
                  fullWidth
                  placeholder="Enter Consignee Prefix"
                  onChange={(e) => setPrefix(e.target.value)}
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} md={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  RC/BN Number{' '}
                </MDTypography>
                <TextField
                  value={rcNumber}
                  name="EmployeeID"
                  fullWidth
                  placeholder="Enter RC/BN Number"
                  onChange={(e) => setRcNumber(e.target.value)}
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Client Category{' '}
                </MDTypography>
                <MDBox>
                  <Autocomplete
                    defaultValue="Client Category"
                    value={consigneeCategory}
                    options={dropdownData.clientCategory}
                    onChange={(e, newVal) => {
                      setConsigneeCategory(newVal)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        placeholder="Select Client Category"
                      />
                    )}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Business Category{' '}
                </MDTypography>
                <MDBox>
                  <Autocomplete
                    defaultValue="Business Category"
                    value={businessCategory}
                    options={dropdownData.businessCategory}
                    onChange={(e, newVal) => {
                      setBusinessCategory(newVal)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        placeholder="Select Business Category"
                      />
                    )}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Tax Identification Number{' '}
                </MDTypography>
                <FormFields
                  name="TaxIdentificationNumber"
                  placeholder="Enter TIN Number"
                  type="number"
                  fullWidthn
                  onChange={(e) => setTin(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Company Phone Number
                </MDTypography>
                <FormFields
                  name="CompanyPhoneNumber"
                  placeholder="Enter Phone Number"
                  type="number"
                  onChange={(e) => setPhone(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Alternative Company Phone Number{' '}
                </MDTypography>
                <FormFields
                  name="CompanyPhoneNumber"
                  placeholder="Enter Phone Number"
                  type="number"
                  onChange={(e) => setAltPhone(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Registered Company Address{' '}
                </MDTypography>
                <FormFields
                  name="RegisteredCompanyAddress"
                  placeholder="Enter Company Address"
                  onChange={(e) => setCompanyAddress(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Business Address
                </MDTypography>
                <FormFields
                  name="BusinessAddress"
                  placeholder="Enter Business Address"
                  onChange={(e) => setBusinessAddress(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Ware House Address
                </MDTypography>
                <FormFields
                  name="WareHouseAddress"
                  placeholder="Enter Ware House Address"
                  onChange={(e) => setWareHouseAddress(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} mb={4}>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Company Email Address{' '}
                </MDTypography>
                <FormFields
                  name="CompanyEmailAddress"
                  placeholder="Enter Company Email Address"
                  onchange={(e) => setCompanyEmail(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>Job Owner </MDTypography>
                <FormFields
                  value={jobOwner}
                  name="JobOwner"
                  placeholder="Enter Ware House Address"
                  onChange={(e) => setJobOwner(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography sx={{ fontSize: 13 }}>
                  Job Owner's Contact{' '}
                </MDTypography>
                <FormFields
                  value={jobOwnerContact}
                  name="JobOwnerContact"
                  placeholder="Enter Ware House Address"
                  type="number"
                  onChange={(e) => setJobOwnerContact(e.target.value)}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                />
              </Grid>
            </Grid>
            <MDBox>
              <MDTypography
                variant="h5"
                fontWeight="bold"
                sx={{ borderBottom: 1, pb: 1, pt: 4 }}
              >
                Contact Person Details
              </MDTypography>
            </MDBox>
            <MDBox sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Contact Name{' '}
                  </MDTypography>
                  <TextField
                    value={contactName}
                    name="ContactName"
                    fullWidth
                    placeholder="Enter Contact Name"
                    onChange={(e) => setContactName(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Company Phone Number{' '}
                  </MDTypography>
                  <TextField
                    value={companyPhone}
                    name="CompanyPhoneNumber"
                    fullWidth
                    placeholder="Enter Phone Number"
                    type="number"
                    onChange={(e) => setCompanyPhone(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Alternative Company Phone Number{' '}
                  </MDTypography>
                  <TextField
                    value={AltCompanyPhone}
                    name="altCompanyPhone"
                    fullWidth
                    placeholder="Enter Phone Number"
                    type="number"
                    onChange={(e) => setAltCompanyPhone(e.target.value)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MDTypography sx={{ fontSize: 13 }}>
                    Registered Company Address{' '}
                  </MDTypography>
                  <TextField
                    value={registeredCompanyAddress}
                    name="CompanyAddress"
                    fullWidth
                    placeholder="Enter  Company Address"
                    onChange={(e) =>
                      setRegisteredCompanyAddress(e.target.value)
                    }
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                  />
                </Grid>
              </Grid>
            </MDBox>

            <MDBox
              sx={{ pt: 6, display: 'flex', justifyContent: 'flex-end' }}
            >
              {!isPending &&
                ((id !== undefined || id !== '') && id !== ':id' ? (
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="dark"
                    sx={{ width: '180px' }}
                  >
                    Update Consginee
                  </MDButton>
                ) : (
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="dark"
                    sx={{ width: '180px' }}
                  >
                    Create Consginee
                  </MDButton>
                ))}
              {isPending && (
                <MDButton
                  variant="gradient"
                  color="dark"
                  disabled
                  sx={{ width: '180px' }}
                >
                  <CircularProgress color="white" size={20} />
                </MDButton>
              )}
            </MDBox>
          </MDBox>
        </Paper>
      </MDBox>
      {message && <PopSuccess />}
    </DashboardLayout>
  )
}

export default CreateConsignee
