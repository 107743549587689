const selectData = {
  clientType: [
    "Sole Proprietorship/Enterprise",
    "Limited Liability Company (Ltd)",
    "Public Limited Company(Plc)",
  ],
  clientCategory: [
    "Corporate Client",
    "Covenntional Client",
    "Agent",
    "Individual Clients",
  ],
  businessCategory: [
    "Agriculture",
    "Education/Training",
    "Engineering/Construction/Fashion/Beauty",
    "Financial Services",
    "FMCG",
    "Food"
  ]
};

export default selectData;
