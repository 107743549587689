import DashboardLayout from 'customs/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'customs/Navbars/DashboardNavbar'
import React from 'react'
import { Paper, Grid, Autocomplete, TextField } from '@mui/material'
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import FormFields from 'layouts/pages/account/components/FormField'
import { selectData } from './data'
import { CloudUpload } from '@mui/icons-material'


const JobRelated = () => {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
                <MDBox mt={5} component="form">
                    <MDTypography
                        variant="h5"
                        fontWeight="bold"
                        sx={{ borderBottom: 1, pb: 1, pt: 4 }}
                    >
                        Job Related Financial Request Form
                    </MDTypography>
                    <MDBox mt={1.625}>
                        <Grid container spacing={3} mb={4}>
                            <Grid item xs={12} sm={4}>
                                <MDTypography sx={{ fontSize: 13 }}>
                                    Payment Type{' '}
                                </MDTypography>
                                <MDBox>
                                    <FormFields
                                        name="fileId"
                                        placeholder="Job File Id"
                                    // value={fileId}
                                    // onChange={(e) => setFileId(e.target.value)}
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MDTypography sx={{ fontSize: 13 }}>
                                    Job File ID{' '}
                                </MDTypography>
                                <MDBox>
                                    <Autocomplete
                                        defaultValue="Select File ID"
                                        options={selectData.paymentType}
                                        renderInput={(params) => (
                                            <FormFields
                                                {...params}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MDTypography sx={{ fontSize: 13 }}>
                                    Bill of Lading/Master Airway Bill{' '}
                                </MDTypography>
                                <FormFields
                                    name="BOL"
                                    placeholder="Enter BOL"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} mb={4}>
                            <Grid item xs={12} sm={4}>
                                <MDTypography sx={{ fontSize: 13 }}>
                                    Consignee{' '}
                                </MDTypography>
                                <FormFields
                                    name="Consignee"
                                    placeholder="Auto Generated"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MDTypography sx={{ fontSize: 13 }}>
                                    Client{' '}
                                </MDTypography>
                                <MDBox>
                                    <FormFields
                                        name="Client"
                                        placeholder="Auto Generated"
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MDTypography sx={{ fontSize: 13 }}>
                                    Number of containers{' '}
                                </MDTypography>
                                <FormFields
                                    name="Containers"
                                    placeholder="Auto Generated"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} mb={4}>
                            <Grid item xs={12} sm={4}>
                                <MDTypography sx={{ fontSize: 13 }}>
                                    Invoice Number{' '}
                                </MDTypography>
                                <MDBox>
                                    <FormFields
                                        name="InvoiceNumber"
                                        placeholder="Enter Invoice Number"
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MDTypography sx={{ fontSize: 13 }}>
                                    Bulk Cargo{' '}
                                </MDTypography>
                                <MDBox>
                                    <FormFields
                                        name="BulkCargo"
                                        placeholder="Enter Bulk Cargo"
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MDTypography sx={{ fontSize: 13 }}>
                                    Request For{' '}
                                </MDTypography>
                                <FormFields
                                    name="RequestFor"
                                    placeholder="Select Request For"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} mb={4}>
                            <Grid item xs={12} sm={4}>
                                <MDTypography sx={{ fontSize: 13 }}>
                                    Payment Value{' '}
                                </MDTypography>
                                <MDBox>
                                    <FormFields
                                        type="number"
                                        name="PaymentValue"
                                        placeholder="N0.00"
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MDTypography sx={{ fontSize: 13 }}>
                                    Upload Pro Forma Invoice{' '}
                                </MDTypography>
                                <MDBox>
                                    <FormFields
                                        name="ProFormaInvoice"
                                        placeholder="Select Choice"
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MDTypography sx={{ fontSize: 13 }}>
                                    Upload Pro Forma Invoice{' '}
                                </MDTypography>
                                <div className="paar__upload__input">
                                    <label htmlFor="fileInput"> Upload Document</label>
                                    <input
                                        className="file"
                                        type="file"
                                        id="fileInput"
                                    />
                                    <label htmlFor="fileInput">
                                        <CloudUpload
                                            style={{ height: '30px', width: '30px' }}
                                        />
                                    </label>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} mb={4}>
                            <Grid item xs={12}>
                                <MDTypography sx={{ fontSize: 13 }}>
                                    Description{' '}
                                </MDTypography>
                                <TextField
                                    fullWidth
                                    placeholder='Enter your description here...'
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                    </MDBox>
                    <MDBox
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: '2rem',
                        }}
                    >
                        <MDButton
                            style={{ fontSize: '12px' }}
                            variant="gradient"
                            color="dark"
                            type="submit"
                        >
                            Submit Request
                        </MDButton>
                    </MDBox>
                </MDBox>
            </Paper>
        </DashboardLayout>
    )
}

export default JobRelated